import { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { List, Paper } from "@mui/material";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import NoteListItem from "./NoteListItem";
import { reorderNotes } from "stores/modules/entities.room.notes/operations";

type NoteListProps = {
  roomId: string;
};

const NoteListContainer = (props: NoteListProps) => {
  const dispatch = useAppDispatch();
  const noteIds = useAppSelector(store.getRoomOrderdNoteIds);

  const onReorder = useCallback(
    (order: DropResult) => {
      dispatch(reorderNotes(props.roomId, order));
    },
    [props.roomId, dispatch]
  );

  return (
    <DragDropContext onDragEnd={onReorder}>
      <Droppable
        droppableId="droppable"
        getContainerForClone={() => document.getElementById("root") as any}
        renderClone={(provided) => (
          <Paper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          />
        )}
      >
        {(provided) => (
          <List
            {...provided.droppableProps}
            ref={provided.innerRef}
            component="div"
          >
            {noteIds.map((noteId, index) => (
              <Draggable key={noteId} draggableId={noteId} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <NoteListItem noteId={noteId} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default memo(NoteListContainer);
