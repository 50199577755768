import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export type Emote = {
  id: string;
  kind: string;
  posPercent: number;
};

const entityAdapter = createEntityAdapter<Emote, string>({
  selectId: (emote) => emote.id,
});

const slice = createSlice({
  name: "app/emotes",
  initialState: entityAdapter.getInitialState(),
  reducers: {
    add: entityAdapter.addOne,
    remove: entityAdapter.removeOne,
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
