import { db } from "initializer";
import { DefaultThunk } from "stores";
import { getUid } from "../app.user/selectors";
import { actions } from "./slice";
import { DiceSkinSettings, UpdateUserSetting } from "./records";
import {
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

const userRef = (uid: string) => {
  return doc(db, "users", uid);
};

export const updateUserSetting =
  (updateUserSetting: UpdateUserSetting): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const uid = getUid(state);
    if (uid == null) {
      return;
    }

    setDoc(userRef(uid), updateUserSetting, { merge: true });
  };

export const updateUserAgreedTermsAt = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const uid = getUid(state);
  if (uid == null) {
    return;
  }

  setDoc(userRef(uid), { agreedTermsAt: serverTimestamp() }, { merge: true });
};

export const updateUserMarkedGamesAt = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const uid = getUid(state);
  if (uid == null) {
    return;
  }

  setDoc(userRef(uid), { markedGamesAt: serverTimestamp() }, { merge: true });
};

export const updateUserAccessStatus = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const uid = getUid(state);
  const isAnonymous = state.app.user.isAnonymous;
  if (uid == null) {
    return;
  }

  setDoc(
    userRef(uid),
    { lastAccessAt: serverTimestamp(), isAnonymous },
    { merge: true }
  );
};

export const updateUserDiceSkin =
  (diceSkinId: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const uid = getUid(state);
    if (uid == null) {
      return;
    }

    const ref = userRef(uid);
    const diceSkin: DiceSkinSettings = {
      d4: diceSkinId,
      d6: diceSkinId,
      d8: diceSkinId,
      d10: diceSkinId,
      d12: diceSkinId,
      d20: diceSkinId,
      d100: diceSkinId,
    };
    setDoc(ref, { diceSkin }, { merge: true });
  };

export const subscribeUserSetting =
  (uid: string): DefaultThunk<() => void> =>
  (dispatch) => {
    return onSnapshot(userRef(uid), (snapshot) => {
      const data: UpdateUserSetting = snapshot.data() || {};
      dispatch(actions.update({ uid, setting: data }));
    });
  };

export const loadUserSetting =
  (uid: string): DefaultThunk =>
  async (dispatch) => {
    const doc = await getDoc(userRef(uid));
    dispatch(actions.update({ uid, setting: doc.data() || {} }));
  };
