import { createSelector } from "@reduxjs/toolkit";
import { DefaultRootState } from "stores";

export const getRoomDeckIds = (state: DefaultRootState) => {
  return state.entities.roomDecks.ids;
};

export const getRoomDecks = (state: DefaultRootState) => {
  return state.entities.roomDecks.entities;
};

export const getRoomDeckById = (state: DefaultRootState, deckId: string) => {
  return state.entities.roomDecks.entities[deckId];
};

type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
  coverImageUrl?: string | null;
};

const getPositionProps = (_: any, props: Position): Position => props;

export const getRoomDeckIdByPosition = createSelector(
  [getRoomDeckIds, getRoomDecks, getPositionProps],
  (deckIds, decks, position): string | undefined => {
    for (const id of deckIds) {
      const deck = decks[id];
      if (
        deck.x < position.x + 1 &&
        deck.x > position.x - 1 &&
        deck.y < position.y + 1 &&
        deck.y > position.y - 1 &&
        deck.width === position.width &&
        deck.height === position.height &&
        (deck.coverImageUrl === position.coverImageUrl ||
          (deck.coverImageUrl === "/grey.png" && !position.coverImageUrl) ||
          (!deck.coverImageUrl && position.coverImageUrl === "/grey.png") ||
          position.coverImageUrl === undefined)
      ) {
        return id;
      }
    }
  }
);

const getDeckIdParams = (_: unknown, deckId: string): string => deckId;

export const getRoomNearDeckId = createSelector(
  [getRoomDeckIds, getRoomDecks, getDeckIdParams],
  (deckIds, decks, deckId): string | undefined => {
    const originDeck = decks[deckId];
    if (!originDeck) {
      return;
    }

    for (const id of deckIds) {
      if (id === deckId) {
        continue;
      }

      const deck = decks[id];
      if (
        deck.x < originDeck.x + 1 &&
        deck.x > originDeck.x - 1 &&
        deck.y < originDeck.y + 1 &&
        deck.y > originDeck.y - 1 &&
        deck.width === originDeck.width &&
        deck.height === originDeck.height
      ) {
        return id;
      }
    }
  }
);
