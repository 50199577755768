import { useCallback, memo, MouseEvent } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemAvatar,
  Avatar,
} from "@mui/material";

import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import PublicIcon from "@mui/icons-material/Public";
import EditIcon from "@mui/icons-material/Edit";

type MediumListItemProps = {
  mediumId: string;
  onEdit: (props: { anchorEl: Element; mediumId: string }) => void;
};

const MediumListItem = ({ mediumId, onEdit }: MediumListItemProps) => {
  const d = useAppDispatch();
  const dir = useAppSelector((state) =>
    store.getAppState(state, "openRoomMediaDir")
  );
  const medium = useAppSelector((state) =>
    store.getMediumById(state, mediumId)
  );

  const onSelect = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onEdit({
        anchorEl: e.currentTarget,
        mediumId,
      });
    },
    [mediumId, onEdit]
  );
  const onPlay = useCallback(() => {
    if (medium.url) {
      if (dir === "bgm") {
        d(
          store.updateCurrentRoomField({
            mediaUrl: medium.url,
            mediaType: "file",
            mediaName: medium.name,
            mediaVolume: medium.volume,
            mediaRepeat: medium.loop,
          })
        );
      } else if (dir === "sound") {
        d(
          store.updateCurrentRoomField({
            soundUrl: medium.url,
            soundName: medium.name,
            soundVolume: medium.volume,
            soundRepeat: medium.loop,
          })
        );
      } else if (dir === "effect") {
        d(
          store.updateCurrentRoomEffect({
            soundUrl: medium.url,
            soundName: medium.name,
          })
        );
      }
    }
  }, [medium, dir, d]);

  if (!medium) {
    return null;
  }
  return (
    <ListItem button onClick={onPlay} disabled={!medium.url} component="div">
      <ListItemAvatar>
        <Avatar>
          <Avatar>
            {medium.external ? <PublicIcon /> : <LibraryMusicIcon />}
          </Avatar>
        </Avatar>
      </ListItemAvatar>
      <ListItemTextWrapped
        primary={medium.name}
        // secondary={`volume:${medium.volume} loop:${medium.loop ? "on" : "off"}`}
      />
      <ListItemSecondaryAction>
        <IconButton onClick={onSelect} edge="end" size="large">
          <EditIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const ListItemTextWrapped = styled(ListItemText)`
  word-wrap: break-word;
`;

export default memo(MediumListItem);
