export type Effect = {
  _id?: string;
  name: string;
  soundUrl: string | null;
  soundVolume: number;
  soundName: string;
  imageUrl: string | null;
  owner: string | null;
  order: number;
  active: boolean;
  playTime: number;
  createdAt: number;
  updatedAt: number;
};
export type UpdateEffect = {
  _id?: string;
  name?: string;
  soundUrl?: string | null;
  soundVolume?: number;
  soundName?: string;
  imageUrl?: string | null;
  owner?: string | null;
  order?: number;
  active?: boolean;
  playTime?: number;
  createdAt?: number;
  updatedAt?: number;
};

export const EffectRecord = (data: UpdateEffect): Effect => {
  return {
    name: data.name ?? "",
    soundUrl: data.soundUrl ?? null,
    soundVolume: data.soundVolume ?? 0.2,
    soundName: data.soundName ?? "",
    imageUrl: data.imageUrl ?? null,
    owner: data.owner ?? "",
    order: data.order ?? 0,
    active: data.active ?? false,
    playTime: data.playTime ?? Date.now(),
    createdAt: data.createdAt ?? Date.now(),
    updatedAt: data.updatedAt ?? Date.now(),
  };
};
