import { memo, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  Dialog,
  TextField,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { appStateMutate } from "stores/modules/app.state/operations";
import { updateRoomMessage } from "stores/modules/entities.room.messages/operations";

type FormData = {
  text: string;
};

const NoteMessageDetail = () => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomMessage")
  );
  const messageId = useAppSelector(
    (state) => store.getAppState(state, "openRoomMessageId") || ""
  );
  const messageText = useAppSelector(
    (state) => store.getRoomMessageById(state, messageId).text
  );

  const { register, handleSubmit, reset } = useForm<FormData>({
    defaultValues: { text: "" },
  });

  useEffect(() => {
    reset({ text: messageText });
  }, [messageText, reset]);

  const onSubmit = useCallback(
    (values: FormData) => {
      if (messageText !== values.text) {
        dispatch(
          updateRoomMessage(messageId, {
            text: values.text,
          })
        );
      }
    },
    [messageId, messageText, dispatch]
  );

  const onClose = useCallback(() => {
    handleSubmit(onSubmit)();
    dispatch(
      appStateMutate((state) => {
        state.openRoomMessage = false;
      })
    );
  }, [onSubmit, handleSubmit, dispatch]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogContent style={{ padding: 0 }}>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <TextField
            variant="filled"
            label={t("メッセージ編集")}
            multiline
            fullWidth
            maxRows={24}
            rows={4}
            {...register("text")}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose} color="primary">
          {t("保存")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Form = styled.form`
  display: block;
`;

export default memo(NoteMessageDetail);
