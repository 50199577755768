import { createSelector } from "reselect";
import { Message } from "./records";
import { DefaultRootState } from "stores";
import { Timestamp } from "firebase/firestore";
import { getJoinedMessageGroups } from "../entities.rooms/selectors";
import { getRoomMemberDirectMessageTabs } from "../app.state/selectors";
import i18n from "i18next";

const TAB_NAMES = {
  main: i18n.t("メイン"),
  info: i18n.t("情報"),
  other: i18n.t("雑談"),
};
const emptyMessage: Message = {
  to: null,
  toName: "",
  from: "",
  name: "",
  iconUrl: null,
  color: "#888888",
  imageUrl: null,
  text: "",
  type: "text",
  extend: {},
  removed: false,
  edited: false,
  channel: "main",
  channelName: "メイン",
  createdAt: Timestamp.now(),
  updatedAt: Timestamp.now(),
};
export const getRoomMessageById = (
  state: DefaultRootState,
  messageId: string
) => {
  return state.entities.roomMessages.entities[messageId] || emptyMessage;
};

export const getRoomMessageIds = (state: DefaultRootState) => {
  return state.entities.roomMessages.ids;
};

export const getRoomMessageIdsByTab = (
  state: DefaultRootState,
  tab: string
) => {
  return state.entities.roomMessages.idsGroupBy[tab] || [];
};

export const getRoomMessageIdsByType = (state: DefaultRootState) => {
  const tab = state.app.state.roomChatTab;
  return state.entities.roomMessages.idsGroupBy[tab] || [];
};

const getAddedRoomMessageIds = (state: DefaultRootState) => {
  return state.entities.roomMessages.addedIds;
};
const getToProp = (_: unknown, props: { to: string | null }) => {
  return props.to;
};
const getFromProp = (_: unknown, props: { from: string }) => {
  return props.from;
};
export const getMessages = (state: DefaultRootState) => {
  return state.entities.roomMessages.entities;
};

export const getRoomMessagesGroupByChannel = (state: DefaultRootState) => {
  return state.entities.roomMessages.idsGroupBy;
};

const createFilteredRoomMessageIdsGetter = (
  getRoomMessageIds: (state: DefaultRootState) => string[]
) => {
  return createSelector(
    getToProp,
    getFromProp,
    getMessages,
    getRoomMessageIds,
    (to, from, messages, ids) => {
      return ids.filter((id) => {
        if (to === null) {
          return messages[id].to === null || messages[id].to === from;
          // todo: messages[id].from === from
        } else {
          return (
            (messages[id].from === from && messages[id].to === to) ||
            (messages[id].from === to && messages[id].to === from)
          );
        }
      });
    }
  );
};

export const getFilteredRoomMessageIds = getRoomMessageIdsByType;
export const getFilteredAddedRoomMessageIds =
  createFilteredRoomMessageIdsGetter(getAddedRoomMessageIds);

export const getLastRoomMessage = createSelector(
  getMessages,
  getRoomMessageIds,
  (messages, ids) => {
    const lastId = ids[ids.length - 1];
    return messages[lastId];
  }
);

export const getFirstRoomMessage = createSelector(
  getMessages,
  getRoomMessageIdsByType,
  (messages, ids): Message | undefined => {
    return messages[ids[0]];
  }
);

export const getRoomMessageFromTime = createSelector(
  // todo: rename
  [getMessages, getFilteredAddedRoomMessageIds],
  (messages, ids) => {
    return messages[ids[0]];
  }
);

export const getChannelName = (state: DefaultRootState, channel: string) => {
  const groups = getJoinedMessageGroups(state);
  const group = groups.find((group) => group.id === channel);
  if (group) {
    return group.name;
  }

  const dmChannels = getRoomMemberDirectMessageTabs(state);
  const dm = dmChannels.find((dm) => dm.key === channel);
  if (dm) {
    const name = state.app.user.displayName || i18n.t("匿名さん") || "匿名さん";
    const userShortName = name.length > 5 ? name.slice(0, 4) + ".." : name;
    const users = [userShortName, dm.name || i18n.t("匿名さん")]
      .sort()
      .join(",");
    return i18n.t("秘匿") + `(${users})`;
  }

  if (TAB_NAMES[channel]) {
    return TAB_NAMES[channel];
  }

  return channel;
};
