import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import theme from "theme";

import styled from "styled-components";
import { PropsWithChildren } from "react";

const Faq = () => {
  return (
    <FaqContainer>
      <FaqTitle>よくある質問</FaqTitle>
      <FaqList>
        <FaqListItem title="ココフォリアとはなんですか？">
          <p>
            ココフォリアは無料で利用できるオンラインセッションツールです。Webブラウザ上で遊べるので、場所や時間の制約にとらわれることなくいつでも友達と遊ぶことができます。
          </p>
          <p>
            ダイスを使った判定を行ったり、駒やカードを盤面に配置したり、画像や音楽を使ってより臨場感のあるシーンを演出することができるので、TRPGだけでなくボードゲームや謎解き、マーダーミステリーなどさまざまなゲームが日々たくさんのユーザーに遊ばれています。
          </p>
        </FaqListItem>
        <FaqListItem title="ココフォリアは無料ですか？">
          <p>はい。ココフォリアはどなたでも無料でご利用いただけます。</p>
          <p>
            ただし一部の機能はサブスクリプションに加入いただいたユーザー様のみご利用いただけます。より多くの機能のご利用を希望される場合は、月額500円（税込）の
            CCFOLIA PRO
            にプランを変更されることをお勧めします。プランに関する詳細は
            <a href="/plans">こちら</a>をご確認ください。
          </p>
          <p>
            またココフォリアのストアで販売しているゲームの一部は有料で販売しています。
          </p>
        </FaqListItem>
        <FaqListItem title="ココフォリアはスマートフォンやタブレットで利用できますか？">
          <p>
            はい。ココフォリアはWebブラウザ上でご利用いただけるアプリケーションです。スマートフォンやタブレットなどのモバイルデバイスでもご利用いただけます。推奨環境は以下をご確認ください。
          </p>
          <p>
            <b>Windows</b>：Windows OS最新2バージョン / Google Chromeの最新版
            <br />
            <b>Mac</b>：Mac OS最新2バージョン / Safari、Google Chromeの最新版
            <br />
            <b>iPad・iPhone</b>：iOS、iPadOS最新2バージョン / Safari、Google
            Chromeの最新版
            <br />
            <b>Android</b>：Android最新2バージョン /
            標準搭載のブラウザ、およびGoogle Chromeの最新版
          </p>
          <p>
            ※推奨環境以外では一部の機能がうまく動かない場合があります。また推奨環境以外での不具合については、検討の上修正が困難だと判断する場合があります。ただし、これらは推奨環境での使用を強制するものではありません。
          </p>
        </FaqListItem>
        <FaqListItem title="CCFOLIA GAMESとは何ですか？">
          <p>
            CCFOLIA
            GAMESとは、ココフォリアを使ってオンラインで遊ぶことができるボードゲーム、パズル、謎解き、マーダーミステリー、ゲームブック、TRPGなどの作品群です。新作だけでなく、オフラインやココフォリア以外のツールでプレイできる既存作品も、ココフォリア用に展開しています。
          </p>
          <p>
            CCFOLIA
            GAMESの作品はストアでご購入後、そのゲームのルームを作成するだけで遊べます。盤面の準備をする必要がないため、遊びたい時にすぐに遊ぶことができます。
          </p>
          <p>
            ゲームのココフォリア版の制作や、販売に関するご相談は、{" "}
            <a href="mailto:games@ccfolia.com">games@ccfolia.com</a>{" "}
            までメールでお問い合わせください。
          </p>
        </FaqListItem>
      </FaqList>
    </FaqContainer>
  );
};

type FaqListItemProps = PropsWithChildren<{
  title: string;
}>;

const FaqListItem = ({ title, children }: FaqListItemProps) => {
  return (
    <FaqAccordion disableGutters>
      <FaqAccordionSummary expandIcon={<CustomExpandIcon />}>
        {title}
      </FaqAccordionSummary>
      <FaqAccordionDetails>{children}</FaqAccordionDetails>
    </FaqAccordion>
  );
};

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
        },
      }}
    >
      <div className="expandIconWrapper">
        <RemoveIcon />
      </div>
      <div className="collapsIconWrapper">
        <AddIcon />
      </div>
    </Box>
  );
};

const FaqContainer = styled.section`
  margin: 200px auto 0;
  font-family: "Noto Sans JP", sans-serif;
  ${theme.breakpoints.down("md")} {
    margin: 120px auto 0;
  }
`;
const FaqTitle = styled.h2`
  font-size: 40px;
  text-align: center;
  color: #fff;
  ${theme.breakpoints.down("md")} {
    font-size: 24px;
  }
`;

const FaqList = styled.div`
  width: calc(760 / 1400 * 100%);
  max-width: 760px;
  margin: 0 auto;
  ${theme.breakpoints.down("md")} {
    width: calc(342 / 390 * 100%);
  }
`;

const FaqAccordion = styled(Accordion)`
  background: transparent;
  box-shadow: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  padding: 28px 0;
  ${theme.breakpoints.down("md")} {
    padding: 24px 0;
  }
  &::before {
    display: none;
  }
`;
const FaqAccordionSummary = styled(AccordionSummary)`
  min-height: auto;
  font-weight: 700;
  padding: 0;
  .MuiAccordionSummary-content {
    margin: 0;
  }
`;
const FaqAccordionDetails = styled(AccordionDetails)`
  padding: 24px 0 0;
  text-align: justify;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.7);

  a {
    color: #2196f3;
    text-decoration: none;
  }

  ${theme.breakpoints.down("md")} {
    padding: 8px 0 0;
  }

  p {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default Faq;
