import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DiceSkinSettings } from "../entities.user.setting";

export type DiceType = "d4" | "d6" | "d8" | "d10" | "d12" | "d20" | "d100";

export type SnapshotPosition = [number, number, number];
export type SnapshotQuaternion = [number, number, number, number];

type Snapshot = {
  position: SnapshotPosition;
  quaternion: SnapshotQuaternion;
};

export type ResultSimulation = {
  key: string;
  type: DiceType;
  modelId: string;
  value: number;
  simulatedValue: number;
  steps: Snapshot[];
};

export type Diceroll = {
  id: string;
  simulated: ResultSimulation[];
  diceSkinSettings: DiceSkinSettings;
};

type DicerollsState = {
  ids: string[];
  entities: Record<string, Diceroll>;
};

const initializeDicerollsState = (): DicerollsState => {
  return {
    ids: [],
    entities: {},
  };
};

const dicerollsSlice = createSlice({
  name: "app/dicerolls",
  initialState: initializeDicerollsState(),
  reducers: {
    add(state, action: PayloadAction<Diceroll>) {
      if (state.entities[action.payload.id] == null) {
        state.ids.push(action.payload.id);
        state.entities[action.payload.id] = action.payload;
      }
    },
    remove(state, action: PayloadAction<string>) {
      const index = state.ids.indexOf(action.payload);
      if (index >= 0) {
        state.ids.splice(index, 1);
        delete state.entities[action.payload];
      }
    },
  },
});

export const reducer = dicerollsSlice.reducer;
export const actions = dicerollsSlice.actions;
