import {
  child,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  onDisconnect,
  ref,
  remove,
} from "firebase/database";
import {
  Suspense,
  lazy,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";

import Chat from "./Chat";
import Frame from "../Screen/Frame";
import Screen from "../Screen/Screen";
import Header from "./Header";
import Background from "./Background";
import PanelDetail from "../PanelDetail";
import CharacterMenu from "../CharacterMenu";
import ItemMenu from "../ItemMenu";
import DeckMenu from "../DeckMenu";
import DeckDetail from "../DeckDetail";
import MarkerMenu from "../MarkerMenu";
import MarkerDetail from "./MarkerDetail";
import DiceDetail from "./DiceDetail";
import MyCharacterListDrawer from "../MyCharacterListDrawer";
import CutList from "../CutList";
import NoteList from "../NoteList";
import FieldDetail from "./FieldDetail";
import NoteDetail from "../NoteDetail";
import EffectDetail from "../EffectDetail";
import Media from "../Media";
import Timer, { TimerRef } from "../Timer";
import TimerSettingDialog from "../TimerSettingDialog";
import Inspector from "../Inspector";
import Chatpalet from "./Chatpalet";
import ColorPicker from "./ColorPicker";
import DiceBotTypeSelect from "../DiceBotTypeSelect";
import DiceBotInfo from "./DiceBotInfo";
import MemberList from "./MemberList";
import NoteMessageDetail from "../NoteMessageDetail";
import ActiveCharacterList from "../ActiveCharacterList";
import Effects from "./Effects";
import FloatMenu from "./FloatMenu";
import EmoteMenu from "./EmoteMenu";
import MessageBox from "./MessageBox";
import Loader from "./Loader";
import RoomLoader from "./RoomLoader";
import ZoomControls from "./ZoomControls";
import ContextMenu from "../Screen/ContextMenu";
import UserSettings from "../UserSettings";

// new directories
import DynamicFileListDialog from "containers/DynamicFileListDialog";
import ExportRoomDialog from "../ExportRoomDialog";
import SceneListDrawer from "../SceneListDrawer";
import SceneEditDialog from "../SceneEditDialog";
import CharacterFormDialog from "../CharacterFormDialog";
import MessageChannelFormDialog from "../MessageChannelFormDialog";
import MediumListDialog from "../MediumListDialog";
import PanelListDrawer from "../PanelListDrawer";
// import MarkerListDrawer from "containers/MarkerListDrawer";
import Information from "containers/Information";
import CommandLine from "containers/CommandLine";
import Notification from "containers/Notification";
import Login from "containers/Login";
import PlanSelect from "containers/PlanSelect";

import ChatPopupWindow from "containers/ChatPopupWindow";
import UnderConstruction from "./UnderConstruction";
import UserDeleteDialog from "containers/UserDeleteDialog";

import StableHeader from "containers/Header";

// next
// import RoomHeader from "./_next/RoomHeader";
// import RoomChatBar from "./_next/RoomChatBar";

import { rtdb } from "initializer";
import {
  roomMemberAdded,
  roomMemberUpdated,
  roomMemberRemoved,
  roomMemberUpdate,
} from "./roomSlice";
import { connectSocket } from "modules/socket";
import Emotes from "containers/Emotes";
import MarkerListDrawer from "containers/MarkerListDrawer";
import ConfirmTermsAgreement from "containers/ConfirmTermsAgreement";
import VideoPlayer from "containers/VideoPlayer/VideoPlayer";
import ErrorSnackbar from "containers/ErrorSnackbar";
import OldMediumDirectoryReplacer from "containers/OldMediumDirectoryReplacer";
// import PDFViewer from "containers/PDFViewer";
import SavedataDialog from "containers/SavedataDialog/SavedataDialog";
import SaveConfirm from "containers/SavedataDialog/SaveConfirm";
import DeleteConfirm from "containers/SavedataDialog/DeleteConfirm";
import LoadDialog from "containers/SavedataDialog/LoadDialog";
import LoadConfirm from "containers/SavedataDialog/LoadConfirm";
// import DiceTray from "containers/DiceTray";
import RoomVariablesDialog from "containers/RoomVariablesDialog/RoomVariablesDialog";
// import DiceSkinSelect from "containers/DiceSkinSelect";
import RoomSettingsDialog from "containers/RoomSettingsDialog";
import InviteDialog from "containers/InviteDialog";
import AccessDenied from "containers/AccessDenied/AccessDenied";
import { getAppState } from "stores/modules/app.state/selectors";
import { setCurrentOwnRoomMember } from "stores/modules/entities.room.members/operations";
import { getRoomMemberById } from "stores/modules/entities.room.members/selectors";
import DeletedMember from "./DeletedMember";
import ExtentionsDialog from "containers/ExtentionsDialog";
import { useObserveSigninError } from "modules/signin";
import ChatTabSettingsDialog from "containers/ChatTabSettingsDialog";
import MessageNotifier from "containers/MessageNotifier";
import { Room as RoomType } from "stores/modules/entities.rooms";
import FloatItemDraggableContext from "containers/FloatItemDraggableContext";
import UndoKeyboardShortcutObserver from "containers/UndoKeyboardShortcutObserver/UndoKeyboardShortcutObserver";
// import RoomMessageBox from "./_next/RoomMessageBox";
// import RoomSetting from "./_next/RoomSetting";
// import RoomWindows from "./_next/RoomWindows";

const DiceTray = lazy(() => import("containers/DiceTray"));
const PDFViewer = lazy(() => import("containers/PDFViewer"));

const membersRef = (roomId: string) => ref(rtdb, `room-members/${roomId}`);

const disableScroll = (node: HTMLDivElement | null) => {
  if (node) {
    node.addEventListener("touchmove", (e) => e.preventDefault(), {
      passive: false,
    });
  }
};

type UseRoomMemberProps = {
  roomId: string;
  uid: string;
};

const useRoomMember = ({ roomId, uid }: UseRoomMemberProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!roomId || !uid) return;
    dispatch(roomMemberUpdate("none"));
    const disconnect = onDisconnect(child(membersRef(roomId), uid));
    disconnect.remove();
    const unsubscribeOnChildAdded = onChildAdded(membersRef(roomId), (data) => {
      dispatch(
        roomMemberAdded({
          uid: data.key,
          ...data.val(),
        })
      );
    });
    const unsubscribeOnChildChanged = onChildChanged(
      membersRef(roomId),
      (data) => {
        dispatch(
          roomMemberUpdated({
            uid: data.key,
            ...data.val(),
          })
        );
      }
    );
    const unsubscribeOnChildRemoved = onChildRemoved(
      membersRef(roomId),
      (data) => {
        data.key && dispatch(roomMemberRemoved(data.key));
      }
    );
    return () => {
      disconnect.cancel();
      unsubscribeOnChildAdded();
      unsubscribeOnChildChanged();
      unsubscribeOnChildRemoved();
      remove(child(membersRef(roomId), uid));
    };
  }, [roomId, uid, dispatch]);
};

type RoomProps = {
  roomId: string;
  uid: string;
};

const Room = ({ roomId, uid }: RoomProps) => {
  const room = useAppSelector(store.getCurrentRoom);
  const settingsUid = useAppSelector((state) => state.entities.userSetting.uid);
  const role = useAppSelector(store.getRole);
  const hasEditableRole = useAppSelector(store.getHasEditableRole);

  const screenCenterRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<TimerRef>(null);
  const [loginOpen, setLoginOpen] = useState(false);
  const onLoginOpen = useCallback(() => {
    setLoginOpen(true);
  }, [setLoginOpen]);
  const onLoginClose = useCallback(() => {
    setLoginOpen(false);
  }, [setLoginOpen]);
  useMounted({ roomId, uid, hasEditableRole, room });
  useRoomMember({
    roomId,
    uid,
  });
  useObserveSigninError({ openLoginDialog: onLoginOpen });

  const { deletedMember } = useObserveOwnMember();

  if (deletedMember) {
    return (
      <Container>
        <StableHeader />
        <DeletedMember />
      </Container>
    );
  }

  if (
    !uid ||
    !roomId ||
    !room ||
    !settingsUid ||
    !role ||
    uid !== settingsUid
  ) {
    return <RoomLoader loading={true} />;
  }

  if (role === "denied") {
    return (
      <Container>
        <StableHeader />
        <AccessDenied />
      </Container>
    );
  }

  if (!hasEditableRole && room.underConstruction) {
    return (
      <Container>
        <Header
          roomId={roomId}
          setLoginOpen={setLoginOpen}
          underConstruction={true}
        />
        <Login open={loginOpen} onClose={onLoginClose} />
        <UserSettings uid={uid} />
        <PlanSelect />
        <Information />
        <Notification />
        <UnderConstruction />
      </Container>
    );
  }

  return (
    <Container tabIndex={0}>
      <Header roomId={roomId} setLoginOpen={setLoginOpen} />
      <Background backgroundUrl={room.backgroundUrl} />
      <Frame>
        <ContextMenu roomId={roomId} centerRef={screenCenterRef}>
          <View ref={disableScroll}>
            <Screen roomId={roomId} uid={uid} centerRef={screenCenterRef} />
          </View>
        </ContextMenu>
        <Media roomId={roomId} uid={uid} />
        <FloatItemDraggableContext>
          {room.features?.timer ? (
            <Timer roomId={roomId} ref={timerRef} />
          ) : null}
        </FloatItemDraggableContext>
        <FloatMenu timerRef={timerRef} />
        <EmoteMenu roomId={roomId} />
        <ZoomControls />
        <MessageBox roomId={roomId} uid={uid} />
        <ActiveCharacterList roomId={roomId} uid={uid} />
        <Effects />
        <Emotes roomId={roomId} />
        <Suspense fallback={null}>
          <DiceTray />
        </Suspense>
      </Frame>

      <Chat roomId={roomId} uid={uid} />
      <ChatPopupWindow />
      <MessageChannelFormDialog />
      <ChatTabSettingsDialog />
      <TimerSettingDialog timerRef={timerRef} />
      {/* Draggable */}
      <FloatItemDraggableContext>
        <Inspector />
        <MyCharacterListDrawer />
        <SceneListDrawer />
        <PanelListDrawer />
        <MarkerListDrawer />
        <NoteList roomId={roomId} uid={uid} />
        <CutList roomId={roomId} uid={uid} />
        <Chatpalet roomId={roomId} />
      </FloatItemDraggableContext>
      {/* Dialogs */}
      <DynamicFileListDialog />
      <ExportRoomDialog />
      <CharacterFormDialog roomId={roomId} uid={uid} />
      <FieldDetail />
      <NoteDetail roomId={roomId} />
      <PanelDetail roomId={roomId} />
      <DeckDetail />
      <CharacterMenu />
      <ItemMenu />
      <DeckMenu />
      <MarkerMenu />
      <MarkerDetail roomId={roomId} />
      <SceneEditDialog />
      <ColorPicker roomId={roomId} uid={uid} />
      <DiceBotTypeSelect roomId={roomId} uid={uid} />
      <DiceBotInfo />
      <MediumListDialog />
      <MemberList />
      <RoomVariablesDialog />
      <Login open={loginOpen} onClose={onLoginClose} />
      <UserSettings uid={uid} />
      <PlanSelect />
      <UserDeleteDialog />
      <NoteMessageDetail />
      <EffectDetail roomId={roomId} />
      <DiceDetail />
      <SavedataDialog />
      {/* <DiceSkinSelect /> */}
      <SaveConfirm />
      <DeleteConfirm />
      <LoadDialog />
      <LoadConfirm />
      {/* <MessageSoundEffect roomId={roomId} uid={uid} /> */}
      <Information />
      <CommandLine />
      <ErrorSnackbar />
      <VideoPlayer id={room.video.id} url={room.video.url} />
      <Notification />
      <ConfirmTermsAgreement />
      <OldMediumDirectoryReplacer />
      <Suspense fallback={null}>
        <PDFViewer />
      </Suspense>
      <InviteDialog />
      <RoomSettingsDialog />
      <ExtentionsDialog />
      <MessageNotifier />
      <Loader />
      <UndoKeyboardShortcutObserver />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  /* border: solid 5px green; */
  overflow: hidden;
`;

const View = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

type UseMountedProps = {
  roomId: string;
  room: RoomType | undefined;
  uid: string;
  hasEditableRole: boolean;
};

const useMounted = (props: UseMountedProps) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const enabledUserFilesLoader = useAppSelector((state) =>
    store.getAppState(state, "enabledUserFilesLoader")
  );
  const enabledUserMediaLoader = useAppSelector((state) =>
    store.getAppState(state, "enabledUserMediaLoader")
  );
  const subscribedUserMediumDir = useAppSelector((state) =>
    store.getAppState(state, "subscribedUserMediumDir")
  );
  const userMediumListLibraryTab = useAppSelector((state) =>
    store.getAppState(state, "userMediumListLibraryTab")
  );

  useEffect(() => {
    dispatch(store.appStateMutate((state) => (state.roomId = props.roomId)));
  }, [props.roomId, dispatch]);

  // priority - high
  useEffect(() => {
    return dispatch(store.subscribeRoom(props.roomId));
  }, [props.roomId, dispatch]);
  useEffect(() => {
    return dispatch(store.subscribeRoomMessages(props.roomId));
  }, [props.roomId, dispatch]);

  // priority - mid
  useEffect(() => {
    if (props.room?._id) {
      return dispatch(store.subscribeRoomCharacters(props.room?._id));
    }
  }, [props.room?._id, dispatch]);
  useEffect(() => {
    if (props.room?._id) {
      return dispatch(store.subscribeRoomItems(props.room._id));
    }
  }, [props.room?._id, dispatch]);
  useEffect(() => {
    if (props.room?._id) {
      return dispatch(store.subscribeRoomEffects(props.room._id));
    }
  }, [props.room?._id, dispatch]);
  useEffect(() => {
    if (props.room?._id) {
      return dispatch(store.subscribeRoomDices(props.room._id));
    }
  }, [props.room?._id, dispatch]);
  useEffect(() => {
    if (props.room?._id) {
      return dispatch(store.subscribeRoomDecks(props.room._id));
    }
  }, [props.room?._id, dispatch]);
  useEffect(() => {
    return dispatch(store.subscribeUserSetting(props.uid));
  }, [props.uid, dispatch]);
  useEffect(() => {
    if (props.room?._id && props.room?.features?.timer) {
      return connectSocket(props.roomId, props.uid);
    }
  }, [props.room?._id, props.roomId, props.uid, props.room?.features?.timer]);
  useEffect(() => {
    if (props.room?._id) {
      dispatch(store.migrationMessageChannels());
    }
  }, [props.room?._id, dispatch]);

  // owner only
  useEffect(() => {
    if (props.hasEditableRole) {
      return dispatch(store.subscribeRoomNotes(props.roomId));
    }
  }, [props.roomId, props.hasEditableRole, dispatch]);
  useEffect(() => {
    if (props.hasEditableRole) {
      return dispatch(store.subscribeRoomScenes(props.roomId));
    }
  }, [props.roomId, props.hasEditableRole, dispatch]);
  useEffect(() => {
    if (props.hasEditableRole) {
      return dispatch(store.subscribeRoomSavedatas(props.roomId));
    }
  }, [props.roomId, props.hasEditableRole, dispatch]);

  // notification
  useEffect(() => {
    if (props.room?.monitored) {
      dispatch(
        store.addNotification(
          t(
            "このルームでは監視モードが有効になっています。ルームマスターに全てのスクリーンパネル、ダイスシンボル、キャラクター駒が見えています。"
          )
        )
      );
    }
  }, [props.room?.monitored, dispatch, t]);

  useEffect(() => {
    if (props.room?.underConstruction && props.hasEditableRole) {
      dispatch(
        store.addNotification(
          t(
            "このルームでは工事中モードが有効になっています。ルームマスター以外の入室ができません。"
          ) +
            t(
              "保護は完全ではなく、旧バージョンのユーザーからは閲覧されてしまう可能性があります。"
            )
        )
      );
    }
  }, [props.room?.underConstruction, props.hasEditableRole, dispatch, t]);

  useEffect(() => {
    return dispatch(
      store.subscribeUserFiles({ uid: props.uid, roomId: props.roomId })
    );
  }, [props.uid, props.roomId, dispatch]);

  // lazyload
  useEffect(() => {
    if (!enabledUserFilesLoader) return;
    dispatch(store.getAllUserFiles(props.uid, props.roomId));
  }, [props.uid, props.roomId, enabledUserFilesLoader, dispatch]);

  useEffect(() => {
    if (!enabledUserMediaLoader) {
      return;
    }

    if (subscribedUserMediumDir.length === 0) {
      return dispatch(
        store.subscribeUserMedia({
          uid: props.uid,
          dirs: [userMediumListLibraryTab],
        })
      );
    }

    return dispatch(
      store.subscribeUserMedia({
        uid: props.uid,
        dirs: subscribedUserMediumDir,
      })
    );
  }, [
    props.uid,
    enabledUserMediaLoader,
    userMediumListLibraryTab,
    subscribedUserMediumDir,
    dispatch,
  ]);

  useEffect(() => {
    return dispatch(store.subscribeUserMediumDirectory(props.uid));
  }, [props.uid, dispatch]);

  useEffect(() => {
    return dispatch(store.subscribeUserHistories(props.uid));
  }, [props.uid, dispatch]);
  useEffect(() => {
    if (props.uid && props.room?.owner && props.room.owner !== props.uid) {
      dispatch(store.recordCurrentUserHistory());
    }
  }, [props.uid, props.room?.owner, dispatch]);

  useEffect(() => {
    if (props.uid) {
      const unsubscribe = dispatch(store.subscribeRoomMembers(props.roomId));
      return () => {
        unsubscribe();
      };
    }
  }, [props.roomId, props.uid, dispatch]);

  useEffect(() => {
    if (props.room?.archived) {
      window.alert("この部屋は既に削除されています。");
      window.location.href = "/";
    }
  }, [props.room?.archived]);
};

const HOUR_MS = 1000 * 60 * 60;

const useObserveOwnMember = () => {
  const dispatch = useAppDispatch();
  const displayName = useAppSelector((state) =>
    getAppState(state, "displayName")
  );
  const photoUrl = useAppSelector((state) => getAppState(state, "photoUrl"));
  const isAnonymous = useAppSelector((state) =>
    getAppState(state, "isAnonymous")
  );
  const existsMember = useAppSelector((state) => {
    const uid = getAppState(state, "uid");
    return getRoomMemberById(state, uid || "") != null;
  });

  const [deleted, setDeleted] = useState(false);
  const initialized = useRef<boolean>(false);

  // 変更があった場合に更新する
  useEffect(() => {
    if (initialized.current && !deleted) {
      dispatch(setCurrentOwnRoomMember());
    }
  }, [displayName, photoUrl, isAnonymous, initialized, deleted, dispatch]);

  // 自分のメンバー情報の初期化
  useEffect(() => {
    if (initialized.current && !existsMember) {
      setDeleted(true);
      return;
    }

    if (!existsMember) {
      dispatch(setCurrentOwnRoomMember());
    }
    initialized.current = true;

    const timer = setInterval(() => {
      dispatch(setCurrentOwnRoomMember());
    }, HOUR_MS); // health check every 1hour
    return () => clearInterval(timer);
  }, [existsMember, initialized, dispatch, setDeleted]);

  return { deletedMember: deleted && !existsMember };
};

export default memo(Room);
