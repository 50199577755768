import React, { memo } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "stores";
import store from "stores/interfaces";
import { List } from "@mui/material";

import MyCharacterListItem from "../MyCharacterListItem";

const MyCharacterList = () => {
  const characterIds = useAppSelector(
    store.getSortedMyRoomCharacterIds,
    shallowEqual
  );
  return (
    <List>
      {characterIds.map((characterId) => (
        <MyCharacterListItem key={characterId} characterId={characterId} />
      ))}
    </List>
  );
};

export default memo(MyCharacterList);
