import { DefaultThunk } from "stores";
import { UpdateCharacter } from "stores/modules/entities.room.characters";
import {
  deleteRoomCharacter,
  updateRoomCharacterWithMessage,
} from "stores/modules/entities.room.characters/operations";

export type SystemMessage = {
  type: "status" | "default";
  label: string;
};

export type UpdateCharacterRecord = {
  kind: "update-character";
  id: string;
  before: UpdateCharacter | null;
  after: UpdateCharacter | null;
  systemMessage?: SystemMessage;
};

export const undoUpdateCharacter =
  (history: UpdateCharacterRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const before = history.before;

    if (!before) {
      // 変更前データがない場合は追加が行われたとして削除処理
      dispatch(deleteRoomCharacter(history.id));
      return;
    }

    // それ以外の場合は通常の更新処理
    dispatch(updateRoomCharacterWithMessage(before, history.systemMessage));
    return;
  };

export const redoUpdateCharacter =
  (history: UpdateCharacterRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const after = history.after;

    if (!after) {
      // 変更後データがない場合は削除処理
      dispatch(deleteRoomCharacter(history.id));
      return;
    }

    // それ以外の場合は通常の更新処理
    dispatch(updateRoomCharacterWithMessage(after, history.systemMessage));
    return;
  };
