import { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import theme from "theme";
import styled from "styled-components";
import store from "stores/interfaces";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "stores";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";

import TranslationButton from "containers/TranslationButton";
import HeaderMenuSp from "./HeaderMenuSp";

const Header = () => {
  const [t] = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const anchorRef = useRef(null);

  const showMenu = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openTopHeaderMenu = true;
      })
    );
  }, [dispatch]);

  return (
    <>
      <StyledAppBar elevation={0}>
        <StyledToolbar>
          <StyledHeaderLogo>
            <Link to="/">
              <h1>
                <img src="images/logo-white.svg" alt="CCFOLIAのロゴ" />
              </h1>
            </Link>
          </StyledHeaderLogo>
          <StyledHeaderMenuList>
            <StyledHeaderMenuListItem href="/plans">
              {t("プラン")}
            </StyledHeaderMenuListItem>
            <StyledHeaderMenuListItem href="/games">
              {t("ストア")}
            </StyledHeaderMenuListItem>
            <StyledHeaderMenuListItem
              ref={anchorRef}
              onClick={() => setOpen(true)}
              endIcon={<ArrowDropDownIcon />}
            >
              {t("コミュニティ")}
            </StyledHeaderMenuListItem>

            <StyledCommunityMenu
              open={open}
              anchorEl={anchorRef.current}
              onClose={() => setOpen(false)}
            >
              <MenuItem>
                <a
                  href="https://x.com/ccfolia"
                  target="_blank"
                  rel="noreferrer"
                >
                  X
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  href="http://discord.gg/jDfJmZS"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Discordサーバー")}
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  href="https://ccfolia.fanbox.cc/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("開発支援（PIXIV FANBOX）")}
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  href="https://docs.ccfolia.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("公式ドキュメント")}
                </a>
              </MenuItem>
            </StyledCommunityMenu>

            <span className="separate" />

            <TranslationButton buttonStyle="Normal" />
          </StyledHeaderMenuList>
          <StyledSpHeaderMenuList>
            <SpMenuToggleButton onClick={showMenu}>
              <MenuIcon sx={{ fontSize: "32px" }} />
            </SpMenuToggleButton>
          </StyledSpHeaderMenuList>
        </StyledToolbar>
      </StyledAppBar>
      <HeaderMenuSp />
    </>
  );
};

const StyledAppBar = styled(AppBar)`
  position: absolute;
  width: 100%;
  margin-inline: auto;
  background: linear-gradient(180deg, #202020 0%, rgba(32, 32, 32, 0) 100.38%);

  ${theme.breakpoints.down("md")} {
    padding: 0 8px 0 16px;
  }
`;

const StyledToolbar = styled(Toolbar)`
  height: 80px;
  justify-content: space-between;

  ${theme.breakpoints.down("md")} {
    height: 56px;
    min-height: auto;
    padding-left: 8px;
    padding-right: 4px;
  }
  ${theme.breakpoints.up("lg")} {
    width: 100%;
    max-width: 1152px;
    margin: 0 auto;
    padding: 0;
  }
`;

const StyledHeaderLogo = styled(Box)`
  width: 100%;
  max-width: 180px;

  h1 {
    line-height: 0;
  }
  img {
    width: 100%;
  }

  ${theme.breakpoints.down("md")} {
    width: 120px;
  }
`;

const StyledHeaderMenuList = styled(Box)`
  display: flex;
  ${theme.breakpoints.down("md")} {
    display: none;
  }
  .separate {
    width: 1px;
    height: 36px;
    margin: 0 16px;
    background: rgba(255, 255, 255, 0.4);
    ${theme.breakpoints.down("md")} {
      display: none;
    }
  }
`;

const StyledSpHeaderMenuList = styled(Box)`
  display: none;
  ${theme.breakpoints.down("md")} {
    display: flex;
    align-items: center;
  }
`;

const StyledHeaderMenuListItem = styled(Button)`
  .MuiButton-endIcon {
    margin-left: 0;
  }
`;

const StyledCommunityMenu = styled(Menu)`
  color: #fff;
  .MuiMenu-paper {
    background: rgba(0, 0, 0, 0.87);
  }
  a {
    width: 100%;
    color: inherit;
    text-decoration: none;
  }
`;

const SpMenuToggleButton = styled(IconButton)`
  padding: 4px;
`;

export default Header;
