import { MouseEvent, memo, useCallback } from "react";
import { useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import theme from "theme";

import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import { Badge, ButtonBase } from "@mui/material";
import toCDNUrl from "modules/toCDNUrl";
import { Character } from "stores/modules/entities.room.characters";

type ActiveCharacterEditCardProps = {
  uid: string;
  characterId: string | null;
  onClick: (e: MouseEvent, characterId: string) => void;
};

const ActiveCharacterEditCard = ({
  uid,
  characterId,
  onClick,
}: ActiveCharacterEditCardProps) => {
  const character = useAppSelector((state) =>
    store.getCharacterById(state, characterId)
  );
  const monitored = useAppSelector(store.getCurrentRoomIsMonitored);

  const onClickCard = useCallback(
    (e: MouseEvent) => {
      if (characterId) {
        onClick(e, characterId);
      }
    },
    [characterId, onClick]
  );

  const secret = character.secret && !monitored;

  return (
    <CharacterCard>
      <CharacterBadge
        badgeContent={!secret ? character.initiative : "??"}
        color="secondary"
      >
        <CharacterCardAvatar onClick={onClickCard}>
          <CharacterAvatarButton>
            <img
              src={toCDNUrl(character.iconUrl) || "/ccfolia.png"}
              draggable={false}
              alt=""
            />
          </CharacterAvatarButton>
        </CharacterCardAvatar>
      </CharacterBadge>
      {!secret || character.owner === uid ? (
        <CharacterCardContent onClick={onClickCard}>
          <CharacterCardBody>
            {character.status.slice(0, 8).map((state, index) => (
              <State key={index} state={state} />
            ))}
          </CharacterCardBody>
        </CharacterCardContent>
      ) : null}
    </CharacterCard>
  );
};

type StateProps = {
  state: Character["status"][number];
};

const State = ({ state }: StateProps) => {
  const max = Math.trunc(state.max);
  const label = state.label;
  const value = Math.trunc(state.value);

  return (
    <CharacterCardContentSlider>
      <Caption>
        <Label variant="body2" noWrap>
          {label}
        </Label>
        <Value variant="body2" noWrap>
          <Chalk color={value / max <= 0.8 ? "secondary" : "default"}>
            {value}
          </Chalk>
          {max === 0 || Number.isNaN(max) ? null : <>/{max}</>}
        </Value>
      </Caption>

      <StateContainer>
        <StateBar></StateBar>
        <StateValue
          style={{ width: `${Math.max(Math.min(value / max, 1), 0) * 100}%` }}
        ></StateValue>
      </StateContainer>
    </CharacterCardContentSlider>
  );
};

const CharacterCard = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
`;

const CharacterBadge = styled(Badge)`
  .MuiBadge-badge {
    top: 4px;
    right: 4px;
    background: ${theme.palette.grey[100]};
    color: ${theme.palette.grey[800]};
  }
`;

const CharacterAvatarButton = styled(ButtonBase)`
  display: block;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const CharacterCardContent = styled.div`
  flex: 1;
`;

const CharacterCardAvatar = styled(Avatar)`
  border-radius: 4px;
  border: 1px solid ${theme.palette.grey[100]};
  width: 40px;
  height: 40px;
  align-items: start;
  background: rgba(0, 0, 0, 0.64);
  img {
    object-position: top;
  }
`;

const CharacterCardBody = styled.div`
  margin-left: 4px;
  max-width: 210px;
  display: flex;
  flex-wrap: wrap;
  ${theme.breakpoints.down("md")} {
    display: none;
  }
`;

const Caption = styled.div`
  padding: 1px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Label = styled(Typography)`
  display: block;
  line-height: 1;
  color: ${theme.palette.grey[800]};
  font-weight: 800;
  text-shadow: 1px 1px 0 ${theme.palette.grey[100]},
    -1px -1px 0 ${theme.palette.grey[100]},
    -1px 1px 0 ${theme.palette.grey[100]}, 1px -1px 0 ${theme.palette.grey[100]},
    0px 1px 0 ${theme.palette.grey[100]}, 0-1px 0 ${theme.palette.grey[100]},
    -1px 0 0 ${theme.palette.grey[100]}, 1px 0 0 ${theme.palette.grey[100]};
  max-width: 60px;
`;

const Value = styled(Typography)`
  display: block;
  line-height: 1;
  font-weight: 800;
  color: ${theme.palette.grey[800]};
  text-shadow: 1px 1px 0 ${theme.palette.grey[100]},
    -1px -1px 0 ${theme.palette.grey[100]},
    -1px 1px 0 ${theme.palette.grey[100]}, 1px -1px 0 ${theme.palette.grey[100]},
    0px 1px 0 ${theme.palette.grey[100]}, 0-1px 0 ${theme.palette.grey[100]},
    -1px 0 0 ${theme.palette.grey[100]}, 1px 0 0 ${theme.palette.grey[100]};
`;

const Chalk = styled.span`
  color: ${({ color }) =>
    color === "secondary" ? theme.palette.secondary.dark : null};
`;

const CharacterCardContentSlider = styled.div`
  margin: 2px;
  width: 96px;
  position: relative;
  cursor: pointer;
`;

const barHeight = 16;
const StateContainer = styled.div`
  height: ${barHeight}px;
  padding: 0;
  position: relative;
  box-sizing: content-box;
`;

const StateBar = styled.div`
  width: 100%;
  height: ${barHeight}px;
  padding: 0;
  position: absolute;
  border-radius: 1px;
  background: #f5f5f5;
  opacity: 0.38;
`;

const StateValue = styled.div`
  left: 0%;
  margin-top: 0px;
  height: ${barHeight}px;
  background: #f5f5f5;
  position: absolute;
  border-radius: 1px;
`;

export default memo(ActiveCharacterEditCard);
