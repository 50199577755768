import { memo, useMemo, KeyboardEvent, useCallback, ChangeEvent } from "react";
import styled from "styled-components";

import Paper from "@mui/material/Paper";
import { useCombobox } from "downshift";

import { MenuItem, Typography, InputBase, InputBaseProps } from "@mui/material";
import { REGEXP_DICE_NOTICE } from "containers/Room/Chat/ChatBox/DiceSelect";

const suggestionItems = (items: string[], inputValue: string) => {
  if (inputValue.length < 1 || inputValue.length > 10) {
    return [];
  }
  return items
    .filter((item) => item.indexOf(inputValue) > -1 && item !== inputValue)
    .slice(0, 10);
};

type AutoCompleteTextFieldProps = {
  onChange: (text: string) => void;
  items: string[];
  value: string;
  inputProps: InputBaseProps;
};

const AutoCompleteTextField = ({
  items: options,
  onChange: handleChange,
  value,
  inputProps,
}: AutoCompleteTextFieldProps) => {
  const items = useMemo(
    () => suggestionItems(options, value),
    [options, value]
  );

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    onSelectedItemChange({ selectedItem }) {
      handleChange(selectedItem ?? "");
    },
    items,
    inputValue: value,
    selectedItem: value,
  });

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleChange(event.target.value);
    },
    [handleChange]
  );

  const isDiceNotice = useMemo(() => {
    return REGEXP_DICE_NOTICE.test(value);
  }, [value]);

  const hiddenMenu = !isOpen || isDiceNotice || items.length === 0;
  const isSelected = highlightedIndex >= 0;
  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (hiddenMenu || (!isSelected && event.key === "Enter")) {
        // Prevent Downshift's default 'Enter' behavior.
        (event.nativeEvent as any).preventDownshiftDefault = true;
      }
    },
    [hiddenMenu, isSelected]
  );

  return (
    <Container>
      <PopPaper
        square
        component="ul"
        {...getMenuProps()}
        data-hidden={hiddenMenu}
      >
        {items.map((item, index) => (
          <MenuItem
            {...getItemProps({
              key: index,
              index,
              item,
            })}
            selected={highlightedIndex === index}
          >
            <Typography noWrap>{item}</Typography>
          </MenuItem>
        ))}
      </PopPaper>
      <TextInput
        {...getInputProps({
          refKey: "inputRef",
          onKeyDown,
          onChange,
        })}
        {...inputProps}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  flex-grow: 1;
`;

const TextInput = styled(InputBase)<{ textColor?: string }>`
  &.MuiInputBase-root {
    padding: 4px 8px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.2);
    color: ${({ textColor }) => textColor || "inherit"};
  }
  .MuiInputBase-input::placeholder {
    font-size: 14px;
  }
`;

const PopPaper = styled(Paper)`
  margin-bottom: 8px;
  max-height: 180px;
  overflow-y: scroll;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  ::-webkit-scrollbar {
    display: none;
  }

  &[data-hidden="true"] {
    display: none;
  }
`;

export default memo(AutoCompleteTextField);
