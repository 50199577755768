import React, {
  useState,
  useRef,
  useCallback,
  MouseEvent,
  RefObject,
  PropsWithChildren,
} from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "stores";
import { Menu, MenuItem } from "@mui/material";
import store from "stores/interfaces";
import styled from "styled-components";
import { addRoomDice } from "stores/modules/entities.room.dices/operations";
import { useTranslation } from "react-i18next";
import useLongTap from "hooks/longTap";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";

type Props = PropsWithChildren<{
  roomId: string;
  centerRef: RefObject<HTMLDivElement>;
}>;

const ContextMenu = ({ roomId, centerRef, children }: Props) => {
  const screenRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const $ = useEnhance({ roomId });
  const [t] = useTranslation();
  const isRoleAudience = useAppSelector(getIsRoleAudience);

  const onContextMenu = (event) => {
    event.preventDefault();
    if (isRoleAudience) {
      return;
    }

    const center = centerRef.current?.getBoundingClientRect() || {
      left: 0,
      top: 0,
    };
    $.onSelectContextMenu({
      event,
      centerX: center?.left ?? 0,
      centerY: center?.top ?? 0,
    });
  };
  const longTapProps = useLongTap(onContextMenu);

  return (
    <>
      <MenuScreen
        ref={screenRef}
        onContextMenu={onContextMenu}
        {...longTapProps}
      >
        {children}
      </MenuScreen>
      <MenuBase
        ref={menuRef}
        style={{ top: $.mouseEvent.pageY, left: $.mouseEvent.pageX }}
      />
      <Menu
        anchorEl={menuRef.current}
        open={$.open}
        onClose={$.onClose}
        MenuListProps={{ dense: true }}
      >
        <MenuItem onClick={$.onEditField}>{t("前景・背景を変更")}</MenuItem>
        <MenuItem onClick={$.onSelectItemImage}>
          {t("スクリーンパネル")}
        </MenuItem>
        <MenuItem onClick={$.onSelectMarkerImage}>
          {t("マーカーパネル")}
        </MenuItem>
        <MenuItem onClick={$.onAddDice}>{t("ダイスシンボル")}</MenuItem>
        <MenuItem onClick={$.onAddDeck}>
          {t("カードデッキ（トランプ）")}
        </MenuItem>
        <MenuItem onClick={$.onPasteCharacter}>{t("貼り付け")}</MenuItem>
      </Menu>
    </>
  );
};

const relativeCell = ({
  x,
  y,
  scale,
  cellSize,
  centerX,
  centerY,
}: {
  x: number;
  y: number;
  scale: number;
  cellSize: number;
  centerX: number;
  centerY: number;
}): { x: number; y: number } => {
  // 相対位置 = 中心点からの距離をcellの大きさ(縮尺込み)で割ったもの
  return {
    x: Math.round((x - centerX) / cellSize / scale),
    y: Math.round((y - centerY) / cellSize / scale),
  };
};

const MenuScreen = styled.div`
  height: 100%;
  user-select: none;
`;

const MenuBase = styled.div`
  position: fixed;
`;

type enhance = {
  roomId: string;
};

const useEnhance = ({ roomId }: enhance) => {
  const d = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [mouseEvent, setMouseEvent] = useState({ pageX: 1, pageY: 1 });

  const [position, cellSize, scale] = useAppSelector((state) => {
    return [
      store.getAppState(state, "contextMouseCellPosition"),
      store.getAppState(state, "roomScreenCellSize"),
      store.getAppState(state, "roomScreenScale"),
    ] as const;
  }, shallowEqual);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onSelectContextMenu = ({
    event,
    centerX,
    centerY,
  }: {
    event: MouseEvent<HTMLDivElement>;
    centerX: number;
    centerY: number;
  }) => {
    setOpen(true);
    setMouseEvent({
      // pageX: event?.clientX || event.touches[0]?.clientX || 0,
      // pageY: event?.clientY || event.touches[0]?.clientY || 0,
      pageX: event?.clientX || 0,
      pageY: event?.clientY || 0,
    });
    d(
      store.appStateMutate((state) => {
        state.contextMouseCellPosition = relativeCell({
          x: event?.clientX,
          y: event?.clientY,
          scale,
          cellSize,
          centerX,
          centerY,
        });
      })
    );
    event.preventDefault();
  };

  const onSelectItemImage = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "item";
        state.openRoomImageSelectTarget = "item/new";
        state.fromContextMenu = true;
      })
    );
    onClose();
  }, [onClose, d]);

  const onSelectMarkerImage = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "marker";
        state.openRoomImageSelectTarget = "marker/new";
        state.fromContextMenu = true;
      })
    );
    onClose();
  }, [onClose, d]);

  const onAddDice = useCallback(() => {
    d(addRoomDice({ x: position.x - 1, y: position.y - 1 }));
    onClose();
  }, [position, onClose, d]);

  const onEditField = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomFieldEdit = true;
      })
    );
    onClose();
  }, [onClose, d]);

  const onAddDeck = useCallback(() => {
    d(store.addRoomDeck({ x: position.x - 1, y: position.y - 1 }));
    onClose();
  }, [onClose, position, d]);

  const onPasteCharacter = useCallback(async () => {
    const textFromClipboard = await navigator.clipboard.readText();
    d(store.importRoomCharacterFromClipboardText(textFromClipboard));
    onClose();
  }, [onClose, d]);

  return {
    open,
    mouseEvent,
    onClose,
    onAddDice,
    onSelectItemImage,
    onSelectMarkerImage,
    onSelectContextMenu,
    onEditField,
    onAddDeck,
    onPasteCharacter,
  };
};

export default ContextMenu;
