import { useCallback, useState, useRef, MouseEvent, memo } from "react";
import { useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import theme from "theme";

import { Popover } from "@mui/material";

import ActiveCharacterEditCard from "./ActiveCharacterEditCard";
import ActiveCharacterMenu from "./ActiveCharacterMenu";

const stopPropagation = (e: MouseEvent) => e.stopPropagation();

type ActiveCharacterListProps = {
  roomId: string;
  uid: string;
};

const ActiveCharacterList = ({ roomId, uid }: ActiveCharacterListProps) => {
  const characterIds = useAppSelector(store.getRoomShowStatusCharacterIds);

  const [characterId, setCharacterId] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<Element | null>(null);
  const onClick = useCallback(
    (e: MouseEvent, characterId: string) => {
      anchorEl.current = e.currentTarget;
      setCharacterId(characterId);
      setOpen(true);
    },
    [anchorEl, setCharacterId, setOpen]
  );
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      {characterIds.length > 0 && (
        <Container onDoubleClick={stopPropagation}>
          {characterIds.map((characterId) => (
            <ActiveCharacterEditCard
              key={characterId}
              onClick={onClick}
              uid={uid}
              characterId={characterId}
            />
          ))}
        </Container>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl.current}
        onClose={onClose}
        onDoubleClick={stopPropagation}
      >
        <ActiveCharacterMenu
          onClose={onClose}
          roomId={roomId}
          uid={uid}
          characterId={characterId}
        />
      </Popover>
    </>
  );
};

const Container = styled.div`
  padding: 8px 16px 8px 8px;
  position: absolute;
  top: 64px;
  left: 0;
  max-height: calc(100% - 80px);
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ${theme.breakpoints.down("sm")} {
    top: 56px;
  }
`;

export default memo(ActiveCharacterList);
