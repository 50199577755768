import styled from "styled-components";
import Digit from "./Digit";
import { memo } from "react";

type CounterProps = {
  value: number;
};

const Counter = ({ value }: CounterProps) => {
  const digits = Array.from(value.toLocaleString("ja-JP"));

  return (
    <CounterWrap>
      {digits.map((item, i) => {
        if (item === ",") {
          // カンマの場合はそのまま表示
          return <Comma key={digits.length - i}>,</Comma>;
        } else {
          return (
            <DigitWrap key={digits.length - i}>
              <Digit value={parseInt(item, 10)} />
            </DigitWrap>
          );
        }
      })}
    </CounterWrap>
  );
};

export default memo(Counter);

const CounterWrap = styled.div`
  display: flex;
  gap: 0 2px;
`;

const Comma = styled.div`
  display: inline;
  width: 8px;
  margin: -8px 0 0;
`;

const DigitWrap = styled.div`
  position: relative;
  z-index: 2;
  width: 18px;
  height: 36px;
  overflow: hidden;
`;
