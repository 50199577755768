import { memo, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";

import { Dialog, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { addUndo } from "stores/modules/entities.room.histories/slice";

type FormData = {
  name: string;
  text: string;
};

const SceneEditDialog = () => {
  const d = useAppDispatch();
  const [t] = useTranslation();
  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomScene")
  );
  const sceneId = useAppSelector((state) =>
    store.getAppState(state, "openRoomSceneId")
  );
  const scene = useAppSelector((state) => {
    if (!sceneId) return null;
    return store.getRoomSceneById(state, sceneId);
  });
  const roomId = useAppSelector((state) => store.getAppState(state, "roomId"));

  const { register, handleSubmit, reset } = useForm<FormData>({
    defaultValues: { name: "", text: "" },
  });

  useEffect(() => {
    reset({
      name: scene?.name || "",
      text: scene?.text || "",
    });
  }, [scene?.name, scene?.text, reset]);

  const onSubmit = useCallback(
    (values: FormData) => {
      if (!roomId || !sceneId) return;
      d(store.updateRoomScene(roomId, sceneId, values));
      d(
        addUndo({
          kind: "update-scene",
          id: sceneId,
          before: scene,
          after: { ...scene, ...values },
        })
      );
      d(
        store.appStateMutate((state) => {
          state.openRoomScene = false;
        })
      );
    },
    [roomId, sceneId, d, scene]
  );

  return (
    <Dialog
      open={open}
      onClose={() => handleSubmit(onSubmit)()}
      maxWidth="xs"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label={t("シーンタイトル")}
          variant="filled"
          fullWidth
          autoFocus
          inputProps={register("name")}
        />
        <TextField
          label={t("シーン切替メッセージ")}
          multiline
          name="text"
          variant="filled"
          fullWidth
          rows={4}
          inputProps={register("text")}
        />
      </form>
    </Dialog>
  );
};

export default memo(SceneEditDialog);
