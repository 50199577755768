import { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

type DigitProps = {
  value: number;
};

const Digit = ({ value }: DigitProps) => {
  const [prevValue, setPrevValue] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    // 初回に0から遷移させたいので、反映を遅延させる
    const timer = window.setTimeout(() => {
      setCurrentValue(value);
    }, 10);

    return () => {
      window.clearTimeout(timer);
    };
  }, [value]);

  const onTransitionEnd = useCallback(() => {
    setPrevValue(value);
  }, [value]);

  return (
    <Reel
      value={currentValue}
      prevValue={prevValue}
      onTransitionEnd={onTransitionEnd}
    >
      <li>0</li>
      <li>1</li>
      <li>2</li>
      <li>3</li>
      <li>4</li>
      <li>5</li>
      <li>6</li>
      <li>7</li>
      <li>8</li>
      <li>9</li>
      <li>0</li>
      <li>1</li>
      <li>2</li>
      <li>3</li>
      <li>4</li>
      <li>5</li>
      <li>6</li>
      <li>7</li>
      <li>8</li>
      <li>9</li>
    </Reel>
  );
};

type ReelProps = {
  value: number;
  prevValue: number;
};

const Reel = styled.ul<ReelProps>(({ value, prevValue }) => {
  const offsetIndex = prevValue > value ? 10 + value : value;
  const translateY = -36 * offsetIndex;

  return {
    position: "relative",
    zIndex: -1,
    margin: "3px 0",
    padding: 0,
    transform: `translateY(${translateY}px)`,
    transition: value !== prevValue ? "transform 1s ease-out" : "none",
    "> li": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "17px",
      height: "36px",
      lineHeight: "1",
      listStyle: "none",
      boxSizing: "border-box",
    },
  };
});

export default memo(Digit);
