import { createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: blue,
    secondary: {
      light: "#e33371",
      main: "#dc004e",
      dark: "#9a0036",
    },
    white: {
      light: "#fff",
      main: "#eee",
      dark: "#ccc",
      contrastText: "#111",
    },
    black: {
      light: "#222",
      main: "#111",
      dark: "#000",
      contrastText: "#fff",
    },
  },
  // For japanese
  typography: {
    fontWeightBold: "bold",
    button: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
    },
    subtitle2: {
      fontWeight: "bold",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: "#212121",
          backgroundImage: "none",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: "white",
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 6,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: "rgba(44, 44, 44, 0.87)",
          backgroundImage: "none",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: "rgba(44, 44, 44, 0.87)",
          backgroundImage: "none",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: "rgba(44, 44, 44, 0.87)",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 6,
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: "rgba(44, 44, 44, 0.87)",
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "unset",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#f50057",
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        disableInteractive: true,
      },
      styleOverrides: {
        tooltip: {
          whiteSpace: "pre-wrap",
          backgroundColor: "rgba(22, 22, 22, 1)",
          fontSize: "0.75rem",
          boxShadow:
            "0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)",
        },
      },
    },
  },
});

export default theme;
