import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ExtentionsDialogContent from "./ExtentionsDialogContent";
import { useAppDispatch, useAppSelector } from "stores";
import { getAppState, getIsOwner } from "stores/modules/app.state/selectors";
import {
  appStateMutate,
  loadParentProduct,
} from "stores/modules/app.state/operations";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";

const ExtentionsDialog = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) =>
    getAppState(state, "openRoomExtentionDialog")
  );
  const isOwner = useAppSelector(getIsOwner);
  const parentProductId = useAppSelector(
    (state) => getCurrentRoom(state)?.parentProductId
  );

  useEffect(() => {
    dispatch(
      appStateMutate((draft) => {
        draft.parentProduct = null;
      })
    );

    if (isOwner && parentProductId) {
      dispatch(loadParentProduct(parentProductId));
    }
  }, [isOwner, parentProductId, dispatch]);

  const onClose = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomExtentionDialog = false;
      })
    );
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" scroll="body">
      <AppBar color="default" position="sticky">
        <Toolbar>
          <Typography style={{ flex: 1 }}>{t("追加・拡張パック")}</Typography>
          <IconButton edge="end" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography paragraph variant="body2" color="textSecondary">
          {t(
            "※ 利用するには追加・拡張パックを購入して、ライブラリからルームを新規作成してください。新規作成の際に利用する追加・拡張パックを選択できます。"
          )}
        </Typography>
        <Divider />
        <ExtentionsDialogContent />
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose}>
          {t("閉じる")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ExtentionsDialog);
