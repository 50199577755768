import { DefaultThunk } from "stores";
import { appStateMutate } from "stores/modules/app.state/operations";
import { UpdateScene } from "stores/modules/entities.room.scenes";
import { applySceneByUpdateScene } from "stores/modules/entities.room.scenes/operations";

export type ApplySceneRecord = {
  kind: "apply-scene";
  before: UpdateScene;
  after: UpdateScene;
  withoutBgm: boolean;
};

export const undoUpdateRoom =
  (history: ApplySceneRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const before = history.before;
    // シーン切替操作前の盤面状態に変更
    dispatch(applySceneByUpdateScene(roomId, before, false));
    dispatch(
      appStateMutate((state) => {
        state.selectedSceneId = before._id ?? "";
      })
    );
    return;
  };

export const redoUpdateRoom =
  (history: ApplySceneRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const after = history.after;
    // シーン切替操作後の盤面状態に変更
    dispatch(applySceneByUpdateScene(roomId, after, history.withoutBgm));
    dispatch(
      appStateMutate((state) => {
        state.selectedSceneId = after._id ?? "";
      })
    );
    return;
  };
