import {
  Avatar,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "stores";
import { getIsOwner } from "stores/modules/app.state/selectors";
import { Role, isRole } from "stores/modules/entities.room.members";
import { updateCurrentRoom } from "stores/modules/entities.rooms/operations";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import styled from "styled-components";
import theme from "theme";
import {
  getRoomMemberById,
  getRoomMemberIds,
  getRoomMembersAnonymousCounts,
} from "stores/modules/entities.room.members/selectors";
import {
  deleteCurrentRoomMember,
  updateCurrentRoomMemberRole,
} from "stores/modules/entities.room.members/operations";
import RoleDescriptionDialog from "./RoleDescriptionDialog";
import { uidToDigest } from "modules/user";

const RoleSettings = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const isOwner = useAppSelector(getIsOwner);
  const defaultRole = useAppSelector((state) => {
    const room = getCurrentRoom(state);
    return room?.defaultRole;
  });
  const members = useAppSelector((state) => getRoomMemberIds(state).length);
  const isSizeSm = useMediaQuery(theme.breakpoints.down("sm"));

  const onChangeDefaultRole = useCallback(
    (event: SelectChangeEvent<Role>) => {
      if (isOwner && isRole(event.target.value)) {
        dispatch(updateCurrentRoom({ defaultRole: event.target.value }));
      }
    },
    [isOwner, dispatch]
  );

  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const onOpen = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setOpen(true);
    },
    [setOpen]
  );

  return (
    <>
      <Heading>
        {t("メンバーリスト")}{" "}
        <Members>（{t("{{count}}人", { count: members })}）</Members>
      </Heading>
      <DefaultRole>
        <DefaultRoleDescription>
          <LanguageIcon
            sx={{ fontSize: isSizeSm ? "40px" : "48px", marginRight: "8px" }}
          />
          <div>
            <Typography fontWeight="bold">{t("デフォルトの権限")}</Typography>
            <Typography color={theme.palette.text.secondary} fontSize="14px">
              {t("すべてのメンバーに共通して与えられる権限です。")}
              <Link href="#" onClick={onOpen} underline="hover">
                {t("権限の詳細はこちら。")}
              </Link>
            </Typography>
          </div>
        </DefaultRoleDescription>
        <StyledSelect
          value={defaultRole}
          onChange={onChangeDefaultRole}
          disabled={!isOwner}
          size="small"
        >
          <MenuItem value="subowner">{t("サブルームマスター")}</MenuItem>
          <MenuItem value="player">{t("プレイヤー")}</MenuItem>
          <MenuItem value="audience">{t("見学")}</MenuItem>
          <MenuItem value="denied">
            <ColorDenied>{t("アクセス不可")}</ColorDenied>
          </MenuItem>
        </StyledSelect>
      </DefaultRole>
      <Typography
        variant="caption"
        paragraph
        color={theme.palette.text.secondary}
        marginBottom="16px"
      >
        ※{" "}
        {t(
          "権限による保護は完全ではなく、旧バージョンのユーザーは設定通りに動作しない可能性があります。"
        )}
      </Typography>
      <div>
        <MemberList />
      </div>
      <RoleDescriptionDialog open={open} onClose={onClose} />
    </>
  );
};

const MemberList = () => {
  const ids = useAppSelector(getRoomMemberIds);

  return (
    <Ul>
      {ids.map((id) => {
        return <MemberListItem key={id} memberId={id} />;
      })}
      <MemberListItemAnonymous />
    </Ul>
  );
};

type MemberListItemProps = {
  memberId: string;
};

const MemberListItem = ({ memberId }: MemberListItemProps) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const ownerId = useAppSelector((state) => getCurrentRoom(state)?.owner);
  const member = useAppSelector((state) => getRoomMemberById(state, memberId));
  const [digest, setDigest] = useState("");
  useEffect(() => {
    uidToDigest(memberId).then(setDigest);
  }, [memberId]);

  const isOwner = useAppSelector(getIsOwner);
  const onChangeRole = useCallback(
    (event: SelectChangeEvent<Role | "default">) => {
      if (!isOwner) {
        return;
      }

      if (isRole(event.target.value)) {
        dispatch(updateCurrentRoomMemberRole(memberId, event.target.value));
      } else if (event.target.value === "default") {
        dispatch(updateCurrentRoomMemberRole(memberId, null));
      }
    },
    [dispatch, memberId, isOwner]
  );

  const [openMenu, setOpenMenu] = useState(false);
  const onToggle = useCallback(() => {
    setOpenMenu((open) => !open);
  }, [setOpenMenu]);
  const anchorEl = useRef<HTMLButtonElement>(null);

  const onDeleteMember = useCallback(() => {
    if (!isOwner) {
      return;
    }

    if (
      window.confirm(
        t("本当に削除しますか？ 削除すると、このメンバーの権限も削除されます。")
      )
    ) {
      dispatch(deleteCurrentRoomMember(memberId));
      onToggle();
    }
  }, [memberId, isOwner, t, dispatch, onToggle]);

  if (member == null || member.isAnonymous) {
    return null;
  }

  return (
    <List>
      <ListUser>
        <Avatar
          src={member.photoUrl || "/ccfolia.png"}
          sx={{ width: 48, height: 48 }}
        />
        <ListMain>
          <Typography fontWeight="bold">
            {member.displayName || t("匿名ユーザー")}
          </Typography>
          <Typography fontSize="14px" color={theme.palette.text.secondary}>
            {digest}
          </Typography>
        </ListMain>
      </ListUser>
      {ownerId === memberId ? (
        <RoomMaster noWrap minWidth="200px">
          {t("ルームマスター")}
        </RoomMaster>
      ) : (
        <Actions>
          <StyledSelect
            value={member.role || "default"}
            onChange={onChangeRole}
            disabled={!isOwner}
            size="small"
          >
            <MenuItem value="default">{t("ルームのデフォルト権限")}</MenuItem>
            <MenuItem value="subowner">{t("サブルームマスター")}</MenuItem>
            <MenuItem value="player">{t("プレイヤー")}</MenuItem>
            <MenuItem value="audience">{t("見学")}</MenuItem>
            <MenuItem value="denied">
              <ColorDenied>{t("アクセス不可")}</ColorDenied>
            </MenuItem>
          </StyledSelect>
          <IconButton
            onClick={onToggle}
            ref={anchorEl}
            style={{ marginLeft: "4px" }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            open={openMenu}
            onClose={onToggle}
            anchorEl={anchorEl.current}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MenuItem disabled={!isOwner} onClick={onDeleteMember}>
              {t("メンバーリストから削除")}
            </MenuItem>
          </Menu>
        </Actions>
      )}
    </List>
  );
};

const MemberListItemAnonymous = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const isOwner = useAppSelector(getIsOwner);
  const defaultAnonymousRole = useAppSelector((state) => {
    const room = getCurrentRoom(state);
    return room?.defaultAnonymousRole;
  });
  const anonynouses = useAppSelector(getRoomMembersAnonymousCounts);

  const onChangeRole = useCallback(
    (event: SelectChangeEvent<Role | "default">) => {
      if (isRole(event.target.value) && event.target.value !== "subowner") {
        dispatch(
          updateCurrentRoom({ defaultAnonymousRole: event.target.value })
        );
      } else if (event.target.value === "default") {
        dispatch(updateCurrentRoom({ defaultAnonymousRole: null }));
      }
    },
    [dispatch]
  );

  return (
    <List>
      <ListUser>
        <Avatar src={"/ccfolia.png"} sx={{ width: 48, height: 48 }} />
        <ListMain>
          <Typography fontWeight="bold">
            {t("ゲストユーザー")}{" "}
            <Members>（{t("{{count}}人", { count: anonynouses })}）</Members>
          </Typography>
        </ListMain>
      </ListUser>
      <Actions>
        <StyledSelect
          value={defaultAnonymousRole || "default"}
          onChange={onChangeRole}
          disabled={!isOwner}
          size="small"
        >
          <MenuItem value="default">{t("ルームのデフォルト権限")}</MenuItem>
          <MenuItem value="subowner" disabled>
            {t("サブルームマスター")}
          </MenuItem>
          <MenuItem value="player">{t("プレイヤー")}</MenuItem>
          <MenuItem value="audience">{t("見学")}</MenuItem>
          <MenuItem value="denied">
            <ColorDenied>{t("アクセス不可")}</ColorDenied>
          </MenuItem>
        </StyledSelect>
        <IconButton style={{ marginLeft: "4px" }} disabled>
          <MoreVertIcon />
        </IconButton>
      </Actions>
    </List>
  );
};

const Heading = styled(Typography).attrs({ variant: "h2" })`
  margin: 0;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
`;

const Members = styled.small`
  font-weight: normal;
`;

const DefaultRole = styled.div`
  display: flex;
  align-items: center;

  background: #202020;
  border-radius: 4px;
  padding: 16px;
  margin: 24px 0 12px 0;

  & > div + div {
    margin-left: 8px;
  }

  ${theme.breakpoints.down("md")} {
    flex-direction: column;

    & > div + div {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;

const DefaultRoleDescription = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
`;

const Actions = styled.div`
  display: flex;

  ${theme.breakpoints.down("md")} {
    flex: 0;
    width: 100%;
  }
`;

const StyledSelect = styled(Select)`
  width: 200px;
  flex: 1 0 200px;

  ${theme.breakpoints.down("md")} {
    flex: 1 0;
    width: 100%;
  }
`;

const RoomMaster = styled(Typography)`
  text-align: right;

  ${theme.breakpoints.down("md")} {
    text-align: left;
  }
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
`;

const List = styled.li`
  display: flex;
  align-items: center;
  padding: 16px 0;

  & + & {
    border-top: 1px solid ${theme.palette.divider};
  }

  ${theme.breakpoints.down("md")} {
    padding: 24px 0;
    flex-direction: column;
    align-items: unset;
  }
`;

const ListUser = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${theme.breakpoints.down("md")} {
    margin-bottom: 16px;
  }
`;

const ListMain = styled.div`
  margin: 0 16px;
  width: 100%;
`;

const ColorDenied = styled.span`
  color: ${theme.palette.secondary.main};
`;

export default memo(RoleSettings);
