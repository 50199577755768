import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { getGamesPurchasedProductIds } from "api";
import { memo } from "react";
import { useAppSelector } from "stores";
import ExtentionItem from "./ExtentionItem";
import { getAppState } from "stores/modules/app.state/selectors";
import { getAuthedUid } from "stores/modules/app.user/selectors";
import useSWR from "swr";
import { useTranslation } from "react-i18next";

const ExtentionsDialogContent = () => {
  const [t] = useTranslation();

  const product = useAppSelector((state) =>
    getAppState(state, "parentProduct")
  );
  const uid = useAppSelector(getAuthedUid);
  const {
    data: purchasedProductIds,
    isLoading: isLoadingPurchase,
    error,
  } = useSWR(
    uid ? ["getGamesPurchasedProductIds", uid] : null,
    getGamesPurchasedProductIds
  );

  if (product == null || isLoadingPurchase) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        margin="16px 0"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (purchasedProductIds == null || error) {
    return (
      <Typography variant="body1" marginTop="16px">
        {t("読み込みに失敗しました")}
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="h5" marginTop="16px">
        {product.name}
      </Typography>
      <Grid container spacing={2} alignItems="stretch" marginTop="0">
        {product.relatedProducts.map((extention) => (
          <ExtentionItem
            key={extention.id}
            productId={extention.id}
            name={extention.shortName}
            thumbnailUrl={extention.thumbnailUrl}
            purchased={purchasedProductIds.includes(extention.id)}
          />
        ))}
      </Grid>
    </>
  );
};

export default memo(ExtentionsDialogContent);
