import React, { useCallback, useState, memo } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { List, ListItem, ListItemText, DialogContent } from "@mui/material";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import MediumListItem from "../MediumListItem";
import MediumListItemMenu from "../MediumListItemMenu";

import { useTranslation } from "react-i18next";

const dialogContentStyle = {
  padding: 0,
  paddingBottom: 96,
  flex: 1,
  overflow: "auto",
};

type CurrentMedium = {
  mediumId?: string;
  anchorEl?: Element;
};

const MediumList = () => {
  const d = useAppDispatch();
  const [t] = useTranslation();
  const [current, setCurrent] = useState<CurrentMedium>({});
  const [uid, mediumIds, dir] = useAppSelector((state) => {
    return [
      store.getAppState(state, "uid"),
      store.getCurrentUserMediumIds(state),
      store.getAppState(state, "userMediumListLibraryTab"),
    ] as const;
  }, shallowEqual);
  const draggingUserMediumId = useAppSelector((state) => {
    return store.getAppState(state, "draggingUserMediumId");
  });
  const onReorder = useCallback(
    (order) => {
      if (uid) {
        d(store.reorderMedia(uid, dir, order));
        d(
          store.appStateMutate((state) => {
            state.draggingUserMediumId = null;
          })
        );
      }
    },
    [uid, dir, d]
  );
  const onDragStart = useCallback(
    (e) => {
      d(
        store.appStateMutate((state) => {
          state.draggingUserMediumId = e.draggableId;
        })
      );
    },
    [d]
  );

  return (
    <DialogContent style={dialogContentStyle}>
      <MediumListItemMenu
        key={current.mediumId}
        open={current.mediumId != null}
        uid={uid}
        mediumId={current.mediumId}
        anchorEl={current.anchorEl}
        onClose={() => setCurrent({})}
      />
      <DragDropContext onDragEnd={onReorder} onDragStart={onDragStart}>
        <Droppable
          droppableId="droppable"
          renderClone={(provided) => (
            /** タブ移動時のレイアウト崩れに対応するためドラッグ&ドロップ時にクローンを作成 */
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                color: "white",
                listStyle: "none",
                ...provided.draggableProps.style,
              }}
            >
              <MediumListItem
                mediumId={draggingUserMediumId ?? ""}
                onEdit={setCurrent}
              />
            </div>
          )}
        >
          {(provided) => (
            <List
              {...provided.droppableProps}
              ref={provided.innerRef}
              component="div"
              // disablePadding
              // style={{ width: 280 }}
            >
              {mediumIds.length < 1 ? (
                <ListItem>
                  <ListItemText
                    primary={t(
                      "ドラッグアンドドロップでファイルを追加することができます。"
                    )}
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </ListItem>
              ) : null}
              {mediumIds.map((mediumId, index) => (
                <Draggable key={mediumId} draggableId={mediumId} index={index}>
                  {(provided, _) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <MediumListItem mediumId={mediumId} onEdit={setCurrent} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </DialogContent>
  );
};

export default memo(MediumList);
