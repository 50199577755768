import { setAutoFreeze } from "immer";
import { providers } from "initializer";
import { UserInfo } from "@firebase/auth-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

setAutoFreeze(false);

export type AppUser = {
  uid: string | null;
  isAnonymous: boolean | null;
  displayName: string | null;
  photoUrl: string | null;
  email: string | null;
  emailVerified: boolean;
  providerData: Record<keyof typeof providers, UserInfo | null>;
  plan: "free" | "ccfolia-pro";
};

const initializeState = (): AppUser => {
  return {
    uid: null,
    isAnonymous: null,
    displayName: null,
    photoUrl: null,
    email: null,
    emailVerified: false,
    providerData: { email: null, google: null, twitter: null },
    plan: "free",
  };
};

type MutateFunc = (state: AppUser) => void;

const slice = createSlice({
  name: "app/user",
  initialState: initializeState(),
  reducers: {
    appUserMutate(state, action: PayloadAction<MutateFunc>) {
      action.payload(state);
    },
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
