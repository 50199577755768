import { FormEventHandler, memo, useCallback } from "react";
import { useAppDispatch } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  AppBar,
  Toolbar,
  DialogContent,
  Switch,
  Button,
  TextField,
  DialogActions,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { addUndoDeleteDeck } from "stores/modules/entities.room.decks/operations";

export type FormData = {
  width: number;
  height: number;
  locked: boolean;
  freezed: boolean;
  plane: boolean;
};

type DeckDetailProps = {
  deckId: string;
  onClose: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
};

const DeckDetail = ({ deckId, onClose, onSubmit }: DeckDetailProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const { control, register } = useFormContext<FormData>();

  const onCoverImageSelect = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "cover";
        state.openRoomImageSelectTarget = "deck/updateCoverImage";
      })
    );
  }, [dispatch]);

  const onDelete = useCallback(() => {
    if (window.confirm(t("本当に削除しますか？"))) {
      dispatch(store.deleteRoomDeck(deckId));
      dispatch(
        store.appStateMutate((state) => {
          state.openRoomDeckDetail = false;
        })
      );
      dispatch(addUndoDeleteDeck(deckId));
    }
  }, [deckId, t, dispatch]);

  return (
    <>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar variant="dense">
          <Typography variant="subtitle2">{t("デッキ設定")}</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <Group>
            <TextField
              fullWidth
              variant="standard"
              label={t("横幅")}
              margin="dense"
              type="number"
              {...register("width", { valueAsNumber: true })}
            />
            <TextField
              fullWidth
              variant="standard"
              label={t("縦幅")}
              margin="dense"
              type="number"
              {...register("height", { valueAsNumber: true })}
            />
          </Group>
          <List>
            <ListItem dense>
              <ListItemText
                primary={t("配置固定")}
                secondary={t("現在の位置とサイズでオブジェクトを固定")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="locked"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense>
              <ListItemText
                primary={t("サイズ固定")}
                secondary={t("現在のサイズのみを固定します")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="freezed"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <Button onClick={onCoverImageSelect} variant="outlined" fullWidth>
            {t("背面画像選択")}
          </Button>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDelete} fullWidth color="secondary">
          {t("削除")}
        </Button>
        <Button onClick={onClose} fullWidth color="primary">
          {t("保存")}
        </Button>
      </DialogActions>
    </>
  );
};

const Group = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 16px;
`;

export default memo(DeckDetail);
