import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  Toolbar,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "stores";
import { getAppState } from "stores/modules/app.state/selectors";
import { appStateMutate } from "stores/modules/app.state/operations";
import { getRoomSavedataById } from "stores/modules/entities.room.savedatas/selectors";
import styled from "styled-components";
import { deleteSavedata } from "stores/modules/entities.room.savedatas/operations";

const DeleteConfirm = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const open = useAppSelector((state) => {
    return getAppState(state, "openDeleteSavedataConfirm");
  });

  const savedataId = useAppSelector((state) => {
    return getAppState(state, "openDeleteSavedataConfirmId");
  });

  const savedata = useAppSelector((state) => {
    return getRoomSavedataById(state, savedataId);
  });

  const onClose = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openDeleteSavedataConfirm = false;
      })
    );
  }, [dispatch]);

  const onDelete = useCallback(() => {
    if (savedata != null && savedataId != null) {
      onClose();
      dispatch(deleteSavedata(savedataId));
    }
  }, [savedataId, savedata, dispatch, onClose]);

  return (
    <Dialog open={open && savedata != null} maxWidth="sm" fullWidth>
      <AppBar position="sticky" color="default">
        <Toolbar>
          <Typography variant="subtitle1">
            {t("このセーブデータを削除しますか？")}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogBody>
        <StyledAvatar src={savedata?.thumbnail || undefined} />
        <Typography display="block">
          {savedata?.name || t("名前未設定")}
        </Typography>
      </DialogBody>
      <DialogActions>
        <Button fullWidth color="primary" onClick={onClose}>
          {t("キャンセル")}
        </Button>
        <Button fullWidth color="secondary" onClick={onDelete}>
          {t("削除")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogBody = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  padding-bottom: 0;
`;

const StyledAvatar = styled(Avatar)`
  .MuiAvatar-fallback {
    visibility: hidden;
  }
`;

export default memo(DeleteConfirm);
