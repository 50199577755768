import { DefaultThunk } from "stores";
import { UpdateDeck } from "stores/modules/entities.room.decks";
import {
  deleteRoomDeck,
  updateRoomDecks,
} from "stores/modules/entities.room.decks/operations";
import { UpdateItem } from "stores/modules/entities.room.items";
import {
  addRoomItems,
  deleteRoomItems,
} from "stores/modules/entities.room.items/operations";

export type SourceDeck = {
  id: string;
  deck: UpdateDeck;
};

export type UpdateDeckRecord = {
  kind: "update-deck";
  id: string;
  cards: UpdateItem[] | null;
  before: UpdateDeck | null;
  after: UpdateDeck | null;
};

export const undoUpdateDeck =
  (history: UpdateDeckRecord): DefaultThunk =>
  (dispatch, _) => {
    const before = history.before;
    if (!before) {
      // 変更前データがない場合は山札が作られているため、山札を削除
      dispatch(deleteRoomDeck(history.id));
      if (history.cards) {
        // カード情報がある場合はカードを盤面に追加
        dispatch(addRoomItems(history.cards));
      }
      return;
    }

    // 変更前データがある場合
    dispatch(updateRoomDecks(history.id, before));
    return;
  };

export const redoUpdateDeck =
  (history: UpdateDeckRecord): DefaultThunk =>
  (dispatch, _) => {
    const after = history.after;
    if (!after) {
      // 変更後データがない場合はデッキを削除
      dispatch(deleteRoomDeck(history.id));
      return;
    }

    // 変更後の状態に山札を更新
    dispatch(updateRoomDecks(history.id, after));

    // カードが存在する場合は削除
    if (history.cards) {
      let cardIds: string[] = [];
      history.cards.forEach((card) => {
        if (card._id) {
          cardIds.push(card._id);
        }
      });
      dispatch(deleteRoomItems(cardIds));
    }
    return;
  };
