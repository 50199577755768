import { useState, useCallback, useRef, memo } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { Menu, MenuItem, IconButton, Tooltip } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTranslation } from "react-i18next";
import { downloadRoomLog } from "api";
import { appStateMutate } from "stores/modules/app.state/operations";
import { removeRoomMessageGroup } from "stores/modules/entities.rooms/operations";
import { deleteAllMessages } from "stores/modules/entities.room.messages/operations";
import { getCurrentTabName } from "stores/modules/entities.rooms/selectors";

type ChatMenuProps = {
  uid: string;
  roomId: string;
  standalone: boolean;
};

const ChatMenu = ({ uid, roomId, standalone }: ChatMenuProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);
  const tab = useAppSelector((state) =>
    store.getAppState(state, "roomChatTab")
  );
  const tabName = useAppSelector(getCurrentTabName);
  const isGroupTab = useAppSelector((state) => {
    const room = store.getRoomById(state, roomId);
    return room.messageGroups.findIndex((group) => group.id === tab) >= 0;
  });
  const hasEditableRole = useAppSelector(store.getHasEditableRole);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onDeleteAllMessages = useCallback(() => {
    if (
      window.confirm(
        t("全てのルームログデータを削除します。本当に削除しますか？")
      )
    ) {
      setOpen(false);
      dispatch(deleteAllMessages(roomId)).then(() => {
        window.alert(
          t("ルームログ削除後も再ログインするまでメッセージは保持されます")
        );
      });
    }
  }, [roomId, setOpen, t, dispatch]);

  const onClickPopup = useCallback(() => {
    setOpen(false);
    dispatch(
      appStateMutate((state) => {
        state.openRoomChatPopupWindow = true;
        state.openRoomDisplays = null;
      })
    );
  }, [setOpen, dispatch]);

  const onClickWideMode = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.roomChatWideMode = !state.roomChatWideMode;
      })
    );
  }, [dispatch]);

  const onClickOpenChatTabSetting = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openChatTabSettings = true;
        state.openChatTabSettingsId = tab;
      })
    );
    onClose();
  }, [tab, dispatch, onClose]);

  const onClickExportLog = useCallback(async () => {
    if (!hasEditableRole) {
      return;
    }

    // todo: move to operation
    dispatch(
      appStateMutate((state) => {
        state.loading = true;
      })
    );
    try {
      await downloadRoomLog({
        roomId: roomId,
        uid: uid,
        includeSecret: true,
        channel: null,
        channelName: "all",
      });
    } catch (_) {
      window.alert("This operation is failed.");
    }
    dispatch(
      appStateMutate((state) => {
        state.loading = false;
      })
    );
  }, [roomId, uid, hasEditableRole, dispatch]);

  const onClickExportCurrentTabLog = useCallback(async () => {
    // todo: move to operation
    dispatch(
      appStateMutate((state) => {
        state.loading = true;
      })
    );
    try {
      await downloadRoomLog({
        roomId: roomId,
        uid: uid,
        includeSecret: false,
        channel: tab,
        channelName: tabName,
      });
    } catch (_) {
      window.alert("This operation is failed.");
    }
    dispatch(
      appStateMutate((state) => {
        state.loading = false;
      })
    );
  }, [roomId, uid, tab, dispatch, tabName]);

  const onClickMessageChannelRemove = useCallback(() => {
    if (window.confirm(t("本当に削除しますか？"))) {
      setOpen(false);
      dispatch(removeRoomMessageGroup(tab));
    }
  }, [tab, setOpen, t, dispatch]);

  return (
    <>
      <Tooltip title={t("チャットメニュー")}>
        <IconButton onClick={onOpen} ref={anchorEl} edge="end" size="large">
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Menu open={open} anchorEl={anchorEl.current} onClose={onClose}>
        {!standalone && (
          <MenuItem onClick={onClickPopup}>{t("別窓で表示（beta）")}</MenuItem>
        )}
        {!standalone && (
          <MenuItem onClick={onClickWideMode}>
            {t("ワイドモード切り替え")}
          </MenuItem>
        )}
        <Tooltip title={t("最新10000件")} placement={"left-start"}>
          <MenuItem onClick={onClickExportLog} disabled={!hasEditableRole}>
            {t("全ログ出力")}
          </MenuItem>
        </Tooltip>
        <Tooltip title={t("最新10000件")} placement={"left-start"}>
          <MenuItem onClick={onClickExportCurrentTabLog}>
            {t("ログ出力")}
          </MenuItem>
        </Tooltip>
        <MenuItem
          onClick={onClickOpenChatTabSetting}
          disabled={!isGroupTab || !hasEditableRole}
        >
          {t("タブの編集")}
        </MenuItem>
        <MenuItem
          onClick={onClickMessageChannelRemove}
          disabled={!isGroupTab || !hasEditableRole}
        >
          {t("現在タブ削除")}
        </MenuItem>
        <MenuItem onClick={onDeleteAllMessages} disabled={!hasEditableRole}>
          {t("ルームログ削除")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default memo(ChatMenu);
