import { TouchEvent, useEffect, useCallback, useRef, useMemo } from "react";

export type LongTap = {
  onTouchStart: (e: TouchEvent) => void;
  onTouchMove: (e: TouchEvent) => void;
  onTouchEnd: (e: TouchEvent) => void;
};

export default function useLongTap(onPress: (e: TouchEvent) => void): LongTap {
  const timer = useRef<number | null>(null);

  const handlePressStart = useCallback(
    (e: TouchEvent) => {
      e.persist();
      e.preventDefault();
      if (timer.current != null) {
        clearTimeout(timer.current);
      }
      timer.current = window.setTimeout(() => onPress(e), 480);
    },
    [timer, onPress]
  );

  const handlePressEnd = useCallback(() => {
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
  }, [timer]);

  useEffect(() => {
    return () => {
      if (timer.current != null) {
        clearTimeout(timer.current);
      }
    };
  }, [timer]);

  return useMemo(
    () => ({
      onTouchStart: handlePressStart,
      onTouchMove: handlePressEnd,
      onTouchEnd: handlePressEnd,
    }),
    [handlePressStart, handlePressEnd]
  );
}
