import { actions } from "./slice";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils/operators";
import {
  getRoomSavedataById,
  getRoomSavedataByOrder,
  getRoomSavedataIdByOrder,
} from "./selectors";
import {
  Savedata,
  SavedataRecord,
  Snapshot,
  SnapshotRecord,
  UpdateSavedata,
  UpdateSnapshot,
} from "./records";
import { DefaultThunk } from "stores";
import { getAppState } from "../app.state/selectors";
import { getCurrentRoom } from "../entities.rooms/selectors";
import shortid from "shortid";
import equal from "fast-deep-equal";
import {
  getRoomItemById,
  getRoomItemIds,
} from "../entities.room.items/selectors";
import { itemsRef } from "../entities.room.items/operations";
import { HugeBatch } from "modules/hugeBatch";
import { DefaultRootState } from "stores";
import {
  getCharacterById,
  getRoomCharacterIds,
} from "../entities.room.characters/selectors";
import { Item } from "../entities.room.items";
import { Character } from "../entities.room.characters";
import { charactersRef } from "../entities.room.characters/operations";
import { Deck } from "../entities.room.decks";
import {
  getRoomDeckById,
  getRoomDeckIds,
} from "../entities.room.decks/selectors";
import { decksRef } from "../entities.room.decks/operations";
import { DiceItem } from "../entities.room.dices";
import {
  getRoomDiceById,
  getRoomDiceIds,
} from "../entities.room.dices/selectors";
import { Effect } from "../entities.room.effects";
import {
  getRoomEffectById,
  getRoomEffectIds,
} from "../entities.room.effects/selectors";
import { Note } from "../entities.room.notes";
import {
  getRoomNoteById,
  getRoomNoteIds,
} from "../entities.room.notes/selectors";
import { Scene } from "../entities.room.scenes";
import {
  getRoomSceneById,
  getRoomSceneIds,
} from "../entities.room.scenes/selectors";
import { scenesRef } from "../entities.room.scenes/operations";
import { dicesRef } from "../entities.room.dices/operations";
import { effectsRef } from "../entities.room.effects/operations";
import { notesRef } from "../entities.room.notes/operations";
import { roomsRef } from "../entities.rooms/operations";
import {
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  collection,
  deleteDoc,
  doc,
  getDoc,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import {
  createSnapshot,
  deleteSnapshot as deleteSnapshotApi,
  getSnapshot,
  importSnapshot,
} from "api";
import { omit } from "lodash-es";

const savedataConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(item: UpdateSavedata) {
    return item;
  },
  fromFirestore(snapshot, options): Savedata {
    const data = snapshot.data(options)!;
    return SavedataRecord(data);
  },
};

export const savedatasRef = (
  roomId: string
): CollectionReference<UpdateSavedata> =>
  collection(db, "rooms", roomId, "savedatas").withConverter(savedataConverter);

export const snapshotsRef = (roomId: string) =>
  collection(db, "rooms", roomId, "snapshots");

export const subscribeRoomSavedatas = createSubscribeCollection(
  actions,
  (roomId: string) => query(savedatasRef(roomId), orderBy("order"))
);

export const saveRoom =
  (slot: number, name: string): DefaultThunk<Promise<void>> =>
  async (_, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    if (!roomId) return;

    // get entities
    const currentRoom = getCurrentRoom(state);
    if (currentRoom == null) {
      return;
    }

    const { snapshotId } = await createSnapshot(roomId);

    const prevSavedataId = getRoomSavedataIdByOrder(state, slot);
    const prevSavedata = getRoomSavedataByOrder(state, slot);

    const now = Date.now();
    const savedataId = prevSavedataId || shortid();
    const savedata: UpdateSavedata = {
      name,
      thumbnail: currentRoom.foregroundUrl,
      snapshotVersion: "2",
      snapshotId,
      order: slot,
      updatedAt: now,
      createdAt: now,
    };

    await setDoc(doc(savedatasRef(roomId), savedataId), savedata);
    if (prevSavedata) {
      return deleteSnapshot(roomId, prevSavedata);
    }
  };

export const loadRoomBySlot =
  (slot: number): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const savedataId = getRoomSavedataIdByOrder(state, slot);
    if (savedataId) {
      dispatch(loadRoom(savedataId));
    }
  };

export const loadRoom =
  (savedataId: string): DefaultThunk<Promise<void>> =>
  async (_, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const uid = getAppState(state, "uid");
    const savedata = getRoomSavedataById(state, savedataId);
    if (!roomId || !uid || savedata == null) {
      return;
    }

    const partialSnapshot = await fetchSnapshot(roomId, savedata);
    if (partialSnapshot == null) {
      return;
    }

    loadSnapshot(partialSnapshot, state);
  };

export const loadRoomBySnapshotId =
  (snapshotId: string): DefaultThunk<Promise<void>> =>
  async (_, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const uid = getAppState(state, "uid");
    if (!roomId || !uid) {
      return;
    }

    const partialSnapshot = await fetchSnapshotV2(roomId, snapshotId);
    if (partialSnapshot == null) {
      return;
    }

    loadSnapshot(partialSnapshot, state);
  };

const loadSnapshot = async (
  partialSnapshot: Partial<Snapshot>,
  state: DefaultRootState
): Promise<void> => {
  const roomId = getAppState(state, "roomId");
  const uid = getAppState(state, "uid");
  if (!roomId || !uid) {
    return;
  }

  const snapshot = SnapshotRecord(partialSnapshot);
  fillCharacterOwnerIfNull(snapshot, uid);

  const batch = new HugeBatch();
  batch.update<DocumentData>(
    doc(roomsRef, roomId),
    omit(snapshot.room, "name")
  );
  syncEntitie<Character>({
    state,
    roomId,
    getIds: getRoomCharacterIds,
    getById: getCharacterById,
    ref: charactersRef,
    batch,
    snapshotEntities: snapshot.characters,
  });
  syncEntitie<Deck>({
    state,
    roomId,
    getIds: getRoomDeckIds,
    getById: getRoomDeckById,
    ref: decksRef,
    batch,
    snapshotEntities: snapshot.decks,
  });
  syncEntitie<DiceItem>({
    state,
    roomId,
    getIds: getRoomDiceIds,
    getById: getRoomDiceById,
    ref: dicesRef,
    batch,
    snapshotEntities: snapshot.dices,
  });
  syncEntitie<Effect>({
    state,
    roomId,
    getIds: getRoomEffectIds,
    getById: getRoomEffectById,
    ref: effectsRef,
    batch,
    snapshotEntities: snapshot.effects,
  });
  syncEntitie<Item>({
    state,
    roomId,
    getIds: getRoomItemIds,
    getById: getRoomItemById,
    ref: itemsRef,
    batch,
    snapshotEntities: snapshot.items,
  });
  syncEntitie<Note>({
    state,
    roomId,
    getIds: getRoomNoteIds,
    getById: getRoomNoteById,
    ref: notesRef,
    batch,
    snapshotEntities: snapshot.notes,
  });
  syncEntitie<Scene>({
    state,
    roomId,
    getIds: getRoomSceneIds,
    getById: getRoomSceneById,
    ref: scenesRef,
    batch,
    snapshotEntities: snapshot.scenes,
  });

  return batch.commit();
};

export const fetchSnapshot = (roomId: string, savedata: Savedata) => {
  switch (savedata.snapshotVersion) {
    case "1":
      return fetchSnapshotV1(roomId, savedata.snapshotId);
    case "2":
      return fetchSnapshotV2(roomId, savedata.snapshotId);
  }
};

// from firestore
const fetchSnapshotV1 = async (roomId: string, snapshotId: string) => {
  const docSnapshot = await getDoc(doc(snapshotsRef(roomId), snapshotId));

  if (!docSnapshot.exists) {
    return;
  }

  return docSnapshot.data();
};

// from cloud storage
const fetchSnapshotV2 = async (roomId: string, snapshotId: string) => {
  return await getSnapshot(roomId, snapshotId);
};

const deleteSnapshot = (roomId: string, savedata: Savedata) => {
  switch (savedata.snapshotVersion) {
    case "1":
      return deleteSnapshotV1(roomId, savedata.snapshotId);
    case "2":
      return deleteSnapshotV2(roomId, savedata.snapshotId);
  }
};

// from firestore
const deleteSnapshotV1 = async (roomId: string, snapshotId: string) => {
  await deleteDoc(doc(snapshotsRef(roomId), snapshotId));
};

const deleteSnapshotV2 = async (roomId: string, snapshotId: string) => {
  await deleteSnapshotApi(roomId, snapshotId);
};

type SyncEntitieProps<T> = {
  state: DefaultRootState;
  roomId: string;
  batch: HugeBatch;
  snapshotEntities: Record<string, T>;
  getIds: (state: DefaultRootState) => string[];
  getById: (state: DefaultRootState, id: string) => T | undefined;
  ref: (roomId: string) => CollectionReference<any>;
};

export const syncEntitie = <T>({
  state,
  roomId,
  getIds,
  getById,
  ref,
  batch,
  snapshotEntities,
}: SyncEntitieProps<T>) => {
  const deleteCandidate = new Set(getIds(state));
  for (const id in snapshotEntities) {
    const snapshotEntitie = snapshotEntities[id];
    const currentEntitie = getById(state, id);
    if (!equal(currentEntitie, snapshotEntitie)) {
      batch.set(doc(ref(roomId), id), snapshotEntitie);
    }

    deleteCandidate.delete(id);
  }

  deleteCandidate.forEach((id) => {
    batch.delete(doc(ref(roomId), id));
  });
};

export const updateRoomSavedata =
  (savedataId: string, savedata: UpdateSavedata): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    if (!roomId) return;

    return updateDoc(doc(savedatasRef(roomId), savedataId), {
      savedata,
      updatedAt: serverTimestamp(),
    });
  };

export const deleteSavedata =
  (savedataId: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const savedata = getRoomSavedataById(state, savedataId);
    const roomId = getAppState(state, "roomId");
    if (roomId == null || savedata == null) {
      return;
    }

    const batch = writeBatch(db);
    batch.delete(doc(savedatasRef(roomId), savedataId));
    if (savedata.snapshotVersion === "1") {
      batch.delete(doc(snapshotsRef(roomId), savedata.snapshotId));
    } else if (savedata.snapshotVersion === "2") {
      deleteSnapshot(roomId, savedata);
    }

    return batch.commit();
  };

export const importRoomSavedatas =
  (savedatas: Record<string, UpdateSavedata>): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    if (!roomId) return;

    const batch = writeBatch(db);
    for (const savedataId in savedatas) {
      const savedata = SavedataRecord({
        ...savedatas[savedataId],
        snapshotVersion: "2",
      });
      const prevSavedataId = getRoomSavedataIdByOrder(state, savedata.order);
      if (prevSavedataId) {
        const prevSavedata = getRoomSavedataById(state, prevSavedataId);
        batch.delete(doc(savedatasRef(roomId), prevSavedataId));
        if (prevSavedata) {
          batch.delete(doc(snapshotsRef(roomId), prevSavedata.snapshotId));
        }
      }

      batch.set(doc(savedatasRef(roomId), savedataId), savedata);
    }

    return batch.commit();
  };

export const importRoomSnapshots =
  (snapshots: Record<string, UpdateSnapshot>): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const uid = getAppState(state, "uid");
    if (!roomId || !uid) return;

    const promises: Promise<unknown>[] = [];
    for (const snapshotId in snapshots) {
      const snapshot = SnapshotRecord(snapshots[snapshotId]);
      changeCharactersOwner(snapshot, uid);

      promises.push(
        importSnapshot(
          roomId,
          snapshotId,
          SnapshotRecord(snapshots[snapshotId])
        )
      );
    }

    return Promise.all(promises);
  };

const changeCharactersOwner = (snapshot: Snapshot, owner: string) => {
  for (const id in snapshot.characters) {
    snapshot.characters[id].owner = owner;
  }
};

const fillCharacterOwnerIfNull = (snapshot: Snapshot, owner: string) => {
  for (const id in snapshot.characters) {
    const character = snapshot.characters[id];
    if (!character.owner) {
      character.owner = owner;
    }
  }
};
