import { DragOverlay, useDraggable } from "@dnd-kit/core";
import { Resizable, ResizeCallback } from "re-resizable";
import React, {
  ComponentPropsWithRef,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { flushSync } from "react-dom";
import { useAppSelector } from "stores";
import { getAppState } from "stores/modules/app.state/selectors";
import styled from "styled-components";

export type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type EnableProps = {
  bottom?: boolean;
  bottomLeft?: boolean;
  bottomRight?: boolean;
  left?: boolean;
  right?: boolean;
  top?: boolean;
  topLeft?: boolean;
  topRight?: boolean;
};
export type DraggableData = {
  initialPosition: Position;
  onUpdate: (position: Position) => void;
  alignWithGrid: boolean;
};

type DraggableItemProps = {
  style?: React.CSSProperties;
  initialPosition: Position;
  draggableId: string;
  disableDragging: boolean;
  enableResizing: boolean;
  enableProps?: EnableProps;
  lockAspectRatio?: boolean;
  alignWithGrid: boolean;
  setDragging?: (dragging: boolean) => void;
  onUpdate: (position: Position) => void;
} & ComponentPropsWithRef<"div">;

const DEFAULT_ENABLE_PROPS = {
  bottom: true,
  bottomLeft: true,
  bottomRight: true,
  left: true,
  right: true,
  top: true,
  topLeft: true,
  topRight: true,
};

const DraggableItem = ({
  style,
  initialPosition,
  draggableId,
  disableDragging,
  enableResizing,
  enableProps,
  lockAspectRatio,
  alignWithGrid,
  setDragging,
  onUpdate,
  children,
  ...props
}: DraggableItemProps) => {
  const cellSize = useAppSelector((state) =>
    getAppState(state, "roomScreenCellSize")
  );
  const scale = useAppSelector((state) =>
    getAppState(state, "roomScreenScale")
  );

  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: initialPosition.x,
    y: initialPosition.y,
  });
  const [resizePosition, setResizePosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });
  const [size, setSize] = useState({
    width: initialPosition.width * cellSize,
    height: initialPosition.height * cellSize,
  });

  useEffect(() => {
    setPosition({
      x: initialPosition.x,
      y: initialPosition.y,
    });
  }, [initialPosition.x, initialPosition.y]);

  useEffect(() => {
    setSize({
      width: initialPosition.width * cellSize,
      height: initialPosition.height * cellSize,
    });
  }, [initialPosition.width, initialPosition.height, cellSize]);

  const handleUpdate = useCallback(
    (updatePosition: Position) => {
      setPosition({
        x: updatePosition.x,
        y: updatePosition.y,
      });
      onUpdate(updatePosition);
    },
    [onUpdate]
  );

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: draggableId,
      data: {
        initialPosition: initialPosition,
        onUpdate: handleUpdate,
        alignWithGrid: alignWithGrid,
      },
      disabled: disableDragging,
    });

  useEffect(() => {
    if (setDragging) {
      setDragging(isDragging);
    }
  }, [setDragging, isDragging]);

  const handleResize: ResizeCallback = useCallback(
    (_e, d, _ref, delta) => {
      const newResizePosition = {
        x: 0,
        y: 0,
      };
      const upperDirection = d.toUpperCase();

      if (upperDirection.includes("TOP")) {
        newResizePosition.y = Math.round(delta.height / cellSize);
      }

      if (upperDirection.includes("LEFT")) {
        newResizePosition.x = Math.round(delta.width / cellSize);
      }

      if (upperDirection.includes("TOP") || upperDirection.includes("LEFT")) {
        flushSync(() => {
          setResizePosition(newResizePosition);
        });
      }
    },
    [cellSize]
  );

  const onResizeStop: ResizeCallback = useCallback(
    (_e, _b, ref, delta) => {
      if (delta.width || delta.height) {
        const updatePosition = {
          x: position.x - resizePosition.x,
          y: position.y - resizePosition.y,
          width: Math.round(Math.trunc(ref.offsetWidth) / cellSize),
          height: Math.round(Math.trunc(ref.offsetHeight) / cellSize),
        };

        setSize({
          width: Math.round(Math.trunc(ref.offsetWidth) / cellSize) * cellSize,
          height:
            Math.round(Math.trunc(ref.offsetHeight) / cellSize) * cellSize,
        });
        handleUpdate(updatePosition);
        setResizePosition({
          x: 0,
          y: 0,
        });
      }
    },
    [handleUpdate, position, cellSize, resizePosition]
  );

  const positionX =
    (position.x - resizePosition.x) * cellSize + (transform?.x || 0) / scale;
  const positionY =
    (position.y - resizePosition.y) * cellSize + (transform?.y || 0) / scale;

  return (
    <>
      <DraggableContent
        className="movable"
        ref={setNodeRef}
        style={{
          ...style,
          cursor: disableDragging ? "auto" : "move",
          transform: `translate(${positionX}px, ${positionY}px)`,
        }}
        {...props}
      >
        <Resizable
          size={size}
          scale={scale}
          minHeight={cellSize}
          minWidth={cellSize}
          enable={
            enableResizing
              ? enableProps
                ? enableProps
                : DEFAULT_ENABLE_PROPS
              : {}
          }
          grid={[cellSize, cellSize]}
          onResize={handleResize}
          onResizeStop={onResizeStop}
          lockAspectRatio={lockAspectRatio}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
            {...attributes}
            {...listeners}
          >
            {children}
          </div>
        </Resizable>
      </DraggableContent>
      {isDragging && (
        <DragOverlay
          style={{
            ...style,
            ...size,
            transform: `translate(${positionX}px, ${positionY}px)`,
            top: 0,
            left: 0,
            cursor: "move",
            pointerEvents: "none",
          }}
        >
          {children}
        </DragOverlay>
      )}
    </>
  );
};

const DraggableContent = styled.div`
  position: absolute;
  user-select: auto;
  touch-action: none;
  display: inline-block;
  top: 0;
  left: 0;
  box-sizing: border-box;
`;

export default memo(DraggableItem);
