import React, { useState, useCallback, useRef, memo, useMemo } from "react";
import { shallowEqual } from "react-redux";
import { DefaultRootState } from "stores";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import theme from "theme";
import store from "stores/interfaces";

import {
  Avatar,
  MenuList,
  MenuItem,
  Tooltip,
  Typography,
  Popover,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import FaceIcon from "@mui/icons-material/Face";
import AddCommentIcon from "@mui/icons-material/AddComment";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LanguageIcon from "@mui/icons-material/Language";
import LayersIcon from "@mui/icons-material/Layers";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";
import RoomMenu from "./RoomMenu";
import UserMenu from "./UserMenu";

type HeaderProps = {
  roomId: string;
  underConstruction?: boolean;
  setLoginOpen: (open: boolean) => void;
};

const getHeaderStateSelector =
  (roomId: string) => (state: DefaultRootState) => {
    const room = store.getRoomById(state, roomId);
    return {
      name: room.name,
      diceBotName: room.diceBotName,
      isPublishedRoom: room.publishedRoomPackageId != null,
      hasParentRoomPackage: room.parentRoomPackageId != null,
      photoUrl: store.getAppState(state, "photoUrl"),
      isRoleAudience: store.getIsRoleAudience(state),
      hasEditableRole: store.getHasEditableRole(state),
      openRight: !!store.getAppState(state, "openRoomControls"),
      openLeft: !!store.getAppState(state, "openRoomDisplays"),
      wideMode: store.getAppState(state, "roomChatWideMode"),
      width: store.getMenuWidth(state),
    };
  };

const Header = (props: HeaderProps) => {
  const { underConstruction } = props;
  const headerStateSelector = useMemo(
    () => getHeaderStateSelector(props.roomId),
    [props.roomId]
  );
  const state = useAppSelector(headerStateSelector, shallowEqual);
  const pdfOpen = useAppSelector((state) => state.app.state.openPDFViewer);
  const pdfWidth = useAppSelector(
    (state) => state.app.state.openPDFViewerWidth
  );
  const $ = useEnhance(props);
  const [t] = useTranslation();

  return (
    <>
      <RoomAppBar color="default" elevation={0}>
        <Toolbar>
          <Button
            color="white"
            onClick={$.onClickRoomMenu}
            disabled={underConstruction}
            style={{ textTransform: "none" }}
            startIcon={
              <RoomIcon
                isPublishedRoom={state.isPublishedRoom}
                hasParentRoomPackage={state.hasParentRoomPackage}
              />
            }
            endIcon={<ExpandMoreIcon />}
            ref={$.anchorRoomMenuEl}
          >
            <Typography variant="subtitle2" noWrap>
              {state.name}{" "}
              <Typography variant="caption">
                {state.diceBotName || t("クリックしてダイスボットを選択")}
              </Typography>
            </Typography>
          </Button>
          <RoomMenu
            open={$.openRoomMenu}
            anchorEl={$.anchorRoomMenuEl.current}
            onClose={$.onCloseRoomMenu}
          />
          <Spacer />
          <Tooltip title={`${t("マイキャラクター一覧")}`}>
            <ToolbarButton
              onClick={$.onClickCharacterList}
              disabled={underConstruction || state.isRoleAudience}
            >
              <FaceIcon />
            </ToolbarButton>
          </Tooltip>
          <Tooltip title={"[GM] " + t("スクリーンパネル一覧")}>
            <ToolbarButton
              onClick={$.onClickPanelList}
              disabled={!state.hasEditableRole}
            >
              <LayersIcon />
            </ToolbarButton>
          </Tooltip>
          <Tooltip title={"[GM] " + t("マーカーパネル一覧")}>
            <ToolbarButton
              onClick={$.onClickMarkerList}
              disabled={!state.hasEditableRole}
            >
              <LayersOutlinedIcon />
            </ToolbarButton>
          </Tooltip>
          <Tooltip title={"[GM] " + t("シナリオテキスト一覧")}>
            <ToolbarButton
              onClick={$.onClickNoteList}
              disabled={!state.hasEditableRole}
            >
              <AddCommentIcon />
            </ToolbarButton>
          </Tooltip>
          <Tooltip title={"[GM] " + t("シーン一覧")}>
            <ToolbarButton
              onClick={$.onClickSceneList}
              disabled={!state.hasEditableRole}
            >
              <OndemandVideoIcon />
            </ToolbarButton>
          </Tooltip>
          <Tooltip title={"[GM] " + t("カットイン一覧")}>
            <ToolbarButton
              onClick={$.onClickCutList}
              disabled={!state.hasEditableRole}
            >
              <MovieFilterIcon />
            </ToolbarButton>
          </Tooltip>
          <Tooltip title={t("メニュー")}>
            <ToolbarButtonSP
              onClick={$.onClickMenu}
              ref={$.ancherMenuEl}
              disabled={underConstruction}
            >
              <MoreVertIcon />
            </ToolbarButtonSP>
          </Tooltip>
          <AvatarIconButton
            onClick={$.onClickUserMenu}
            size="small"
            edge="end"
            ref={$.anchorEl}
          >
            <Avatar
              src={toCDNUrl(state.photoUrl) || "/ccfolia.png"}
              imgProps={{ draggable: false }}
            />
          </AvatarIconButton>
          <Expander
            openRight={
              !underConstruction && (state.openRight || state.openLeft)
            }
            width={pdfOpen ? pdfWidth : state.width}
          />
          {underConstruction || (
            <FilledButton onClick={$.onClickChat}>
              <FirstPageIcon />
            </FilledButton>
          )}
        </Toolbar>
      </RoomAppBar>
      <UserMenu
        open={$.openUserMenu}
        anchorEl={$.anchorEl.current}
        onClose={$.onClickUserMenu}
        onOpenLogin={$.onClickLogin}
      />
      <Popover
        open={$.openMenu}
        anchorEl={$.ancherMenuEl.current}
        onClose={$.onClickMenu}
      >
        <MenuList>
          <MenuItem
            onClick={$.onClickCharacterList}
            disabled={underConstruction || state.isRoleAudience}
          >
            {t("マイキャラクター")}
          </MenuItem>
          <MenuItem
            onClick={$.onClickPanelList}
            disabled={!state.hasEditableRole}
          >
            [GM] {t("スクリーンパネル")}
          </MenuItem>
          <MenuItem
            onClick={$.onClickMarkerList}
            disabled={!state.hasEditableRole}
          >
            [GM] {t("マーカーパネル")}
          </MenuItem>
          <MenuItem
            onClick={$.onClickNoteList}
            disabled={!state.hasEditableRole}
          >
            [GM] {t("シナリオテキスト")}
          </MenuItem>
          <MenuItem
            onClick={$.onClickSceneList}
            disabled={!state.hasEditableRole}
          >
            [GM] {t("シーン")}
          </MenuItem>
          <MenuItem
            onClick={$.onClickCutList}
            disabled={!state.hasEditableRole}
          >
            [GM] {t("カットイン")}
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};

const MENU_MIN_WIDTH = "16px";

const Spacer = styled.div`
  flex-grow: 1;
`;

const AvatarIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    margin-left: 8px;
  }
`;

type ExpanderProps = {
  width: string;
  openRight: boolean;
};

const Expander = styled.div<ExpanderProps>`
  width: ${({ width, openRight }: ExpanderProps) =>
    openRight ? width : MENU_MIN_WIDTH};
  min-width: ${({ width, openRight }: ExpanderProps) =>
    openRight ? width : MENU_MIN_WIDTH};
  ${theme.breakpoints.down("sm")} {
    width: ${MENU_MIN_WIDTH};
    min-width: ${MENU_MIN_WIDTH};
  }
`;

const FilledButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 20px 0;
  height: 64px;
  width: 24px;
  min-width: 24px;
  display: block;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.4);
  ${theme.breakpoints.down("sm")} {
    padding: 16px 0;
    height: 56px;
  }
`;

const RoomAppBar = styled(AppBar)`
  background: transparent;
  transition: background 200ms ease-out;
  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  ${theme.breakpoints.down("sm")} {
    background: rgba(0, 0, 0, 0.4);
  }
`;

const ToolbarButton = styled(IconButton)`
  ${theme.breakpoints.down("md")} {
    display: none;
  }
`;

const ToolbarButtonSP = styled(IconButton)`
  ${theme.breakpoints.up("md")} {
    display: none;
  }
`;

const useEnhance = ({ setLoginOpen }: HeaderProps) => {
  const anchorEl = useRef<HTMLButtonElement>(null);
  const ancherMenuEl = useRef<HTMLButtonElement>(null);
  const dispatch = useAppDispatch();
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const onClickUserMenu = useCallback(() => {
    setOpenUserMenu((value) => !value);
  }, [setOpenUserMenu]);

  const [openMenu, setOpenMenu] = useState(false);
  const onClickMenu = useCallback(() => {
    setOpenMenu((value) => !value);
  }, [setOpenMenu]);

  const anchorRoomMenuEl = useRef<HTMLButtonElement>(null);
  const [openRoomMenu, setOpenRoomMenu] = useState(false);
  const onClickRoomMenu = useCallback(() => {
    setOpenRoomMenu(true);
  }, [setOpenRoomMenu]);
  const onCloseRoomMenu = useCallback(() => {
    setOpenRoomMenu(false);
  }, [setOpenRoomMenu]);

  const onClickSceneList = useCallback(() => {
    setOpenMenu(false);
    dispatch(
      store.appStateMutate((state) => {
        const control = "scene";
        state.openRoomControlWindows[control].open =
          !state.openRoomControlWindows[control].open;
      })
    );
  }, [dispatch, setOpenMenu]);

  const onClickCharacterList = useCallback(() => {
    setOpenMenu(false);
    dispatch(
      store.appStateMutate((state) => {
        const control = "character";
        state.openRoomControlWindows[control].open =
          !state.openRoomControlWindows[control].open;
      })
    );
  }, [dispatch, setOpenMenu]);

  const onClickNoteList = useCallback(() => {
    setOpenMenu(false);
    dispatch(
      store.appStateMutate((state) => {
        const control = "note";
        state.openRoomControlWindows[control].open =
          !state.openRoomControlWindows[control].open;
      })
    );
  }, [dispatch, setOpenMenu]);

  const onClickChat = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomDisplays = "chat";
      })
    );
  }, [dispatch]);

  const onClickCutList = useCallback(() => {
    setOpenMenu(false);
    dispatch(
      store.appStateMutate((state) => {
        const control = "cut";
        state.openRoomControlWindows[control].open =
          !state.openRoomControlWindows[control].open;
      })
    );
  }, [dispatch, setOpenMenu]);

  const onClickPanelList = useCallback(() => {
    setOpenMenu(false);
    dispatch(
      store.appStateMutate((state) => {
        const control = "panel";
        state.openRoomControlWindows[control].open =
          !state.openRoomControlWindows[control].open;
      })
    );
  }, [dispatch, setOpenMenu]);

  const onClickMarkerList = useCallback(() => {
    setOpenMenu(false);
    dispatch(
      store.appStateMutate((state) => {
        const control = "marker";
        state.openRoomControlWindows[control].open =
          !state.openRoomControlWindows[control].open;
      })
    );
  }, [dispatch, setOpenMenu]);

  const onClickLogin = useCallback(() => {
    setLoginOpen(true);
  }, [setLoginOpen]);

  return {
    anchorEl,
    openUserMenu,
    onClickUserMenu,
    ancherMenuEl,
    openMenu,
    openRoomMenu,
    anchorRoomMenuEl,
    onClickMenu,
    onClickSceneList,
    onClickCharacterList,
    onClickNoteList,
    onClickCutList,
    onClickPanelList,
    onClickMarkerList,
    onClickRoomMenu,
    onCloseRoomMenu,
    onClickLogin,
    onClickChat,
  };
};

type RoomIconProps = {
  isPublishedRoom: boolean;
  hasParentRoomPackage: boolean;
};

const RoomIcon = ({ isPublishedRoom, hasParentRoomPackage }: RoomIconProps) => {
  if (isPublishedRoom) {
    return <LanguageIcon color="primary" fontSize="small" />;
  }

  if (hasParentRoomPackage) {
    return <WidgetsIcon color="disabled" fontSize="small" />;
  }

  return null;
};

export default memo(Header);
