import {
  Product,
  RoomPackageInfo,
  createRoomFromRoomPackage,
  getGamesProduct,
  getRoomPackage,
} from "api";
import Header from "containers/Header";
import Login from "containers/Login";
import Loader from "containers/Room/Loader";
import RoomPackageCard from "containers/RoomPackageCard";
import RoomPackageCardNotFound from "containers/RoomPackageCardNotFound";
import RoomPackageCardProduct from "containers/RoomPackageCardProduct";
import { getDocs, query, where } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "stores";

import { RouteComponentProps, useHistory } from "react-router-dom";
import { getAppState } from "stores/modules/app.state/selectors";
import { subscribeUserState } from "stores/modules/app.user/operations";
import { roomsRef } from "stores/modules/entities.rooms/operations";
import styled from "styled-components";

type RoomPackageProps = RouteComponentProps<{ roomPackageId: string }>;

const RoomPackage = ({ match }: RoomPackageProps) => {
  const { roomPackageId } = match.params;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const uid = useAppSelector((state) => getAppState(state, "uid"));
  const isAnonymous = useAppSelector((state) =>
    getAppState(state, "isAnonymous")
  );
  const [existedRoomId, setExistedRoomId] = useState<string | null | "loading">(
    "loading"
  );
  const [roomPackageInfo, setRoomPackageInfo] = useState<
    RoomPackageInfo | "loading" | "notfound"
  >("loading");
  const [product, setProduct] = useState<Product | "loading" | "notfound">(
    "loading"
  );
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    return dispatch(subscribeUserState());
  }, [dispatch]);

  useEffect(() => {
    if (uid == null || isAnonymous) return;

    getRoomPackage(roomPackageId)
      .then(setRoomPackageInfo)
      .catch(() => {
        setRoomPackageInfo("notfound");
      });
  }, [roomPackageId, uid, isAnonymous]);

  useEffect(() => {
    if (uid == null || isAnonymous) return;

    fetchExistedRoomId(uid, roomPackageId)
      .then(setExistedRoomId)
      .catch(() => {
        setExistedRoomId(null);
      });
  }, [dispatch, roomPackageId, isAnonymous, uid, setExistedRoomId]);

  useEffect(() => {
    if (
      roomPackageInfo === "loading" ||
      roomPackageInfo === "notfound" ||
      roomPackageInfo.purchased ||
      !roomPackageInfo.productId
    ) {
      return;
    }

    getGamesProduct(roomPackageInfo.productId)
      .then((product) => setProduct(product || "notfound"))
      .catch(() => {
        setProduct("notfound");
      });
  }, [roomPackageInfo, setProduct]);

  const onCreateRoom = useCallback(() => {
    setCreating(true);

    Promise.all([
      createRoomFromRoomPackage(roomPackageId),
      new Promise((resolve) => setTimeout(resolve, 2000)),
    ])
      .then(([{ roomId }]) => {
        history.replace(`/rooms/${roomId}`);
      })
      .catch(() => {
        setCreating(false);
        window.alert(t("ルームの作成に失敗しました。"));
      });
  }, [roomPackageId, history, setCreating, t]);

  if (roomPackageInfo === "notfound" || product === "notfound") {
    return (
      <>
        <Header />
        <Wrap>
          <RoomPackageCardNotFound />
        </Wrap>
      </>
    );
  }

  if (isAnonymous) {
    return <Login open isHome />;
  }

  if (
    uid == null ||
    isAnonymous == null ||
    roomPackageInfo === "loading" ||
    existedRoomId === "loading" ||
    (roomPackageInfo.productId &&
      !roomPackageInfo.purchased &&
      product === "loading")
  ) {
    return <Loader loading={true} />;
  }

  if (creating) {
    return (
      <Wrap>
        <ImgLoadingAnimation src="/images/now-loading.png" alt="now loading" />
      </Wrap>
    );
  }

  if (
    roomPackageInfo.productId &&
    !roomPackageInfo.purchased &&
    product !== "loading"
  ) {
    return (
      <>
        <Header />
        <Wrap>
          <RoomPackageCardProduct product={product} />
        </Wrap>
      </>
    );
  }

  return (
    <>
      <Header />
      <Wrap>
        <RoomPackageCard
          ownerDigest={roomPackageInfo.ownerDigest}
          ownerName={roomPackageInfo.ownerName}
          name={roomPackageInfo.name}
          thumbnailUrl={roomPackageInfo.thumbnailUrl}
          updatedAt={roomPackageInfo.updatedAt}
          existedRoomId={existedRoomId}
          onClick={onCreateRoom}
        />
      </Wrap>
    </>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 64px 8px;
`;

const ImgLoadingAnimation = styled.img`
  max-width: 80%;
`;

const fetchExistedRoomId = async (
  uid: string,
  roomPackagesId: string
): Promise<string | null> => {
  const snapshot = await getDocs(
    query(roomsRef, where("owner", "==", uid), where("archived", "==", false))
  );

  const rooms = snapshot.docs
    .map((doc) => {
      const room = doc.data();
      return {
        id: doc.id,
        parentRoomPackageId: room.parentRoomPackageId,
        createdAt: room.createdAt || 0,
      };
    })
    .sort(compareCreatedAtToDesc);

  const index = rooms.findIndex(
    (room) => room.parentRoomPackageId === roomPackagesId
  );
  if (index >= 0) {
    return rooms[index].id;
  }
  return null;
};

interface HasCreatedAt {
  createdAt: number;
}

const compareCreatedAtToDesc = (a: HasCreatedAt, b: HasCreatedAt): number => {
  if (a.createdAt > b.createdAt) {
    return -1;
  }
  if (a.createdAt < b.createdAt) {
    return 1;
  }
  return 0;
};

export default RoomPackage;
