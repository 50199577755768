import { Button, Typography, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import GamesCarousel from "./GamesCarousel";
import useSWR from "swr";
import { getProducts } from "api";
import { useMemo } from "react";
import _ from "lodash";
import theme from "theme";

const Games = () => {
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const {
    data: products,
    isLoading: isLoadingProducts,
    error: errorProducts,
  } = useSWR("getProducts", getProducts);

  //TODO: ローディング時とエラー時表示
  const shuffleProducts = useMemo(() => {
    const gameContents = _.shuffle(products);
    // 最大15件、表示用に抜き出す
    const gameList = _.sampleSize(gameContents, 15);
    const gameListConcat = gameList.concat(gameList);
    return gameListConcat;
  }, [products]);

  return (
    <GamesContainer>
      <GamesTitle>
        <span className="u-font--futura">CCFOLIA GAMES</span>
        で遊ぼう
      </GamesTitle>
      <GamesText color="textSecondary">
        TRPG、ボードゲーム、謎解き、マーダーミステリーなど、ココフォリアで遊べるゲームを毎月リリースしています。
        <br style={{ display: matches ? "block" : "none" }} />
        ココフォリアだけでしか遊べないゲームも、あの有名ゲームも、ココフォリアならいつでもどこでも遊べます。
      </GamesText>
      <GamesSlider>
        <GamesCarousel
          loading={isLoadingProducts}
          reverse={false}
          gamesContents={shuffleProducts}
        />
        <GamesCarousel
          loading={isLoadingProducts}
          reverse
          gamesContents={shuffleProducts}
        />
      </GamesSlider>
      <GamesButton variant="outlined" href="/games">
        ゲームを探す
      </GamesButton>
    </GamesContainer>
  );
};

const GamesContainer = styled.section`
  margin: 120px auto 0;
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  ${theme.breakpoints.down("md")} {
    margin: 80px auto 0;
  }
`;
const GamesTitle = styled.h2`
  font-size: 40px;
  text-align: center;
  color: #fff;
  .u-font--futura {
    font-family: Futura, sans-serif;
  }

  ${theme.breakpoints.down("md")} {
    font-size: 24px;
  }
`;

const GamesSlider = styled.div`
  margin-top: 40px;
  min-height: 380px;
  > * + * {
    margin-top: 24px;
  }
  ${theme.breakpoints.down("md")} {
    min-height: 300px;
  }
`;

const GamesText = styled(Typography)`
  margin: 16px auto 0;
  font-size: 16px;
  line-height: 1.7;
  text-align: center;

  ${theme.breakpoints.down("md")} {
    width: calc(342 / 390 * 100%);
    margin: 24px auto 0;
    text-align: left;
    font-size: 14px;
  }
`;

const GamesButton = styled(Button)`
  display: flex;
  justify-content: center;
  width: 248px;
  height: 56px;
  margin: 40px auto 0;
  font-size: 16px;
  ${theme.breakpoints.down("md")} {
    width: 208px;
  }
`;

export default Games;
