import { DefaultRootState } from "stores";
import { Emote } from "./slice";

export const getEmoteIds = (state: DefaultRootState): string[] => {
  return state.app.emotes.ids;
};

export const getEmoteById = (
  state: DefaultRootState,
  id: string
): Emote | undefined => {
  return state.app.emotes.entities[id];
};
