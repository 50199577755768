import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import {
  Product,
  applyRoomPackage,
  createRoomFromRoomPackage,
  getGamesPurchasedProductIds,
  getProductById,
} from "api";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "stores";
import { getAuthedUid } from "stores/modules/app.user/selectors";
import useSWR from "swr";
import ExtentionCard from "./ExtentionCard";
import styled from "styled-components";
import { saveInitialRoomState } from "stores/modules/entities.rooms/operations";

type CreateRoomFromProductDialogContentProps = {
  productId: string;
  defaultChecked: string | undefined;
};

type FormData = {
  extentions: { productId: string; checked: boolean }[];
};

const CreateRoomFromProductDialogContent = ({
  productId,
  defaultChecked,
}: CreateRoomFromProductDialogContentProps) => {
  const [t] = useTranslation();
  const uid = useAppSelector(getAuthedUid);
  const [creating, setCreating] = useState(false);

  const {
    data: product,
    isLoading,
    error,
  } = useSWR(["getProductById", productId], ([_, productId]) =>
    getProductById(productId)
  );

  const {
    data: purchasedProductIds,
    isLoading: isLoadingPurchase,
    error: errorPurchase,
  } = useSWR(
    uid ? ["getGamesPurchasedProductIds", uid] : null,
    getGamesPurchasedProductIds
  );

  const relatedProducts = useMemo(() => {
    if (!product?.candidateRelatedProducts || !purchasedProductIds) {
      return [];
    }

    return product.candidateRelatedProducts.filter((product) =>
      purchasedProductIds.includes(product.id)
    );
  }, [product?.candidateRelatedProducts, purchasedProductIds]);

  const { control, handleSubmit, reset } = useForm<FormData>({
    defaultValues: { extentions: [] },
  });

  const { fields } = useFieldArray({ control, name: "extentions" });

  useEffect(() => {
    if (relatedProducts.length > 0) {
      reset({
        extentions: relatedProducts.map((product) => {
          return {
            productId: product.id,
            checked: product.id === defaultChecked,
          };
        }),
      });
    }
  }, [relatedProducts, defaultChecked, reset]);

  const createRoom = useCallback(
    async (roomPackageId: string, extentionProducts: Product[]) => {
      try {
        const { roomId } = await createRoomFromRoomPackage(roomPackageId);

        try {
          for (const extention of extentionProducts) {
            if (extention.roomPackageId && extention.applyOptions) {
              await applyRoomPackage(
                roomId,
                extention.roomPackageId,
                extention.applyOptions
              );
            }
          }
        } catch (_) {
          window.alert(t("追加パッケージの適用に失敗しました。"));
        }

        await saveInitialRoomState(roomId);

        window.alert(
          t("ルームを作成しました。新しく作成したルームに移動します。")
        );
        window.location.href = `/rooms/${roomId}`;
      } catch (_) {
        window.alert(t("ルームの作成に失敗しました。"));
      }
    },
    [t]
  );

  const onSubmit = useCallback(
    ({ extentions }: FormData) => {
      if (!product?.roomPackageId) {
        return;
      }

      const extentionProducts = extentions
        .filter(({ checked }) => checked)
        .map(
          ({ productId }) =>
            relatedProducts.find((p) => p.id === productId) as Product
        );

      setCreating(true);
      createRoom(product.roomPackageId, extentionProducts).finally(() => {
        setCreating(false);
      });
    },
    [setCreating, createRoom, relatedProducts, product?.roomPackageId]
  );

  if (isLoading || isLoadingPurchase) {
    return (
      <Box display="flex" justifyContent="center" marginTop="64px">
        <CircularProgress />
      </Box>
    );
  }

  if (error || errorPurchase || product?.candidateRelatedProducts == null) {
    return <Typography>{t("情報の取得に失敗しました")}</Typography>;
  }

  return (
    <>
      <ProductImage src={product.thumbnailUrl} />
      <Typography variant="h6" marginBottom="4px">
        {t("利用する 追加・拡張コンテンツ を選択")}
      </Typography>
      <Typography variant="body2" color="text.secondary" marginBottom="24px">
        {t(
          "追加・拡張コンテンツを使用しない場合は、すべてのコンテンツにチェックを入れずにルームを作成してください。"
        )}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup sx={{ marginBottom: "24px" }}>
          {fields.map((field, index) => (
            <FormControlLabel
              key={field.id}
              disabled={creating}
              control={
                <Controller
                  name={`extentions.${index}.checked`}
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Checkbox
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                      color="secondary"
                      disabled={creating}
                    />
                  )}
                />
              }
              label={
                <ExtentionCard
                  name={relatedProducts[index].name}
                  thumbnailUrl={relatedProducts[index].thumbnailUrl}
                  description={relatedProducts[index].description}
                  disabled={creating}
                />
              }
            />
          ))}
        </FormGroup>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          size="large"
          type="submit"
          disabled={creating}
        >
          {creating && (
            <CircularProgress size={16.6} sx={{ marginRight: "8px" }} />
          )}
          {t("ルームを作成する")}
        </Button>
      </form>
    </>
  );
};

const ProductImage = styled.img`
  width: 100%;
  margin-bottom: 16px;
`;

export default memo(CreateRoomFromProductDialogContent);
