import { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";

import { useTranslation } from "react-i18next";

import NoteListContainer from "./NoteListContainer";
import FloatWindow from "components/FloatWindow";
import { appStateMutate } from "stores/modules/app.state/operations";
import { addRoomNote } from "stores/modules/entities.room.notes/operations";

type NoteListProps = {
  roomId: string;
  uid: string;
};

const NoteList = ({ roomId, uid }: NoteListProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const open = useAppSelector(
    (state) => store.getAppState(state, "openRoomControlWindows").note.open
  );

  const onClose = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomControls = null;
        state.openRoomControlWindows.note.open = false;
      })
    );
  }, [dispatch]);

  const onAdd = useCallback(() => {
    dispatch(addRoomNote(roomId, uid));
  }, [roomId, uid, dispatch]);

  return (
    <FloatWindow
      onClose={onClose}
      onAdd={onAdd}
      open={open}
      title={t("シナリオテキスト一覧")}
    >
      <NoteListContainer roomId={roomId} />
    </FloatWindow>
  );
};

export default memo(NoteList);
