import { actions } from "./slice";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils/operators";
import { getRoomEffectIdsByText, getRoomOrderdEffectIds } from "./selectors";
import { UpdateEffect, EffectRecord } from "./records";
import { DefaultRootState, DefaultThunk } from "stores";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { addUndo } from "../entities.room.histories/slice";
import {
  calcOrderAppendingToTail,
  reorderEntities,
  undoRedoReorderEntities,
} from "../firestoreModuleUtils/ordaring";

export const effectsRef = (roomId: string) =>
  collection(db, "rooms", roomId, "effects");

export const subscribeRoomEffects = createSubscribeCollection(
  actions,
  (roomId: string) => query(effectsRef(roomId), orderBy("order"))
);

export const addRoomEffect =
  (roomId: string, uid: string): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const ids = getRoomOrderdEffectIds(state);
    const data = EffectRecord({
      owner: uid,
      order: calcOrderAppendingToTail(ids, state.entities.roomEffects.entities),
      active: false,
    });
    const doc = await addDoc(effectsRef(roomId), data);
    dispatch(
      addUndo({
        kind: "update-effect",
        id: doc.id,
        before: null,
        after: data,
      })
    );
  };
export const updateRoomEffectName =
  (roomId: string, effectId: string, item: UpdateEffect): DefaultThunk =>
  () => {
    return setDoc(
      doc(effectsRef(roomId), effectId),
      {
        name: item.name,
      },
      { merge: true }
    );
  };
export const updateRoomEffect =
  (roomId: string, effectId: string, item: UpdateEffect): DefaultThunk =>
  () => {
    return setDoc(
      doc(effectsRef(roomId), effectId),
      {
        ...item,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };
export const updateCurrentRoomEffect =
  (item: UpdateEffect): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const effectId = state.app.state.openRoomEffectId;
    if (!roomId || !effectId) return null;
    return setDoc(
      doc(effectsRef(roomId), effectId),
      {
        ...item,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };
export const activeRoomEffectByText =
  (roomId: string, text: string): DefaultThunk =>
  (_, getState) => {
    const effectIds = getRoomEffectIdsByText(getState(), { text });
    return effectIds.map((effectId) => {
      return setDoc(
        doc(effectsRef(roomId), effectId),
        {
          playTime: Date.now(),
          updatedAt: Date.now(),
        },
        { merge: true }
      );
    });
  };
export const deleteRoomEffect = (roomId: string, effectId: string) => () => {
  return deleteDoc(doc(effectsRef(roomId), effectId));
};

export const reorderEffects = reorderEntities({
  selectOrderdIds: getRoomOrderdEffectIds,
  selectEntities: (state: DefaultRootState) =>
    state.entities.roomEffects.entities,
  actionReorder: actions.reorder,
  collectionRef: effectsRef,
  type: "effect",
});

export const undoRedoReorderEffects = undoRedoReorderEntities({
  selectOrderdIds: getRoomOrderdEffectIds,
  selectEntities: (state: DefaultRootState) =>
    state.entities.roomEffects.entities,
  actionReorder: actions.reorder,
  collectionRef: effectsRef,
});

export const importRoomEffects =
  (effectsData: { [effectId: string]: UpdateEffect }): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return null;
    const effectIds = Object.keys(effectsData);
    const batch = writeBatch(db);
    const ref = effectsRef(roomId);
    effectIds.forEach((effectId) => {
      batch.set(doc(ref, effectId), EffectRecord(effectsData[effectId]));
    });
    return batch.commit();
  };
