import { DiceType } from "stores/modules/app.dicerolls";
import { DiceSkinSettings } from "stores/modules/entities.user.setting";

type ModelMap = Record<DiceType, string>;

const wholeCdnUrl = (path: string) => {
  return process.env.REACT_APP_CDN_URL + path;
};

const BASIC_MODELS: ModelMap = {
  d4: "/dice/basic-d4.glb",
  d6: "/dice/basic-d6.glb",
  d8: "/dice/basic-d8.glb",
  d10: "/dice/basic-d10.glb",
  d12: "/dice/basic-d12.glb",
  d20: "/dice/basic-d20.glb",
  d100: "/dice/basic-d100.glb",
};

const MODELS: Record<string, ModelMap> = {
  basic: BASIC_MODELS,
  ccfolia: {
    d4: wholeCdnUrl("/dice_skins/ccfolia/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/ccfolia/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/ccfolia/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/ccfolia/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/ccfolia/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/ccfolia/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/ccfolia/d100.glb"),
  },
  white: {
    d4: wholeCdnUrl("/dice_skins/white/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/white/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/white/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/white/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/white/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/white/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/white/d100.glb"),
  },
  red: {
    d4: wholeCdnUrl("/dice_skins/red/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/red/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/red/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/red/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/red/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/red/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/red/d100.glb"),
  },
  green: {
    d4: wholeCdnUrl("/dice_skins/green/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/green/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/green/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/green/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/green/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/green/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/green/d100.glb"),
  },
  blue: {
    d4: wholeCdnUrl("/dice_skins/blue/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/blue/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/blue/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/blue/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/blue/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/blue/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/blue/d100.glb"),
  },
  "metallic-silver": {
    d4: wholeCdnUrl("/dice_skins/metallic-silver/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/metallic-silver/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/metallic-silver/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/metallic-silver/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/metallic-silver/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/metallic-silver/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/metallic-silver/d100.glb"),
  },
  "metallic-red": {
    d4: wholeCdnUrl("/dice_skins/metallic-red/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/metallic-red/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/metallic-red/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/metallic-red/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/metallic-red/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/metallic-red/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/metallic-red/d100.glb"),
  },
  "metallic-blue": {
    d4: wholeCdnUrl("/dice_skins/metallic-blue/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/metallic-blue/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/metallic-blue/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/metallic-blue/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/metallic-blue/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/metallic-blue/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/metallic-blue/d100.glb"),
  },
  nrr: {
    d4: wholeCdnUrl("/dice_skins/nrr/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/nrr/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/nrr/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/nrr/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/nrr/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/nrr/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/nrr/d100.glb"),
  },
  "toypop-purple": {
    d4: wholeCdnUrl("/dice_skins/toypop-purple/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/toypop-purple/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/toypop-purple/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/toypop-purple/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/toypop-purple/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/toypop-purple/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/toypop-purple/d100.glb"),
  },
  "toypop-pink": {
    d4: wholeCdnUrl("/dice_skins/toypop-pink/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/toypop-pink/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/toypop-pink/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/toypop-pink/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/toypop-pink/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/toypop-pink/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/toypop-pink/d100.glb"),
  },
  "toypop-navy": {
    d4: wholeCdnUrl("/dice_skins/toypop-navy/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/toypop-navy/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/toypop-navy/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/toypop-navy/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/toypop-navy/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/toypop-navy/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/toypop-navy/d100.glb"),
  },
  "jewelry-amethyst": {
    d4: wholeCdnUrl("/dice_skins/jewelry-amethyst/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/jewelry-amethyst/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/jewelry-amethyst/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/jewelry-amethyst/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/jewelry-amethyst/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/jewelry-amethyst/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/jewelry-amethyst/d100.glb"),
  },
  "jewelry-aquamarine": {
    d4: wholeCdnUrl("/dice_skins/jewelry-aquamarine/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/jewelry-aquamarine/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/jewelry-aquamarine/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/jewelry-aquamarine/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/jewelry-aquamarine/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/jewelry-aquamarine/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/jewelry-aquamarine/d100.glb"),
  },
  "jewelry-emerald": {
    d4: wholeCdnUrl("/dice_skins/jewelry-emerald/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/jewelry-emerald/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/jewelry-emerald/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/jewelry-emerald/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/jewelry-emerald/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/jewelry-emerald/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/jewelry-emerald/d100.glb"),
  },
  "buriki-fighter": {
    d4: wholeCdnUrl("/dice_skins/buriki-fighter/d4.glb"),
    d6: wholeCdnUrl("/dice_skins/buriki-fighter/d6.glb"),
    d8: wholeCdnUrl("/dice_skins/buriki-fighter/d8.glb"),
    d10: wholeCdnUrl("/dice_skins/buriki-fighter/d10.glb"),
    d12: wholeCdnUrl("/dice_skins/buriki-fighter/d12.glb"),
    d20: wholeCdnUrl("/dice_skins/buriki-fighter/d20.glb"),
    d100: wholeCdnUrl("/dice_skins/buriki-fighter/d100.glb"),
  },
};

const DEFAULT_DICESKIN_ID = "basic";

type DiceSkin = {
  id: string;
  name: string;
  thumbnailUrl: string;
  requiredPro?: boolean;
};

export const DICESKINS: DiceSkin[] = [
  {
    id: "basic",
    name: "デフォルトダイス",
    thumbnailUrl: "/images/thumbnail-basic-dice.png",
  },
  {
    id: "ccfolia",
    name: "ココフォリアダイス",
    thumbnailUrl: wholeCdnUrl("/dice_skins/ccfolia/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "white",
    name: "カラーダイス ホワイト",
    thumbnailUrl: wholeCdnUrl("/dice_skins/white/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "red",
    name: "カラーダイス レッド",
    thumbnailUrl: wholeCdnUrl("/dice_skins/red/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "green",
    name: "カラーダイス グリーン",
    thumbnailUrl: wholeCdnUrl("/dice_skins/green/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "blue",
    name: "カラーダイス ブルー",
    thumbnailUrl: wholeCdnUrl("/dice_skins/blue/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "metallic-silver",
    name: "メタリックダイス シルバー",
    thumbnailUrl: wholeCdnUrl("/dice_skins/metallic-silver/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "metallic-red",
    name: "メタリックダイス レッド",
    thumbnailUrl: wholeCdnUrl("/dice_skins/metallic-red/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "metallic-blue",
    name: "メタリックダイス ブルー",
    thumbnailUrl: wholeCdnUrl("/dice_skins/metallic-blue/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "toypop-purple",
    name: "トイポップダイス パープル",
    thumbnailUrl: wholeCdnUrl("/dice_skins/toypop-purple/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "toypop-pink",
    name: "トイポップダイス ピンク",
    thumbnailUrl: wholeCdnUrl("/dice_skins/toypop-pink/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "toypop-navy",
    name: "トイポップダイス ネイビー",
    thumbnailUrl: wholeCdnUrl("/dice_skins/toypop-navy/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "jewelry-amethyst",
    name: "宝石ダイス アメジスト",
    thumbnailUrl: wholeCdnUrl("/dice_skins/jewelry-amethyst/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "jewelry-aquamarine",
    name: "宝石ダイス アクアマリン",
    thumbnailUrl: wholeCdnUrl("/dice_skins/jewelry-aquamarine/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "jewelry-emerald",
    name: "宝石ダイス エメラルド",
    thumbnailUrl: wholeCdnUrl("/dice_skins/jewelry-emerald/thumbnail.png"),
    requiredPro: true,
  },
  {
    id: "buriki-fighter",
    name: "ブリキファイターダイス",
    thumbnailUrl: wholeCdnUrl("/dice_skins/buriki-fighter/thumbnail.png"),
    requiredPro: true,
  },
];

const DICE_SKIN_SETTINGS_NRR = {
  d4: "nrr",
  d6: "nrr",
  d8: "nrr",
  d10: "nrr",
  d12: "nrr",
  d20: "nrr",
  d100: "nrr",
};

export const getDiceSkin = (
  diceSkinId: string | undefined
): DiceSkin | undefined => {
  for (const diceSkin of DICESKINS) {
    if (diceSkin.id === diceSkinId) {
      return diceSkin;
    }
  }

  return;
};

export const getModelPath = (
  diceSkinId: string,
  diceType: DiceType
): string => {
  const skin = MODELS[diceSkinId] || BASIC_MODELS;
  return skin[diceType];
};

export const filterDiceSkinSettings = (
  diceSkinSettings: DiceSkinSettings,
  diceBotSystem: string | null,
  isPro: boolean
): DiceSkinSettings => {
  if (diceBotSystem === "NRR") {
    return DICE_SKIN_SETTINGS_NRR;
  }

  return {
    d4: filterDiceSkin(diceSkinSettings.d4, isPro),
    d6: filterDiceSkin(diceSkinSettings.d6, isPro),
    d8: filterDiceSkin(diceSkinSettings.d8, isPro),
    d10: filterDiceSkin(diceSkinSettings.d10, isPro),
    d12: filterDiceSkin(diceSkinSettings.d12, isPro),
    d20: filterDiceSkin(diceSkinSettings.d20, isPro),
    d100: filterDiceSkin(diceSkinSettings.d100, isPro),
  };
};

const filterDiceSkin = (
  diceSkinId: string | undefined,
  isPro: boolean
): string | undefined => {
  const diceSkin = getDiceSkin(diceSkinId);
  if (diceSkin == null || (diceSkin?.requiredPro && !isPro)) {
    return DEFAULT_DICESKIN_ID;
  }

  return diceSkinId;
};
