import { useCallback, useMemo, useEffect, memo } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import FloatWindow from "components/FloatWindow";
import { addMessage } from "stores/modules/entities.room.messages/operations";
import { appChatStateMutate } from "stores/modules/app.chat/operations";
import { appStateMutate } from "stores/modules/app.state/operations";

type ChatpaletProps = {
  roomId: string;
};

const Chatpalet = ({ roomId }: ChatpaletProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomChatpalet")
  );
  const characterId = useAppSelector((state) =>
    store.getAppState(state, "openRoomChatpaletId")
  );
  const character = useAppSelector((state) =>
    store.getCharacterById(state, characterId)
  );
  const text = useAppSelector((state) =>
    store.getAppChatState(state, "inputText")
  );

  const onClickItem = useCallback(
    (selected: string) => {
      if (selected === text) {
        dispatch(addMessage(roomId, characterId));
      } else {
        dispatch(
          appChatStateMutate((state) => {
            state.inputText = selected;
          })
        );
      }
    },
    [roomId, characterId, text, dispatch]
  );

  const chatpaletItems = useMemo(() => {
    if (!character.commands) return [];
    return character.commands.split(/[\r\n]+/).map((s) => String(s));
  }, [character.commands]);

  const onEdit = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomCharacterId = characterId;
        state.openRoomCharacter = true;
      })
    );
  }, [characterId, dispatch]);

  const onClose = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomChatpalet = false;
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!character._id) {
      dispatch(
        appStateMutate((state) => {
          state.openRoomChatpalet = false;
        })
      );
    }
  }, [character._id, dispatch]);

  return (
    <FloatWindow
      onClose={onClose}
      onEdit={onEdit}
      open={open}
      title={t("チャットパレット")}
    >
      <List>
        {chatpaletItems.map((item, index) => (
          <ListItemButton key={index} onClick={() => onClickItem(item)}>
            <ListItemText primary={item} />
          </ListItemButton>
        ))}
      </List>
    </FloatWindow>
  );
};

export default memo(Chatpalet);
