import { doc, onSnapshot } from "firebase/firestore";
import { db } from "initializer";
import { useEffect, useState } from "react";

type CounterData = {
  userCount: {
    all: number;
    login: number;
    guest: number;
  };
  roomCount: {
    all: number;
    new: number;
  };
  diceRoll: {
    all: number;
    failure: number;
    success: number;
  };
};

export const useGetDicerollCount = (): {
  data: CounterData | null;
  loading: boolean;
  error: Error | null;
} => {
  const [data, setData] = useState<CounterData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const docRef = doc(db, "counters", "today");

    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          setData(docSnap.data() as CounterData);
          setLoading(false);
        } else {
          setData(null);
          setLoading(false);
        }
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return { data, loading, error };
};
