import React, { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";

import SceneList from "../SceneList";
import FloatWindow from "components/FloatWindow";

import { useTranslation } from "react-i18next";

const SceneDrawer = () => {
  const d = useAppDispatch();
  const [t] = useTranslation();
  const open = useAppSelector(
    (state) => store.getAppState(state, "openRoomControlWindows").scene.open
  );
  const onClose = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomControlWindows.scene.open = false;
        state.openRoomControls = null;
      })
    );
  }, [d]);
  // todo
  const roomId = useAppSelector((state) => store.getAppState(state, "roomId"));
  const selectedSceneId = useAppSelector((state) =>
    store.getAppState(state, "selectedSceneId")
  );
  const onAdd = useCallback(() => {
    if (!roomId) return;
    d(store.addScene(roomId, selectedSceneId));
  }, [roomId, selectedSceneId, d]);
  return (
    <FloatWindow
      onClose={onClose}
      onAdd={onAdd}
      open={open}
      title={t("シーン一覧")}
    >
      <SceneList />
    </FloatWindow>
  );
};

export default memo(SceneDrawer);
