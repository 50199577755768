import { createSelector } from "reselect";
import { sortBy } from "lodash-es";
import { Scene } from "./records";
import { DefaultRootState } from "stores";

const emptyScene: Scene = {
  name: "",
  text: "",
  backgroundUrl: "",
  foregroundUrl: "",
  fieldObjectFit: "fill",
  fieldWidth: 0,
  fieldHeight: 0,
  displayGrid: false,
  gridSize: 1,
  mediaName: "",
  mediaUrl: "",
  mediaType: "",
  mediaVolume: 0.5,
  mediaRepeat: true,
  soundName: "",
  soundUrl: "",
  soundVolume: 0.5,
  soundRepeat: false,
  locked: false,
  order: -1,
  markers: {},
  updatedAt: 0,
  createdAt: 0,
};

export const getRoomScenes = (state: DefaultRootState) => {
  return state.entities.roomScenes.entities;
};

export const getRoomSceneById = (state: DefaultRootState, sceneId: string) => {
  return state.entities.roomScenes.entities[sceneId] || emptyScene;
};

export const getRoomSceneIds = (state: DefaultRootState) => {
  return state.entities.roomScenes.ids;
};

export const getRoomSceneIdByName = (
  state: DefaultRootState,
  sceneName: string
): string | undefined => {
  const ids = getRoomSceneOrderedIds(state);
  const scenes = getRoomScenes(state);

  for (const id of ids) {
    if (scenes[id]?.name === sceneName) {
      return id;
    }
  }

  return;
};

export const getRoomSceneOrderedIds = createSelector(
  [getRoomSceneIds, getRoomScenes],
  (ids, scenes) => {
    return sortBy(ids, [
      (id) => scenes[id].order,
      (id) => scenes[id].createdAt,
    ]);
  }
);

export const getRoomSceneMarkerById = (
  state: DefaultRootState,
  sceneId: string,
  markerId: string
) => {
  const room = state.entities.roomScenes.entities[sceneId];
  if (!room || !room.markers) return {};
  return room.markers[markerId] || {};
};

const getProps = (_: unknown, props: { sceneId: string }) => props.sceneId;
export const getRoomSceneMarkerIds = createSelector(
  [getRoomScenes, getProps],
  (scenes, sceneId) => {
    if (scenes[sceneId]) {
      return Object.keys(scenes[sceneId].markers || {});
    } else {
      return [];
    }
  }
);
