import { DiceItem } from "./records";
import { DefaultRootState } from "stores";

const emptyItem: DiceItem = {
  x: -1,
  y: -1,
  width: 2,
  height: 2,
  faces: 6,
  value: 1,
  closed: false,
  name: "",
  owner: "",
  changeCount: 0,
  createdAt: 0,
  updatedAt: 0,
};
// const emptyItem: any = {};
export const getRoomDiceById = (
  state: DefaultRootState,
  diceId: string
): DiceItem => {
  return state.entities.roomDices.entities[diceId] || emptyItem;
};
export const getCurrentRoomDice = (state: DefaultRootState) => {
  const diceId = state.app.state.openRoomDiceDetailId;
  if (!diceId) return emptyItem;
  return state.entities.roomDices.entities[diceId] || emptyItem;
};
export const getRoomDiceIds = (state: DefaultRootState) => {
  return state.entities.roomDices.ids;
};
