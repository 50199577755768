import React from "react";
import PopupWindow from "components/PopupWindow";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";

const ChatPopupWindow: React.FC = () => {
  const dispatch = useAppDispatch();
  const [roomId, open] = useAppSelector(
    (state) =>
      [
        state.app.state.roomId,
        state.app.state.openRoomChatPopupWindow,
      ] as const,
    shallowEqual
  );
  const onClose = React.useCallback(() => {
    dispatch(
      store.appStateMutate((state) => (state.openRoomChatPopupWindow = false))
    );
  }, [dispatch]);
  if (!roomId || !open) return null;
  return (
    <PopupWindow
      name="ccfolia.chat"
      url={`/rooms/${roomId}/chat`}
      width="380"
      height="640"
      onClose={onClose}
    />
  );
};

export default ChatPopupWindow;
