import { ClickAction, ClickActionRecord } from "modules/clickAction";

export type Item = {
  _id?: string;
  x: number;
  y: number;
  z: number;
  angle: number;
  width: number;
  height: number;
  locked: boolean;
  visible: boolean; // not used
  memo: string;
  closed: boolean;
  withoutOwner: boolean;
  freezed: boolean;
  type: string;
  active: boolean;
  owner: string | null;
  ownerName: string | null;
  ownerColor: string | null;
  imageUrl: string | null;
  coverImageUrl: string | null;
  deckId: string | null;
  clickAction: ClickAction | null;
  order: number;
  createdAt: number;
  updatedAt: number;
};
export type UpdateItem = Partial<Item>;

const migrateImageUrl = (item: any): string => {
  if (!item.imageUrl && item.image?.url) {
    return item.image.url;
  } else {
    return item.imageUrl;
  }
};

export const ItemRecord = (item: UpdateItem): Item => {
  return {
    x: "x" in item && item.x !== undefined ? Number(item.x) : -2,
    y: "y" in item && item.y !== undefined ? Number(item.y) : -2,
    z: "z" in item && item.z !== undefined ? Number(item.z) : 1,
    angle: item.angle || 0,
    width: item.width || 4,
    height: item.height || 4,
    deckId: item.deckId || null,
    locked: "locked" in item ? Boolean(item.locked) : false,
    visible: "visible" in item ? Boolean(item.visible) : true, // no used
    closed: "closed" in item ? Boolean(item.closed) : false,
    withoutOwner: "withoutOwner" in item ? Boolean(item.withoutOwner) : false,
    freezed: "freezed" in item ? Boolean(item.freezed) : false,
    type: item.type || "object",
    active: "active" in item ? Boolean(item.active) : true,
    owner: item.owner || null,
    ownerName: item.ownerName || null,
    ownerColor: item.ownerColor || null,
    memo: item.memo || "",
    imageUrl: migrateImageUrl(item) || null,
    coverImageUrl: item.coverImageUrl || null,
    clickAction: ClickActionRecord(item.clickAction || {}),
    order: item.order ?? 0,
    createdAt: item.createdAt || Date.now(),
    updatedAt: item.updatedAt || Date.now(),
  };
};
