import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import theme from "theme";
import { useAppDispatch, useAppSelector } from "stores";
import { getAppState } from "stores/modules/app.state/selectors";
import { appStateMutate } from "stores/modules/app.state/operations";
import LoaddataList from "./LoaddataList";

const SavedataDialog = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const open = useAppSelector((state) => getAppState(state, "openLoadDialog"));
  const handleClose = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openLoadDialog = false;
      })
    );
  }, [dispatch]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
    >
      <AppBar position="sticky" color="default">
        <Toolbar>
          <Typography variant="subtitle1">{t("ロード")}</Typography>
        </Toolbar>
      </AppBar>
      <LoaddataList />
      <DialogActions>
        <Button fullWidth onClick={handleClose}>
          {t("閉じる")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(SavedataDialog);
