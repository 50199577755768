import React, { useEffect, useState, memo } from "react";
// import { useAppDispatch, useAppSelector } from "stores";
// import store from "stores/interfaces";
import { checkClientVersion } from "api";
import { Snackbar, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const install = async () => {
  try {
    const registration = await window.navigator.serviceWorker.ready;
    await registration.unregister();
    window.location.reload();
  } catch (err) {
    console.error(err);
  }
};

const action = (
  <Button color="secondary" size="small" onClick={install}>
    install
  </Button>
);

const UpdateNotification: React.FC = () => {
  const [version, setVersion] = useState<String | null>(null);
  const [t] = useTranslation();
  useEffect(() => {
    checkClientVersion().then((res) => {
      if (res) {
        setVersion(res);
      }
    });
  }, [setVersion]);
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={!!version}
      message={`${t("新しいバージョン")} v${version} ${t(
        "がリリースされました！"
      )}`}
      action={action}
    />
  );
};

export default memo(UpdateNotification);
