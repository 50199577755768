import { setAutoFreeze } from "immer";
import { UserFile } from "../entities.user.files/records";
import { Basic as PhotoBasic } from "unsplash-js/dist/methods/photos/types";
import { OfficialPlaylist, Product } from "api";
import { createAction, createReducer } from "@reduxjs/toolkit";
import { actions as messageActions } from "../entities.room.messages/slice";

export type AppState = {
  roomId: string | null;
  role: string | null;
  tokenError: null;
  loading: boolean;
  muted: boolean;
  registered: boolean;

  updatedVersion: string | null;
  storeVersion: string | null;

  onlyFilteredFiles: boolean;
  notifications: string[];
  openUserDelete: boolean;

  // Room
  launchedTime: number;
  informationVersion: string | null;
  informationDate: number;

  // Inspector
  openInspector: boolean;
  inspectImageUrl: string | null;
  inspectText: string | null;

  // Open
  openRoomChatPopupWindow: boolean;
  currentRoomSceneId: string | null;
  roomScreenPosition: {
    x: number;
    y: number;
  };
  roomScreenScale: number;
  roomScreenCellSize: number;
  openRoomPanelDetailId: string | null;
  openRoomPanelDetail: boolean;
  openRoomMarkerDetailId: string | null;
  openRoomMarkerDetail: boolean;
  openRoomSceneId: string | null;
  openRoomScene: boolean;
  openRoomMemberList: boolean;
  openRoomSettings: boolean;
  openRoomSettingsDialog: boolean;
  openRoomNoteId: string | null;
  openRoomNote: boolean;
  openRoomEffectId: string | null;
  openRoomEffect: boolean;
  openRoomCharacterSelect: boolean;
  openRoomDetail: boolean;
  openRoomDetailId: string | null;
  openRoomDiceDetail: boolean;
  openRoomDiceDetailId: string | null;
  openRoomDeckMenu: boolean;
  openRoomDeckMenuId: string | null;
  openRoomDeckDetail: boolean;
  openRoomDeckDetailId: string | null;
  openRoomInviteDialog: boolean;
  openRoomItemMenu: boolean;
  openRoomItemMenuId: string | null;
  openRoomMarkerMenu: boolean;
  openRoomMarkerMenuId: string | null;
  openRoomVariables: boolean;

  openRoomControls:
    | "character"
    | "scene"
    | "note"
    | "cut"
    | "panel"
    | "marker"
    | null;
  openRoomControlWindows: {
    character: {
      open: boolean;
      x: 0;
      y: 0;
      width: 0;
      height: 0;
    };
    scene: {
      open: boolean;
      x: 0;
      y: 0;
      width: 0;
      height: 0;
    };
    note: {
      open: boolean;
      x: 0;
      y: 0;
      width: 0;
      height: 0;
    };
    cut: {
      open: boolean;
      x: 0;
      y: 0;
      width: 0;
      height: 0;
    };
    panel: {
      open: boolean;
      x: 0;
      y: 0;
      width: 0;
      height: 0;
    };
    marker: {
      open: boolean;
      x: 0;
      y: 0;
      width: 0;
      height: 0;
    };
  };
  openRoomDisplays: "chat" | "pdf" | null;
  prevOpenRoomDisplay: "chat" | "pdf" | null;

  openTopHeaderMenu: boolean;

  // Character
  // openRoomCharacterId: null,
  openRoomCharacter: boolean;
  openRoomCharacterId: string | null;
  openRoomCharacterMenu: boolean;
  openRoomCharacterMenuId: string | null;

  // Scene
  selectedSceneId: string;

  // Media
  roomMediaVolume: number;
  openRoomMedia: boolean;
  openRoomMediaDir: "bgm" | "sound" | "effect" | null;
  previewMediaUrl: string | null;
  previewMediaVolume: number;
  previewMediaLoop: boolean;
  officialPlaylistVolume: number;

  // Video
  openVideoPlayer: boolean;
  videoPlayerVolume: number;

  // PDF
  openPDFViewer: boolean;
  openPDFViewerUrl: string;
  openPDFViewerWidth: string;

  // Timer
  openRoomTimerSetting: boolean;
  openRoomCommandLine: boolean;

  // DiceBot
  openRoomDiceBotTypeList: boolean;
  openRoomDiceBotInfo: boolean;
  openRoomDiceBotInfoText: string;

  // Field
  openRoomFieldEdit: boolean;

  // ContextMenu
  fromContextMenu: boolean;
  contextMouseCellPosition: {
    x: number;
    y: number;
  };

  // Images
  openRoomImageSelect: boolean;
  openRoomImageSelectGroup: "room" | "all" | "unsplash";
  openRoomImageSelectDir: UserFile["dir"] | null;
  openRoomImageSelectTarget:
    | "background/set"
    | "effect/set"
    | "foreground/set"
    | "foreground/set"
    | "item/new"
    | "item/update"
    | "marker/new"
    | "marker/update"
    | "item/updateCover"
    | "deck/updateCoverImage"
    | null;

  selectingFiles: boolean;
  selectedFileIds: string[];

  unsplashQuery: string;
  unsplashLoading: boolean;
  unsplashSearchResult: null | PhotoBasic[];
  unsplashError: "reached-rate-limit" | "unknown" | null;

  // Chat
  openRoomColorPicker: boolean;
  openRoomMessageId: string | null;
  openRoomMessage: boolean;
  openRoomChatpalet: boolean;
  openRoomChatpaletId: string | null;
  openRoomMessageChannel: boolean;
  openChatTabSettings: boolean;
  openChatTabSettingsId: string | null;

  userMediumListGroup: "library" | "officialPlaylist";
  userMediumListLibraryTab: string;
  userMediumListOfficialPlaylistTab: string;
  draggingUserMediumId: string | null;
  subscribedUserMediumDir: string[];

  // Command
  openRoomUserCommandList: boolean;
  openRoomUserCommandId: string | null;
  openRoomUserCommand: boolean;
  openRoomExport: boolean;

  openRoomMenu: boolean;
  currentRoomField: "default"; // never used

  roomChatTo: string | null;
  roomChatToName: string;
  roomChatName: string;
  roomChatText: string;
  roomChatWideMode: boolean;
  roomChatColor: string;
  roomChatTab: string;
  roomChatDirectMessageKey: string | null;
  roomChatInitialized: boolean;
  roomChatChannelLoading: {
    [channel: string]: boolean;
  };
  roomChatChannelLoaded: {
    [channel: string]: boolean;
  };
  roomChatTabs: {
    [channel: string]: number;
  };
  roomChatMessageCounts: {
    [channel: string]: number;
  };
  roomChatMessageLoadingId: string | null;
  // roomChatMessageLoadingIds: {
  //   [channel: string]: string | null;
  // };
  updatedMessageId: string | null;
  updatedMessageTiming: number;

  openImageMenu: boolean;
  openImageMenuId: string | null;
  anchorTop: number;
  anchorLeft: number;

  roomPointerX: number;
  roomPointerY: number;
  roomPointerWidth: number;
  roomPointerHeight: number;

  roomMessageBoxTime: number;

  showRoomOnlyNotes: boolean;
  showRoomSystemMessages: boolean;

  // User
  openUserSettings: boolean;
  openPlanSelect: boolean;
  destOnClosePlanSelect: "none" | "userSettings";

  // Savedata
  openSaveDialog: boolean;
  openSaveConfirm: number | null;
  saveConformDisabledNameEdit: boolean;
  openDeleteSavedataConfirm: boolean;
  openDeleteSavedataConfirmId: string;
  openLoadDialog: boolean;
  openLoadConfirm: boolean;
  openLoadConfirmId: string;
  slotNumberProcessingSave: number | null;

  // DiceBot
  diceBotTypes: { name: string; system: string }[];

  // Dicerolls
  dicerollAreaWidth: number;
  dicerollAreaHeight: number;

  // DiceSkin
  openDiceSkinSelect: boolean;
  targetDiceSkinSelect: "user" | "character";
  characterIdDiceSkinSelect: string;

  // Supporters
  supportersCount: { value: number } | null;

  // Official playlists
  officialPlaylists: OfficialPlaylist[];

  // Error snackbar
  errorSnackbarMessage: string | null;

  // Loaders
  enabledUserFilesLoader: boolean;
  enabledUserMediaLoader: boolean;

  // CCFOLIA PRO
  openJoinedPro: boolean;

  // Extention
  openRoomExtentionDialog: boolean;
  parentProduct: Product | null;
};

setAutoFreeze(false);

const initialState: AppState = {
  roomId: null,
  role: null,
  tokenError: null,
  loading: false,
  muted: false,
  registered: false,

  updatedVersion: null,
  storeVersion: null,

  onlyFilteredFiles: true,
  notifications: [],
  openUserDelete: false,

  // Room
  launchedTime: Date.now(),
  informationVersion: null,
  informationDate: 0,

  // Inspector
  openInspector: false,
  inspectImageUrl: null,
  inspectText: null,

  openRoomChatPopupWindow: false,
  currentRoomSceneId: null,
  roomScreenPosition: {
    x: 0,
    y: 0,
  },
  roomScreenScale: 1,
  roomScreenCellSize: 24,
  openRoomPanelDetailId: null,
  openRoomPanelDetail: false,
  openRoomMarkerDetailId: null,
  openRoomMarkerDetail: false,
  openRoomScene: false,
  openRoomSceneId: null,
  openRoomMemberList: false,
  openRoomSettings: false,
  openRoomSettingsDialog: false,
  openRoomNoteId: null,
  openRoomNote: false,
  openRoomEffectId: null,
  openRoomEffect: false,
  openRoomCharacterSelect: false,
  openRoomDetail: false,
  openRoomDetailId: null,
  openRoomDiceDetail: false,
  openRoomDiceDetailId: null,
  openRoomDeckMenu: false,
  openRoomDeckMenuId: null,
  openRoomDeckDetail: false,
  openRoomDeckDetailId: null,
  openRoomInviteDialog: false,
  openRoomItemMenu: false,
  openRoomItemMenuId: null,
  openRoomMarkerMenu: false,
  openRoomMarkerMenuId: null,
  openRoomVariables: false,

  openRoomControls: null,
  openRoomControlWindows: {
    character: {
      open: false,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    scene: {
      open: false,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    note: {
      open: false,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    cut: {
      open: false,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    panel: {
      open: false,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    marker: {
      open: false,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
  },
  openRoomDisplays: "chat",
  prevOpenRoomDisplay: "chat",
  // Character
  // openRoomCharacterId: null,
  openRoomCharacter: false,
  openRoomCharacterId: null,
  openRoomCharacterMenu: false,
  openRoomCharacterMenuId: null,
  openTopHeaderMenu: false,

  // Scene
  selectedSceneId: "",

  // Media
  roomMediaVolume: 0.5,
  openRoomMedia: false,
  openRoomMediaDir: null,
  previewMediaUrl: null,
  previewMediaVolume: 0.5,
  previewMediaLoop: true,
  officialPlaylistVolume: 0.5,

  // Video
  openVideoPlayer: false,
  videoPlayerVolume: 0.5,

  // PDF
  openPDFViewer: false,
  openPDFViewerUrl: "",
  openPDFViewerWidth: "700px",

  // Timer
  openRoomTimerSetting: false,
  openRoomCommandLine: false,
  // DiceBot
  openRoomDiceBotTypeList: false,
  openRoomDiceBotInfo: false,
  openRoomDiceBotInfoText: "",

  // Field
  openRoomFieldEdit: false,
  // ContextMenu
  fromContextMenu: false,
  contextMouseCellPosition: {
    x: -1,
    y: -1,
  },
  // Images
  openRoomImageSelect: false,
  openRoomImageSelectGroup: "room",
  openRoomImageSelectDir: null,
  openRoomImageSelectTarget: null,
  selectingFiles: false,
  selectedFileIds: [],
  unsplashQuery: "",
  unsplashLoading: false,
  unsplashSearchResult: null,
  unsplashError: null,

  // Chat
  openRoomColorPicker: false,
  openRoomMessageId: null,
  openRoomMessage: false,
  openRoomChatpalet: false,
  openRoomChatpaletId: null,
  openRoomMessageChannel: false,
  openChatTabSettings: false,
  openChatTabSettingsId: null,

  // Command
  openRoomUserCommandList: false,
  openRoomUserCommandId: null,
  openRoomUserCommand: false,
  openRoomExport: false,

  openRoomMenu: false,
  currentRoomField: "default",

  roomChatTo: null,
  roomChatToName: "",
  roomChatName: "",
  roomChatText: "",
  roomChatWideMode: false,
  roomChatColor: "#888888",
  roomChatTab: "main",
  roomChatDirectMessageKey: null,
  roomChatInitialized: false,
  roomChatChannelLoading: {
    main: false,
    info: false,
    other: false,
  },
  roomChatChannelLoaded: {},
  roomChatTabs: {
    main: 0,
    info: 0,
    other: 0,
  },
  roomChatMessageCounts: {
    main: 0,
    info: 0,
    other: 0,
  },
  roomChatMessageLoadingId: null,
  userMediumListGroup: "library",
  userMediumListLibraryTab: "bgm01",
  userMediumListOfficialPlaylistTab: "free",
  draggingUserMediumId: null,
  subscribedUserMediumDir: [],
  updatedMessageId: null,
  updatedMessageTiming: 0,

  openImageMenu: false,
  openImageMenuId: null,
  anchorTop: 0,
  anchorLeft: 0,

  roomPointerX: 0,
  roomPointerY: 0,
  roomPointerWidth: 1,
  roomPointerHeight: 1,

  roomMessageBoxTime: Date.now(),

  showRoomOnlyNotes: false,
  showRoomSystemMessages: true,

  // User
  openUserSettings: false,
  openPlanSelect: false,
  destOnClosePlanSelect: "none",

  // Savedata
  openSaveDialog: false,
  openSaveConfirm: null,
  saveConformDisabledNameEdit: false,
  openDeleteSavedataConfirm: false,
  openDeleteSavedataConfirmId: "",
  openLoadDialog: false,
  openLoadConfirm: false,
  openLoadConfirmId: "",
  slotNumberProcessingSave: null,

  // DiceBot
  diceBotTypes: [],

  // Dicerolls
  dicerollAreaWidth: 100,
  dicerollAreaHeight: 100,

  // DiceSkin
  openDiceSkinSelect: false,
  targetDiceSkinSelect: "user",
  characterIdDiceSkinSelect: "",

  // Supporters
  supportersCount: null,

  // Official playlists
  officialPlaylists: [
    {
      id: "free",
      name: "フリー",
      free: true,
      sounds: [],
    },
  ],

  // Error Snackbar
  errorSnackbarMessage: null,

  // Loaders
  enabledUserFilesLoader: false,
  enabledUserMediaLoader: false,

  // CCFOLIA PRO
  openJoinedPro: false,

  // Extention
  openRoomExtentionDialog: false,
  parentProduct: null,
};

// Actions

type MutateFunc = (state: AppState) => void;

export const actions = {
  appStateMutate: createAction<MutateFunc>("app/state/mutate"),
};

// Reducers

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.appStateMutate, (state, action) => {
      action.payload(state);
    })
    .addCase(messageActions.init, (state) => {
      state.roomChatInitialized = false;
    })
    .addCase(messageActions.add, (state, action) => {
      const { entity } = action.payload;
      const channel = entity.channel;

      // newMessages
      if (!state.roomChatTabs[channel]) {
        state.roomChatTabs[channel] = 0;
      }
      if (channel !== state.roomChatTab) {
        state.roomChatTabs[channel]++;
      }

      // allMessages
      if (!state.roomChatMessageCounts[channel]) {
        state.roomChatMessageCounts[channel] = 0;
      }
      state.roomChatMessageCounts[channel]++;

      // init flag
      state.roomChatInitialized = true;
    })
    .addCase(messageActions.update, (state, action) => {
      state.updatedMessageId = action.payload.id;
      state.updatedMessageTiming = Date.now();
    });
});

export default reducer;
