import { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { Dialog, List } from "@mui/material";

import MemberListItem from "./MemberListItem";

const MemberList = () => {
  const dispatch = useAppDispatch();

  const memberIds = useAppSelector(store.getRoomMemberIds);
  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomMemberList")
  );

  const onClose = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomMemberList = false;
      })
    );
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <List>
        {memberIds.map((memberId) => (
          <MemberListItem key={memberId} memberId={memberId} />
        ))}
      </List>
    </Dialog>
  );
};

export default memo(MemberList);
