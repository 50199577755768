import { actions, AppUser } from "./slice";
import { auth, db, providers } from "initializer";
import { DefaultThunk } from "stores";
import { deleteAccount, syncSubscriptionState, uploadFile } from "api";
import { appStateMutate } from "../app.state/operations";
import { getUid } from "./selectors";
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  getIdTokenResult,
  linkWithCredential,
  linkWithRedirect,
  onAuthStateChanged,
  sendEmailVerification,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut as signOutAuth,
  unlink,
  updateProfile,
} from "firebase/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";

export const appUserMutate = actions.appUserMutate;

export const subscribeUserState =
  (): DefaultThunk<() => void> => (dispatch) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const displayName = user.displayName;
        const email = user.email;
        const emailVerified = user.emailVerified;
        const photoURL = user.photoURL;
        const isAnonymous = user.isAnonymous;
        const providerData = {
          email:
            user.providerData.find(
              (p) => p?.providerId === EmailAuthProvider.PROVIDER_ID
            ) ?? null,
          twitter:
            user.providerData.find((p) => p?.providerId === "twitter.com") ??
            null,
          google:
            user.providerData.find((p) => p?.providerId === "google.com") ??
            null,
        };
        dispatch(
          appUserMutate((draft) => {
            draft.uid = uid;
            draft.isAnonymous = isAnonymous;
            draft.displayName = displayName;
            draft.photoUrl = photoURL;
            draft.email = email;
            draft.emailVerified = emailVerified;
            draft.providerData = providerData;
          })
        );
        dispatch(
          appStateMutate((draft) => {
            draft.registered = true;
          })
        );

        if (isAnonymous) {
          dispatch(
            appUserMutate((draft) => {
              draft.plan = "free";
            })
          );
        } else {
          user.getIdTokenResult().then((token) => {
            const plan: AppUser["plan"] =
              token.claims.plan === "ccfolia-pro" ? "ccfolia-pro" : "free";
            dispatch(
              appUserMutate((draft) => {
                draft.plan = plan;
              })
            );

            gtag("set", "user_properties", {
              is_pro: plan === "ccfolia-pro" ? "true" : "false",
            });

            if (plan === "ccfolia-pro") {
              syncSubscriptionState({ force: false }).then((remotePlan) => {
                if (remotePlan !== plan) {
                  dispatch(refreshCustomClaim());
                }
              });
            }
          });
        }
      } else {
        signInAnonymously(auth); // force login
        dispatch(
          appUserMutate((draft) => {
            draft.uid = null;
            draft.isAnonymous = null;
            draft.displayName = null;
            draft.photoUrl = null;
            draft.email = null;
            draft.emailVerified = false;
            draft.providerData = { email: null, google: null, twitter: null };
            draft.plan = "free";
          })
        );
        dispatch(
          appStateMutate((draft) => {
            draft.registered = false;
          })
        );
      }
    });
    return () => unsubscribe();
  };

export const refreshCustomClaim = (): DefaultThunk => async (dispatch) => {
  if (auth.currentUser == null) {
    return;
  }

  const token = await getIdTokenResult(auth.currentUser, true);
  const plan: AppUser["plan"] =
    token.claims.plan === "ccfolia-pro" ? "ccfolia-pro" : "free";
  dispatch(
    appUserMutate((draft) => {
      draft.plan = plan;
    })
  );
};

export const signInWithProvider =
  (providerName: "twitter" | "google") => async () => {
    if (
      process.env.NODE_ENV !== "production" &&
      process.env.REACT_APP_USE_EMR === "true"
    ) {
      signInWithRedirect(auth, providers[providerName]);
      return;
    }

    const search = new URLSearchParams({ ref: window.location.pathname });
    window.location.href = `/signin_handler/${providerName}/signin?${search.toString()}`;
  };

export const signUpWithProvider =
  (providerName: keyof typeof providers): DefaultThunk =>
  async (_, getState) => {
    const state = getState();
    const uid = getUid(state);
    if (uid == null || auth.currentUser == null) {
      return;
    }

    const ref = doc(db, "users", uid);
    await setDoc(ref, { agreedTermsAt: serverTimestamp() }, { merge: true });

    linkWithRedirect(auth.currentUser, providers[providerName]);
  };

export const signInWithEmail =
  (email: string, password: string): DefaultThunk<Promise<void>> =>
  async () => {
    await signInWithEmailAndPassword(auth, email, password);
  };

export const signUpWithEmail =
  (email: string, password: string): DefaultThunk<Promise<void>> =>
  async () => {
    const credential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const ref = doc(db, "users", credential.user.uid);
    await Promise.all([
      setDoc(ref, { agreedTermsAt: serverTimestamp() }, { merge: true }),
      sendEmailVerification(credential.user).catch(() => {}),
    ]);
  };

export const linkWithProvider =
  (providerName: keyof typeof providers) => async () => {
    if (auth.currentUser) {
      linkWithRedirect(auth.currentUser, providers[providerName]);
    }
  };

export const unlinkFromProvider =
  (providerName: keyof typeof providers): DefaultThunk =>
  async (dispatch) => {
    const hasOtherAccount =
      auth.currentUser?.providerData &&
      Object.keys(auth.currentUser?.providerData).length > 1;
    if (!hasOtherAccount) {
      window.alert(`連携を解除するためには他のログイン手段が必要です。`);
      return;
    }
    if (auth.currentUser) {
      unlink(auth.currentUser, providers[providerName].providerId).catch((r) =>
        console.error(r)
      );
    }
    dispatch(
      appUserMutate((state) => {
        state.providerData = {
          ...state.providerData,
          [providerName]: null,
        };
      })
    );
  };

export const linkWithEmailAuth =
  (email: string, password: string): DefaultThunk<Promise<void>> =>
  async (_, getState) => {
    const state = getState();
    const uid = getUid(state);
    if (uid == null || auth.currentUser == null) {
      return;
    }

    await linkWithCredential(
      auth.currentUser,
      EmailAuthProvider.credential(email, password)
    );

    // auth.currentUser.sendEmailVerification();
  };

export const signOut = () => () => {
  signOutAuth(auth);
};

export const deleteUser = () => async () => {
  if (auth.currentUser?.isAnonymous) return;
  try {
    await deleteAccount();
  } catch (err) {
    window.alert("Failed delete your account");
  }
};

export const updateUserProfile =
  (profile: { displayName?: string; photoURL?: string | null }): DefaultThunk =>
  (dispatch) => {
    const user = auth.currentUser;
    if (user) {
      updateProfile(user, {
        displayName: profile.displayName,
        photoURL: profile.photoURL,
      }).then(() => {
        dispatch(
          appUserMutate((draft) => {
            draft.displayName = user.displayName;
            draft.photoUrl = user.photoURL;
          })
        );
      });
    }
  };

export const updateUserPhoto =
  (photo: File): DefaultThunk =>
  async (dispatch) => {
    const user = auth.currentUser;
    if (!user) return;
    try {
      const uploaded = await uploadFile({
        filePath: "users/" + user.uid + "/icon.png",
        file: photo,
      });
      if (!uploaded) return;
      dispatch(
        updateUserProfile({
          photoURL:
            [process.env.REACT_APP_CDN_URL, uploaded.name].join("/") +
            `?generation=${uploaded.generation}`,
        })
      );
    } catch (err) {
      console.error(err);
    }
  };
