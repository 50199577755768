import { DefaultThunk } from "stores";
import { UpdateScene } from "stores/modules/entities.room.scenes";
import { undoRedoReorderScenes } from "stores/modules/entities.room.scenes/operations";

export type OrderSceneRecord = {
  kind: "change-scene-index";
  id: string;
  beforeIndex: number;
  afterIndex: number;
  item: UpdateScene;
};

export const undoOrderScene =
  (history: OrderSceneRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;

    dispatch(
      undoRedoReorderScenes(
        roomId,
        history.id,
        history.beforeIndex,
        history.item
      )
    );
    return;
  };

export const redoOrderScene =
  (history: OrderSceneRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;

    dispatch(
      undoRedoReorderScenes(
        roomId,
        history.id,
        history.afterIndex,
        history.item
      )
    );
    return;
  };
