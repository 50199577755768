import React, { memo } from "react";
import { useAppSelector } from "stores";
import store from "stores/interfaces";

import Piece from "./Piece";

type PiecesProps = {
  uid: string;
  roomId: string;
};

const Pieces: React.FC<PiecesProps> = (props) => {
  const characterIds = useAppSelector(store.getRoomActiveCharacterIds);
  return (
    <>
      {characterIds.map((characterId) => (
        <Piece
          key={characterId}
          uid={props.uid}
          roomId={props.roomId}
          characterId={characterId}
        />
      ))}
    </>
  );
};

export default memo(Pieces);
