import React from "react";
import { useAppSelector } from "stores";
import store from "stores/interfaces";
import Item from "containers/Item";

const Panels: React.FC = () => {
  const itemIds = useAppSelector(store.getActiveRoomItemIds);
  return (
    <>
      {itemIds.map((itemId) => (
        <Item key={itemId} itemId={itemId} />
      ))}
    </>
  );
};

export default React.memo(Panels);
