import { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import { useTranslation } from "react-i18next";

import { Button, IconButton, Tooltip } from "@mui/material";

import CharacterNameSelect from "./CharacterNameSelect";

import HelpIcon from "@mui/icons-material/Help";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import DiceSelect from "./DiceSelect";
import {
  appStateMutate,
  loadDiceBotInfo,
} from "stores/modules/app.state/operations";

type ChatBoxToolbarProps = {
  uid: string;
};

const ChatBoxToolbar = ({ uid }: ChatBoxToolbarProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const character = useAppSelector((state) => {
    const name = store.getAppState(state, "roomChatName");
    return store.getUserCharacterByName(state, {
      name,
      uid,
    });
  });
  const roomChatColor = useAppSelector((state) =>
    store.getAppState(state, "roomChatColor")
  );
  const isRoleAudience = useAppSelector(store.getIsRoleAudience);

  const textColor = character ? character.color || "#888888" : roomChatColor;

  const onOpenChatpalet = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomChatpalet = true;
        state.openRoomChatpaletId = character?._id || null;
      })
    );
  }, [character?._id, dispatch]);

  const onClickColorPicker = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomColorPicker = !state.openRoomColorPicker;
      })
    );
  }, [dispatch]);

  const onClickDiceSystemName = useCallback(() => {
    dispatch(loadDiceBotInfo());
  }, [dispatch]);

  return (
    <>
      <MinToolbar>
        <CharacterNameSelect uid={uid} />
        <Spacer />
        <Tooltip title={t("チャットパレット")}>
          <div>
            <IconButton
              size="small"
              onClick={onOpenChatpalet}
              disabled={!character || isRoleAudience}
            >
              <ListAltIcon />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title={t("キャラクターカラー変更")}>
          <div>
            <IconButton
              size="small"
              onClick={onClickColorPicker}
              disabled={isRoleAudience}
            >
              <ColorLensIcon style={{ color: textColor }} />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title={t("チャットコマンドについて")}>
          <IconButton size="small" onClick={onClickDiceSystemName}>
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </MinToolbar>
      <MinToolbar>
        <DiceSelect />
        <Spacer />
        <Button size="small" type="submit" disabled={isRoleAudience}>
          {t("送信")}
        </Button>
      </MinToolbar>
    </>
  );
};

const Spacer = styled.div`
  flex-grow: 1;
`;

const MinToolbar = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  & + & {
    padding-top: 0;
  }
  > .MuiButtonBase-root {
    margin-left: 4px;
  }
`;

export default memo(ChatBoxToolbar);
