import React, { useMemo, useCallback, MouseEvent, TouchEvent } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import styled from "styled-components";
import { Chip } from "@mui/material";
import useLongTap from "hooks/longTap";
import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";
import DraggableItem from "containers/DraggableItem";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import { fixSelectionOnDragStop } from "modules/drag";

type DeckProps = {
  deckId: string;
};
const Deck = ({ deckId }: DeckProps) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const deck = useAppSelector((state) => store.getRoomDeckById(state, deckId));
  const itemCount = useMemo(() => {
    if (deck?.items) return Object.keys(deck?.items).length;
    return 0;
  }, [deck?.items]);
  const isRoleAudience = useAppSelector(getIsRoleAudience);
  const alignWithGrid = useAppSelector(
    (state) => getCurrentRoom(state)?.alignWithGrid ?? true
  );

  const onContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isRoleAudience) {
        return;
      }

      dispatch(
        store.appStateMutate((state) => {
          state.openRoomDeckMenu = true;
          state.openRoomDeckMenuId = deckId;
          state.roomPointerY = e.pageY || e.touches[0]?.screenY || 0;
          state.roomPointerX = e.pageX || e.touches[0]?.screenX || 0;
        })
      );
    },
    [deckId, isRoleAudience, dispatch]
  );
  const onDraw = useCallback(
    (e) => {
      e.stopPropagation();
      if (isRoleAudience) {
        return;
      }

      dispatch(
        store.addRandomRoomItemFromRoomDeck({
          deckId,
          amount: 1,
          mode: "private",
        })
      );
    },
    [deckId, isRoleAudience, dispatch]
  );
  const onUpdate = useCallback(
    (position) => {
      dispatch(store.updateRoomDeck(deckId, position));
    },
    [deckId, dispatch]
  );
  const disableDragging = deck?.locked || isRoleAudience;
  const enableResizing = !deck?.locked && !deck?.freezed && !isRoleAudience;

  const longTapProps = useLongTap(onContextMenu);

  const onMouseDown = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!isRoleAudience && !deck.locked) {
        event.stopPropagation();
        fixSelectionOnDragStop();
      }
    },
    [isRoleAudience, deck.locked]
  );

  const stopPropagation = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!isRoleAudience && !deck.locked) {
        event.stopPropagation();
      }
    },
    [isRoleAudience, deck.locked]
  );
  return (
    <DraggableItem
      initialPosition={{
        x: deck.x,
        y: deck.y,
        width: deck.width,
        height: deck.height,
      }}
      draggableId={deckId}
      alignWithGrid={alignWithGrid}
      onUpdate={onUpdate}
      onContextMenu={onContextMenu}
      onDoubleClick={onDraw}
      className="movable"
      disableDragging={disableDragging}
      enableResizing={enableResizing}
      onMouseDown={onMouseDown}
      onTouchStart={stopPropagation}
    >
      <DeckContainer {...longTapProps}>
        {itemCount > 0 ? (
          <img
            src={toCDNUrl(deck.coverImageUrl) || "/grey.png"}
            draggable={false}
          />
        ) : (
          <img src={"/grey.png"} draggable={false} />
        )}
        <ItemChip
          label={t("{{cards}}枚", { cards: Object.keys(deck.items).length })}
        />
      </DeckContainer>
    </DraggableItem>
  );
};

const DeckContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 100;
  img {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`;

const ItemChip = styled(Chip)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.84);
`;

export default Deck;
