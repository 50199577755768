import {
  useState,
  useCallback,
  useMemo,
  useEffect,
  KeyboardEvent,
  memo,
} from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import AutoCompleteTextField from "components/AutoCompleteTextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { roomMemberUpdate } from "containers/Room/roomSlice";
import { appChatStateMutate } from "stores/modules/app.chat/operations";
import { DEFAULT_COMMAND } from "stores/modules/entities.room.messages/operations";

type ChatBoxTextFieldProps = {
  uid: string;
  standalone: boolean;
  onSubmit: (event: KeyboardEvent<HTMLDivElement>) => void;
};

const ChatBoxTextField = ({
  uid,
  standalone,
  onSubmit,
}: ChatBoxTextFieldProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const [typing, setTyping] = useState(false);

  const hasEditableRole = useAppSelector(store.getHasEditableRole);
  const tab = useAppSelector((state) =>
    store.getAppState(state, "roomChatTab")
  );
  const text = useAppSelector((state) =>
    store.getAppChatState(state, "inputText")
  );
  const character = useAppSelector((state) => {
    const name = store.getAppState(state, "roomChatName");
    return store.getUserCharacterByName(state, {
      uid: uid,
      name,
    });
  });
  const disabled = useAppSelector(store.getIsRoleAudience);
  const noteTexts = useAppSelector(store.getRoomNoteTexts);

  useEffect(() => {
    dispatch(roomMemberUpdate(typing ? String(tab) : "none"));
  }, [typing, tab, dispatch]);

  useEffect(() => {
    setTyping(text !== "");
  }, [text, setTyping]);

  const onChange = useCallback(
    (text: string) => {
      setTyping(text !== "");
      dispatch(
        appChatStateMutate((state) => {
          state.inputText = text;
        })
      );
    },
    [setTyping, dispatch]
  );

  const onKeyPress = useHandleKeyPress(onSubmit, standalone);

  const items = useMemo(() => {
    const texts = hasEditableRole ? noteTexts : [];
    if (!character) return texts;
    const status = character.status.map((stat) => ":" + stat.label);
    const defaultCommand = DEFAULT_COMMAND.map((com) => ":" + com);
    return character.commands
      .split(/[\r\n]+/)
      .map((s) => String(s))
      .filter((str) => str.slice(0, 2) !== "//")
      .concat(texts)
      .concat(status)
      .concat(defaultCommand);
  }, [character, noteTexts, hasEditableRole]);

  return (
    <AutoCompleteTextField
      onChange={onChange}
      value={text}
      items={items}
      inputProps={{
        disabled: disabled,
        placeholder: disabled
          ? t("見学者はメッセージを送信できません")
          : t("メッセージを入力"),
        fullWidth: true,
        multiline: true,
        onKeyPress: onKeyPress,
        minRows: 4,
        maxRows: 12,
        name: "text",
      }}
    />
  );
};

const useHandleKeyPress = (
  submit: (event: KeyboardEvent<HTMLDivElement>) => void,
  standalone: boolean
) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter" && !e.shiftKey && (matches || standalone)) {
        submit(e);
      }
    },
    [submit, matches, standalone]
  );
};

export default memo(ChatBoxTextField);
