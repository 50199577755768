import { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";

import EffectList from "./EffectList";
import FloatWindow from "components/FloatWindow";
import { useTranslation } from "react-i18next";
import { appStateMutate } from "stores/modules/app.state/operations";
import { addRoomEffect } from "stores/modules/entities.room.effects/operations";
import { getAppState } from "stores/modules/app.state/selectors";

type CutListProps = {
  roomId: string;
  uid: string;
};

const CutList = ({ roomId, uid }: CutListProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const open = useAppSelector(
    (state) => getAppState(state, "openRoomControlWindows").cut.open
  );

  const onClose = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomControls = null;
        state.openRoomControlWindows.cut.open = false;
      })
    );
  }, [dispatch]);

  const onAdd = useCallback(() => {
    dispatch(addRoomEffect(roomId, uid));
  }, [roomId, uid, dispatch]);

  return (
    <FloatWindow
      onClose={onClose}
      onAdd={onAdd}
      open={open}
      title={t("カットイン一覧")}
    >
      <EffectList roomId={roomId} />
    </FloatWindow>
  );
};

export default memo(CutList);
