import { createEntitySliceGroupBy } from "../firestoreModuleUtils/slice";
import { Character } from "./records";

const slice = createEntitySliceGroupBy<Character>({
  name: "character",
  selectGroup: (character) => character.owner || "",
});

export const reducer = slice.reducer;
export const actions = slice.actions;
