import { createEntitySliceGroupBy } from "../firestoreModuleUtils/slice";
import { UserMedium } from "./records";

const slice = createEntitySliceGroupBy<UserMedium>({
  name: "userMedia",
  selectGroup: (medium) => medium.dir,
});

export const reducer = slice.reducer;
export const actions = slice.actions;
