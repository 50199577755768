import React, { memo, useCallback, useEffect, useRef } from "react";
import { Paper, Modal, Typography, Fab, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import theme from "theme";
import CloseIcon from "@mui/icons-material/Close";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import toCDNUrl from "modules/toCDNUrl";
import { useDraggable } from "@dnd-kit/core";

const Inspector: React.FC = () => {
  const d = useAppDispatch();
  const [openInspector, imageUrl, text] = useAppSelector(
    (state) =>
      [
        store.getAppState(state, "openInspector"),
        store.getAppState(state, "inspectImageUrl"),
        store.getAppState(state, "inspectText"),
      ] as const,
    shallowEqual
  );
  const onClose = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openInspector = false;
      })
    );
  }, [d]);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: "inspector",
    disabled: isMobile,
  });
  const position = useRef({
    x: 0,
    y: 0,
  });
  const prevPosition = useRef({
    x: 0,
    y: 0,
  });
  const style = transform
    ? {
        transform: `translate3d(${prevPosition.current.x + transform.x}px, ${
          prevPosition.current.y + transform.y
        }px, 0)`,
      }
    : {
        transform: `translate3d(${position.current.x}px, ${position.current.y}px, 0)`,
      };

  useEffect(() => {
    if (transform) {
      position.current.x = prevPosition.current.x + transform.x;
      position.current.y = prevPosition.current.y + transform.y;
    } else {
      prevPosition.current.x = position.current.x;
      prevPosition.current.y = position.current.y;
    }
  }, [transform, position]);

  return (
    <Modal open={openInspector && !!imageUrl} onClose={onClose}>
      <Centering>
        <Container
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          style={style}
        >
          <CloseFab size="small" onClick={onClose} data-no-dnd="true">
            <CloseIcon />
          </CloseFab>
          <Inner>
            {imageUrl ? (
              <Figure>
                <img src={toCDNUrl(imageUrl)} width="100%" draggable={false} />
              </Figure>
            ) : null}
            {text ? (
              <Paragraph>
                <Typography style={{ whiteSpace: "pre-wrap" }}>
                  {text}
                </Typography>
              </Paragraph>
            ) : null}
          </Inner>
        </Container>
      </Centering>
    </Modal>
  );
};

const Centering = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  max-width: 960px;
  max-height: 960px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  ${theme.breakpoints.down("sm")} {
    top: auto;
    bottom: 16px;
    left: 16px;
    right: 16px;
    transform: none;
  }
`;

const Container = styled(Paper)`
  /* width: 100%; */
  /* height: 100%; */
  /* display: flex; */
  background: #222;
  /* z-index: ${theme.zIndex.drawer + 1}; */
  /* outline: none; */
`;

const Figure = styled.figure`
  background: #111;
`;

const CloseFab = styled(Fab)`
  position: absolute;
  top: -16px;
  right: -16px;
`;

const Inner = styled.div`
  box-sizing: border-box;
  padding: 8px;
  flex: 1;
  overflow: auto;
`;

const Paragraph = styled.div`
  /* margin-top: 8px; */
  padding: 8px;
`;

export default memo(Inspector);
