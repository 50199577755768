import { actions } from "./slice";
import { db } from "initializer";
import sendEvent from "modules/sendEvent";
import { createSubscribeCollection } from "../firestoreModuleUtils/operators";
import { uploadFile, deleteFile } from "modules/firebase-utils/uploader";
import { getMediumById, getUserMediumIds } from "./selectors";
import { UserMediumRecord } from "./records";
import { UpdateUserMedium, UserMedium } from "./";
import { DefaultThunk } from "stores";
import { DropResult } from "react-beautiful-dnd";
import { getUid } from "../app.user/selectors";
import {
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  calcOrderAppendingToTail,
  calcOrderByDrag,
  forceAlignOrders,
  moveIdByIndex,
} from "../firestoreModuleUtils/ordaring";

const mediumConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(character: UpdateUserMedium) {
    return character;
  },
  fromFirestore(snapshot, options): UserMedium {
    const data = snapshot.data(options)!;
    return UserMediumRecord(data);
  },
};

const mediumRef = (uid: string) =>
  collection(db, "users", uid, "media").withConverter(
    mediumConverter
  ) as CollectionReference<UserMedium>;

export const subscribeUserMedia = createSubscribeCollection<
  UserMedium,
  { uid: string; dirs: string[] }
>(actions, ({ uid, dirs }) =>
  query(mediumRef(uid), where("dir", "in", dirs), orderBy("order"))
);

const LIMIT_USER_MEDIUM_SIZE = 10 * 1024 * 1024; // 10 MiB

export const addUserMedium =
  (uid: string, roomId: string, dir: string, file: File): DefaultThunk =>
  (_, getState) => {
    sendEvent("addUserMedium");

    if (file.size >= LIMIT_USER_MEDIUM_SIZE) {
      window.alert(
        "ファイルサイズが10MBを超えているため、アップロードに失敗しました。"
      );
      return;
    }

    const state = getState();
    const ids = getUserMediumIds(state, dir);
    return uploadFile(file, {
      collectionRef: mediumRef(uid),
      data: UserMediumRecord({
        owner: uid,
        roomId,
        dir,
        volume: 0.5,
        loop: true,
        order: calcOrderAppendingToTail(ids, state.entities.userMedia.entities),
        external: false,
      }),
    });
  };

export const addUserMediumFromUrl =
  (uid: string, roomId: string, dir: string, url: string): DefaultThunk =>
  (_, getState) => {
    sendEvent("addUserMedium");
    const state = getState();
    const ids = getUserMediumIds(state, dir);
    return addDoc(
      mediumRef(uid),
      UserMediumRecord({
        owner: uid,
        roomId,
        dir,
        volume: 0.5,
        loop: true,
        order: calcOrderAppendingToTail(ids, state.entities.userMedia.entities),
        url,
        name: "新規メディア",
        uploaded: false,
        archived: false,
        contentType: null,
        size: 0,
        updatedAt: Date.now(),
        createdAt: Date.now(),
        external: true,
      })
    );
  };

export const updateUserMedium =
  (uid: string, mediumId: string, values: UpdateUserMedium) => () => {
    updateDoc(doc(mediumRef(uid), mediumId), {
      ...values,
    });
  };

export const deleteUserMedium =
  (uid: string, mediumId: string, external?: boolean) => () => {
    sendEvent("deleteUserMedium");
    if (external) {
      return deleteDoc(doc(mediumRef(uid), mediumId));
    }
    return deleteFile(doc(mediumRef(uid), mediumId));
  };

export const deleteUserMediumByDirectory =
  (uid: string, directoryId: string): DefaultThunk<Promise<void>> =>
  async (_, getState) => {
    const state = getState();
    const ids = getUserMediumIds(state, directoryId);
    if (ids.length === 0) {
      return;
    }

    const promises: Promise<void>[] = [];
    const batch = writeBatch(db);
    for (const id of ids) {
      const medium = getMediumById(state, id);
      if (medium.external) {
        batch.delete(doc(mediumRef(uid), id));
      } else {
        promises.push(deleteFile(doc(mediumRef(uid), id)));
      }
    }
    await Promise.all([...promises, batch.commit()]);
  };

export const reorderMedia =
  (_uid: string, dir: string, order: DropResult): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);

    if (!uid || !order.destination) {
      return;
    }

    const destIndex = order.destination.index;
    const srcIndex = order.source.index;

    const ids = getUserMediumIds(state, dir);
    const targetId = ids[srcIndex];
    if (!targetId || ids.length <= destIndex || ids.length <= srcIndex) {
      return;
    }

    dispatch(
      actions.groupReorder({
        group: dir,
        startIndex: order.source.index,
        endIndex: order.destination.index,
      })
    );

    const newOrder = calcOrderByDrag(ids, state.entities.userMedia.entities, {
      destIndex,
      srcIndex,
    });

    if (newOrder === null) {
      const movedIds = moveIdByIndex(ids, { destIndex, srcIndex });
      forceAlignOrders(movedIds, uid, mediumRef);
    } else {
      updateDoc(doc(mediumRef(uid), targetId), { order: newOrder });
    }
  };

export const changeDirMedia =
  (mediumId: string, dir: string): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);
    const mediumIds = getUserMediumIds(state, dir);
    if (!uid || !mediumId) return;
    dispatch(actions.groupRemove(mediumId)); // remove once local state

    let order = 0;
    if (mediumIds.length > 0) {
      order = calcOrderAppendingToTail(
        mediumIds,
        state.entities.userMedia.entities
      );
    } else {
      const snapshot = await getDocs(
        query(mediumRef(uid), where("dir", "==", dir))
      );
      const maxOrder = snapshot.docs.reduce(
        (acc, doc) => Math.max(acc, doc.data().order || 0),
        0
      );
      order = maxOrder + 1;
    }

    updateDoc(doc(mediumRef(uid), mediumId), { order, dir });
  };
