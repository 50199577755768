import { memo, useCallback } from "react";
import { useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import toCDNUrl from "modules/toCDNUrl";

type CharacterNameSelectItemProps = {
  characterId: string;
  onSelect: (name: string) => void;
  isActive?: boolean;
};

const CharacterNameSelectItem = ({
  characterId,
  isActive,
  onSelect,
}: CharacterNameSelectItemProps) => {
  const [t] = useTranslation();
  const character = useAppSelector((state) =>
    store.getCharacterById(state, characterId)
  );

  const handleSelect = useCallback(() => {
    onSelect(character.name);
  }, [character.name, onSelect]);

  if (!character.name) return null;
  return (
    <ListItemButton dense onClick={handleSelect}>
      <ListItemAvatar>
        <CharacterAvatar src={toCDNUrl(character.iconUrl)} />
      </ListItemAvatar>
      <ListItemText
        primary={character.name}
        secondary={isActive ? t("アクティブ状態") : t("非アクティブ状態")}
      />
    </ListItemButton>
  );
};

const CharacterAvatar = styled(Avatar)`
  background: rgba(0, 0, 0, 0.4);
  .MuiAvatar-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
  }
  &.MuiAvatar-colorDefault {
    color: #363636;
  }
`;

export default memo(CharacterNameSelectItem);
