import { actions } from "./slice";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils/operators";
import {
  getMaxZIndex,
  getRoomItemById,
  getRoomItemIds,
  getSortedRoomItemIds,
} from "./selectors";
import { UpdateItem } from "./";
import { ItemRecord } from "./records";
import { DefaultRootState, DefaultThunk } from "stores";
import { addCurrentUserFile } from "../entities.user.files/operations";
import { getAppState } from "../app.state/selectors";
import { getUid } from "../app.user/selectors";
import mesureImage from "modules/mesureImage";
import {
  DocumentData,
  FirestoreDataConverter,
  addDoc,
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { getRoomDeckIdByPosition } from "../entities.room.decks/selectors";
import { addRoomDeckItem } from "../entities.room.decks/operations";
import { addUndo } from "../entities.room.histories/slice";
import { Position } from "containers/DraggableItem";
import {
  calcOrderAppendingToTail,
  reorderEntities,
  undoRedoReorderEntities,
} from "../firestoreModuleUtils/ordaring";

const itemConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(item: UpdateItem) {
    return item;
  },
  fromFirestore(snapshot, options): DocumentData {
    const data = snapshot.data(options)!;
    return ItemRecord(data);
  },
};

export const itemsRef = (roomId: string) =>
  collection(db, "rooms", roomId, "items").withConverter(itemConverter);

export const subscribeRoomItems = createSubscribeCollection(
  actions,
  (roomId: string) => query(itemsRef(roomId), orderBy("order"))
);

export const addRoomItem =
  (data: UpdateItem): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const ids = getSortedRoomItemIds(state);
    if (!roomId) return;
    // always new items in the very front
    data.z = getMaxZIndex(state);
    const cellSize = 24; // todo
    if (data.imageUrl && !data.width && !data.height) {
      const image = await mesureImage(data.imageUrl, cellSize, {
        width: data.width || 2,
        height: data.height || 2,
      });
      data.width = image.width;
      data.height = image.height;
      data.x = Math.ceil(-image.width / 2);
      data.y = Math.ceil(-image.height / 2);
    }
    data.order = calcOrderAppendingToTail(
      ids,
      state.entities.roomItems.entities
    );
    const doc = await addDoc(
      itemsRef(roomId),
      ItemRecord({
        ...data,
      })
    );
    dispatch(
      addUndo({
        kind: "update-item",
        id: doc.id,
        before: null,
        after: data,
      })
    );
  };

export const reorderItems = reorderEntities({
  selectOrderdIds: getSortedRoomItemIds,
  selectEntities: (state: DefaultRootState) =>
    state.entities.roomItems.entities,
  actionReorder: actions.reorder,
  collectionRef: itemsRef,
  type: "item",
});

export const undoRedoReorderItems = undoRedoReorderEntities({
  selectOrderdIds: getSortedRoomItemIds,
  selectEntities: (state: DefaultRootState) =>
    state.entities.roomItems.entities,
  actionReorder: actions.reorder,
  collectionRef: itemsRef,
});

export const importRoomItems =
  (itemsData: { [itemId: string]: UpdateItem }): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const itemIds = Object.keys(itemsData);
    const batch = writeBatch(db);
    const ref = itemsRef(roomId);
    itemIds.forEach((itemId) => {
      batch.set(doc(ref, itemId), ItemRecord(itemsData[itemId]));
    });
    return batch.commit();
  };

export const addRoomItemByImageDrop =
  (file: File): DefaultThunk<Promise<void>> =>
  async (dispatch) => {
    const addedFile = (await dispatch(addCurrentUserFile(file, "item"))) as {
      url?: string;
    };
    if (addedFile?.url) {
      const cellSize = 24; // todo
      const image = await mesureImage(addedFile?.url, cellSize, {
        width: 2,
        height: 2,
      });
      const x = -Math.ceil(image.width / 2);
      const y = -Math.ceil(image.height / 2);
      dispatch(
        addRoomItem({
          imageUrl: addedFile.url,
          ...image,
          x,
          y,
        })
      );
    } else {
      alert("File upload is failed");
    }
  };

export const addRoomItemsByImagesDrop =
  (files: File[]): DefaultThunk<Promise<void>> =>
  async (dispatch) => {
    if (files.length > 100) {
      alert("Exceeded the upper limit of 100");
      return;
    }
    for (let i = 0; i < files.length; i++) {
      await dispatch(addRoomItemByImageDrop(files[i]));
    }
  };

export const updateRoomItemLocked =
  (roomId: string, itemId: string, locked: boolean): DefaultThunk =>
  () => {
    if (!roomId) return;
    return updateDoc(doc(itemsRef(roomId), itemId), {
      locked,
      updatedAt: Date.now(),
    });
  };

export const updateRoomItemPosition =
  (roomId: string, itemId: string, { x, y }: { x: number; y: number }) =>
  () => {
    return updateDoc(doc(itemsRef(roomId), itemId), {
      x,
      y,
      updatedAt: Date.now(),
    });
  };

export const updateRoomItemSize =
  (
    roomId: string,
    itemId: string,
    { width, height }: { width: number; height: number }
  ) =>
  () => {
    return updateDoc(doc(itemsRef(roomId), itemId), {
      width,
      height,
      updatedAt: Date.now(),
    });
  };

export const updateRoomItemImage =
  (roomId: string, itemId: string, url: string | null) => () => {
    return updateDoc(doc(itemsRef(roomId), itemId), {
      imageUrl: url,
      updatedAt: Date.now(),
    });
  };

export const updateRoomItem =
  (_: unknown, itemId: string, item: UpdateItem): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    const beforeItem = getRoomItemById(getState(), itemId);
    if (!roomId || !beforeItem) return;
    updateDoc(doc(itemsRef(roomId), itemId), {
      ...item,
      updatedAt: Date.now(),
    });
    // 更新前のItemから変更がある場合のみundoのスタックに追加
    const isUpdate = Object.keys(item).some(
      (key) => item[key] !== beforeItem[key]
    );
    if (isUpdate) {
      dispatch(
        addUndo({
          kind: "update-item",
          id: itemId,
          before: beforeItem,
          after: { ...beforeItem, ...item },
        })
      );
    }
    return;
  };

export const dragedItem =
  (itemId: string, position: Position): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const item = getRoomItemById(state, itemId);
    if (!roomId || !item) return;

    const overlappedDeckId = getRoomDeckIdByPosition(state, {
      x: position.x,
      y: position.y,
      width: item.width,
      height: item.height,
      coverImageUrl: item.coverImageUrl,
    });
    if (overlappedDeckId) {
      dispatch(addRoomDeckItem(overlappedDeckId, itemId));
    } else {
      dispatch(updateRoomItem(roomId, itemId, position));
    }
  };

export const updateCurrentRoomItem =
  (item: UpdateItem): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    const itemId = getState().app.state.openRoomPanelDetailId;
    if (!roomId || !itemId) return;
    const beforeItem = getRoomItemById(getState(), itemId);
    if (!beforeItem) return;
    updateDoc(doc(itemsRef(roomId), itemId), {
      ...item,
      updatedAt: Date.now(),
    });
    dispatch(
      addUndo({
        kind: "update-item",
        id: itemId,
        before: beforeItem,
        after: { ...beforeItem, ...item },
      })
    );
    return;
  };

export const addRoomItemById =
  (roomId: string, itemId: string, item: UpdateItem) => () => {
    return setDoc(doc(itemsRef(roomId), itemId), {
      ...item,
      updatedAt: Date.now(),
    });
  };

export const addRoomItems =
  (items: UpdateItem[]): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const batch = writeBatch(db);
    items.forEach((item) => {
      batch.set(doc(itemsRef(roomId), item._id), { ...item }, { merge: true });
    });
    return batch.commit();
  };

// Cards
export const takeRoomItem =
  (itemId: string): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);
    const roomId = getAppState(state, "roomId");
    const name = getAppState(state, "roomChatName") || "noname";
    const color = getAppState(state, "roomChatColor") || "#cccccc";
    const item = getRoomItemById(state, itemId);
    if (!roomId || !item) return;
    const itemSettings = {
      closed: true,
      withoutOwner: false,
      owner: uid,
      ownerName: name,
      ownerColor: color,
      updatedAt: Date.now(),
    };

    updateDoc(doc(itemsRef(roomId), itemId), itemSettings);
    dispatch(
      addUndo({
        kind: "update-item",
        id: itemId,
        before: item,
        after: { ...item, ...itemSettings },
      })
    );
  };

export const openWithoutOwnerRoomItem =
  (itemId: string): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);
    const roomId = getAppState(state, "roomId");
    const name = getAppState(state, "roomChatName") || "noname";
    const color = getAppState(state, "roomChatColor") || "#cccccc";
    const item = getRoomItemById(getState(), itemId);
    if (!roomId || !itemId || !item) return;
    const itemSettings = {
      closed: true,
      withoutOwner: true,
      owner: uid,
      ownerName: name,
      ownerColor: color,
      updatedAt: Date.now(),
    };
    updateDoc(doc(itemsRef(roomId), itemId), itemSettings);
    dispatch(
      addUndo({
        kind: "update-item",
        id: itemId,
        before: item,
        after: { ...item, ...itemSettings },
      })
    );
  };

export const closeRoomItem =
  (itemId: string): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    const item = getRoomItemById(getState(), itemId);
    if (!roomId || !item) return;
    const itemSettings = {
      closed: true,
      withoutOwner: false,
      owner: null,
      ownerName: null,
      ownerColor: null,
      updatedAt: Date.now(),
    };
    updateDoc(doc(itemsRef(roomId), itemId), itemSettings);
    dispatch(
      addUndo({
        kind: "update-item",
        id: itemId,
        before: item,
        after: { ...item, ...itemSettings },
      })
    );
    return;
  };

export const openRoomItem =
  (itemId: string): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    const item = getRoomItemById(getState(), itemId);
    if (!roomId || !item) return;
    const itemSettings = {
      closed: false,
      withoutOwner: false,
      owner: null,
      ownerName: null,
      ownerColor: null,
      updatedAt: Date.now(),
    };
    updateDoc(doc(itemsRef(roomId), itemId), itemSettings);
    dispatch(
      addUndo({
        kind: "update-item",
        id: itemId,
        before: item,
        after: { ...item, ...itemSettings },
      })
    );
    return;
  };

export const deleteRoomItem = (roomId: string, itemId: string) => () => {
  return deleteDoc(doc(itemsRef(roomId), itemId));
};

export const deleteCurrentRoomItem =
  (itemId: string): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    return deleteDoc(doc(itemsRef(roomId), itemId));
  };

export const deleteRoomItems =
  (itemIds: string[]): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const batch = writeBatch(db);
    itemIds.forEach((itemId) => {
      batch.delete(doc(itemsRef(roomId), itemId));
    });
    return batch.commit();
  };

export const closeAllRoomItems = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const roomId = getState().app.state.roomId;
  const itemIds = getRoomItemIds(state);
  if (!roomId) return;
  const batch = writeBatch(db);
  itemIds.forEach((itemId) => {
    const item = getRoomItemById(state, itemId);
    if (item?.active && item?.coverImageUrl) {
      batch.update(doc(itemsRef(roomId), itemId), {
        closed: true,
        owner: null,
      });
    }
  });
  return batch.commit();
};

export const deleteAllRoomItems = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const roomId = getState().app.state.roomId;
  const itemIds = getRoomItemIds(state);
  if (!roomId) return;
  const batch = writeBatch(db);
  itemIds.forEach((itemId) => {
    batch.delete(doc(itemsRef(roomId), itemId));
  });
  return batch.commit();
};
