import React, {
  ReactNode,
  RefObject,
  useCallback,
  WheelEventHandler,
} from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import Items from "./Panels";
import Markers from "./Markers";
import Pieces from "./Pieces";
import DiceSymbols from "./DiceSymbols";
import Field from "./Field";
import Decks from "../../Decks";
import { fixSelectionOnDragStop } from "modules/drag";
import DraggableField from "./DraggableField";
import DraggableContext from "containers/DraggableContext";

type ScreenProps = {
  roomId: string;
  uid: string;
  centerRef: RefObject<HTMLDivElement>;
};

const Screen = (props: ScreenProps) => {
  const dispatch = useAppDispatch();
  const scale = useAppSelector((state) =>
    store.getAppState(state, "roomScreenScale")
  );
  const position = useAppSelector((state) =>
    store.getAppState(state, "roomScreenPosition")
  );
  const p = React.useRef({ x: 0, y: 0 });
  React.useLayoutEffect(() => {
    p.current.x = position.x;
    p.current.y = position.y;
    if (!props.centerRef.current) return;
    props.centerRef.current.style.transform = `translate(${p.current.x}px, ${p.current.y}px)`;
  }, [position, props.centerRef]);
  const onScaling = useCallback(
    (e) => {
      let nextScale;
      if (e.deltaY < 0) {
        nextScale = Math.min((scale * 10 + 1) / 10, 2.0);
      } else {
        nextScale = Math.max((scale * 10 - 1) / 10, 0.2);
      }
      const magnification = nextScale / scale;
      const nextPosition = {
        x: Math.round(position.x * magnification),
        y: Math.round(position.y * magnification),
      };
      dispatch(
        store.appStateMutate((state) => {
          state.roomScreenScale = nextScale;
          state.roomScreenPosition = nextPosition;
        })
      );
    },
    [dispatch, position, scale]
  );
  const onDrag = useCallback(
    (_e, data) => {
      if (!props.centerRef.current) return;
      p.current.x += data.deltaX;
      p.current.y += data.deltaY;
      props.centerRef.current.style.transform = `translate(${p.current.x}px, ${p.current.y}px)`;
    },
    [props.centerRef]
  );
  const onDragStop = useCallback(() => {
    fixSelectionOnDragStop();
    const nextPosition = {
      x: Math.round(p.current.x),
      y: Math.round(p.current.y),
    };
    dispatch(
      store.appStateMutate((state) => {
        state.roomScreenPosition = nextPosition;
      })
    );
  }, [dispatch]);
  return (
    <DraggableField onDrag={onDrag} onStop={onDragStop}>
      <DraggableContext>
        <View onWheel={onScaling}>
          <Anchor ref={props.centerRef}>
            <Scaler scale={scale}>
              <Field />
              <Items />
              <Pieces roomId={props.roomId} uid={props.uid} />
              <Markers roomId={props.roomId} />
              <DiceSymbols roomId={props.roomId} />
              <Decks />
            </Scaler>
          </Anchor>
        </View>
      </DraggableContext>
    </DraggableField>
  );
};

type ScalerProps = {
  scale: number;
  onWheel?: WheelEventHandler<HTMLDivElement>;
  children: ReactNode;
};

const Scaler = ({ scale, onWheel, children }: ScalerProps) => (
  <div style={{ transform: `scale(${scale})` }} onWheel={onWheel}>
    {children}
  </div>
);

const Anchor = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
`;

const View = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: none;
`;

export default React.memo(Screen);
