import { DefaultThunk } from "stores";
import { UpdateNote } from "stores/modules/entities.room.notes";
import { undoRedoReorderNotes } from "stores/modules/entities.room.notes/operations";

export type OrderNoteRecord = {
  kind: "change-note-index";
  id: string;
  beforeIndex: number;
  afterIndex: number;
  item: UpdateNote;
};

export const undoOrderNote =
  (history: OrderNoteRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;

    dispatch(
      undoRedoReorderNotes(
        roomId,
        history.id,
        history.beforeIndex,
        history.item
      )
    );
    return;
  };

export const redoOrderNote =
  (history: OrderNoteRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;

    dispatch(
      undoRedoReorderNotes(roomId, history.id, history.afterIndex, history.item)
    );
    return;
  };
