import { DefaultThunk } from "stores";
import { UpdateDeck } from "stores/modules/entities.room.decks";
import {
  deleteRoomDeck,
  updateMergeRoomDecks,
} from "stores/modules/entities.room.decks/operations";

export type SourceDeck = {
  id: string;
  deck: UpdateDeck;
};

export type MergeDecksRecord = {
  kind: "merge-decks";
  id: string;
  before: UpdateDeck;
  after: UpdateDeck;
  sourceDeck: SourceDeck;
};

export const undoMergeDecks =
  (history: MergeDecksRecord): DefaultThunk =>
  (dispatch, _) => {
    const before = history.before;

    // デッキを合わせている場合
    dispatch(updateMergeRoomDecks(history.id, before, history.sourceDeck));
    return;
  };

export const redoMergeDecks =
  (history: MergeDecksRecord): DefaultThunk =>
  (dispatch, _) => {
    const after = history.after;

    // 上に重なっていた山札を削除
    dispatch(deleteRoomDeck(history.sourceDeck.id));

    // 変更後の状態に山札を更新
    dispatch(updateMergeRoomDecks(history.id, after));
    return;
  };
