import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { memo, useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import ShareButton from "./ShareButton";
import XIcon from "./Icons/XIcon";
import LineIcon from "./Icons/LineIcon";
import FacebookIcon from "./Icons/FacebookIcon";
import { useAppDispatch } from "stores";
import { sendPurchaseProductEvent } from "stores/modules/app.state/operations";

type PurchasedDialogProps = {
  open: boolean;
  onClose: () => void;
  onRequestCreateRoom: () => void;
  name: string;
  thumbnailUrl: string;
  productId: string;
  roomPackageId: string;
  parentProductId: string | undefined;
  isFree: boolean;
};

const PurchasedDialog = ({
  open,
  onClose,
  name,
  thumbnailUrl,
  productId,
  parentProductId,
  isFree,
}: PurchasedDialogProps) => {
  const [t] = useTranslation();

  const shareUrl = makeShareUrl();
  const lineHref = makeLineShareURL();
  const xHref = makeXShareURL(name);
  const facebookHref = makeFaceboookShareURL();

  const [openTooltip, setOpen] = useState(false);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const copyUrlToClickboard = useCallback(() => {
    if (!navigator.clipboard) {
      alert(
        t(
          "エラー：このページのURLをクリップボードにコピーしようとしましたが、失敗しました。"
        )
      );
    }

    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setOpen(true);
        window.setTimeout(() => {
          setOpen(false);
        }, 1000);
      })
      .catch(() =>
        alert(
          t(
            "エラー：このページのURLをクリップボードにコピーしようとしましたが、失敗しました。"
          )
        )
      );
  }, [shareUrl, setOpen, t]);

  useSendPurchaseProductEvent({ open, productId });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle textAlign="center">
        {isFree ? t("ライブラリに追加しました") : t("購入が完了しました")}
      </DialogTitle>
      <DialogContent>
        <Thumbnail src={thumbnailUrl} />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          gap="8px"
          marginTop="20px"
        >
          <Typography color="textPrimary" marginRight="8px" whiteSpace="nowrap">
            {t("シェア")}
          </Typography>
          <Box display="flex" gap="8px">
            <ShareButton href={xHref} target="_blank" rel="noreferrer">
              <XIcon />
            </ShareButton>
            <ShareButton href={lineHref} target="_blank" rel="noreferrer">
              <LineIcon />
            </ShareButton>
            <ShareButton href={facebookHref} target="_blank" rel="noreferrer">
              <FacebookIcon />
            </ShareButton>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={openTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={t("URLをコピーしました")}
            >
              <ShareButton onClick={copyUrlToClickboard}>
                <ContentCopyIcon sx={{ color: "#fff" }} />
              </ShareButton>
            </Tooltip>
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogContent>
        <DialogContentText
          variant="body1"
          fontSize="14px"
          color="textPrimary"
          marginTop="8px"
          marginBottom="4px"
        >
          {isFree
            ? t(
                "ルームの作成やファイルのダウンロードをするには、ライブラリに移動してください。"
              )
            : t(
                "お買い上げいただきありがとうございます。ルームの作成やファイルのダウンロードをするには、ライブラリに移動してください。"
              )}
        </DialogContentText>
        <DialogContentText
          variant="body1"
          fontSize="14px"
          color="textSecondary"
          marginBottom="24px"
        >
          <Trans>
            ※ ご利用方法は
            <Link
              underline="hover"
              href="https://docs.ccfolia.com/tutorial/ccfoliagames-rta"
              target="_blank"
            >
              ヘルプ
            </Link>
            をご確認ください。
          </Trans>
        </DialogContentText>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          component={RouterLink}
          to={`/library/${parentProductId || productId}`}
        >
          {t("ライブラリに移動")}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" fullWidth>
          {t("閉じる")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type UseSendPurchasedEventProps = {
  open: boolean;
  productId: string;
};

const useSendPurchaseProductEvent = ({
  open,
  productId,
}: UseSendPurchasedEventProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (open) {
      dispatch(sendPurchaseProductEvent(productId));
    }
  }, [open, productId, dispatch]);
};

const Thumbnail = styled.img`
  width: 100%;
  border-radius: 4px;
`;

const makeShareUrl = () => {
  return (
    window.location.origin + window.location.pathname + "?referer=clipboard"
  );
};

const makeLineShareURL = (): string => {
  const targetUrl =
    window.location.origin + window.location.pathname + "?referer=share-line";
  const getParams = new URLSearchParams();
  getParams.append("url", targetUrl);

  return `https://social-plugins.line.me/lineit/share?${getParams.toString()}`;
};

const makeXShareURL = (projectName: string): string => {
  const targetUrl =
    window.location.origin + window.location.pathname + "?referer=share-x";

  const getParams = new URLSearchParams();
  getParams.append("url", targetUrl);
  getParams.append("text", `『${projectName}』をCCFOLIAで買いました！`);

  return `https://twitter.com/intent/tweet?${getParams.toString()}`;
};

const makeFaceboookShareURL = (): string => {
  const targetUrl =
    window.location.origin +
    window.location.pathname +
    "?referer=share-facebook";

  const getParams = new URLSearchParams();
  getParams.append("u", targetUrl);

  return `https://www.facebook.com/share.php?${getParams.toString()}`;
};

export default memo(PurchasedDialog);
