import { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";

import PanelList from "./MarkerList";
import FloatWindow from "components/FloatWindow";

import { useTranslation } from "react-i18next";

const MarkerListDrawer = () => {
  const d = useAppDispatch();
  const [t] = useTranslation();
  const open = useAppSelector(
    (state) => store.getAppState(state, "openRoomControlWindows").marker.open
  );
  const onClose = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomControlWindows.marker.open = false;
        state.openRoomControls = null;
      })
    );
  }, [d]);
  const onAdd = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "marker";
        state.openRoomImageSelectTarget = "marker/new";
      })
    );
  }, [d]);
  return (
    <FloatWindow
      onClose={onClose}
      onAdd={onAdd}
      open={open}
      title={t("マーカーパネル一覧")}
    >
      <PanelList />
    </FloatWindow>
  );
};

export default memo(MarkerListDrawer);
