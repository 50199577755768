import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HelpIcon from "@mui/icons-material/Help";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "stores";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import { getAppState, getIsOwner } from "stores/modules/app.state/selectors";
import {
  duplicateRoom,
  updateCurrentRoom,
} from "stores/modules/entities.rooms/operations";
import { FormEvent, MouseEvent, useCallback, useEffect, useState } from "react";
import { getIsPro } from "stores/modules/app.user/selectors";
import { useForm } from "react-hook-form";
import {
  appStateMutate,
  loadDiceBotInfo,
} from "stores/modules/app.state/operations";
import CcfoliaProIcon from "./CcfoliaProIcon";
import theme from "theme";
import {
  SettingAction,
  SettingActionButton,
  SettingActionDescription,
} from "./SettingAction";
import { getHasEditableRole } from "stores/modules/entities.room.members/selectors";

const GeneralSettings = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const isOwner = useAppSelector(getIsOwner);
  const hasEditableRole = useAppSelector(getHasEditableRole);
  const isPro = useAppSelector(getIsPro);
  const roomId = useAppSelector((state) => getAppState(state, "roomId"));
  const diceBotName = useAppSelector(
    (state) => getCurrentRoom(state)?.diceBotName
  );
  const hasParentRoomPackage = useAppSelector((state) => {
    const room = getCurrentRoom(state);
    return room?.parentRoomPackageId != null;
  });

  const monitored = useAppSelector((state) => {
    const room = getCurrentRoom(state);
    return room?.monitored ?? false;
  });

  const underConstruction = useAppSelector((state) => {
    const room = getCurrentRoom(state);
    return room?.underConstruction ?? false;
  });

  const hidden3dDice = useAppSelector((state) => {
    const room = getCurrentRoom(state);
    return room?.hidden3dDice ?? false;
  });

  const noGrid = useAppSelector((state) => {
    const room = getCurrentRoom(state);
    return !(room?.alignWithGrid ?? true);
  });

  const onClickDuplicate = useCallback(() => {
    if (
      isPro &&
      roomId &&
      !hasParentRoomPackage &&
      isOwner &&
      window.confirm("Start duplicating this room.")
    ) {
      dispatch(duplicateRoom(roomId));
    }
  }, [dispatch, isPro, roomId, isOwner, hasParentRoomPackage]);

  const onOpenRoomDiceBotTypeList = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomDiceBotTypeList = true;
      })
    );
  }, [dispatch]);

  const onOpenDiceBotHelp = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      dispatch(loadDiceBotInfo());
    },
    [dispatch]
  );

  return (
    <>
      <Heading>{t("ルームの概要")}</Heading>
      <TextFieldRoomName />
      <TextField
        label={t("ダイスボット")}
        variant="outlined"
        fullWidth
        margin="normal"
        value={diceBotName || t("ダイスボットを選択")}
        InputProps={{
          readOnly: true,
          endAdornment: <ArrowDropDownIcon />,
        }}
        inputProps={{
          style: { cursor: hasEditableRole ? "pointer" : "default" },
        }}
        onClick={onOpenRoomDiceBotTypeList}
        disabled={!hasEditableRole}
      />
      <Box display="flex" alignItems="center" gap="8px">
        <HelpIcon color="primary" />
        <Typography variant="body2" color={theme.palette.text.secondary}>
          {t("ルームで使用するダイスボットを選択できます。")}
          <Link href="#" onClick={onOpenDiceBotHelp} underline="hover">
            {t("選択中のダイスボットの詳細はこちら。")}
          </Link>
        </Typography>
      </Box>
      <Divider style={{ margin: "40px 0" }} />
      <Heading>{t("ルーム設定")}</Heading>
      <SubTitle>{t("モードの設定")}</SubTitle>
      <Box ml={3} mb={3}>
        <List disablePadding>
          <StyledListItem>
            <StyledListItemText
              primary={t("監視者モード")}
              secondary={t(
                "ルームマスターが全てのカードとキャラクター駒を見通します"
              )}
            />
            <StyledListItemSecondaryAction>
              <Switch
                name="monitored"
                checked={monitored}
                onChange={(_, checked) => {
                  if (hasEditableRole) {
                    dispatch(updateCurrentRoom({ monitored: checked }));
                  }
                }}
                disabled={!hasEditableRole}
              />
            </StyledListItemSecondaryAction>
          </StyledListItem>
          <StyledListItem>
            <StyledListItemText
              primary={t("工事中モード")}
              secondary={t("ルームマスター以外の入室を禁止します")}
            />
            <StyledListItemSecondaryAction>
              <Switch
                name="underConstruction"
                checked={underConstruction}
                onChange={(_, checked) => {
                  if (hasEditableRole) {
                    dispatch(updateCurrentRoom({ underConstruction: checked }));
                  }
                }}
                disabled={!hasEditableRole}
              />
            </StyledListItemSecondaryAction>
          </StyledListItem>
          <StyledListItem>
            <StyledListItemText
              primary={t("自由配置モード")}
              secondary={t("グリットに関係なく自由な位置に配置します")}
            />
            <StyledListItemSecondaryAction>
              <Switch
                name="noGrid"
                checked={noGrid}
                onChange={(_, checked) => {
                  if (hasEditableRole) {
                    dispatch(updateCurrentRoom({ alignWithGrid: !checked }));
                  }
                }}
                disabled={!hasEditableRole}
              />
            </StyledListItemSecondaryAction>
          </StyledListItem>
        </List>
      </Box>
      <SubTitle>{t("その他の設定")}</SubTitle>
      <Box ml={3} mb={3}>
        <List disablePadding>
          <StyledListItem>
            <StyledListItemText
              primary={t("旧ダイス演出を利用する")}
              secondary={t("メッセージウインドウにダイスを表示します")}
            />
            <StyledListItemSecondaryAction>
              <Switch
                name="hidden3dDice"
                checked={hidden3dDice}
                onChange={(_, checked) => {
                  if (hasEditableRole) {
                    dispatch(updateCurrentRoom({ hidden3dDice: checked }));
                  }
                }}
                disabled={!hasEditableRole}
              />
            </StyledListItemSecondaryAction>
          </StyledListItem>
        </List>
      </Box>
      <Divider style={{ margin: "40px 0" }} />
      <Heading>
        {t("ルームを複製")} <CcfoliaProIcon />
      </Heading>
      <SettingAction>
        <SettingActionDescription>
          <Typography variant="body1">{t("ルームを複製")}</Typography>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {t("現在のルームの状態を複製します。")}
          </Typography>
        </SettingActionDescription>
        <SettingActionButton
          onClick={onClickDuplicate}
          disabled={!isPro || hasParentRoomPackage || !isOwner}
        >
          {t("ルームを複製")}
        </SettingActionButton>
      </SettingAction>
    </>
  );
};

const preventDefault = (event: FormEvent) => {
  event.preventDefault();
};

const TextFieldRoomName = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const roomName = useAppSelector((state) => getCurrentRoom(state)?.name);
  const hasEditableRole = useAppSelector(getHasEditableRole);

  const { register, handleSubmit, reset } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: roomName || "",
    },
  });

  useEffect(() => {
    reset({
      name: roomName,
    });
  }, [reset, roomName]);

  const [openTooltip, setOpenTooltip] = useState(false);
  const onSubmit = useCallback(
    ({ name }: { name: string }) => {
      if (hasEditableRole) {
        dispatch(updateCurrentRoom({ name })).then(() => {
          setOpenTooltip(true);
          setTimeout(() => {
            setOpenTooltip(false);
          }, 1000);
        });
      }
    },
    [hasEditableRole, dispatch, setOpenTooltip]
  );

  return (
    <form onSubmit={preventDefault} onBlur={handleSubmit(onSubmit)}>
      <Tooltip
        open={openTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={t("ルーム名を変更しました")}
      >
        <TextField
          label={t("ルーム名")}
          variant="filled"
          fullWidth
          margin="normal"
          disabled={!hasEditableRole}
          {...register("name")}
        />
      </Tooltip>
    </form>
  );
};

const Heading = styled(Typography).attrs({ variant: "h2" })`
  margin: 0;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
`;

const SubTitle = styled(Typography).attrs({ variant: "h2" })`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`;

const StyledListItem = styled(ListItem)`
  padding-left: 0;
  padding-right: 0;
`;

const StyledListItemText = styled(ListItemText)`
  font-size: 16px;
  padding-right: 110px;
`;

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
  right: 0;
`;

export default GeneralSettings;
