import { DefaultThunk } from "stores";
import { UpdateMarker } from "stores/modules/entities.rooms";
import {
  deleteRoomMarker,
  updateRoomMarker,
} from "stores/modules/entities.rooms/operations";

export type UpdateMarkerRecord = {
  kind: "update-marker";
  id: string;
  before: UpdateMarker | null;
  after: UpdateMarker | null;
};

export const undoUpdateMarker =
  (history: UpdateMarkerRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const before = history.before;

    if (!before) {
      // 変更前データがない場合は追加が行われたとして削除処理
      dispatch(deleteRoomMarker(roomId, history.id));
      return;
    }

    // それ以外の場合は通常の更新処理
    dispatch(updateRoomMarker(roomId, history.id, before));
    return;
  };

export const redoUpdateMarker =
  (history: UpdateMarkerRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const after = history.after;

    if (!after) {
      // 変更後データがない場合は削除処理
      dispatch(deleteRoomMarker(roomId, history.id));
      return;
    }

    // それ以外の場合は通常の更新処理
    dispatch(updateRoomMarker(roomId, history.id, after));
    return;
  };
