import React, { memo } from "react";
import { useAppSelector } from "stores";
import store from "stores/interfaces";
import Effect from "./Effect";

const Effects = () => {
  const effectIds = useAppSelector(store.getRoomEffectIds);
  return (
    <>
      {effectIds.map((effectId) => (
        <Effect key={effectId} effectId={effectId} />
      ))}
    </>
  );
};

export default memo(Effects);
