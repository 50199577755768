import { memo, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import {
  Dialog,
  TextField,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { addUndo } from "stores/modules/entities.room.histories/slice";
import { DEFAULT_TEXT_COLOR_CODE } from "stores/modules/entities.room.messages/operations";

const style = { padding: 0 };

type NoteDetailProps = {
  roomId: string;
};

type FormData = {
  name: string;
  text: string;
};

const NoteDetail = ({ roomId }: NoteDetailProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomNote")
  );
  const noteId = useAppSelector(
    (state) => store.getAppState(state, "openRoomNoteId") || ""
  );
  const note = useAppSelector((state) => store.getRoomNoteById(state, noteId));

  const onSubmit = useCallback(
    (values: FormData) => {
      const updateNote = {
        name: values.name,
        text: values.text,
      };
      dispatch(store.updateRoomNote(roomId, noteId, updateNote));
      const isUpdate = Object.keys(updateNote).some(
        (key) => note[key] !== updateNote[key]
      );
      if (isUpdate) {
        dispatch(
          addUndo({
            kind: "update-note",
            id: noteId,
            before: note,
            after: { ...note, ...updateNote },
          })
        );
      }
    },
    [roomId, noteId, dispatch, note]
  );
  const { register, handleSubmit, getValues, reset } = useForm<FormData>({
    defaultValues: {
      name: "",
      text: "",
    },
  });

  useEffect(() => {
    reset({ name: note.name, text: note.text });
  }, [note.name, note.text, reset]);

  const onClose = useCallback(() => {
    onSubmit(getValues());
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomNote = false;
      })
    );
  }, [onSubmit, getValues, dispatch]);

  const onDelete = useCallback(() => {
    if (window.confirm(t("本当に削除しますか？"))) {
      dispatch(store.deleteRoomNote(roomId, noteId));
      dispatch(
        addUndo({
          kind: "update-note",
          id: noteId,
          before: note,
          after: null,
        })
      );
      dispatch(
        store.appStateMutate((state) => {
          state.openRoomNote = false;
        })
      );
    }
  }, [roomId, noteId, t, dispatch, note]);

  const onSend = useCallback(() => {
    const values = getValues();
    dispatch(
      store.addMessage(
        roomId,
        null,
        {
          name: values.name,
          text: values.text,
          iconUrl: note.iconUrl || null,
          color: DEFAULT_TEXT_COLOR_CODE,
        },
        false
      )
    );
  }, [roomId, note.iconUrl, getValues, dispatch]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent style={style}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="filled"
            label={t("タイトル")}
            fullWidth
            maxRows={24}
            rows={12}
            {...register("name")}
          />
          <TextField
            variant="filled"
            label={t("テキスト")}
            multiline
            fullWidth
            maxRows={24}
            rows={12}
            {...register("text")}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onDelete} color="secondary">
          {t("削除")}
        </Button>
        <Button fullWidth onClick={onSend}>
          {t("送信")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Form = styled.form`
  display: block;
`;

export default memo(NoteDetail);
