import { useCallback, memo } from "react";
import { useAppDispatch } from "stores";
import store from "stores/interfaces";
import { TextField, Box, IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

type FormData = {
  roomId: string;
};

const RoomSelectionForm = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: { roomId: "" },
  });

  const onSubmit = useCallback(
    ({ roomId }) => {
      dispatch(store.loginRoom(roomId));
    },
    [dispatch]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box padding={2} display="flex" justifyContent="flex-start">
        <TextField
          variant="standard"
          label={t("部屋IDを指定して入室")}
          fullWidth
          type="text"
          placeholder={t("9文字の半角英数字")}
          inputProps={register("roomId")}
        />
        <IconButton type="submit" size="large">
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </form>
  );
};

export default memo(RoomSelectionForm);
