import { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import theme from "theme";
import { Slider, ButtonBase } from "@mui/material";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { getAppState } from "stores/modules/app.state/selectors";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import { appStateMutate } from "stores/modules/app.state/operations";

const stopPropagation = (e) => e.stopPropagation();

const ZoomControls = () => {
  const dispatch = useAppDispatch();

  const scale = useAppSelector((state) =>
    getAppState(state, "roomScreenScale")
  );
  const position = useAppSelector((state) =>
    getAppState(state, "roomScreenPosition")
  );
  const enabledEmote = useAppSelector(
    (state) => getCurrentRoom(state)?.features.emote || false
  );

  const onScaleDown = useCallback(() => {
    const nextScale = Math.max((scale * 10 - 1) / 10, 0.2);
    const magnification = nextScale / scale;
    const nextPosition = {
      x: Math.round(position.x * magnification),
      y: Math.round(position.y * magnification),
    };
    dispatch(
      appStateMutate((state) => {
        state.roomScreenScale = nextScale;
        state.roomScreenPosition = nextPosition;
      })
    );
  }, [scale, position, dispatch]);

  const onScaleUp = useCallback(() => {
    const nextScale = Math.min((scale * 10 + 1) / 10, 2.0);
    const magnification = nextScale / scale;
    const nextPosition = {
      x: Math.round(position.x * magnification),
      y: Math.round(position.y * magnification),
    };
    dispatch(
      appStateMutate((state) => {
        state.roomScreenScale = nextScale;
        state.roomScreenPosition = nextPosition;
      })
    );
  }, [scale, position, dispatch]);

  const onScaleChange = useCallback(
    (_, value) => {
      const nextScale = value;
      const magnification = nextScale / scale;
      const nextPosition = {
        x: Math.round(position.x * magnification),
        y: Math.round(position.y * magnification),
      };
      dispatch(
        appStateMutate((state) => {
          state.roomScreenScale = nextScale;
          state.roomScreenPosition = nextPosition;
        })
      );
    },
    [scale, position, dispatch]
  );

  return (
    <ZoomBox onDoubleClick={stopPropagation} shiftUp={enabledEmote}>
      <ZoomButton onClick={onScaleUp}>
        <ZoomInIcon fontSize="small" />
      </ZoomButton>
      <ZoomSlider
        orientation="vertical"
        onChange={onScaleChange}
        aria-labelledby="vertical-slider"
        min={0.2}
        max={2}
        step={0.1}
        value={scale}
        valueLabelDisplay="auto"
      />
      <ZoomOutButton onClick={onScaleDown}>
        <ZoomOutIcon fontSize="small" />
      </ZoomOutButton>
    </ZoomBox>
  );
};

const ZoomBox = styled.div<{ shiftUp: boolean }>`
  position: absolute;
  right: 32px;
  bottom: ${(props) => (props.shiftUp ? "152px" : "96px")};
  z-index: 103;
  height: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ZoomButton = styled(ButtonBase)`
  display: block;
  padding-top: 2px;
  width: 24px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  border-radius: 2px 2px 0 0;
`;

const ZoomOutButton = styled(ZoomButton)`
  border-radius: 0 0 2px 2px;
`;

const ZoomSlider = styled(Slider)`
  flex: 1;
  .MuiSlider-track {
    background: ${theme.palette.grey[100]};
    transition: none;
  }
  .MuiSlider-rail {
    background: ${theme.palette.grey[100]};
  }
  .MuiSlider-trackAfter {
    background: ${theme.palette.grey[100]};
  }
  .MuiSlider-thumb {
    margin-top: 1px;
    border-radius: 0;
    width: 24px;
    height: 2px;
    z-index: 1;
    background: ${theme.palette.grey[100]};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    transition: none;
  }
  .MuiSlider-valueLabel {
    right: 32px;
  }
  &.MuiSlider-root {
    padding: 0 13px;
  }
`;

export default memo(ZoomControls);
