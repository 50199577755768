import { createEntitySliceGroupBy } from "../firestoreModuleUtils/slice";
import { UserFile } from "./records";

const slice = createEntitySliceGroupBy<UserFile>({
  name: "userFiles",
  selectGroup: (file) => file.dir,
});

export const reducer = slice.reducer;
export const actions = slice.actions;
