import { DefaultThunk } from "stores";
import {
  addRoomDeckItems,
  returnRoomDeckItems,
} from "stores/modules/entities.room.decks/operations";
import { UpdateItem } from "stores/modules/entities.room.items";

export type DrawCardRecord = {
  kind: "draw-card";
  deckId: string;
  before: UpdateItem[] | null;
  after: UpdateItem[] | null;
  deletedIds?: string[];
};

export const undoDrawCard =
  (history: DrawCardRecord): DefaultThunk =>
  (dispatch, _) => {
    const before = history.before;
    if (!before) {
      const after = history.after;
      if (!after) return;
      // 変更前データがない場合はドローが行われたため、カードを山札に戻す
      dispatch(addRoomDeckItems(history.deckId, after));
      return;
    }

    // 変更前データがある場合は山札にカードを戻しているため、カードを変更前に戻す
    dispatch(returnRoomDeckItems(history.deckId, before, history.deletedIds));
    return;
  };

export const redoDrawCard =
  (history: DrawCardRecord): DefaultThunk =>
  (dispatch, _) => {
    const after = history.after;
    if (!after) {
      const before = history.before;
      if (!before) return;
      // 変更後データがない場合は変更前のカードを山札に戻す
      dispatch(addRoomDeckItems(history.deckId, before));
      return;
    }

    // 変更後データがある場合はカードと山札の状態を更新する
    dispatch(returnRoomDeckItems(history.deckId, after, history.deletedIds));
    return;
  };
