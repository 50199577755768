import { DefaultThunk } from "stores";
import { UpdateItem } from "stores/modules/entities.room.items";
import {
  addRoomItemById,
  deleteRoomItem,
} from "stores/modules/entities.room.items/operations";

export type UpdateItemRecord = {
  kind: "update-item";
  id: string;
  before: UpdateItem | null;
  after: UpdateItem | null;
};

export const undoUpdateItem =
  (history: UpdateItemRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const before = history.before;

    if (!before) {
      // 変更前データがない場合は追加が行われたとして削除処理
      dispatch(deleteRoomItem(roomId, history.id));
      return;
    }

    // それ以外の場合は通常の更新処理
    dispatch(addRoomItemById(roomId, history.id, before));
    return;
  };

export const redoUpdateItem =
  (history: UpdateItemRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const after = history.after;

    if (!after) {
      // 変更後データがない場合は削除処理
      dispatch(deleteRoomItem(roomId, history.id));
      return;
    }

    // それ以外の場合は通常の更新処理
    dispatch(addRoomItemById(roomId, history.id, after));
    return;
  };
