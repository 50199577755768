import React, {
  useCallback,
  useState,
  useRef,
  memo,
  MouseEvent,
  RefObject,
} from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import theme from "theme";
import {
  Fab,
  Menu,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemButton,
} from "@mui/material";

import WallpaperIcon from "@mui/icons-material/Wallpaper";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ExposureIcon from "@mui/icons-material/Exposure";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import EditIcon from "@mui/icons-material/Edit";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";

import { useTranslation } from "react-i18next";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import { getIsPro } from "stores/modules/app.user/selectors";
import CcfoliaProIcon from "containers/CcfoliaProIcon";
import { appStateMutate } from "stores/modules/app.state/operations";
import { addRoomDice } from "stores/modules/entities.room.dices/operations";
import { addRoomDeck } from "stores/modules/entities.room.decks/operations";
import { updateCurrentRoomFeature } from "stores/modules/entities.rooms/operations";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";
import { TimerRef } from "containers/Timer";

const FloatMenu = (props: { timerRef: RefObject<TimerRef> }) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);
  const onToggle = useCallback(() => {
    setOpen((open) => !open);
  }, [setOpen]);

  const timer = useAppSelector((state) => {
    const room = getCurrentRoom(state);
    return room ? room.features.timer : false;
  });
  const isPro = useAppSelector(getIsPro);
  const isRoleAudience = useAppSelector(getIsRoleAudience);
  const disableToggleTimer = !isPro && !timer;

  const onSelectItemImage = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "item";
        state.openRoomImageSelectTarget = "item/new";
      })
    );
    setOpen(false);
  }, [dispatch, setOpen]);

  const onSelectMarkerImage = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomImageSelect = true;
        state.openRoomImageSelectDir = "marker";
        state.openRoomImageSelectTarget = "marker/new";
      })
    );
    setOpen(false);
  }, [dispatch, setOpen]);

  const onEditField = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomFieldEdit = true;
      })
    );
    setOpen(false);
  }, [dispatch, setOpen]);

  const onAddDice = useCallback(() => {
    dispatch(addRoomDice({}));
    setOpen(false);
  }, [dispatch, setOpen]);

  const onAddDeck = useCallback(() => {
    dispatch(addRoomDeck({}));
    setOpen(false);
  }, [dispatch, setOpen]);

  const onToggleTimer = useCallback(() => {
    if (disableToggleTimer) {
      return;
    }

    if (timer) {
      props.timerRef.current?.pause();
    }
    dispatch(updateCurrentRoomFeature("timer", !timer));
    setOpen(false);
  }, [dispatch, setOpen, disableToggleTimer, timer, props.timerRef]);

  return (
    <div onDoubleClick={stopPropagation}>
      <Menu
        anchorEl={anchorEl.current}
        open={open}
        onClose={onToggle}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        MenuListProps={{ dense: true }}
      >
        <ListItemButton onClick={onEditField}>
          <ListItemAvatar>
            <Avatar>
              <WallpaperIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("前景・背景を変更")}
            secondary={t("メインフィールドの画像を設定します")}
          />
        </ListItemButton>
        <ListItemButton onClick={onSelectItemImage}>
          <ListItemAvatar>
            <Avatar>
              <AddPhotoAlternateIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("スクリーンパネルを追加")}
            secondary={t("常に表示し続ける画像オブジェクトを配置します")}
          />
        </ListItemButton>
        <ListItemButton onClick={onSelectMarkerImage}>
          <ListItemAvatar>
            <Avatar>
              <AddLocationIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("マーカーパネルを追加")}
            secondary={t("シーン内でのみ表示する画像オブジェクトを配置します")}
          />
        </ListItemButton>
        <ListItemButton onClick={onAddDice}>
          <ListItemAvatar>
            <Avatar>
              <ExposureIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("ダイスシンボルを追加")}
            secondary={t("盤面にダイスシンボルを追加します")}
          />
        </ListItemButton>
        <ListItemButton onClick={onAddDeck}>
          <ListItemAvatar>
            <Avatar>
              <ViewCarouselIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t("カードデッキを追加")}
            secondary={t("パネルを格納可能なデッキを追加します")}
          />
        </ListItemButton>
        <ListItemButton onClick={onToggleTimer} disabled={disableToggleTimer}>
          <ListItemAvatar>
            <Avatar>
              <AccessAlarmsIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                {t("タイマーを表示")} <CcfoliaProIcon height="12px" />
              </>
            }
            secondary={t("残り時間を表示するタイマーを配置します")}
          />
        </ListItemButton>
      </Menu>
      <FloatFab onClick={onToggle} ref={anchorEl} disabled={isRoleAudience}>
        <EditIcon />
      </FloatFab>
    </div>
  );
};

const stopPropagation = (event: MouseEvent) => event.stopPropagation();

const FloatFab = styled(Fab)`
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 103;
  background: ${theme.palette.grey[100]};
`;

export default memo(FloatMenu);
