import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AppChatState = {
  inputText: string;
};

const initializeState = (): AppChatState => {
  return {
    inputText: "",
  };
};

type MutateFunc = (state: AppChatState) => void;

const slice = createSlice({
  name: "app/chat",
  initialState: initializeState(),
  reducers: {
    appChatStateMutate(state, action: PayloadAction<MutateFunc>) {
      action.payload(state);
    },
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
