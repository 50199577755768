import styled from "styled-components";
import { Link } from "react-router-dom";
import theme from "theme";

import AboutCard from "./AboutCard";
import { Button, ButtonProps, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

const aboutCardList = [
  {
    img: "/images/top/can-01.png",
    title: "自分だけのルーム",
    text: "ココフォリアでは遊びたいゲームごとにルームが作成できます。作成したルームにはあなたが招待したユーザーしか入室できません。",
  },
  {
    img: "/images/top/can-02.png",
    title: "便利なダイスロール",
    text: "チャット機能を利用してさまざまなゲームのダイスロールが行えます。盤面を転るダイスがセッションを盛り上げます。",
  },
  {
    img: "/images/top/can-03.png",
    title: "盤面や演出",
    text: "ココフォリアの盤面は自由に作成できます。画像を使ってカードや駒を作ったり、背景やBGMを使ってセッションを盛り上げましょう。",
  },
  {
    img: "/images/top/can-04.png",
    title: "すぐに遊べる",
    text: "ストアで販売しているゲームは、ルームを作成するだけですぐに遊ぶことができます。TRPGだけでなくさまざまなゲームが遊べます。",
  },
];

const About = () => {
  const [t] = useTranslation();
  return (
    <AboutWrap>
      <AboutContainer>
        <AboutTitle>
          <Trans>
            国内トップシェアの
            <br />
            オンラインセッションツール
          </Trans>
        </AboutTitle>
        <AboutText align="center" color="textSecondary">
          {t("ココフォリアは無料で利用できるオンラインセッションツールです。")}

          <br />
          {t(
            "TRPGだけでなくボードゲームや謎解き、マーダーミステリーなどたくさんのゲームが遊ばれています。"
          )}
          <br />
          {t(
            "Webブラウザ上で利用できるため、パソコンやスマートフォンがあればどこでも誰とでも遊ぶことができます。"
          )}
        </AboutText>
        <AboutImage>
          <img
            src="/images/top/mock-image.png"
            alt="複数端末で表示されているココフォリアの画面の画像"
          />
        </AboutImage>
      </AboutContainer>
      <AboutLists>
        <AboutListTitle>{t("ココフォリアでできること")}</AboutListTitle>
        <CardList>
          <AboutCard
            img="/images/top/can-01.png"
            title={t("自分だけのルーム")}
            text={t(
              "ココフォリアでは遊びたいゲームごとにルームが作成できます。作成したルームにはあなたが招待したユーザーしか入室できません。"
            )}
          />
          <AboutCard
            img="/images/top/can-02.png"
            title={t("便利なダイスロール")}
            text={t(
              "チャット機能を利用してさまざまなゲームのダイスロールが行えます。盤面を転るダイスがセッションを盛り上げます。"
            )}
          />
          <AboutCard
            img="/images/top/can-03.png"
            title={t("盤面や演出")}
            text={t(
              "ココフォリアの盤面は自由に作成できます。画像を使ってカードや駒を作ったり、背景やBGMを使ってセッションを盛り上げましょう。"
            )}
          />
          <AboutCard
            img="/images/top/can-04.png"
            title={t("すぐに遊べる")}
            text={t(
              "ストアで販売しているゲームは、ルームを作成するだけですぐに遊ぶことができます。TRPGだけでなくさまざまなゲームが遊べます。"
            )}
          />
        </CardList>
      </AboutLists>
      <AboutSignupButton
        size="large"
        variant="contained"
        fullWidth
        component={Link}
        to="/home"
        color="secondary"
      >
        {t("無料でアカウントを作成する")}
      </AboutSignupButton>
    </AboutWrap>
  );
};

const AboutWrap = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  &::before {
    content: "";
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;

    top: 152px;
    left: calc(-136 / 1400 * 100%);
    z-index: -1;
    width: calc(456 / 1400 * 100%);
    max-width: 456px;
    height: 100%;
    background-image: url("/images/top/dice01.png");
    ${theme.breakpoints.up("xl")} {
      left: 50%;
      right: 50%;
      transform: translateX(calc(-50% + -580px));
    }
    ${theme.breakpoints.down("md")} {
      display: none;
    }
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;

    top: 165px;
    right: calc(-102 / 1400 * 100%);
    z-index: -1;
    width: calc(451 / 1400 * 100%);
    max-width: 451px;

    height: 100%;
    background-image: url("/images/top/dice02.png");
    ${theme.breakpoints.up("xl")} {
      left: 50%;
      right: 50%;
      transform: translateX(calc(-50% + 560px));
    }
    ${theme.breakpoints.down("md")} {
      display: none;
    }
  }
`;

const AboutContainer = styled.section`
  ${theme.breakpoints.down("md")} {
    width: calc(342 / 390 * 100%);
    margin: 0 auto;
  }
`;

const AboutTitle = styled.h2`
  margin: 120px auto 0;
  font-size: 40px;
  text-align: center;
  color: #fff;
  ${theme.breakpoints.down("md")} {
    margin-top: 80px;
    font-size: 24px;
  }
`;

const AboutText = styled(Typography)`
  margin: 16px auto 0;
  font-size: 16px;
  line-height: 1.7;
  ${theme.breakpoints.down("md")} {
    font-size: 14px;
    text-align: left;
  }
`;

const AboutImage = styled.div`
  margin: 40px auto 0;
  width: calc(665 / 1400 * 100%);
  max-width: 760px;

  img {
    width: 100%;
  }

  ${theme.breakpoints.down("md")} {
    margin: 24px auto 0;
    width: 100%;
  }
`;

const AboutLists = styled.section`
  width: calc(1152 / 1400 * 100%);
  max-width: 1152px;
  margin: 40px auto 0;
  ${theme.breakpoints.down("md")} {
    width: calc(342 / 390 * 100%);
  }
`;

const AboutListTitle = styled.h3`
  font-size: 32px;
  text-align: center;
  color: #fff;
  ${theme.breakpoints.down("md")} {
    font-size: 20px;
  }
`;

const CardList = styled.div`
  display: flex;
  gap: 0 calc(24 / 1152 * 100%);
  width: 100%;
  margin: 16px auto 0;
  ${theme.breakpoints.down("md")} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 16px;
    margin: 24px auto 0;
  }
`;

const AboutSignupButton = styled(Button)<ButtonProps<"a">>`
  display: flex;
  justify-content: center;
  max-width: 280px;
  height: 56px;
  margin: 35px auto 0;
  font-size: 16px;
  font-weight: 700;

  ${theme.breakpoints.down("md")} {
    margin: 40px auto 0;
    max-width: 246px;
    height: 48px;
    padding: 0 16px;
  }
`;

export default About;
