import { actions } from "./slice";
import { db } from "initializer";
import { shuffle } from "lodash-es";
import { createSubscribeCollection } from "../firestoreModuleUtils/operators";
import { DeckRecord, DeckItemsRecord } from "./records";
import { getRoomDeckById } from "./selectors";
import { ItemRecord, UpdateItem } from "../entities.room.items/records";
import {
  addRoomItem,
  itemsRef,
  deleteRoomItem,
  deleteRoomItems,
} from "../entities.room.items/operations";
import {
  getMaxZIndex,
  getRoomItemById,
  getRoomItemIdsByDeckId,
  getRoomNearItemsByPosition,
} from "../entities.room.items/selectors";
import { UpdateDeck, DeckItem } from "./records";
import { DefaultThunk } from "stores";
import { getAppState } from "../app.state/selectors";
import { getUid } from "../app.user/selectors";
import {
  DocumentData,
  FieldValue,
  FirestoreDataConverter,
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import shortid from "shortid";
import { addUndo } from "../entities.room.histories/slice";
import { SourceDeck } from "../entities.room.histories/records/updateDeck";

const deckConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(deck: UpdateDeck): DocumentData {
    return deck;
  },
  fromFirestore(snapshot, options): DocumentData {
    const data = snapshot.data(options)!;
    return DeckRecord(data);
  },
};

export const decksRef = (roomId: string) =>
  collection(db, "rooms", roomId, "decks").withConverter(deckConverter);

export const subscribeRoomDecks = createSubscribeCollection(actions, decksRef);

export const createPlayingCards = (): Record<string, DeckItem> => {
  const types = ["c", "d", "h", "s"];
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  const items: Record<string, DeckItem> = {
    x01: {
      imageUrl: "/images/trump/x01.png",
      memo: "",
    },
    x02: {
      imageUrl: "/images/trump/x02.png",
      memo: "",
    },
  };
  types.forEach((tp) => {
    numbers.forEach((number) => {
      items[`${tp}${("0" + number).slice(-2)}`] = {
        imageUrl: `/images/trump/${tp}${("0" + number).slice(-2)}.png`,
        memo: "",
      };
    });
  });
  return items;
};
export const addRoomDeck =
  (deck: UpdateDeck): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return;
    const data = {
      ...deck,
      x: typeof deck.x === "number" && !isNaN(deck.x) ? deck.x : -1,
      y: typeof deck.y === "number" && !isNaN(deck.y) ? deck.y : -1,
      coverImageUrl: "/images/trump/z01.png",
      items: createPlayingCards(),
    };
    const doc = await addDoc(decksRef(roomId), data);
    dispatch(
      addUndo({
        kind: "update-deck",
        id: doc.id,
        cards: null,
        before: null,
        after: data,
      })
    );
    return;
  };

export const addRoomDeckItemFromPanelPosition =
  (
    deck: UpdateDeck,
    position: { x: number; y: number; width: number; height: number }
  ): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const items = getRoomNearItemsByPosition(state, position);
    const itemIds = Object.keys(items);
    if (!roomId || itemIds.length < 1) return;
    const itemsValues = Object.values(items);
    const coverImageUrl = itemsValues[0].coverImageUrl;
    if (itemsValues.every((item) => item.coverImageUrl === coverImageUrl)) {
      // 山札を作る対象の背面が全て一致する場合、その背面画像を新しい山札の背面画像に設定
      deck.coverImageUrl = coverImageUrl;
    }
    const batch = writeBatch(db);
    const deckRef = doc(decksRef(roomId));
    const data = {
      ...deck,
      ...position,
      items: DeckItemsRecord(items),
    };
    batch.set(deckRef, data);
    itemIds.forEach((itemId) => {
      batch.delete(doc(itemsRef(roomId), itemId));
    });
    batch.commit();
    const cardItems = Object.entries(items).map((item) => item[1]);
    dispatch(
      addUndo({
        kind: "update-deck",
        id: deckRef.id,
        cards: cardItems,
        before: null,
        after: data,
      })
    );
    return;
  };

type MergeProps = {
  src: string;
  dest: string;
};

export const mergeRoomDecks =
  ({ src: sourceDeckId, dest: destDeckId }: MergeProps): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const destDeck = getRoomDeckById(state, destDeckId);
    const sourceDeck = getRoomDeckById(state, sourceDeckId);
    if (!roomId || destDeck == null || sourceDeck == null) {
      return;
    }

    // トランプなどでDeck内のIDが同じ場合があるため、キーをランダムな値に変更する
    const items = transformRandomKeys(sourceDeck.items);

    if (Object.keys(items).length !== 0) {
      setDoc(doc(decksRef(roomId), destDeckId), { items }, { merge: true });
    }
    dispatch(deleteRoomDeck(sourceDeckId));
    dispatch(
      addUndo({
        kind: "merge-decks",
        id: destDeckId,
        before: destDeck,
        after: { ...destDeck, items: { ...destDeck.items, ...items } },
        sourceDeck: { id: sourceDeckId, deck: sourceDeck },
      })
    );
  };

const transformRandomKeys = <T = unknown>(record: {
  [key: string]: T;
}): { [key: string]: T } => {
  let transformed: Record<string, T> = {};
  for (const key in record) {
    transformed[shortid()] = record[key];
  }

  return transformed;
};

export const deleteRoomDeck =
  (deckId: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return;
    return deleteDoc(doc(decksRef(roomId), deckId));
  };

export const addUndoDeleteDeck =
  (deckId: string): DefaultThunk =>
  (dispatch, getState) => {
    const deck = getRoomDeckById(getState(), deckId);
    dispatch(
      addUndo({
        kind: "update-deck",
        id: deckId,
        cards: null,
        before: deck,
        after: null,
      })
    );
  };

export const updateRoomDeck =
  (deckId: string, deck: UpdateDeck): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return;
    const beforeDeck = getRoomDeckById(getState(), deckId);
    setDoc(doc(decksRef(roomId), deckId), deck, { merge: true });
    const isUpdate = Object.keys(deck).some(
      (key) => deck[key] !== beforeDeck[key]
    );
    if (isUpdate) {
      dispatch(
        addUndo({
          kind: "update-deck",
          id: deckId,
          cards: null,
          before: beforeDeck,
          after: { ...beforeDeck, ...deck },
        })
      );
    }
    return;
  };

export const updateCurrentRoomDeck =
  (deck: UpdateDeck): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const deckId = state.app.state.openRoomDeckDetailId;
    if (!roomId || !deckId) return;
    const beforeDeck = getRoomDeckById(getState(), deckId);
    setDoc(doc(decksRef(roomId), deckId), deck, { merge: true });
    dispatch(
      addUndo({
        kind: "update-deck",
        id: deckId,
        cards: null,
        before: beforeDeck,
        after: { ...beforeDeck, ...deck },
      })
    );
    return;
  };

export const addRoomItemFromRoomDeck =
  (deckId: string, itemId: string): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const deck = getRoomDeckById(state, deckId);
    if (!roomId || !deck) return;
    const item = deck.items[itemId]; // todo
    if (!item) return;
    setDoc(
      doc(decksRef(roomId), deckId),
      {
        items: {
          [itemId]: deleteField(),
        },
      },
      { merge: true }
    );
    dispatch(addRoomItem(item));
  };

type AddRandomRoomItemFromRoomDeckProps = {
  deckId: string;
  amount: number;
  mode: "public" | "private" | "closed";
};

export const addRandomRoomItemFromRoomDeck =
  ({
    deckId,
    amount,
    mode,
  }: AddRandomRoomItemFromRoomDeckProps): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);
    const roomId = getAppState(state, "roomId");
    const name = getAppState(state, "roomChatName");
    const deck = getRoomDeckById(state, deckId);
    const z = getMaxZIndex(state);
    if (!roomId || !uid || !deck) return;
    const itemIds = shuffle(Object.keys(deck.items)).slice(
      0,
      Math.min(amount, 100)
    );
    if (itemIds.length < 1) return;
    const deletedItems: Record<string, FieldValue> = {};
    let items: UpdateItem[] = [];
    // const addedItems: UpdateItem[] = [];
    const batch = writeBatch(db);
    const createdAt = Date.now();
    itemIds.forEach((itemId, index) => {
      deletedItems[itemId] = deleteField();
      const data = {
        ...deck.items[itemId],
        coverImageUrl: deck.coverImageUrl,
        x: deck.x + 1 + index,
        y: deck.y + 1 + index,
        z,
        width: deck.width,
        height: deck.height,
        deckId,
        closed: mode !== "public",
        freezed: true,
        owner: mode === "private" ? uid : null,
        ownerName: mode === "private" ? name || "NONAME" : null,
        createdAt: createdAt + index,
        updatedAt: createdAt + index,
      };
      const newItemRef = doc(itemsRef(roomId));
      batch.set(newItemRef, ItemRecord(data), { merge: true });
      items.push({ ...data, _id: newItemRef.id });
    });
    batch.set(
      doc(decksRef(roomId), deckId),
      { items: deletedItems },
      { merge: true }
    );
    batch.commit();
    dispatch(
      addUndo({
        kind: "draw-card",
        deckId: deckId,
        before: null,
        after: items,
        deletedIds: itemIds,
      })
    );
  };

export const addRoomDeckItem =
  (deckId: string, itemId: string): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const item = getRoomItemById(state, itemId);
    if (!roomId || !item) return;
    setDoc(
      doc(decksRef(roomId), deckId),
      {
        items: {
          [itemId]: {
            imageUrl: item.imageUrl,
            memo: item.memo,
          },
        },
      },
      { merge: true }
    );
    deleteRoomItem(roomId, itemId)();
    dispatch(
      addUndo({
        kind: "draw-card",
        deckId: deckId,
        before: [{ ...item, _id: itemId }],
        after: null,
      })
    );
  };

export const addRoomDeckItems =
  (deckId: string, items: UpdateItem[]): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return;
    const batch = writeBatch(db);
    items.forEach((item) => {
      if (!item._id) return;
      batch.set(
        doc(decksRef(roomId), deckId),
        {
          items: {
            [item._id]: {
              imageUrl: item.imageUrl,
              memo: item.memo,
            },
          },
        },
        { merge: true }
      );
      batch.delete(doc(itemsRef(roomId), item._id));
    });
    return batch.commit();
  };

export const addRoomDeckItemsById =
  (deckId: string): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const itemIds = getRoomItemIdsByDeckId(state, deckId);
    if (!roomId || itemIds.length < 1) return;
    const items: {
      [key: string]: { imageUrl: string | null; memo: string };
    } = {};
    const undoItems: UpdateItem[] = [];
    itemIds.forEach((itemId) => {
      const item = getRoomItemById(state, itemId);
      if (item) {
        items[itemId] = {
          imageUrl: item.imageUrl,
          memo: item.memo,
        };
        undoItems.push(item);
      }
    });
    setDoc(doc(decksRef(roomId), deckId), { items }, { merge: true });
    dispatch(deleteRoomItems(itemIds));
    dispatch(
      addUndo({
        kind: "draw-card",
        deckId: deckId,
        before: undoItems,
        after: null,
      })
    );
  };

export const importRoomDecks =
  (decksData: { [deckId: string]: UpdateDeck }): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const deckIds = Object.keys(decksData);
    const batch = writeBatch(db);
    const ref = decksRef(roomId);
    deckIds.forEach((deckId) => {
      batch.set(doc(ref, deckId), DeckRecord(decksData[deckId]));
    });
    return batch.commit();
  };

export const returnRoomDeckItems =
  (deckId: string, items: UpdateItem[], deletedIds?: string[]): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId || items.length < 1) return;
    const deletedItems: Record<string, FieldValue> = {};
    const batch = writeBatch(db);
    items.forEach((item) => {
      if (!item._id) return;
      deletedItems[item._id] = deleteField();
      batch.set(doc(itemsRef(roomId), item._id), ItemRecord({ ...item }), {
        merge: true,
      });
    });
    if (deletedIds) {
      // 山札内のIDと引いた後のIDが異なる場合があるためどちらも削除する
      deletedIds.forEach((id) => {
        deletedItems[id] = deleteField();
      });
    }
    batch.set(
      doc(decksRef(roomId), deckId),
      { items: deletedItems },
      { merge: true }
    );
    batch.commit();
  };

export const updateRoomDecks =
  (deckId: string, deck: UpdateDeck): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return;
    const batch = writeBatch(db);
    const roomDeck = getRoomDeckById(state, deckId);
    if (roomDeck) {
      // 盤面にデッキが存在する場合はアイテム以外を更新
      const { items, ...updateDeck } = deck;
      batch.set(doc(decksRef(roomId), deckId), updateDeck, { merge: true });
    } else {
      // 盤面にデッキが存在しない場合はそのまま更新
      batch.set(doc(decksRef(roomId), deckId), deck);
    }
    return batch.commit();
  };

export const updateMergeRoomDecks =
  (deckId: string, deck: UpdateDeck, sourceDeck?: SourceDeck): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return;
    const batch = writeBatch(db);
    batch.set(doc(decksRef(roomId), deckId), deck);
    if (sourceDeck) {
      batch.set(doc(decksRef(roomId), sourceDeck.id), sourceDeck.deck);
    }
    return batch.commit();
  };
