import { actions } from "./slice";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils/operators";
import { appStateMutate } from "../app.state/operations";
import { addMessage } from "../entities.room.messages/operations";
import { getCurrentRoomDice } from "./selectors";
import { DefaultThunk } from "stores";
import { DiceItemRecord, UpdateDiceItem } from "./records";
import { getAppState } from "../app.state/selectors";
import { getUid } from "../app.user/selectors";
import {
  DocumentData,
  FirestoreDataConverter,
  addDoc,
  collection,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { addUndo } from "../entities.room.histories/slice";

const diceConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(item: UpdateDiceItem) {
    return item;
  },
  fromFirestore(snapshot, options): DocumentData {
    const data = snapshot.data(options)!;
    return DiceItemRecord(data);
  },
};

export const dicesRef = (roomId: string) =>
  collection(db, "rooms", roomId, "dices").withConverter(diceConverter);

export const subscribeRoomDices = createSubscribeCollection(actions, dicesRef);

export const addRoomDice =
  (data: UpdateDiceItem): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);
    const roomId = getAppState(state, "roomId");
    const name = getAppState(state, "roomChatName") || "";

    if (!roomId || !uid) return null;
    const item = {
      x: typeof data.x === "number" && !isNaN(data.x) ? data.x : -1,
      y: typeof data.y === "number" && !isNaN(data.y) ? data.y : -1,
      width: 2,
      height: 2,
      faces: 6,
      value: 1,
      closed: false,
      name,
      owner: uid,
      changeCount: 0,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };
    const doc = await addDoc(dicesRef(roomId), item);
    dispatch(
      addUndo({
        kind: "update-dice",
        id: doc.id,
        before: null,
        after: item,
      })
    );
  };

export const addRoomDiceById =
  (diceId: string, item: UpdateDiceItem): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return null;
    return setDoc(doc(dicesRef(roomId), diceId), {
      ...item,
      updatedAt: Date.now(),
    });
  };

export const updateRoomDice =
  (diceId: string, item: UpdateDiceItem): DefaultThunk =>
  (_, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return null;
    return updateDoc(doc(dicesRef(roomId), diceId), {
      ...item,
      updatedAt: Date.now(),
    });
  };

export const updateCurrentRoomDice =
  (item: UpdateDiceItem, id?: string): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    const name = getState().app.state.roomChatName;
    const diceId = id ? id : getState().app.state.openRoomDiceDetailId;
    const dice = getCurrentRoomDice(getState());
    if (!roomId || !diceId) return null;
    if (!item.closed) {
      if (dice.closed) {
        const text =
          `/system [ ${name} ] がダイスシンボルを公開。` +
          (item.faces !== 0 ? `出目は ${item.value} です。` : "");
        dispatch(
          addMessage(
            roomId,
            null,
            {
              text,
              name: "system",
              channel: "main",
            },
            false
          )
        );
      } else if (item.value !== dice.value) {
        const text =
          `/system [ ${name} ] がダイスシンボルを` +
          (item.faces !== 0 ? `${item.value} に` : "") +
          "変更しました。";
        dispatch(
          addMessage(
            roomId,
            null,
            {
              text,
              name: "system",
              channel: "main",
            },
            false
          )
        );
      }
    }
    dispatch(appStateMutate((state) => (state.openRoomDiceDetail = false)));
    if (id) {
      return setDoc(doc(dicesRef(roomId), diceId), {
        ...item,
        updatedAt: Date.now(),
      });
    } else {
      return updateDoc(doc(dicesRef(roomId), diceId), {
        ...item,
        updatedAt: Date.now(),
      });
    }
  };

export const deleteRoomDice =
  (diceId: string | undefined): DefaultThunk =>
  (dispatch, getState) => {
    if (diceId == null) return;
    const roomId = getState().app.state.roomId;
    if (!roomId) return null;
    dispatch(appStateMutate((state) => (state.openRoomDiceDetail = false)));
    return deleteDoc(doc(dicesRef(roomId), diceId));
  };
