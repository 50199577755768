import { actions } from "./slice";
import { db } from "initializer";
import {
  createSubscribeCollection,
  createSubscribeDocument,
} from "../firestoreModuleUtils/operators";
import {
  getUserCharacterByName,
  getCharacterCountByName,
  getCharacterById,
} from "./selectors";
import {
  Character,
  CharacterRecord,
  CharacterRecord_V2,
  UpdateCharacter,
} from "./records";
import { appStateMutate } from "../app.state/operations";
import { DefaultThunk } from "stores";
import { getAppState } from "../app.state/selectors";
import { getUid } from "../app.user/selectors";
import i18next from "i18next";
import {
  DocumentData,
  FirestoreDataConverter,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import { addUndo } from "../entities.room.histories/slice";
import { addMessage } from "../entities.room.messages/operations";
import { SystemMessage } from "../entities.room.histories/records/updateCharacter";

const characterConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(character: UpdateCharacter) {
    return character;
  },
  fromFirestore(snapshot, options): Character {
    const data = snapshot.data(options)!;
    return CharacterRecord(data);
  },
};

export const charactersRef = (roomId: string) =>
  collection(db, "rooms", roomId, "characters").withConverter(
    characterConverter
  );

export const subscribeRoomCharacters = createSubscribeCollection(
  actions,
  (roomId: string) => charactersRef(roomId)
);
export const subscribeRoomCharacter = createSubscribeDocument(
  actions,
  (props: { roomId: string; characterId: string }) =>
    doc(charactersRef(props.roomId), props.characterId)
);

export const addCharacter = (roomId: string, data: UpdateCharacter) => () => {
  return addDoc(charactersRef(roomId), {
    ...CharacterRecord_V2(data),
    updatedAt: Date.now(),
    createdAt: Date.now(),
  });
};

export const importRoomCharacters =
  (charactersData: { [characterId: string]: UpdateCharacter }): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const uid = getUid(state);
    if (!roomId || !uid) return;
    const characterIds = Object.keys(charactersData);
    const batch = writeBatch(db);
    const ref = charactersRef(roomId);
    characterIds.forEach((characterId) => {
      batch.set(
        doc(ref, characterId),
        CharacterRecord_V2({
          ...charactersData[characterId],
          owner: uid,
        })
      );
    });
    return batch.commit();
  };

const parseClipboardTextIfCharacter = (text) => {
  try {
    const parsed = JSON.parse(text);
    return parsed.kind === "character" ? parsed.data : null;
  } catch (_) {
    return null;
  }
};

export const importRoomCharacterFromClipboardText =
  (text: string): DefaultThunk =>
  (dispatch) => {
    const character = parseClipboardTextIfCharacter(text);
    if (character != null) {
      character.active = true;
      dispatch(addRoomCharacterToCenter(character));
    } else {
      alert(i18next.t("クリップボードからのデータ読み込みに失敗しました"));
    }
  };

export const addRoomCharacter =
  (data: UpdateCharacter): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const uid = getUid(state);
    if (!roomId || !uid) return null;
    return addDoc(charactersRef(roomId), {
      ...CharacterRecord_V2(data),
      owner: uid,
      updatedAt: Date.now(),
      createdAt: Date.now(),
    });
  };

const addRoomCharacterToCenter =
  (data: UpdateCharacter): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const uid = getUid(state);

    if (!roomId || !uid) return null;

    const cellSize = state.app.state.roomScreenCellSize;
    const record = CharacterRecord_V2(data);
    record.x = (-record.width * cellSize) / 2;
    record.y = (-record.height * cellSize) / 2;

    const character = {
      ...record,
      owner: uid,
      updatedAt: Date.now(),
      createdAt: Date.now(),
    };
    const doc = await addDoc(charactersRef(roomId), character);
    dispatch(
      addUndo({
        kind: "update-character",
        id: doc.id,
        before: null,
        after: { ...character, _id: doc.id },
      })
    );
    return;
  };

export const addRoomCharacterWithEdit =
  (data: UpdateCharacter): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const uid = getUid(state);
    if (!roomId || !uid) return null;
    const character = {
      ...CharacterRecord_V2(data),
      owner: uid,
      updatedAt: Date.now(),
      createdAt: Date.now(),
    };
    return addDoc(charactersRef(roomId), character).then((docRef: any) => {
      dispatch(
        appStateMutate((state: any) => {
          state.openRoomCharacter = true;
          state.openRoomCharacterId = docRef.id;
        })
      );
      dispatch(
        addUndo({
          kind: "update-character",
          id: docRef.id,
          before: null,
          after: { ...character, _id: docRef.id },
        })
      );
    });
  };

export const updateCharacter =
  (roomId: string, characterId: string, data: UpdateCharacter) => () => {
    // format
    if (data.width !== undefined) {
      const size = Math.min(Math.max(data.width, 1), 100);
      data.width = size;
      data.height = size;
    }
    if (data.x !== undefined && data.y !== undefined) {
      data.x = ~~data.x;
      data.y = ~~data.y;
    }
    return setDoc(
      doc(charactersRef(roomId), characterId),
      {
        ...data,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const updateRoomCharacter =
  (characterId: string, data: UpdateCharacter): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return null;
    // format
    if (data.width !== undefined) {
      const size = Math.min(Math.max(data.width, 1), 100);
      data.width = size;
      data.height = size;
    }
    if (data.x !== undefined && data.y !== undefined) {
      data.x = ~~data.x;
      data.y = ~~data.y;
    }
    return setDoc(
      doc(charactersRef(roomId), characterId),
      {
        ...data,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const updateCurrentRoomCharacter =
  (data: UpdateCharacter): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const characterId = state.app.state.openRoomCharacterId;
    if (!roomId || !characterId) return null;
    // format
    if (data.width !== undefined) {
      const size = Math.min(Math.max(data.width, 1), 100);
      data.width = size;
      data.height = size;
    }
    if (data.x !== undefined && data.y !== undefined) {
      data.x = ~~data.x;
      data.y = ~~data.y;
    }

    return setDoc(
      doc(charactersRef(roomId), characterId),
      {
        ...data,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const updateRoomCharacterState =
  (
    roomId: string,
    uid: string,
    characterName: string,
    speaking: boolean
  ): DefaultThunk =>
  (_, getState) => {
    const character = getUserCharacterByName(getState(), {
      name: characterName,
      uid,
    });
    if (!character) return null;
    return setDoc(
      doc(charactersRef(roomId), character._id),
      {
        speaking,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const addUndoUpdateCharacter =
  (
    characterId: string,
    data: UpdateCharacter,
    systemMessage?: SystemMessage
  ): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    const character = getCharacterById(getState(), characterId);
    if (!roomId || !character) return null;
    dispatch(
      addUndo({
        kind: "update-character",
        id: characterId,
        before: character,
        after: { ...character, ...data },
        systemMessage: systemMessage,
      })
    );
  };

export const deleteCharacter = (roomId: string, characterId: string) => () => {
  return deleteDoc(doc(charactersRef(roomId), characterId));
};

export const deleteRoomCharacter =
  (characterId: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return null;
    return deleteDoc(doc(charactersRef(roomId), characterId));
  };

export const duplicateCharacter =
  (roomId: string, characterId: string) => () => {
    return getDoc(doc(charactersRef(roomId), characterId)).then(
      (docSnapshot) => {
        const data = docSnapshot.data();
        if (!data) return;
        addDoc(charactersRef(roomId), {
          ...CharacterRecord(data),
          updatedAt: Date.now(),
          createdAt: Date.now(),
        });
      }
    );
  };

export const originalName = (name: string): string =>
  name.replace(/^(.+)\(\d+\)$/, "$1");
export const duplicateRoomCharacter =
  (characterId: string): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const character = state.entities.roomCharacters.entities[characterId];
    if (!roomId || !character) return null;
    const clone = CharacterRecord(character);
    const duplicateNum = getCharacterCountByName(state, clone);
    clone.name = originalName(clone.name) + "(" + duplicateNum + ")";
    const doc = await addDoc(charactersRef(roomId), {
      ...clone,
      updatedAt: Date.now(),
      createdAt: Date.now(),
    });
    dispatch(
      addUndo({
        kind: "update-character",
        id: doc.id,
        before: null,
        after: { ...clone, _id: doc.id },
      })
    );
  };

export const updateRoomCharacterWithMessage =
  (character: UpdateCharacter, systemMessage?: SystemMessage): DefaultThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId || !character._id) return;
    const roomCharacter = state.entities.roomCharacters.entities[character._id];

    dispatch(updateRoomCharacter(character._id, { ...character }));
    if (systemMessage) {
      // undo/redo実行前に盤面上に対象の駒が存在しなかった場合はメッセージを表示せず処理を終了
      if (!roomCharacter) return;
      let beforeValue: string | number = 0;
      let afterValue: string | number = 0;
      if (systemMessage.type == "status" && character.status) {
        const stateIdx = character.status.findIndex(
          (state) => state.label === systemMessage.label
        );
        afterValue = character.status[stateIdx].value;
      } else if (systemMessage.type == "default") {
        afterValue = character[systemMessage.label];
      }
      const stateIdx = roomCharacter.status.findIndex(
        (state) => state.label === systemMessage.label
      );
      // 対象のステータスが削除されていた場合もメッセージを表示せず処理を終了
      if (stateIdx === -1) return;
      beforeValue =
        systemMessage.type == "status"
          ? roomCharacter.status[stateIdx].value
          : roomCharacter[systemMessage.label];
      dispatch(
        addMessage(
          roomId,
          null,
          {
            text: `/system [ ${character.name} ] ${systemMessage.label} : ${beforeValue} → ${afterValue}`,
            name: "system",
            channel: "main",
          },
          false
        )
      );
    }
  };

type CharacterClipboardData = {
  kind: "character";
  data: Partial<CharacterData>;
};

type CharacterData = {
  name: string;
  memo: string;
  initiative: number;
  externalUrl: string;
  status: {
    label: string;
    value: number;
    max: number;
  }[];
  params: { label: string; value: string }[];
  iconUrl: string | null;
  faces: { iconUrl: string | null; label: string }[];
  angle: number;
  width: number;
  height: number;
  secret: boolean;
  invisible: boolean;
  hideStatus: boolean;
  color: string;
  commands: string;
};

export const copyRoomCharacterToClipboard =
  (characterId: string): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const character = state.entities.roomCharacters.entities[characterId];
    if (!character) return null;

    const characterClipboardData: CharacterClipboardData = {
      kind: "character",
      data: {
        name: character.name,
        memo: character.memo,
        initiative: character.initiative,
        externalUrl: character.externalUrl,
        status: character.status,
        params: character.params,
        iconUrl: character.iconUrl,
        faces: character.faces,
        angle: character.angle,
        width: character.width,
        height: character.height,
        secret: character.secret,
        invisible: character.invisible,
        hideStatus: character.hideStatus,
        color: character.color,
        commands: character.commands,
      },
    };

    navigator.clipboard
      .writeText(JSON.stringify(characterClipboardData))
      .then(() => {
        dispatch(
          appStateMutate(
            (state) =>
              (state.errorSnackbarMessage =
                "コマデータをクリップボードにコピーしました。")
          )
        );
      })
      .catch(() =>
        alert(
          "エラー：このページのURLをクリップボードにコピーしようとしましたが、失敗しました。"
        )
      );
  };
