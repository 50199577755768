import { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { ListItemButton, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { appStateMutate } from "stores/modules/app.state/operations";

type NoteListItemProps = {
  noteId: string;
};

const NoteListItem = ({ noteId }: NoteListItemProps) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const note = useAppSelector((state) => store.getRoomNoteById(state, noteId));

  const onEdit = useCallback(() => {
    dispatch(
      appStateMutate((state) => {
        state.openRoomNote = true;
        state.openRoomNoteId = noteId;
      })
    );
  }, [noteId, dispatch]);

  return (
    <ListItemButton dense onClick={onEdit}>
      <ListItemText
        primary={note.name || t("新しいノート")}
        secondary={note.text.slice(0, 48) + "..."}
      />
    </ListItemButton>
  );
};

export default memo(NoteListItem);
