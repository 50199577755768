import {
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  SvgIcon,
  Typography,
} from "@mui/material";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import LogoutIcon from "@mui/icons-material/Logout";
import DataObjectIcon from "@mui/icons-material/DataObject";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import SettingsIcon from "@mui/icons-material/Settings";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "stores";
import { Link } from "react-router-dom";
import { appStateMutate } from "stores/modules/app.state/operations";
import styled from "styled-components";
import { ReactComponent as BoxAddIcon } from "./BoxAddIcon.svg";
import { ReactComponent as ReopenWindowSvg } from "./ReopenWindowIcon.svg";
import { getHasEditableRole } from "stores/modules/entities.room.members/selectors";
import { getAppState } from "stores/modules/app.state/selectors";
import { redo, undo } from "stores/modules/entities.room.histories/operations";
import { ReactComponent as UndoIcon } from "./UndoIcon.svg";
import { ReactComponent as RedoIcon } from "./RedoIcon.svg";

const RoomMenu = (props: MenuProps) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const hasEditableRole = useAppSelector(getHasEditableRole);
  const isExtendable = useAppSelector((state) => {
    const parentProduct = getAppState(state, "parentProduct");
    return parentProduct && parentProduct.relatedProducts.length > 0;
  });
  const hasUndoRecord = useAppSelector(
    (state) => state.entities.roomHistories.undoHistories.length > 0
  );
  const hasRedoRecord = useAppSelector(
    (state) => state.entities.roomHistories.redoHistories.length > 0
  );

  const { onClose } = props;

  const closeMenu = useCallback(() => {
    if (onClose) {
      onClose({}, "backdropClick");
    }
  }, [onClose]);

  const openRoomInviteDialog = useCallback(() => {
    dispatch(appStateMutate((state) => (state.openRoomInviteDialog = true)));
    closeMenu();
  }, [dispatch, closeMenu]);

  const openRoomSettingsDialog = useCallback(() => {
    dispatch(appStateMutate((state) => (state.openRoomSettingsDialog = true)));
    closeMenu();
  }, [dispatch, closeMenu]);

  const openRoomVariables = useCallback(() => {
    dispatch(appStateMutate((state) => (state.openRoomVariables = true)));
    closeMenu();
  }, [dispatch, closeMenu]);

  const openRoomExtentionDialog = useCallback(() => {
    dispatch(appStateMutate((state) => (state.openRoomExtentionDialog = true)));
    closeMenu();
  }, [dispatch, closeMenu]);

  const openSaveDialog = useCallback(() => {
    dispatch(appStateMutate((state) => (state.openSaveDialog = true)));
    closeMenu();
  }, [dispatch, closeMenu]);

  const openLoadDialog = useCallback(() => {
    dispatch(appStateMutate((state) => (state.openLoadDialog = true)));
    closeMenu();
  }, [dispatch, closeMenu]);

  const onUndo = useCallback(() => {
    dispatch(undo());
  }, [dispatch]);

  const onRedo = useCallback(() => {
    dispatch(redo());
  }, [dispatch]);

  return (
    <Menu {...props}>
      <MenuItem onClick={openRoomInviteDialog}>
        <StyledListItemText>{t("友達を招待")}</StyledListItemText>
        <PersonAddAlt1Icon fontSize="small" />
      </MenuItem>
      <Divider />
      <MenuItem disabled={!hasEditableRole} onClick={openRoomSettingsDialog}>
        <StyledListItemText>{t("ルーム設定")}</StyledListItemText>
        <SettingsIcon fontSize="small" />
      </MenuItem>
      <MenuItem disabled={!hasEditableRole} onClick={openRoomVariables}>
        <StyledListItemText>{t("ルーム変数")}</StyledListItemText>
        <DataObjectIcon fontSize="small" />
      </MenuItem>
      <MenuItem
        disabled={!hasEditableRole || !isExtendable}
        onClick={openRoomExtentionDialog}
      >
        <StyledListItemText>{t("ゲームの拡張")}</StyledListItemText>
        <SvgIcon fontSize="small">
          <BoxAddIcon />
        </SvgIcon>
      </MenuItem>
      <Divider />
      <MenuItem disabled={!hasEditableRole} onClick={openSaveDialog}>
        <StyledListItemText>{t("セーブ")}</StyledListItemText>
        <DomainVerificationIcon fontSize="small" />
      </MenuItem>
      <MenuItem disabled={!hasEditableRole} onClick={openLoadDialog}>
        <StyledListItemText>{t("ロード")}</StyledListItemText>
        <SvgIcon fontSize="small">
          <ReopenWindowSvg />
        </SvgIcon>
      </MenuItem>
      <Divider />
      <MenuItem disabled={!hasUndoRecord} onClick={onUndo}>
        <StyledListItemText>
          {t("元に戻す")}
          <ShortcutKeyInfo color="textSecondary" variant="caption">
            Ctrl+Z
          </ShortcutKeyInfo>
        </StyledListItemText>
        <SvgIcon fontSize="small">
          <UndoIcon />
        </SvgIcon>
      </MenuItem>
      <MenuItem disabled={!hasRedoRecord} onClick={onRedo}>
        <StyledListItemText>
          {t("やり直し")}
          <ShortcutKeyInfo color="textSecondary" variant="caption">
            Ctrl+Y
          </ShortcutKeyInfo>
        </StyledListItemText>
        <SvgIcon fontSize="small">
          <RedoIcon />
        </SvgIcon>
      </MenuItem>
      <Divider />
      <MenuItem component={Link} to="/home">
        <StyledListItemText>{t("ルームから退出")}</StyledListItemText>
        <LogoutIcon fontSize="small" />
      </MenuItem>
    </Menu>
  );
};

const StyledListItemText = styled(ListItemText)`
  margin-right: 28px;
`;

const ShortcutKeyInfo = styled(Typography)`
  margin-left: 5px;
  font-size: 10px;
`;

export default RoomMenu;
