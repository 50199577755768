import { memo, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";

import Dialog from "@mui/material/Dialog";
import DeckDetail, { FormData } from "./DeckDetail";
import { FormProvider, useForm } from "react-hook-form";
import { DeckRecord } from "stores/modules/entities.room.decks";
import { clamp } from "lodash-es";

const blankItem = DeckRecord({});

const DeckDetailDialog = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomDeckDetail")
  );
  const deckId = useAppSelector(
    (state) => store.getAppState(state, "openRoomDeckDetailId") || ""
  );

  const formMethods = useForm<FormData>({
    defaultValues: {
      width: 1,
      height: 1,
      locked: false,
      freezed: false,
    },
  });

  const { getValues, reset, handleSubmit } = formMethods;
  const deck =
    useAppSelector((state) => store.getRoomDeckById(state, deckId)) ||
    blankItem;

  useEffect(() => {
    if (open) {
      reset({
        width: deck.width,
        height: deck.height,
        locked: deck.locked,
        freezed: deck.freezed,
      });
    }
  }, [reset, deck.width, deck.height, deck.locked, deck.freezed, open]);

  const onSubmit = useCallback(
    (values: FormData) => {
      if (deckId) {
        dispatch(
          store.updateRoomDeck(deckId, {
            width: clamp(~~values.width, 1, 1000),
            height: clamp(~~values.height, 1, 1000),
            locked: values.locked,
            freezed: values.freezed,
          })
        );
      }
    },
    [deckId, dispatch]
  );

  const onClose = useCallback(() => {
    onSubmit(getValues());
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomDeckDetail = false;
      })
    );
  }, [onSubmit, getValues, dispatch]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <FormProvider {...formMethods}>
        <DeckDetail
          deckId={deckId}
          onClose={onClose}
          onSubmit={handleSubmit(onSubmit)}
        />
      </FormProvider>
    </Dialog>
  );
};

export default memo(DeckDetailDialog);
