import { memo, useCallback, useEffect, useRef } from "react";
import { useAppDispatch } from "stores";
import { redo, undo } from "stores/modules/entities.room.histories/operations";

const UndoKeyboardShortcutObserver = () => {
  const dispatch = useAppDispatch();
  const prevKeyDownTime = useRef(0);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const now = Date.now();
      if (now - prevKeyDownTime.current < 100) {
        prevKeyDownTime.current = Date.now();
        return;
      }
      prevKeyDownTime.current = now;
      const target = e.target as HTMLElement;

      if (target.tagName === "INPUT" || target.tagName === "TEXTAREA") {
        return;
      }

      if (
        (e.code === "KeyY" && (e.metaKey || e.ctrlKey)) ||
        (e.code === "KeyZ" && e.shiftKey && (e.metaKey || e.ctrlKey))
      ) {
        e.preventDefault();
        e.stopPropagation();
        dispatch(redo());
        return;
      } else if (e.code === "KeyZ" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        e.stopPropagation();
        dispatch(undo());
        return;
      }
    },
    [dispatch, prevKeyDownTime]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  return null;
};

export default memo(UndoKeyboardShortcutObserver);
