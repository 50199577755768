import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import styled from "styled-components";
import ProAnime from "./pro-anime.png";
import { ReactComponent as WelcomeImage } from "./welcome.svg";

const JoinedProDialog = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) =>
    store.getAppState(state, "openJoinedPro")
  );
  const onClose = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openJoinedPro = false;
      })
    );
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogContent>
        <WrapAnimation>
          <img src={ProAnime} />
        </WrapAnimation>
        <WrapWelcome>
          <WelcomeImage role="img" aria-label={t("CCFOLIA PRO にようこそ")} />
        </WrapWelcome>
        <DialogContentText component="div">
          <Typography paragraph>{t("おめでとうございます！")}</Typography>
          <Typography paragraph>
            {t(
              "今日からあなたはCCFOLIA PROです。追加された機能でセッションをさらに盛り上げましょう。"
            )}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" fullWidth>
          {t("閉じる")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const WrapAnimation = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0 32px 0;
`;

const WrapWelcome = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0 32px 0;
`;

export default memo(JoinedProDialog);
