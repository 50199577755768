import { createEntitySliceGroupBy } from "../firestoreModuleUtils/slice";
import { Room } from "./records";

const slice = createEntitySliceGroupBy<Room>({
  name: "rooms",
  selectGroup: (room) => room.owner,
});

export const reducer = slice.reducer;
export const actions = slice.actions;
