import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UpdateDiceRecord } from "./records/updateDice";
import { ApplySceneRecord } from "./records/applyScene";
import { UpdateMarkerRecord } from "./records/updateMarker";
import { UpdateItemRecord } from "./records/updateItem";
import { UpdateDeckRecord } from "./records/updateDeck";
import { DrawCardRecord } from "./records/drawCard";
import { UpdateCharacterRecord } from "./records/updateCharacter";
import { UpdateFieldRecord } from "./records/updateField";
import { MergeDecksRecord } from "./records/MergeDecks";
import { UpdateNoteRecord } from "./records/updateNote";
import { UpdateEffectRecord } from "./records/updateEffect";
import { UpdateSceneRecord } from "./records/updateScene";
import { OrderSceneRecord } from "./records/orderScene";
import { OrderItemRecord } from "./records/orderItem";
import { OrderEffectRecord } from "./records/orderEffect";
import { OrderNoteRecord } from "./records/orderNote";

export type HistoryRecord =
  | UpdateDiceRecord
  | ApplySceneRecord
  | UpdateMarkerRecord
  | UpdateItemRecord
  | UpdateDeckRecord
  | DrawCardRecord
  | UpdateCharacterRecord
  | UpdateFieldRecord
  | MergeDecksRecord
  | UpdateNoteRecord
  | UpdateEffectRecord
  | UpdateSceneRecord
  | OrderSceneRecord
  | OrderItemRecord
  | OrderEffectRecord
  | OrderNoteRecord;

type HistoryType = {
  undoHistories: HistoryRecord[];
  redoHistories: HistoryRecord[];
};

const initialState: HistoryType = {
  undoHistories: [],
  redoHistories: [],
};

const historySlice = createSlice({
  name: "roomHistories",
  initialState: initialState,
  reducers: {
    addUndo: (state, action: PayloadAction<HistoryRecord>) => {
      if (state.undoHistories.length >= 10) {
        state.undoHistories.shift();
      }
      state.undoHistories.push(action.payload);
      if (state.redoHistories.length > 0) {
        state.redoHistories = [];
      }
    },
    popUndoAddRedo: (state, action: PayloadAction<HistoryRecord>) => {
      const undoHistory = state.undoHistories.pop();
      if (undoHistory) {
        state.redoHistories.push(action.payload);
      }
    },
    popRedoAddUndo: (state, action: PayloadAction<HistoryRecord>) => {
      const redoHistory = state.redoHistories.pop();
      if (redoHistory) {
        state.undoHistories.push(action.payload);
      }
    },
  },
});

export const { addUndo, popUndoAddRedo, popRedoAddUndo } = historySlice.actions;
export default historySlice.reducer;
