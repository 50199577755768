import React, { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";

import FileListDialog from "../FileListDialog";

// todo: split "ItemFileListDialog", "MarkarFileListDialog"
const DynamicFileListDialog = () => {
  const d = useAppDispatch();
  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomImageSelect")
  );
  const onClose = useCallback(() => {
    d(store.appStateMutate((state) => (state.openRoomImageSelect = false)));
  }, [d]);
  const onSelect = useCallback(
    (url) => {
      d(store.selectUserFile(url));
      d(
        store.appStateMutate((state) => {
          state.openRoomImageSelect = false;
        })
      );
    },
    [d]
  );
  return <FileListDialog open={open} onClose={onClose} onSelect={onSelect} />;
};

export default memo(DynamicFileListDialog);
