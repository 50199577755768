import React, { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Paper } from "@mui/material";

import SceneListItem from "../SceneListItem";

const SceneList = () => {
  const d = useAppDispatch();
  const sceneIds = useAppSelector(store.getRoomSceneOrderedIds);
  // todo
  const roomId = useAppSelector((state) => store.getAppState(state, "roomId"));
  // const currentRoomSceneId = useAppSelector(store.getRoomSceneIds);
  const onReorder = useCallback(
    (order) => {
      if (!roomId) return;
      d(store.reorderScenes(roomId, order));
    },
    [roomId, d]
  );
  return (
    <DragDropContext onDragEnd={onReorder}>
      <Droppable
        droppableId="droppable"
        renderClone={(provided, snapshot, rubric) => (
          <Paper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          />
        )}
      >
        {(provided, _) => (
          <Content {...provided.droppableProps} ref={provided.innerRef}>
            {sceneIds.map((sceneId, index) => (
              <Draggable key={sceneId} draggableId={sceneId} index={index}>
                {(provided, _) => (
                  <DraggableItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <SceneListItem key={sceneId} sceneId={sceneId} />
                  </DraggableItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Content>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const Content = styled.div`
  padding: 1px;
`;

const DraggableItem = styled.div`
  padding: 1px;
`;

export default memo(SceneList);
