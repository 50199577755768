import { memo, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";

import Dialog from "@mui/material/Dialog";
import PanelDetail, { FormData } from "./PanelDetail";
import { FormProvider, useForm } from "react-hook-form";
import { toClickAction } from "./FormClickAction";
import { ItemRecord } from "stores/modules/entities.room.items";
import { clamp } from "lodash-es";

type PanelDetailDialogProps = {
  roomId: string;
};

const blankItem = ItemRecord({});

const PanelDetailDialog = ({ roomId }: PanelDetailDialogProps) => {
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomPanelDetail")
  );
  const panelId =
    useAppSelector((state) =>
      store.getAppState(state, "openRoomPanelDetailId")
    ) || "";

  const formMethods = useForm<FormData>({
    defaultValues: {
      width: 1,
      height: 1,
      z: 0,
      memo: "",
      locked: false,
      freezed: false,
      plane: false,
      clickAction: "none",
      linkUrl: "",
      messageText: "",
    },
  });

  const { handleSubmit, reset } = formMethods;
  const panel =
    useAppSelector((state) => store.getRoomItemById(state, panelId)) ||
    blankItem;

  useEffect(() => {
    if (open) {
      reset({
        width: panel.width,
        height: panel.height,
        z: panel.z,
        memo: panel.memo,
        locked: panel.locked,
        freezed: panel.freezed,
        plane: panel.type === "plane",
        clickAction: panel.clickAction?.type || "none",
        linkUrl:
          panel.clickAction?.type === "link" ? panel.clickAction.url : "",
        messageText:
          panel.clickAction?.type === "message" ? panel.clickAction.text : "",
      });
    }
  }, [
    reset,
    panel.width,
    panel.height,
    panel.z,
    panel.memo,
    panel.locked,
    panel.freezed,
    panel.type,
    panel.clickAction,
    open,
  ]);

  const onSubmit = useCallback(
    (values: FormData) => {
      if (panelId) {
        dispatch(
          store.updateRoomItem(roomId, panelId, {
            memo: values.memo || "",
            width: clamp(~~values.width, 1, 1000),
            height: clamp(~~values.height, 1, 1000),
            z: Math.min(~~values.z, 999),
            locked: values.locked,
            freezed: values.freezed,
            type: values.plane ? "plane" : "object",
            clickAction: toClickAction(values),
          })
        );
      }
    },
    [roomId, panelId, dispatch]
  );

  const onClose = useCallback(() => {
    handleSubmit(onSubmit)();
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomPanelDetail = false;
      })
    );
  }, [onSubmit, handleSubmit, dispatch]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <FormProvider {...formMethods}>
        <PanelDetail
          roomId={roomId}
          panelId={panelId}
          onClose={onClose}
          onSubmit={handleSubmit(onSubmit)}
        />
      </FormProvider>
    </Dialog>
  );
};

export default memo(PanelDetailDialog);
