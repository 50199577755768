import { DefaultRootState } from "stores";
import { UserMediumDirectory } from "./records";

export const getUserMediumDirectoryById = (
  state: DefaultRootState,
  directoryId: string
): UserMediumDirectory | undefined => {
  return state.entities.userMediumDirectories.entities[directoryId];
};

export const getUserMediumDirectory = (state: DefaultRootState): string[] => {
  return state.entities.userMediumDirectories.ids || [];
};

type DirectoryInfo = {
  id: string;
  name: string;
};

export const getUserMediumDirectories = (
  state: DefaultRootState
): DirectoryInfo[] => {
  const ids = state.entities.userMediumDirectories.ids || [];
  return ids.map((id) => {
    return {
      id,
      name: state.entities.userMediumDirectories.entities[id]?.name || "",
    };
  });
};
