import { DefaultThunk } from "stores";
import { UpdateRoom } from "stores/modules/entities.rooms";
import { updateCurrentRoom } from "stores/modules/entities.rooms/operations";

export type UpdateFieldRecord = {
  kind: "update-field";
  id: string;
  before: UpdateRoom;
  after: UpdateRoom;
};

export const undoUpdateField =
  (history: UpdateFieldRecord): DefaultThunk =>
  (dispatch, _) => {
    // フィールド設定を更新
    dispatch(updateCurrentRoom(history.before));
    return;
  };

export const redoUpdateField =
  (history: UpdateFieldRecord): DefaultThunk =>
  (dispatch, _) => {
    // フィールド設定を更新
    dispatch(updateCurrentRoom(history.after));
    return;
  };
