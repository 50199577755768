import React, { useCallback } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { addUndo } from "stores/modules/entities.room.histories/slice";

const ItemMenu = () => {
  const d = useAppDispatch();
  const [t] = useTranslation();
  const uid = useAppSelector((state) => store.getAppState(state, "uid"));
  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomItemMenu")
  );
  const top = useAppSelector((state) =>
    store.getAppState(state, "roomPointerY")
  );
  const left = useAppSelector((state) =>
    store.getAppState(state, "roomPointerX")
  );
  const [itemId, item, addableDeckId] = useAppSelector((state) => {
    const itemId = store.getAppState(state, "openRoomItemMenuId");
    const item = store.getRoomItemById(state, itemId ?? "");
    const addableDeckId = item
      ? store.getRoomDeckIdByPosition(state, {
          x: item.x,
          y: item.y,
          width: item.width,
          height: item.height,
        })
      : undefined;
    return [itemId, item, addableDeckId] as const;
  }, shallowEqual);
  const isOpened = uid === item?.owner || !item?.closed;
  const isGod = useAppSelector(
    (state) => store.getAppState(state, "role") === "god"
  );
  const _isMonitored = useAppSelector(store.getCurrentRoomIsMonitored);
  const isMonitored = isGod || _isMonitored;
  const onClose = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomItemMenu = false;
      })
    );
  }, [d]);
  const onOpenItem = useCallback(() => {
    if (itemId == null) return;
    d(store.openRoomItem(itemId));
    onClose();
  }, [itemId, onClose, d]);
  const onLockItem = useCallback(() => {
    if (itemId == null) return;
    d(store.updateRoomItem(null, itemId, { locked: true }));
    onClose();
  }, [itemId, onClose, d]);
  const onUnlockItem = useCallback(() => {
    if (itemId == null) return;
    d(store.updateRoomItem(null, itemId, { locked: false }));
    onClose();
  }, [itemId, onClose, d]);
  const onRotateItem = useCallback(() => {
    if (itemId == null || item == null) return;
    d(store.updateRoomItem(null, itemId, { angle: item.angle + 45 }));
    onClose();
  }, [itemId, item, onClose, d]);
  const onCloseItem = useCallback(() => {
    if (itemId == null) return;
    d(store.closeRoomItem(itemId));
    onClose();
  }, [itemId, onClose, d]);
  const onGetItem = useCallback(() => {
    if (itemId == null) return;
    d(store.takeRoomItem(itemId));
    onClose();
  }, [itemId, onClose, d]);
  const onOpenWithoutOwnerItem = useCallback(() => {
    if (itemId == null) return;
    d(store.openWithoutOwnerRoomItem(itemId));
    onClose();
  }, [itemId, onClose, d]);
  const onBackItem = useCallback(() => {
    if (itemId == null) return;
    if (item?.deckId) {
      d(store.addRoomDeckItem(item?.deckId, itemId));
    }
    onClose();
  }, [itemId, item?.deckId, onClose, d]);
  const onHideItem = useCallback(() => {
    if (itemId == null) return;
    d(
      store.updateRoomItem(null, itemId, {
        active: false,
      })
    );
    onClose();
  }, [itemId, onClose, d]);
  const onCloneItem = useCallback(() => {
    if (item == null) {
      return;
    }
    d(store.addRoomItem(item));
    onClose();
  }, [item, onClose, d]);
  const onCreateDeck = useCallback(() => {
    if (item == null) {
      return;
    }
    d(
      store.addRoomDeckItemFromPanelPosition(
        {
          coverImageUrl: "/grey.png",
        },
        {
          x: item.x,
          y: item.y,
          width: item.width,
          height: item.height,
        }
      )
    );
    onClose();
  }, [item?.x, item?.y, item?.width, item?.height, onClose, d]);
  const onAddDeck = useCallback(() => {
    if (itemId == null || addableDeckId == null) return;
    d(store.addRoomDeckItem(addableDeckId, itemId));
    onClose();
  }, [itemId, addableDeckId, onClose, d]);
  const onDeleteItem = useCallback(() => {
    if (window.confirm(t("本当に削除しますか？"))) {
      if (!item || !itemId) return;
      d(store.deleteCurrentRoomItem(itemId));
      d(
        addUndo({
          kind: "update-item",
          id: itemId,
          before: item,
          after: null,
        })
      );
      onClose();
    }
  }, [itemId, onClose, d, t, item]);
  const onEditItem = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomPanelDetail = true;
        state.openRoomPanelDetailId = itemId;
      })
    );
    onClose();
  }, [itemId, onClose, d]);
  const onShowImage = useCallback(
    (e) => {
      d(
        store.appStateMutate((state) => {
          state.openInspector = true;
          state.inspectImageUrl =
            isOpened || isMonitored
              ? item?.imageUrl || null
              : item?.coverImageUrl || null;
        })
      );
      onClose();
    },
    [item?.imageUrl, item?.coverImageUrl, isOpened, isMonitored, onClose, d]
  );

  return (
    <Menu
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{ left, top }}
      onClose={onClose}
      MenuListProps={{ dense: true }}
      PaperProps={{
        style: {
          width: "20ch",
        },
      }}
      className={"user-select-none"}
    >
      <MenuItem disabled={!isOpened && !isMonitored} onClick={onEditItem}>
        {t("編集")}
      </MenuItem>
      {item?.locked ? (
        <MenuItem onClick={onUnlockItem}>
          {t("配置固定を解除")}
          {item?.type !== "plane" ? (
            <ShortcutKeyInfo
              color="textSecondary"
              variant="caption"
              align="right"
            >
              L
            </ShortcutKeyInfo>
          ) : null}
        </MenuItem>
      ) : (
        <MenuItem onClick={onLockItem}>
          {t("配置固定")}
          {item?.type !== "plane" ? (
            <ShortcutKeyInfo
              color="textSecondary"
              variant="caption"
              align="right"
            >
              L
            </ShortcutKeyInfo>
          ) : null}
        </MenuItem>
      )}
      <MenuItem onClick={onHideItem}>
        {t("パネルをしまう")}
        {item?.type !== "plane" && (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            S
          </ShortcutKeyInfo>
        )}
      </MenuItem>
      <MarginedDivider />
      <MenuItem disabled={!item?.closed} onClick={onOpenItem}>
        {t("全体に公開する")}
        {item?.type !== "plane" && (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            O
          </ShortcutKeyInfo>
        )}
      </MenuItem>
      <MenuItem
        disabled={item?.closed && item?.owner !== uid}
        onClick={onCloseItem}
      >
        {t("非公開にする")}
        {item?.type !== "plane" && (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            T
          </ShortcutKeyInfo>
        )}
      </MenuItem>
      <MenuItem
        disabled={item?.owner === uid && !item?.withoutOwner}
        onClick={onGetItem}
      >
        {t("自分だけ見る")}
        {item?.type !== "plane" && (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            T
          </ShortcutKeyInfo>
        )}
      </MenuItem>
      <MenuItem
        disabled={item?.withoutOwner && item?.owner === uid}
        onClick={onOpenWithoutOwnerItem}
      >
        {t("自分以外に公開する")}
        {item?.type !== "plane" && (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            W
          </ShortcutKeyInfo>
        )}
      </MenuItem>
      <MarginedDivider />
      <MenuItem onClick={onRotateItem}>
        {t("回転する")}
        {item?.type !== "plane" && (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            R or Shift+R
          </ShortcutKeyInfo>
        )}
      </MenuItem>
      <MenuItem onClick={onShowImage}>
        {t("拡大表示")}
        {item?.type !== "plane" && (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            E
          </ShortcutKeyInfo>
        )}
      </MenuItem>
      <MarginedDivider />
      <MenuItem disabled={!item?.deckId} onClick={onBackItem}>
        {t("山札に戻す")}
      </MenuItem>
      <MenuItem onClick={onCreateDeck}>{t("山札を作る")}</MenuItem>
      <MenuItem
        disabled={!addableDeckId || item?.deckId === addableDeckId}
        onClick={onAddDeck}
      >
        {t("山札に追加する")}
      </MenuItem>
      <MarginedDivider />
      <MenuItem disabled={!isOpened} onClick={onCloneItem}>
        {t("複製")}
        {item?.type !== "plane" && (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            Ctrl+D
          </ShortcutKeyInfo>
        )}
      </MenuItem>
      <MenuItem onClick={onDeleteItem}>
        {t("削除")}
        {item?.type !== "plane" && (
          <ShortcutKeyInfo
            color="textSecondary"
            variant="caption"
            align="right"
          >
            Ctrl+
            <BackspaceIcon
              fontSize="inherit"
              style={{ verticalAlign: "middle" }}
            />
          </ShortcutKeyInfo>
        )}
      </MenuItem>
    </Menu>
  );
};

const MarginedDivider = styled(Divider)({
  marginTop: "8px",
  marginBottom: "8px",
});

const ShortcutKeyInfo = styled(Typography)`
  flex: 1;
`;

export default ItemMenu;
