import { registerListener } from "modules/socket";
import { memo, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import { getEmoteIds } from "stores/modules/app.emotes/selectors";
import EmoteItem from "./EmoteItem";
import { addEmote } from "stores/modules/app.emotes/operations";

type Emote = {
  id: string;
  kind: string;
  posPercent: number;
};

const Emotes = (props: { roomId: string }) => {
  const emoteIds = useAppSelector(getEmoteIds);
  const dispatch = useAppDispatch();

  const onReceiveEmote = useCallback(
    ({ emoteKind, posPercent }) => {
      const id = Math.random().toString(32).substring(2);
      const newEmote: Emote = { id, kind: emoteKind, posPercent };
      dispatch(addEmote(newEmote));
    },
    [dispatch]
  );

  useEffect(() => {
    return registerListener("emote", onReceiveEmote);
  }, [props.roomId, onReceiveEmote]);

  return (
    <>
      {emoteIds.map((emoteId) => {
        return <EmoteItem key={emoteId} emoteId={emoteId} />;
      })}
    </>
  );
};

export default memo(Emotes);
