import React, { memo, useCallback } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";

import {
  Card,
  CardMedia,
  CardActionArea,
  ButtonBase,
  Typography,
  Tooltip,
} from "@mui/material";

import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import MusicOffIcon from "@mui/icons-material/MusicOff";

import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";
import { addUndoSceneChange } from "stores/modules/entities.room.scenes/operations";
import { addUndo } from "stores/modules/entities.room.histories/slice";
import { DEFAULT_TEXT_COLOR_CODE } from "stores/modules/entities.room.messages/operations";

const FieldListItem: React.FC<{ sceneId: string }> = ({ sceneId }) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const { scene, roomId, selectedSceneId } = useAppSelector(
    (state) => ({
      scene: store.getRoomSceneById(state, sceneId),
      roomId: store.getAppState(state, "roomId"),
      selectedSceneId: store.getAppState(state, "selectedSceneId"),
    }),
    shallowEqual
  );

  const onSelect = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.selectedSceneId = sceneId;
      })
    );
  }, [dispatch, sceneId]);

  const onEdit = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomScene = true;
        state.openRoomSceneId = sceneId;
      })
    );
  }, [sceneId, dispatch]);
  const onDelete = useCallback(() => {
    if (!roomId) return;
    if (window.confirm(t("本当に削除しますか？"))) {
      dispatch(store.deleteScene(roomId, sceneId));
      dispatch(
        addUndo({
          kind: "update-scene",
          id: sceneId,
          before: scene,
          after: null,
        })
      );
    }
  }, [roomId, sceneId, t, dispatch, scene]);
  const onSave = useCallback(() => {
    if (!roomId) return;
    dispatch(store.updateSceneFromRoom(roomId, sceneId));
  }, [roomId, sceneId, dispatch]);
  const onAppend = useCallback(() => {
    if (!roomId) return;
    dispatch(store.applyScene(roomId, sceneId));
    dispatch(addUndoSceneChange(sceneId));
  }, [roomId, sceneId, dispatch]);
  const onAppendOnlyField = useCallback(() => {
    if (!roomId) return;
    dispatch(
      store.updateRoom(roomId, {
        fieldWidth: scene.fieldWidth,
        fieldHeight: scene.fieldHeight,
        backgroundUrl: scene.backgroundUrl,
        foregroundUrl: scene.foregroundUrl,
        fieldObjectFit: scene.fieldObjectFit,
        markers: scene.markers || {}, // todo: migration
      })
    );
    if (scene.text) {
      dispatch(
        store.addMessage(
          roomId,
          null,
          {
            name: scene.name,
            text: scene.text,
            // to: null,
            color: DEFAULT_TEXT_COLOR_CODE,
            channel: "main",
          },
          false
        )
      );
    }
    dispatch(addUndoSceneChange(sceneId, true));
  }, [roomId, scene, dispatch]);
  const onAppendSilent = useCallback(() => {
    if (!roomId) return;
    dispatch(
      store.updateRoom(roomId, {
        fieldWidth: scene.fieldWidth,
        fieldHeight: scene.fieldHeight,
        backgroundUrl: scene.backgroundUrl,
        foregroundUrl: scene.foregroundUrl,
        fieldObjectFit: scene.fieldObjectFit,
        mediaName: scene.mediaName,
        mediaType: scene.mediaType,
        mediaUrl: scene.mediaUrl,
        mediaVolume: scene.mediaVolume || 0.5, // todo: migration
        mediaRepeat: scene.mediaRepeat,
        soundName: scene.soundName,
        soundUrl: scene.soundUrl,
        soundVolume: scene.soundVolume || 0.5,
        soundRepeat: scene.soundRepeat,
        markers: scene.markers || {}, // todo: migration
      })
    );
    dispatch(addUndoSceneChange(sceneId));
  }, [roomId, scene, dispatch]);

  return (
    <FieldCard
      elevation={1}
      square
      onClick={onSelect}
      style={{
        outline: selectedSceneId === sceneId ? "1px solid #fff" : undefined,
      }}
    >
      <FieldCardActionArea onClick={onAppend}>
        <FieldCardMedia
          image={toCDNUrl(
            scene.foregroundUrl || scene.backgroundUrl || "/blank.gif"
          )}
        />
      </FieldCardActionArea>
      <FieldCardContent>
        <Text
          variant="caption"
          noWrap
          onClick={onEdit}
          //size="small"
        >
          {scene.name || t<string>("新しいシーン")}
        </Text>
        <FieldCardControls>
          <Tooltip title={t<string>("盤面を保存")}>
            <ActionButton onClick={onSave}>
              <SaveIcon fontSize="small" />
            </ActionButton>
          </Tooltip>
          <Tooltip title={t<string>("BGMなしで反映")}>
            <ActionButton onClick={onAppendOnlyField}>
              <MusicOffIcon fontSize="small" />
            </ActionButton>
          </Tooltip>
          <Tooltip title={t<string>("テキストなしで反映")}>
            <ActionButton onClick={onAppendSilent}>
              <SpeakerNotesOffIcon fontSize="small" />
            </ActionButton>
          </Tooltip>
          <Tooltip title={t<string>("削除")}>
            <ActionButton onClick={onDelete}>
              <DeleteIcon fontSize="small" />
            </ActionButton>
          </Tooltip>
        </FieldCardControls>
      </FieldCardContent>
    </FieldCard>
  );
};

const Text = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
  flex: 1;
  max-width: 128px;
  min-width: 64px;
  min-height: 1em;
  :hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const FieldCard = styled(Card)`
  display: flex;
  align-items: center;
  position: relative;
  // border-top: 8px solid #222;
  // border-bottom: 4px solid #222;
`;
const FieldCardMedia = styled(CardMedia)`
  &.MuiCardMedia-root {
    width: 60px;
    height: 40px;
  }
`;
const FieldCardActionArea = styled(CardActionArea)`
  &.MuiCardActionArea-root {
    width: 60px;
  }
`;

const FieldCardControls = styled.div`
  margin-top: 4px;
  margin-left: auto;
  display: flex;
  align-items: center;
`;
const FieldCardContent = styled.div`
  padding: 0 8px;
  padding-left: 12px;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
const ActionButton = styled(ButtonBase)`
  display: block;
  width: 24px;
  font-size: 14px;
`;

export default memo(FieldListItem);
