import { Divider, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CcfoliaProIcon from "./CcfoliaProIcon";
import {
  SettingAction,
  SettingActionButton,
  SettingActionDescription,
  SettingActionLoadingButton,
} from "./SettingAction";
import theme from "theme";
import { useAppDispatch, useAppSelector } from "stores";
import { getIsPro } from "stores/modules/app.user/selectors";
import { ChangeEvent, useCallback, useRef } from "react";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import {
  appStateMutate,
  deleteRoomPackage,
  importRoomDataFromZipFile,
  publishRoomPackage,
} from "stores/modules/app.state/operations";
import { getIsOwner } from "stores/modules/app.state/selectors";
import { getHasEditableRole } from "stores/modules/entities.room.members/selectors";

const RoomDataSettings = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const isOwner = useAppSelector(getIsOwner);
  const hasEditableRole = useAppSelector(getHasEditableRole);
  const isPro = useAppSelector(getIsPro);
  const hasParentRoomPackage = useAppSelector((state) => {
    return getCurrentRoom(state)?.parentRoomPackageId != null;
  });

  const allowExport = isOwner && isPro && !hasParentRoomPackage;

  const onClickExportRoomZip = useCallback(() => {
    if (allowExport) {
      dispatch(appStateMutate((state) => (state.openRoomExport = true)));
    }
  }, [dispatch, allowExport]);

  const refFileInput = useRef<HTMLInputElement>(null);
  const onClickImportRoomZip = useCallback(() => {
    refFileInput.current?.click();
  }, [refFileInput]);

  const onChangeFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.item(0);
      if (file) {
        dispatch(importRoomDataFromZipFile(file));
        dispatch(
          appStateMutate((state) => (state.openRoomSettingsDialog = false))
        );
      }

      event.target.value = "";
    },
    [dispatch]
  );

  return (
    <>
      <Heading>
        {t("ルームデータ公開リンク")} <CcfoliaProIcon />
      </Heading>
      <SettingRoomPublishing allowExport={allowExport} />
      <Divider style={{ margin: "40px 0" }} />
      <Heading>{t("ルームデータのインポート")}</Heading>
      <SettingAction>
        <SettingActionDescription>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {t(
              "インポートにはルームデータのZIPファイルが必要です。ルームデータのZIPファイルを元にルームデータを再現します。"
            )}
          </Typography>
        </SettingActionDescription>
        <SettingActionButton
          disabled={!hasEditableRole}
          onClick={onClickImportRoomZip}
        >
          {t("インポート")}
        </SettingActionButton>
        <input
          ref={refFileInput}
          type="file"
          accept="application/zip,application/x-zip-compressed"
          hidden
          onChange={onChangeFile}
        />
      </SettingAction>
      <Divider style={{ margin: "40px 0" }} />
      <Heading>
        {t("ルームデータのエクスポート")} <CcfoliaProIcon />
      </Heading>
      <SettingAction>
        <SettingActionDescription>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {t(
              "ルームデータをZIPファイルとして出力します。出力したZIPファイルをインポートすることで、ルームを再現できます。"
            )}
          </Typography>
        </SettingActionDescription>
        <SettingActionButton
          onClick={onClickExportRoomZip}
          disabled={!allowExport}
        >
          {t("エクスポート")}
        </SettingActionButton>
      </SettingAction>
    </>
  );
};

type SettingRoomPublishingProps = {
  allowExport: boolean;
};

const SettingRoomPublishing = ({ allowExport }: SettingRoomPublishingProps) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const isOwner = useAppSelector(getIsOwner);
  const roomPackageId = useAppSelector((state) => {
    return getCurrentRoom(state)?.publishedRoomPackageId;
  });

  const onClickPublish = useCallback(() => {
    return dispatch(publishRoomPackage());
  }, [dispatch]);

  const onDelete = useCallback(async () => {
    if (isOwner && roomPackageId && window.confirm(t("本当に削除しますか？"))) {
      await dispatch(deleteRoomPackage(roomPackageId)).catch(() => {
        window.alert(t("削除に失敗しました"));
      });
    }
  }, [dispatch, isOwner, roomPackageId, t]);

  return (
    <>
      {roomPackageId && (
        <TextField
          label={t("データ公開URL")}
          variant="filled"
          fullWidth
          margin="normal"
          value={window.location.origin + "/room-packages/" + roomPackageId}
          InputProps={{ readOnly: true }}
        />
      )}
      <SettingAction>
        <SettingActionDescription>
          {roomPackageId ? (
            <>
              <Typography variant="body1">
                {t("公開中のルームデータを更新")}
              </Typography>
              <Typography variant="body2" color={theme.palette.text.secondary}>
                {t(
                  "公開中のルームの状態を、最新の状態に更新します。一度更新すると、更新前の状態に戻すことはできません。"
                )}
              </Typography>
            </>
          ) : (
            <Typography variant="body2" color={theme.palette.text.secondary}>
              {t(
                "公開リンクにアクセスすると、発行時点のルームデータを元にした新しいルームが作成されます。"
              )}
            </Typography>
          )}
        </SettingActionDescription>
        <SettingActionLoadingButton
          onClick={onClickPublish}
          disabled={!allowExport}
        >
          {roomPackageId
            ? t("データ公開URLのルームデータを更新")
            : t("公開リンクを発行")}
        </SettingActionLoadingButton>
      </SettingAction>
      {roomPackageId && (
        <SettingAction>
          <SettingActionDescription>
            <Typography variant="body1">{t("公開URLを削除する")}</Typography>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              {t(
                "この操作を行うと、誰かがデータ公開URLにアクセスしてもルームの作成ができなくなります。"
              )}
            </Typography>
          </SettingActionDescription>
          <SettingActionLoadingButton
            color="error"
            onClick={onDelete}
            disabled={!isOwner}
          >
            {t("公開URLを削除する")}
          </SettingActionLoadingButton>
        </SettingAction>
      )}
    </>
  );
};

const Heading = styled(Typography).attrs({ variant: "h2" })`
  margin: 0;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
`;

export default RoomDataSettings;
