import { DefaultThunk } from "stores";
import { UpdateNote } from "stores/modules/entities.room.notes";
import {
  deleteRoomNote,
  updateRoomNote,
} from "stores/modules/entities.room.notes/operations";

export type UpdateNoteRecord = {
  kind: "update-note";
  id: string;
  before: UpdateNote | null;
  after: UpdateNote | null;
};

export const undoUpdateNote =
  (history: UpdateNoteRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const before = history.before;

    if (!before) {
      // 変更前データがない場合は追加が行われたとして削除処理
      dispatch(deleteRoomNote(roomId, history.id));
      return;
    }

    // それ以外の場合は通常の更新処理
    dispatch(updateRoomNote(roomId, history.id, before));
    return;
  };

export const redoUpdateNote =
  (history: UpdateNoteRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;
    const after = history.after;

    if (!after) {
      // 変更後データがない場合は削除処理
      dispatch(deleteRoomNote(roomId, history.id));
      return;
    }

    // それ以外の場合は通常の更新処理
    dispatch(updateRoomNote(roomId, history.id, after));
    return;
  };
