import { actions } from "./slice";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils/operators";
import { Member, MemberRecord, Role, UpdateMember } from "./";
import { DefaultThunk } from "stores";
import { getUid } from "../app.user/selectors";
import {
  DocumentData,
  FirestoreDataConverter,
  collection,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getAppState } from "../app.state/selectors";

const memberConverter: FirestoreDataConverter<DocumentData> = {
  toFirestore(member: UpdateMember) {
    return member;
  },
  fromFirestore(snapshot, options): Member {
    const data = snapshot.data(options)!;
    return MemberRecord(data);
  },
};

const membersRef = (roomId: string) =>
  collection(db, "rooms", roomId, "members").withConverter(memberConverter);

export const subscribeRoomMembers = createSubscribeCollection(
  actions,
  membersRef
);

export const setRoomMember =
  (roomId: string, uid: string, member: UpdateMember) => () => {
    if (roomId == null || uid == null) return;
    return setDoc(
      doc(membersRef(roomId), uid),
      {
        ...member,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const updateCurrentRoomMember =
  (member: UpdateMember): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    const uid = getUid(state);
    if (!roomId || !uid) return;
    return setDoc(
      doc(membersRef(roomId), uid),
      {
        ...member,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const setCurrentOwnRoomMember = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const roomId = state.app.state.roomId;
  const uid = getUid(state);
  const displayName = getAppState(state, "displayName") || "";
  const photoUrl = getAppState(state, "photoUrl");
  const isAnonymous = getAppState(state, "isAnonymous") ?? false;

  if (!roomId || !uid) return;

  const now = Date.now();
  const member: UpdateMember = {
    photoUrl,
    displayName,
    isAnonymous,
    createdAt: now,
    updatedAt: now,
  };

  return setDoc(doc(membersRef(roomId), uid), member, { merge: true });
};

export const updateCurrentRoomMemberRole =
  (memberId: string, role: Role | null): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const roomId = state.app.state.roomId;
    if (!roomId) return;
    return setDoc(
      doc(membersRef(roomId), memberId),
      {
        role,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const updateRoomMemberTyping =
  (roomId: string, uid: string, typing: boolean) => () => {
    if (roomId == null || uid == null) return;
    return updateDoc(doc(membersRef(roomId), uid), {
      typing,
      updatedAt: Date.now(),
    }).catch((err) => window.location.reload()); // for the 12 hours limit
  };

export const deleteRoomMember = (roomId: string, uid: string) => () => {
  return deleteDoc(doc(membersRef(roomId), uid));
};

export const deleteCurrentRoomMember = (uid: string) => (_, getState) => {
  const state = getState();
  const roomId = state.app.state.roomId;
  if (!roomId) return;

  return deleteDoc(doc(membersRef(roomId), uid));
};
