import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import { useParams } from "react-router";
import store from "stores/interfaces";
import Chat from "containers/Room/Chat";
import NoteMessageDetail from "containers/NoteMessageDetail";
import DiceBotInfo from "containers/Room/DiceBotInfo";
import Chatpalet from "containers/Room/Chatpalet";
import MessageChannelFormDialog from "containers/MessageChannelFormDialog";
import CharacterFormDialog from "containers/CharacterFormDialog";
import ColorPicker from "containers/Room/ColorPicker";
import styled from "styled-components";
import MessageNotifier from "containers/MessageNotifier";
import ChatTabSettingsDialog from "containers/ChatTabSettingsDialog";
import ErrorSnackbar from "containers/ErrorSnackbar";

const RoomCharacter: FC = () => {
  const { roomId } = useParams<{ roomId: string }>();
  const uid = useAppSelector((state) => store.getAppState(state, "uid"));
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(store.appStateMutate((state) => (state.roomId = roomId)));
  }, [dispatch, roomId]);
  useEffect(() => {
    const resolver = dispatch(store.subscribeUserState());
    return () => {
      dispatch(resolver);
    };
  }, [dispatch]);
  useEffect(() => {
    const resolvers = [
      dispatch(store.subscribeRoom(roomId)),
      dispatch(store.subscribeRoomMessages(roomId)),
      dispatch(store.subscribeRoomCharacters(roomId)),
      dispatch(store.subscribeRoomNotes(roomId)),
      dispatch(store.subscribeRoomMembers(roomId)),
      dispatch(store.subscribeRoomEffects(roomId)),
    ];
    return () => {
      resolvers.map((resolver) => dispatch(resolver));
    };
  }, [dispatch, roomId]);
  if (!uid || !roomId) return null;
  return (
    <>
      <Container>
        <Chat roomId={roomId} uid={uid} standalone={true} />
      </Container>
      <MessageChannelFormDialog />
      <ChatTabSettingsDialog />
      <CharacterFormDialog uid={uid} roomId={roomId} />
      <DiceBotInfo />
      <NoteMessageDetail />
      <Chatpalet roomId={roomId} />
      <ColorPicker roomId={roomId} uid={uid} />
      <ErrorSnackbar />
      <MessageNotifier />
    </>
  );
};

const Container = styled.div`
  padding: 8px;
`;

export default RoomCharacter;
