/**
 * スクリーンパネル
 */

import React, {
  useCallback,
  memo,
  useRef,
  TouchEvent,
  MouseEvent as ReactMouseEvent,
  useState,
} from "react";
import styled from "styled-components";
import store from "stores/interfaces";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "stores";
import { Chip, Tooltip } from "@mui/material";
import useLongTap from "hooks/longTap";
import toCDNUrl from "modules/toCDNUrl";
import { handleClickAction } from "modules/clickAction";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";
import { Trans, useTranslation } from "react-i18next";
import { addUndo } from "stores/modules/entities.room.histories/slice";
import DraggableItem, { Position } from "containers/DraggableItem";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import { fixSelectionOnDragStop } from "modules/drag";

interface ItemProps {
  itemId: string;
}
const Item = (props: ItemProps) => {
  const { itemId } = props;
  const d = useAppDispatch();
  const { item, uid, members, isGod, _isMonitored } = useAppSelector(
    (state) => ({
      item: store.getRoomItemById(state, itemId),
      uid: store.getAppState(state, "uid"),
      members: store.getSameGroupRoomMemberIds(state),
      isGod: store.getAppState(state, "role") === "god",
      _isMonitored: store.getCurrentRoomIsMonitored(state),
    }),
    shallowEqual
  );
  const isMonitored = isGod || _isMonitored;
  const isMine = item && members.includes(item.owner) && !item.withoutOwner;
  const isOpened =
    isMine || !item?.closed || (item.withoutOwner && item.owner !== uid);
  const hasClickAction = item?.clickAction != null && isOpened;
  const isRoleAudience = useAppSelector(getIsRoleAudience);
  const isClicked = useRef(false);
  const [t] = useTranslation();
  const [dragging, setDragging] = useState(false);
  const alignWithGrid = useAppSelector(
    (state) => getCurrentRoom(state)?.alignWithGrid ?? true
  );

  // handlers
  const onContextMenu = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (isRoleAudience) {
        return;
      }

      d(
        store.appStateMutate((state) => {
          state.openRoomItemMenu = true;
          state.openRoomItemMenuId = itemId;
          state.roomPointerY = e.pageY || 0;
          state.roomPointerX = e.pageX || 0;
        })
      );
    },
    [itemId, d, isRoleAudience]
  );
  const onUpdate = useCallback(
    (position: Position) => {
      d(store.dragedItem(itemId, position));
    },
    [itemId, d]
  );
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (item?.clickAction != null && isClicked.current) {
        return;
      }
      isClicked.current = true;

      setTimeout(() => {
        isClicked.current = false;
      }, 350);

      if (
        dragging ||
        item?.clickAction == null ||
        !isOpened ||
        isRoleAudience
      ) {
        return;
      }

      handleClickAction(item.clickAction, d);
    },
    [dragging, isOpened, item?.clickAction, d, isRoleAudience]
  );
  const onDoubleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (item?.locked || isRoleAudience) return;
      if (item?.clickAction && isOpened) return;

      if (!item?.coverImageUrl && isOpened) {
        d(
          store.appStateMutate((state) => {
            state.openRoomPanelDetail = true;
            state.openRoomPanelDetailId = itemId;
          })
        );
        return;
      }
      if (isMine) {
        d(store.openRoomItem(itemId));
      } else if (isOpened) {
        d(store.closeRoomItem(itemId));
      } else {
        d(store.takeRoomItem(itemId));
      }
    },
    [
      isOpened,
      isMine,
      itemId,
      item?.locked,
      item?.coverImageUrl,
      item?.clickAction,
      isRoleAudience,
      d,
    ]
  );
  const keyDownTimer = useRef(0);
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (isRoleAudience) {
        return;
      }

      // Skip Paste
      if (e.keyCode === 86 && (e.metaKey || e.ctrlKey)) {
        return;
      }

      // Skip Undo/Redo
      if (
        (e.code === "KeyZ" || e.code === "KeyY") &&
        (e.metaKey || e.ctrlKey)
      ) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      if (Date.now() - keyDownTimer.current < 100) {
        keyDownTimer.current = Date.now();
        return;
      }
      keyDownTimer.current = Date.now();
      switch (e.keyCode) {
        // Expand
        case 69:
          d(
            store.appStateMutate((state) => {
              state.openInspector = true;
              state.inspectImageUrl =
                isOpened || isMonitored
                  ? item?.imageUrl || null
                  : item?.coverImageUrl || null;
            })
          );
          break;
        // Deactivate
        case 83:
          if (item && itemId) {
            d(
              store.updateRoomItem(null, itemId, {
                active: false,
              })
            );
          }
          break;
        // Take
        case 84:
          if (item && itemId) {
            if (item.owner === uid) {
              d(store.closeRoomItem(itemId));
            } else {
              d(store.takeRoomItem(itemId));
            }
          }
          break;
        // Without Owner
        case 87:
          if (item && itemId) {
            d(store.openWithoutOwnerRoomItem(itemId));
          }
          break;
        // Open
        case 79:
          if (item && itemId && item.closed) {
            d(store.openRoomItem(itemId));
          }
          break;
        // Lock
        case 76:
          if (itemId && item) {
            d(store.updateRoomItem(null, itemId, { locked: !item.locked }));
          }
          break;
        // Rotate
        case 82:
          if (itemId && item) {
            const angleUnit = 45;
            const addAngle = e.shiftKey ? -angleUnit : angleUnit;
            d(
              store.updateRoomItem(null, itemId, {
                angle: item.angle + addAngle,
              })
            );
          }
          break;
        // Duplicate
        case 68:
          if (item && itemId && (e.metaKey || e.ctrlKey)) {
            d(store.addRoomItem(item));
          }
          break;
        // Delete（Backspace）
        case 8:
          if (item && (e.metaKey || e.ctrlKey)) {
            if (window.confirm(t("本当に削除しますか？"))) {
              d(store.deleteCurrentRoomItem(itemId));
              d(
                addUndo({
                  kind: "update-item",
                  id: itemId,
                  before: item,
                  after: null,
                })
              );
            }
          }
          break;
      }
    },
    [uid, itemId, item, isOpened, isMonitored, d, isRoleAudience, t]
  );

  const onContextMenuTouch = useCallback(
    (e: TouchEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (isRoleAudience) {
        return;
      }

      d(
        store.appStateMutate((state) => {
          state.openRoomItemMenu = true;
          state.openRoomItemMenuId = itemId;
          state.roomPointerY = e.touches[0]?.screenY || 0;
          state.roomPointerX = e.touches[0]?.screenX || 0;
        })
      );
    },
    [itemId, d, isRoleAudience]
  );

  const longTapProps = useLongTap(onContextMenuTouch);

  const onMouseDown = useCallback(
    (e: ReactMouseEvent | TouchEvent) => {
      if (!item?.locked && !isRoleAudience) {
        e.stopPropagation();
        fixSelectionOnDragStop();
      }
    },
    [item?.locked, isRoleAudience]
  );
  const stopPropagation = useCallback(
    (e: ReactMouseEvent | TouchEvent) => {
      if (!item?.locked && !isRoleAudience) {
        e.stopPropagation();
      }
    },
    [item?.locked, isRoleAudience]
  );

  const disableDragging = item?.locked || isRoleAudience;
  const enableResizing = !item?.locked && !item?.freezed && !isRoleAudience;
  const imageUrl =
    isOpened || isMonitored
      ? item?.imageUrl || "/blank.gif"
      : item?.coverImageUrl || "/grey.png";
  const coverImageUrl = item?.coverImageUrl || "/grey.png";

  if (!item) return null;
  return (
    <DraggableItem
      initialPosition={{
        x: item.x,
        y: item.y,
        width: item.width,
        height: item.height,
      }}
      style={{
        zIndex: item.z,
      }}
      draggableId={itemId}
      disableDragging={disableDragging}
      enableResizing={enableResizing}
      alignWithGrid={alignWithGrid}
      setDragging={setDragging}
      onUpdate={onUpdate}
      onKeyDown={onKeyDown}
      onContextMenu={onContextMenu}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseDown={onMouseDown}
      onTouchStart={stopPropagation}
    >
      <Tooltip
        title={isOpened && !dragging ? item.memo || "" : ""}
        placement="bottom"
      >
        <ItemContainer
          {...longTapProps}
          locked={item.locked}
          style={{
            transform: `rotate(${item.angle}deg)`,
            cursor: hasClickAction ? "pointer" : undefined,
          }}
        >
          <img
            src={toCDNUrl(imageUrl)}
            draggable={false}
            width="100%"
            height="100%"
            alt=""
          />
          {isMonitored && !isOpened ? (
            <Cover
              src={toCDNUrl(coverImageUrl)}
              draggable={false}
              width="100%"
              height="100%"
            />
          ) : null}
          <ChipVisibleScope
            ownerName={item.ownerName}
            withoutOwner={item.withoutOwner}
            angle={-item.angle}
          />
        </ItemContainer>
      </Tooltip>
    </DraggableItem>
  );
};

type ChipVisibleScopeProps = {
  ownerName: string | null;
  withoutOwner: boolean;
  angle: number;
};

const ChipVisibleScope = ({
  ownerName,
  withoutOwner,
  angle,
}: ChipVisibleScopeProps) => {
  if (!ownerName) {
    return null;
  }

  if (withoutOwner) {
    return (
      <ItemChipWithoutOwner
        label={
          <Trans ownerName={ownerName}>
            {{ ownerName }}
            <br />
            以外に公開
          </Trans>
        }
        angle={angle}
      />
    );
  } else {
    return <ItemChip label={ownerName} angle={angle} />;
  }
};

const ItemContainer = styled.div<{ locked: boolean }>`
  width: 100%;
  height: 100%;
  &:hover {
    background: ${(p: { locked: boolean }) =>
      p.locked ? "none" : "rgba(0, 0, 0, 0.2)"};
  }
  &:active {
    // transform: scale(1.1) !important;
    // outline: 1px solid #fff;
    // z-index: 99999 !important;
    // box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
  }
  img {
    pointer-events: none;
  }
`;

const ItemChip = styled(Chip)<{ angle: number }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
    rotate(${({ angle }: { angle: number }) => angle}deg);
  max-width: 120%;
  background: rgba(0, 0, 0, 0.68);
  transition: opacity 80ms linear;
  opacity: 1;
`;

type ItemChipWithoutOwnerProps = {
  angle: number;
};

const ItemChipWithoutOwner = styled(Chip)<ItemChipWithoutOwnerProps>(
  ({ angle }) => ({
    position: "absolute",
    left: "50%",
    top: "50%",
    height: "auto",
    transform: `translate(-50%, -50%) rotate(${angle}deg)`,
    maxWidth: "120%",
    background: "rgb(0 0 0 / 0.68)",
    transition: "opacity 80ms linear",
    opacity: 1,
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    paddingTop: "4px",
    paddingBottom: "4px",
  })
);

const Cover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
`;

export default memo(Item);
