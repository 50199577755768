import React, {
  useEffect,
  useCallback,
  memo,
  useMemo,
  useState,
  useRef,
} from "react";
import { shallowEqual } from "react-redux";
import { DefaultRootState } from "stores";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import {
  Dialog,
  AppBar,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  roomId: string;
  uid: string;
};

const stateSelector = (state: DefaultRootState) => {
  return {
    systems: store.getAppState(state, "diceBotTypes"),
    open: store.getAppState(state, "openRoomDiceBotTypeList"),
  };
};

const DiceBotTypeSelect: React.FC<Props> = (props) => {
  const state = useAppSelector(stateSelector, shallowEqual);
  const $ = useEnhance(state, props);
  const [t] = useTranslation();
  return (
    <Dialog
      open={state.open}
      onClose={$.onClose}
      fullWidth
      PaperProps={{
        style: { height: "45%" },
      }}
    >
      <AppBar position="sticky" elevation={0} color="inherit">
        <TextField
          label={t("検索")}
          variant="filled"
          fullWidth
          defaultValue={$.searchText}
          onChange={$.onChange}
          autoFocus
        />
      </AppBar>
      <List>
        {$.filteredSystems.map((system) => (
          <DiceBotTypeListItem
            system={system}
            onSelect={$.onSelect}
            key={system.system}
          />
        ))}
      </List>
    </Dialog>
  );
};

const DiceBotTypeListItem: React.FC<{
  system: any;
  onSelect: (system: any) => void;
  key: any;
}> = memo((props) => {
  return (
    <ListItem button onClick={() => props.onSelect(props.system)}>
      <ListItemText primary={props.system.name} />
    </ListItem>
  );
});

const useEnhance = (state: ReturnType<typeof stateSelector>, props: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(store.loadDiceBotSystems());
  }, [dispatch]);

  const [searchText, setSearchText] = useState("");

  const filteredSystems = useMemo(() => {
    if (!searchText) return state.systems;
    return state.systems.filter((system) => {
      return system.name.includes(searchText);
    });
  }, [state.systems, searchText]);

  const onClose = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomDiceBotTypeList = false;
      })
    );
  }, [dispatch]);

  const timer = useRef<number | null>(null);
  const onChange = useCallback((e) => {
    const text = e.currentTarget.value;
    if (timer.current != null) window.clearTimeout(timer.current);
    timer.current = window.setTimeout(() => {
      setSearchText(text);
    }, 100);
  }, []);
  useEffect(() => {
    return () => {
      if (timer.current != null) window.clearTimeout(timer.current);
    };
  }, []);

  const onSelect = useCallback(
    (system) => {
      const sysinfo = async (roomId: string) => {
        dispatch(
          store.updateRoom(roomId, {
            diceBotName: system.name,
            diceBotSystem: system.system,
          })
        );
        onClose();
      };
      sysinfo(props.roomId);
    },
    [props.roomId, dispatch, onClose]
  );

  return {
    searchText,
    onSelect,
    onClose,
    onChange,
    filteredSystems,
  };
};

export default memo(DiceBotTypeSelect);
