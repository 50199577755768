import { memo, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  DialogTitle,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { Controller, useForm } from "react-hook-form";

type FormData = {
  room: boolean;
  items: boolean;
  characters: boolean;
  decks: boolean;
  notes: boolean;
  scenes: boolean;
  effects: boolean;
  savedatas: boolean;
};

const ExportRoomDialog = () => {
  const [t] = useTranslation();
  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomExport")
  );
  const dispatch = useAppDispatch();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      room: true,
      items: true,
      characters: true,
      decks: true,
      notes: true,
      scenes: true,
      effects: true,
      savedatas: true,
    },
  });

  const onSubmit = useCallback(
    (values: FormData) => {
      dispatch(store.exportRoomDataForPreset(values));
      dispatch(store.appStateMutate((state) => (state.openRoomExport = false)));
    },
    [dispatch]
  );
  const onClose = useCallback(() => {
    dispatch(store.appStateMutate((state) => (state.openRoomExport = false)));
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("エクスポート設定")}</DialogTitle>
        <DialogContent style={{ background: "rgba(0, 0, 0, 0.4)" }}>
          <Box pt={2} pb={2}>
            <Typography variant="body2" color="textPrimary">
              {t(
                "注：出力時に全ての音源ファイルは除外されます。出力データを配布する場合は、配布する権利のないデータが含まれていないことを必ず確認してください。また、出力されたデータは今後のアップデートにより動作しなくなる場合があります。"
              )}
              {t(
                "出力された画像データは共有ストレージを参照します。ユーザーごとのファイル一覧にアップロードされません。"
              )}
            </Typography>
          </Box>
          <Divider />
          <List>
            <ListItem disableGutters>
              <ListItemText
                primary={t("フィールド設定")}
                secondary={t("背景・前景・マーカーなど")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="room"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={t("マイキャラクター")}
                secondary={t("所有権のあるキャラクター")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="characters"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={t("スクリーンパネル")}
                secondary={t("全てのスクリーンパネル")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="items"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={t("カードデッキ")}
                secondary={t("全てのカードデッキ")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="decks"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={t("シナリオテキスト")}
                secondary={t("全てのシナリオテキスト")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="notes"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={t("シーン")}
                secondary={t("音源の設定を除くシーンリスト")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="scenes"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={t("カットイン")}
                secondary={t("音源の設定を除くカットイン効果")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="effects"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={t("セーブデータ")}
                secondary={t("全てのセーブデータ")}
              />
              <ListItemSecondaryAction>
                <Controller
                  name="savedatas"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      name={name}
                      inputRef={ref}
                    />
                  )}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button fullWidth color="primary" type="submit">
            {t("エクスポート")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(ExportRoomDialog);
