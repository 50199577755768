import { CircularProgress, Typography } from "@mui/material";
import { memo, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "stores";
import store from "stores/interfaces";
import styled from "styled-components";
import theme from "theme";

const stopPropagation = (event: MouseEvent) => {
  event.stopPropagation();
};

const UnsplashFileList = (props: { onSelect: (url: string) => void }) => {
  const [t] = useTranslation();
  const apiError = useAppSelector((state) =>
    store.getAppState(state, "unsplashError")
  );
  const photos = useAppSelector((state) => {
    return store.getAppState(state, "unsplashSearchResult");
  }, shallowEqual);
  const loading = useAppSelector((state) =>
    store.getAppState(state, "unsplashLoading")
  );

  if (loading) {
    return (
      <Tiles>
        <Loading />
      </Tiles>
    );
  }

  if (apiError != null) {
    return (
      <Tiles>
        <EmptyText variant="body2">
          {apiError === "reached-rate-limit"
            ? t(
                "Unsplash APIの利用上限に達したため、検索結果の取得に失敗しました。"
              )
            : t("エラーが発生し、検索結果の取得に失敗しました。")}
        </EmptyText>
      </Tiles>
    );
  }

  if (photos == null) {
    return (
      <Tiles>
        <EmptyText variant="body2">
          {t("キーワードを入力して画像を検索することができます。")}
        </EmptyText>
      </Tiles>
    );
  }

  return (
    <Tiles>
      {photos.map((photo) => (
        <FileTile
          key={photo.id}
          onClick={() => props.onSelect(photo.urls.regular)}
        >
          <img src={photo.urls.thumb} draggable={false} />
          <Provider>
            <a
              target="_blank"
              href={
                photo.user.links.html +
                "?utm_source=ccfolia&utm_medium=referral"
              }
              onClick={stopPropagation}
            >
              {photo.user.name}
            </a>
          </Provider>
        </FileTile>
      ))}
      {photos.length <= 0 && (
        <EmptyText variant="body2">
          {t("検索に該当する画像がありませんでした。")}
        </EmptyText>
      )}
    </Tiles>
  );
};

const EmptyText = styled(Typography)`
  &.MuiTypography-root {
    padding: 16px;
  }
`;

const Tiles = styled.div`
  padding: 1%;
  height: 640px;
  // background: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  align-content: flex-start;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar: {
    display: none;
  }
`;

const FileTile = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0.5%;
  width: 19%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  transition: opacity 160ms ease-out;
  opacity: 1;
  cursor: pointer;
  position: relative;
  &:active {
    opacity: 0.5;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Provider = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 6px;

  font-size: 12px;
  text-align: center;

  background-color: rgba(0, 0, 0, 0.5);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: ${theme.palette.text.secondary};
  }
`;

const Loading = styled(CircularProgress)`
  margin: 32px auto;
`;

export default memo(UnsplashFileList);
