import { memo, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";

import Dialog from "@mui/material/Dialog";
import MarkerDetail, { FormData } from "./MarkerDetail";

import { FormProvider, useForm } from "react-hook-form";
import { toClickAction } from "containers/PanelDetail/FormClickAction";
import { getRoomMarkerById } from "stores/modules/entities.rooms/selectors";
import { addUndo } from "stores/modules/entities.room.histories/slice";
import { clamp } from "lodash-es";

type MarkerDetailDialogProps = {
  roomId: string;
};

const MarkerDetailDialog = ({ roomId }: MarkerDetailDialogProps) => {
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomMarkerDetail")
  );
  const markerId = useAppSelector((state) =>
    store.getAppState(state, "openRoomMarkerDetailId")
  );
  const marker = useAppSelector((state) =>
    getRoomMarkerById(state, roomId, markerId ?? "")
  );

  const formMethods = useForm<FormData>({
    defaultValues: {
      width: 1,
      height: 1,
      z: 0,
      text: "",
      locked: false,
      freezed: false,
      clickAction: "none",
      linkUrl: "",
      messageText: "",
    },
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (open) {
      reset({
        width: marker.width,
        height: marker.height,
        z: marker.z,
        text: marker.text,
        locked: marker.locked,
        freezed: marker.freezed,
        clickAction: marker.clickAction?.type || "none",
        linkUrl:
          marker.clickAction?.type === "link" ? marker.clickAction.url : "",
        messageText:
          marker.clickAction?.type === "message" ? marker.clickAction.text : "",
      });
    }
  }, [
    reset,
    marker.width,
    marker.height,
    marker.z,
    marker.text,
    marker.locked,
    marker.freezed,
    marker.clickAction,
    open,
  ]);

  const onSubmit = useCallback(
    (values: FormData) => {
      if (markerId) {
        const changeData = {
          text: values.text || "",
          width: clamp(~~values.width, 1, 1000),
          height: clamp(~~values.height, 1, 1000),
          z: Math.min(~~values.z, 999),
          locked: values.locked,
          freezed: values.freezed,
          clickAction: toClickAction(values),
        };
        dispatch(store.updateRoomMarker(roomId, markerId, changeData));
        const isUpdate = Object.keys(changeData).some(
          (key) => changeData[key] !== marker[key]
        );
        if (isUpdate) {
          dispatch(
            addUndo({
              kind: "update-marker",
              id: markerId,
              before: marker,
              after: { ...marker, ...changeData },
            })
          );
        }
      }
    },
    [roomId, markerId, dispatch, marker]
  );

  const onClose = useCallback(() => {
    handleSubmit(onSubmit)();
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomMarkerDetail = false;
      })
    );
  }, [onSubmit, handleSubmit, dispatch]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <FormProvider {...formMethods}>
        <MarkerDetail
          roomId={roomId}
          markerId={markerId || ""}
          onClose={onClose}
          onSubmit={handleSubmit(onSubmit)}
        />
      </FormProvider>
    </Dialog>
  );
};

export default memo(MarkerDetailDialog);
