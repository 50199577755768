import React from "react";
import { useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import { Modal, CircularProgress } from "@mui/material";

const Loader: React.FC<{ loading?: boolean }> = (props) => {
  const open = useAppSelector((state) => store.getAppState(state, "loading"));
  return (
    <Modal open={open || !!props.loading}>
      <Center>
        <CircularProgress />
      </Center>
    </Modal>
  );
};

const Center = styled.div`
  position: absolute;
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default React.memo(Loader);
