import { UserHistory } from "./";
import { DefaultRootState } from "stores";

const emptyUserHistory: UserHistory = {};

export const getUserHistories = (state: DefaultRootState) => {
  return state.entities.userHistories.entities;
};

export const getUserHistoryById = (state: DefaultRootState, id: string) => {
  return state.entities.userHistories.entities[id] || emptyUserHistory;
};

export const getUserHistoryIds = (state: DefaultRootState) => {
  return state.entities.userHistories.ids || [];
};
