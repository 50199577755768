import { MouseEvent, TouchEvent, memo, useCallback } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import useLongTap from "hooks/longTap";
import { getRoomDiceById } from "stores/modules/entities.room.dices/selectors";
// import { getCurrentRoomIsMonitored } from "stores/modules/entities.rooms/selectors";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";
import { addUndo } from "stores/modules/entities.room.histories/slice";
import DraggableItem from "containers/DraggableItem";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import { fixSelectionOnDragStop } from "modules/drag";

const stopPropagation = (e: MouseEvent | TouchEvent) => e.stopPropagation();

const diceTypes = [0, 4, 6, 8, 10, 12, 20];
const enableProps = {
  right: true,
  bottom: true,
  bottomRight: true,
};

type DiceSymbolProps = {
  diceId: string;
};

const DiceSymbol = (props: DiceSymbolProps) => {
  const { diceId } = props;
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const dice = useAppSelector((state) => getRoomDiceById(state, diceId));
  // const monitored = useAppSelector(getCurrentRoomIsMonitored);
  const monitored = false;
  const isRoleAudience = useAppSelector(getIsRoleAudience);
  const alignWithGrid = useAppSelector(
    (state) => getCurrentRoom(state)?.alignWithGrid ?? true
  );

  const onContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isRoleAudience) {
        return;
      }

      dispatch(
        store.appStateMutate((state) => {
          state.openRoomDiceDetail = true;
          state.openRoomDiceDetailId = diceId;
          state.roomPointerY = e.pageY || e.touches[0]?.screenY || 0;
          state.roomPointerX = e.pageX || e.touches[0]?.screenX || 0;
        })
      );
    },
    [diceId, isRoleAudience, dispatch]
  );
  const onUpdate = useCallback(
    (changes) => {
      dispatch(store.updateRoomDice(diceId, changes));
      dispatch(
        addUndo({
          kind: "update-dice",
          id: diceId,
          before: dice,
          after: { ...dice, ...changes },
        })
      );
    },
    [diceId, dispatch, dice]
  );

  const onMousedown = useCallback(
    (e) => {
      if (!isRoleAudience) {
        e.stopPropagation();
        fixSelectionOnDragStop();
      }
    },
    [isRoleAudience]
  );

  const longTapProps = useLongTap(onContextMenu);
  if (diceTypes.indexOf(~~dice.faces) < 0) return <div />;
  return (
    <DraggableItem
      initialPosition={{
        x: dice.x,
        y: dice.y,
        width: dice.width,
        height: dice.height,
      }}
      draggableId={diceId}
      disableDragging={isRoleAudience}
      enableResizing={!isRoleAudience}
      enableProps={enableProps}
      alignWithGrid={alignWithGrid}
      onUpdate={onUpdate}
      onContextMenu={onContextMenu}
      lockAspectRatio={true}
      onMouseDown={onMousedown}
      onTouchStart={isRoleAudience ? undefined : stopPropagation}
      style={{
        zIndex: 10000,
      }}
    >
      {!dice.closed || monitored ? (
        <Tooltip
          title={`${t("{{name}}のダイス", { name: dice.name })} [${
            dice.value
          }]`}
          placement="bottom"
        >
          <DiceContainer {...longTapProps} onDoubleClick={stopPropagation}>
            <img
              src={`/images/${dice.faces}_dice/${dice.faces}_dice[${dice.value}].png`}
              draggable={false}
              width="100%"
              height="100%"
            />
            {dice.closed && monitored && (
              <BadgeClosed src="/images/unknown.png" draggable={false} />
            )}
          </DiceContainer>
        </Tooltip>
      ) : (
        <Tooltip
          title={t("{{name}}のダイス", { name: dice.name })}
          placement="bottom"
        >
          <DiceContainer {...longTapProps} onDoubleClick={stopPropagation}>
            <img
              src="/images/unknown.png"
              draggable={false}
              width="100%"
              height="100%"
            />
          </DiceContainer>
        </Tooltip>
      )}
    </DraggableItem>
  );
};

const DiceContainer = styled.div`
  width: 100%;
  height: 100%;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  img {
    pointer-events: none;
  }
`;

const BadgeClosed = styled.img`
  width: 40%;
  height: 40%;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export default memo(DiceSymbol);
