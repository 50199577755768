import React, { memo } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";
import theme from "theme";
import { formatRelative } from "date-fns";
import jaLocale from "date-fns/locale/ja";
import { useTranslation } from "react-i18next";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
  Divider,
  Typography,
} from "@mui/material";
import toCDNUrl from "modules/toCDNUrl";
import ReplyIcon from "@mui/icons-material/Reply";
import RedoIcon from "@mui/icons-material/Redo";
import EditIcon from "@mui/icons-material/Edit";
import { Message as MessageType } from "stores/modules/entities.room.messages";
import { getIsRoleAudience } from "stores/modules/entities.room.members/selectors";

const formatRelativeLocale = {
  lastWeek: "先週 eeee p",
  yesterday: "昨日 p",
  today: "今日 p",
  tomorrow: "明日 p",
  nextWeek: "翌週のeeee p",
  other: "P",
};
jaLocale.formatRelative = (token, _date, _baseDate, _options) => {
  return formatRelativeLocale[token];
}; // override

const toDateString = (timestamp, now) => {
  const date = timestamp.toDate();
  return formatRelative(date, now, {
    locale: jaLocale,
  });
};

const toRollTextColor = (
  text: string,
  roll: MessageType["extend"]["roll"] | undefined
) => {
  if (text === "Secret dice 🎲") return "secondary";
  if (roll?.success) return "primary";
  if (roll?.failure) return "secondary";
  if (text.includes("成功")) {
    return "primary";
  } else if (text.includes("スペシャル")) {
    return "primary";
  } else if (text.includes("失敗")) {
    return "secondary";
  } else {
    return "textSecondary";
  }
};

const Message = ({ messageId }) => {
  const dispatch = useAppDispatch();
  const message = useAppSelector((state) =>
    store.getRoomMessageById(state, messageId)
  );
  const uid = useAppSelector((state) => store.getAppState(state, "uid"));
  const isRoleAudience = useAppSelector(getIsRoleAudience);
  const texts = messageToTextTokens(message, uid);
  const onEdit = React.useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomMessage = true;
        state.openRoomMessageId = messageId;
      })
    );
  }, [dispatch, messageId]);
  const onOpen = React.useCallback(() => {
    if (message._id === undefined) return;
    dispatch(
      store.updateRoomMessage(
        message._id,
        {
          extend: {
            roll: {
              secret: false,
            },
          },
        },
        false
      )
    );
  }, [dispatch, message._id]);
  const onOpenDM = React.useCallback(() => {
    dispatch(store.currentDirectMessageTab(message.from));
  }, [dispatch, message.from]);
  const now = new Date();
  const [t] = useTranslation();
  return (
    <>
      <ListItem alignItems="flex-start" component="div">
        <ListItemAvatar>
          {message.type !== "system" ? (
            <MessageAvatar>
              <img
                src={toCDNUrl(message.iconUrl) || "/blank.gif"}
                alt="avatar"
                draggable={false}
              />
            </MessageAvatar>
          ) : (
            <div />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              {message.type !== "system" ? message.name || "noname" : null}
              {message.type !== "system" && message.createdAt ? (
                <Typography
                  variant="caption"
                  component="span"
                  style={{ color: theme.palette.grey[600] }}
                >
                  &nbsp;-&nbsp;{toDateString(message.createdAt, now)}
                </Typography>
              ) : null}
            </>
          }
          secondary={
            <>
              {texts[0]}
              {texts[1] ? (
                <Typography
                  variant="body2"
                  component="span"
                  color={toRollTextColor(texts[1], message?.extend?.roll)}
                >
                  &nbsp;{texts[1]}
                </Typography>
              ) : null}
              {message.edited ? (
                <Typography
                  variant="caption"
                  component="span"
                  style={{ color: theme.palette.grey[600] }}
                >
                  &nbsp;[{t("編集済")}]
                </Typography>
              ) : null}
            </>
          }
          primaryTypographyProps={{
            noWrap: true,
            display: "block",
            variant: "subtitle2",
            style: {
              color: message.color,
            },
          }}
          secondaryTypographyProps={{
            style: {
              wordBreak: "break-all",
              whiteSpace: "pre-wrap",
              overflowWrap: "anywhere",
            },
            color: message.type !== "system" ? "textPrimary" : "textSecondary",
          }}
        />
        {message.from === uid &&
        message.type !== "system" &&
        !isRoleAudience &&
        !message.removed ? (
          <MessageListItemActions>
            {message.extend.roll && message.extend.roll.secret ? (
              <IconButton size="small" onClick={onOpen} disableRipple>
                <RedoIcon fontSize="small" />
              </IconButton>
            ) : null}
            <IconButton size="small" onClick={onEdit} disableRipple>
              <EditIcon fontSize="small" />
            </IconButton>
          </MessageListItemActions>
        ) : null}
        {message.from !== uid &&
        message.type !== "system" &&
        !isRoleAudience ? (
          <MessageListItemActions>
            <IconButton size="small" onClick={onOpenDM} disableRipple>
              <ReplyIcon fontSize="small" />
            </IconButton>
          </MessageListItemActions>
        ) : null}
      </ListItem>
      <Divider variant="middle" light />
    </>
  );
};

const MessageListItemActions = styled.div`
  position: absolute;
  top: 12px;
  right: 16px;
  display: flex;
  opacity: 0;
  .MuiListItem-root:hover & {
    opacity: 0.64;
  }
`;

const MessageAvatar = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);

  img {
    width: 40px;
  }
`;

const messageToTextTokens = (message: MessageType, uid: string | null) => {
  const isMine = message.from === uid;
  const isDiceNotation = !!message.extend.roll;
  const isSecret = message.extend.roll && message.extend.roll.secret;
  if (!isMine && isSecret) {
    return ["Secret dice 🎲"];
  } else if (isDiceNotation) {
    return [message.text, message.extend.roll?.result];
  } else {
    return [message.text];
  }
};

export default memo(Message);
