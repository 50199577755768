import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { auth } from "initializer";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReauthenticateDialog from "./ReauthenticateDialog";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "stores";
import { appUserMutate } from "stores/modules/app.user/operations";
import { EmailAuthProvider, UserInfo, linkWithCredential } from "firebase/auth";

type LinkWithEmailAuthDialogProps = {
  open: boolean;
  onClose: () => void;
  onOpenSnackbar: (text: string) => void;
};

type FormParams = {
  email: string;
  password: string;
  passwordConfirm: string;
};

const LinkWithEmailAuthDialog = ({
  open,
  onClose,
  onOpenSnackbar,
}: LinkWithEmailAuthDialogProps) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const reauthed = useRef(false);
  const [openMain, setOpenMain] = useState(false);
  const [openReauth, setOpenReauth] = useState(false);

  const onReauthenticated = useCallback(() => {
    reauthed.current = true;
    setOpenReauth(false);
    setOpenMain(true);
  }, [reauthed, setOpenMain, setOpenReauth]);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<FormParams>({
    defaultValues: { email: "", password: "", passwordConfirm: "" },
    reValidateMode: "onChange",
  });
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    reset();
    setErrorMessage("");
    if (!open) {
      setOpenMain(false);
      setOpenReauth(false);
    } else if (reauthed.current) {
      setOpenReauth(false);
      setOpenMain(true);
    } else {
      setOpenMain(false);
      setOpenReauth(true);
    }
  }, [open, reauthed, setOpenMain, setOpenReauth, reset]);

  const toErrorMessage = useErrorMessage();

  const updateUserAuthState = useCallback(
    (email: string, user: UserInfo) => {
      dispatch(
        appUserMutate((state) => {
          state.email = email;
          state.providerData.email = user;
        })
      );
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    ({ email, password, passwordConfirm }: FormParams) => {
      if (!auth.currentUser) {
        return;
      }

      if (password !== passwordConfirm) {
        setErrorMessage(
          t("再入力したパスワードが新しいパスワードと一致しませんでした。")
        );
        return;
      }

      linkWithCredential(
        auth.currentUser,
        EmailAuthProvider.credential(email, password)
      )
        .then((userCredential) => {
          if (userCredential.user) {
            updateUserAuthState(email, userCredential.user);
            onClose();
            reset();
            onOpenSnackbar(t("パスワード認証を設定しました。"));
          }
        })
        .catch((error) => {
          if (error?.code === "auth/requires-recent-login") {
            reauthed.current = false;
            setOpenMain(false);
            setOpenReauth(true);
            reset();
            window.alert(t("再認証が必要です。"));
            return;
          }

          const errorMessage = toErrorMessage(error);
          setErrorMessage(errorMessage);
        });
    },
    [
      t,
      reset,
      setErrorMessage,
      setOpenMain,
      setOpenReauth,
      toErrorMessage,
      onClose,
      onOpenSnackbar,
      updateUserAuthState,
    ]
  );

  return (
    <>
      <Dialog open={openMain} onClose={onClose} maxWidth="sm">
        <DialogTitle>
          {t("パスワード認証の設定")}
          <IconButton
            onClick={onClose}
            style={{ position: "absolute", right: 6, top: 6 }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ paddingTop: 0 }}>
            <TextField
              variant="standard"
              type="email"
              label={t("メールアドレス")}
              fullWidth
              style={{ marginBottom: "16px" }}
              {...register("email", { required: true })}
            />
            <TextField
              variant="standard"
              type="password"
              label={t("パスワード")}
              helperText={t("6文字以上")}
              fullWidth
              style={{ marginBottom: "8px" }}
              {...register("password", { required: true, minLength: 6 })}
            />
            <TextField
              variant="standard"
              type="password"
              label={t("パスワード（確認）")}
              fullWidth
              style={{ marginBottom: "16px" }}
              {...register("passwordConfirm", { required: true, minLength: 6 })}
            />
            {errorMessage && (
              <Typography color="error" variant="body2" paragraph>
                {errorMessage}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} fullWidth color="secondary">
              {t("キャンセル")}
            </Button>
            <Button type="submit" disabled={!isValid} fullWidth color="primary">
              {t("設定")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ReauthenticateDialog
        open={openReauth}
        onCancelled={onClose}
        onReauthenticated={onReauthenticated}
      />
    </>
  );
};

const useErrorMessage = () => {
  const [t] = useTranslation();
  const toErrorMessage = useCallback(
    (error: any): string => {
      switch (error?.code) {
        case "auth/email-already-in-use":
          return t("このメールアドレスはすでに使われています。");
        case "auth/invalid-email":
          return t("メールアドレスの形式に誤りがあります。");
        default:
          return t("不明なエラーが発生しました。") + `(${error?.code})`;
      }
    },
    [t]
  );

  return toErrorMessage;
};

export default LinkWithEmailAuthDialog;
