import styled from "styled-components";
import theme from "theme";
import {
  Typography,
  AppBar,
  Toolbar,
  Avatar,
  Button,
  useMediaQuery,
} from "@mui/material";
import version from "version";
import { useTranslation } from "react-i18next";
import TranslationButton from "containers/TranslationButton";
import { Link } from "react-router-dom";

const StaticHeader = () => {
  const [t] = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <StyledAppBar elevation={0}>
      <Toolbar>
        <Link to="/">
          <Avatar src="/android-chrome-192x192.png" />
        </Link>
        <StyledAppBarTitle variant="h6">CCFOLIA</StyledAppBarTitle>
        <StyledAppBarCaption variant="caption">{version}</StyledAppBarCaption>
        <TranslationButton buttonStyle={matches ? "Normal" : "Small"} />
        <Button
          href="https://twitter.com/ccfolia"
          target="_blank"
          component="a"
        >
          {t("公式Twitter")}
        </Button>
      </Toolbar>
    </StyledAppBar>
  );
};

const StyledAppBar = styled(AppBar)`
  background: transparent;
`;
const StyledAppBarTitle = styled(Typography)`
  margin-left: 8px;
`;
const StyledAppBarCaption = styled(Typography)`
  flex-grow: 1;
`;

export default StaticHeader;
