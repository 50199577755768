import { FC, useEffect } from "react";
import { useAppDispatch } from "stores";
import { useParams } from "react-router";
import store from "stores/interfaces";
import CharacterStatusItem from "containers/CharacterStatusItem";
import styled from "styled-components";

const RoomCharacter: FC = () => {
  const { roomId, characterId } = useParams<{
    roomId: string;
    characterId: string;
  }>();
  const dispatch = useAppDispatch();
  useEffect(() => {
    document.body.className = "transparent";
    return () => {
      document.body.className = "";
    };
  }, []);
  useEffect(() => {
    const resolver = dispatch(
      store.subscribeRoomCharacter({ roomId, characterId })
    );
    return () => {
      dispatch(resolver);
    };
  }, [roomId, characterId, dispatch]);
  return (
    <Container>
      <CharacterStatusItem
        characterId={characterId}
        variant="bar"
        onClick={console.log}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 8px;
`;

export default RoomCharacter;
