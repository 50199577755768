import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import { memo, useCallback, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "stores";
import { appStateMutate } from "stores/modules/app.state/operations";
import { getAppState } from "stores/modules/app.state/selectors";
import { RoomVariable } from "stores/modules/entities.rooms";
import { updateCurrentRoom } from "stores/modules/entities.rooms/operations";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import styled from "styled-components";

const MAX_VARIABLES = 16;

const RoomVariablesDialog = () => {
  const open = useAppSelector((state) =>
    getAppState(state, "openRoomVariables")
  );
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const {
    control,
    register,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      roomVariables: [] as RoomVariable[],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "roomVariables",
  });

  const variables = useAppSelector(
    (state) => getCurrentRoom(state)?.variables || [],
    shallowEqual
  );
  useEffect(() => {
    if (open) {
      reset({ roomVariables: variables });
    }
  }, [open, variables, reset]);

  const onClose = useCallback(async () => {
    if (!(await trigger())) {
      return;
    }

    const roomVariables = getValues().roomVariables.filter(
      (v) => v.label.length > 0 && v.value.length > 0
    );
    dispatch(updateCurrentRoom({ variables: roomVariables }));
    dispatch(
      appStateMutate((state) => {
        state.openRoomVariables = false;
      })
    );
  }, [dispatch, getValues, trigger]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <AppBar color="default" position="sticky">
        <Toolbar>
          <Typography style={{ flex: 1 }} fontSize="20px" fontWeight="bold">
            {t("ルーム変数を編集")}
          </Typography>
          <IconButton edge="end" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Toolbar variant="dense" disableGutters>
          <StyledTitle variant="subtitle2">{t("ルーム変数")}</StyledTitle>
          <IconButton
            onClick={() => remove(fields.length - 1)}
            disabled={fields.length < 1}
            size="small"
          >
            <RemoveIcon />
          </IconButton>
          <IconButton
            onClick={() => append({ label: "", value: "" })}
            disabled={fields.length >= MAX_VARIABLES}
            size="small"
            edge="end"
          >
            <AddIcon />
          </IconButton>
        </Toolbar>
        <Typography
          variant="caption"
          display="block"
          style={{ marginBottom: 16 }}
        >
          {t(
            "ルームに変数を設定します。{ラベル名}のように発言するとチャットから値を参照することができます。"
          )}
        </Typography>
        {fields.map((field, index) => {
          const errorMessageLabel =
            errors.roomVariables && errors.roomVariables[index]?.label?.message;
          const errorMessageValue =
            errors.roomVariables && errors.roomVariables[index]?.value?.message;
          return (
            <StyledGroup key={field.id}>
              <TextField
                variant="standard"
                label={t("ラベル")}
                margin="dense"
                fullWidth
                error={errorMessageLabel != null}
                helperText={errorMessageLabel || (errorMessageValue && " ")}
                {...register(`roomVariables.${index}.label`, {
                  maxLength: {
                    value: 16,
                    message: t("16文字以下としてください"),
                  },
                })}
              />
              <TextField
                variant="standard"
                label={t("値")}
                margin="dense"
                fullWidth
                error={errorMessageValue != null}
                helperText={errorMessageValue || (errorMessageLabel && " ")}
                {...register(`roomVariables.${index}.value`, {
                  maxLength: {
                    value: 64,
                    message: t("64文字以下としてください"),
                  },
                })}
              />
            </StyledGroup>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button color="primary" fullWidth onClick={onClose}>
          {t("閉じる")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StyledGroup = styled.div`
  display: flex;
  align-items: center;
  .MuiTextField-root {
    flex: 1;
  }
  > * + * {
    margin-left: 16px;
  }
  .MuiAvatar-root {
    background: rgba(0, 0, 0, 0.2);
    img {
      object-position: 50% 0%;
    }
  }
`;
const StyledTitle = styled(Typography)`
  flex-grow: 1;
`;

export default memo(RoomVariablesDialog);
