import { DefaultRootState, DefaultThunk } from "stores";
import { HistoryRecord, popRedoAddUndo, popUndoAddRedo } from "./slice";
import { redoUpdateDice, undoUpdateDice } from "./records/updateDice";
import { redoUpdateRoom, undoUpdateRoom } from "./records/applyScene";
import { redoUpdateMarker, undoUpdateMarker } from "./records/updateMarker";
import { redoUpdateItem, undoUpdateItem } from "./records/updateItem";
import { redoDrawCard, undoDrawCard } from "./records/drawCard";
import { redoUpdateDeck, undoUpdateDeck } from "./records/updateDeck";
import {
  redoUpdateCharacter,
  undoUpdateCharacter,
} from "./records/updateCharacter";
import { redoUpdateField, undoUpdateField } from "./records/updateField";
import { redoMergeDecks, undoMergeDecks } from "./records/MergeDecks";
import { redoUpdateNote, undoUpdateNote } from "./records/updateNote";
import { redoUpdateEffect, undoUpdateEffect } from "./records/updateEffect";
import { redoUpdateScene, undoUpdateScene } from "./records/updateScene";
import { appStateMutate } from "../app.state/operations";
import { redoOrderScene, undoOrderScene } from "./records/orderScene";
import { redoOrderItem, undoOrderItem } from "./records/orderItem";
import { redoOrderEffect, undoOrderEffect } from "./records/orderEffect";
import { redoOrderNote, undoOrderNote } from "./records/orderNote";
import { getRoomDiceById } from "../entities.room.dices/selectors";
import {
  getCurrentRoomField,
  getRoomMarkerById,
  getRoomMarkerExists,
} from "../entities.rooms/selectors";
import {
  getRoomItemById,
  getSortedRoomItemIds,
} from "../entities.room.items/selectors";
import {
  getRoomNoteById,
  getRoomOrderdNoteIds,
} from "../entities.room.notes/selectors";
import {
  getRoomEffectById,
  getRoomOrderdEffectIds,
} from "../entities.room.effects/selectors";
import {
  getRoomSceneById,
  getRoomSceneOrderedIds,
} from "../entities.room.scenes/selectors";
import { getCharacterById } from "../entities.room.characters/selectors";
import { UpdateItem } from "../entities.room.items";
import { getRoomDeckById } from "../entities.room.decks/selectors";
import { UpdateDeck } from "../entities.room.decks";

const IS_ALL_SNACK_MESSAGE = true; // スナックバーのメッセージ true:常に表示 false:個別に設定
const MESSAGE_TEXT = {
  "update-dice": "ダイスシンボルの操作",
  "apply-scene": "シーン切替",
  "update-marker": "マーカーパネルの操作",
  "update-item": "スクリーンパネルの操作",
  "draw-card": "カードの操作",
  "update-deck": "山札の操作",
  "merge-decks": "山札の操作",
  "update-character": "キャラクターの操作",
  "update-field": "フィールド設定",
  "update-note": "シナリオテキストの変更",
  "update-effect": "カットインの変更",
  "update-scene": "シーンの変更",
  "change-scene-index": "シーン一覧の並び順",
  "change-item-index": "スクリーンパネル一覧の並び順",
  "change-effect-index": "カットイン一覧の並び順",
  "change-note-index": "シナリオテキスト一覧の並び順",
};

export const undo = (): DefaultThunk => (dispatch, getState) => {
  const state = getState();
  const histories = state.entities.roomHistories.undoHistories;
  const roomId = state.app.state.roomId;
  if (!roomId) return;
  if (histories.length !== 0) {
    const undoHistory = histories[histories.length - 1];
    let redoHistory: HistoryRecord = { ...undoHistory };
    let isDisplaySnack = false;
    let isDeleted = false;
    let isChanged = false;
    switch (undoHistory.kind) {
      case "update-dice":
        const dice = getRoomDiceById(state, undoHistory.id);
        if (undoHistory.after !== null) {
          if (!dice._id) {
            isDeleted = true;
            break;
          }
          redoHistory = { ...undoHistory, after: dice };
        }
        dispatch(undoUpdateDice(undoHistory));
        break;
      case "apply-scene":
        const field = getCurrentRoomField(state);
        redoHistory = { ...undoHistory, after: field };
        dispatch(undoUpdateRoom(undoHistory));
        break;
      case "update-marker":
        const marker = getRoomMarkerById(state, roomId, undoHistory.id);
        const markerExists = getRoomMarkerExists(state, roomId, undoHistory.id);
        if (undoHistory.after !== null) {
          if (!markerExists) {
            isDeleted = true;
            break;
          }
          redoHistory = { ...undoHistory, after: marker };
        }
        dispatch(undoUpdateMarker(undoHistory));
        break;
      case "update-item":
        const item = getRoomItemById(state, undoHistory.id);
        if (undoHistory.after !== null) {
          if (!item) {
            isDeleted = true;
            break;
          }
          redoHistory = { ...undoHistory, after: item };
        }
        dispatch(undoUpdateItem(undoHistory));
        break;
      case "draw-card":
        const drawDeck = getRoomDeckById(state, undoHistory.deckId);
        if (!drawDeck) {
          isDeleted = true;
          break;
        }
        if (undoHistory.after !== null) {
          const drawCards = getCardsByFieldItems(state, undoHistory.after);
          if (!equalCards(undoHistory.after, drawCards)) {
            // カードを引く操作において、カードの情報が変わっている場合はUndo不可
            isChanged = true;
            break;
          }
          redoHistory = { ...undoHistory, after: drawCards };
        } else if (undoHistory.before !== null) {
          const drawDeckCards = getCardsByCardsAndDeck(
            undoHistory.before,
            drawDeck
          );
          if (!equalCards(undoHistory.before, drawDeckCards)) {
            // 山札にカードを戻す操作において、山札が削除済みあるいは山札内でカードの情報が変わっている場合はUndo不可
            isChanged = true;
            break;
          }
        }
        dispatch(undoDrawCard(undoHistory));
        break;
      case "update-deck":
        const updateDeck = getRoomDeckById(state, undoHistory.id);
        if (undoHistory.after !== null) {
          if (!updateDeck) {
            isDeleted = true;
            break;
          }
          const undoCards = undoHistory.cards || [];
          const deckCards = getCardsByCardsAndDeck(undoCards, updateDeck);
          if (!equalCards(undoCards, deckCards)) {
            isChanged = true;
            break;
          }
          redoHistory = {
            ...undoHistory,
            after: updateDeck,
          };
        }
        dispatch(undoUpdateDeck(undoHistory));
        break;
      case "merge-decks":
        const fieldDeck = getRoomDeckById(state, undoHistory.id);
        if (!fieldDeck) {
          isDeleted = true;
          break;
        }
        const margeCards = getCardsByDeckAndDeck(undoHistory.after, fieldDeck);
        if (!equalCards(margeCards.deckCards, margeCards.fieldDeckCards)) {
          isChanged = true;
          break;
        }
        redoHistory = { ...undoHistory, after: fieldDeck };
        dispatch(undoMergeDecks(undoHistory));
        break;
      case "update-character":
        const character = getCharacterById(state, undoHistory.id);
        if (undoHistory.after !== null) {
          if (!character._id) {
            isDeleted = true;
            break;
          }
          redoHistory = { ...undoHistory, after: character };
        }
        dispatch(undoUpdateCharacter(undoHistory));
        break;
      case "update-field":
        const room = getState().entities.rooms.entities[roomId];
        redoHistory = { ...undoHistory, after: room };
        dispatch(undoUpdateField(undoHistory));
        break;
      case "update-note":
        isDisplaySnack = true;
        const note = getRoomNoteById(state, undoHistory.id);
        if (undoHistory.after !== null) {
          if (!note._id) {
            isDeleted = true;
            break;
          }
          redoHistory = { ...undoHistory, after: note };
        }
        dispatch(undoUpdateNote(undoHistory));
        break;
      case "update-effect":
        isDisplaySnack = true;
        const effect = getRoomEffectById(state, undoHistory.id);
        if (undoHistory.after !== null) {
          if (!effect._id) {
            isDeleted = true;
            break;
          }
          redoHistory = { ...undoHistory, after: effect };
        }
        dispatch(undoUpdateEffect(undoHistory));
        break;
      case "update-scene":
        isDisplaySnack = true;
        const scene = getRoomSceneById(state, undoHistory.id);
        if (undoHistory.after !== null) {
          if (!scene._id) {
            isDeleted = true;
            break;
          }
          redoHistory = { ...undoHistory, after: scene };
        }
        dispatch(undoUpdateScene(undoHistory));
        break;
      case "change-scene-index":
        const indexScene = getRoomSceneById(state, undoHistory.id);
        if (!indexScene._id) {
          isDeleted = true;
          break;
        }
        const sceneIds = getRoomSceneOrderedIds(state);
        const sceneIndex = sceneIds.indexOf(undoHistory.id);
        redoHistory = { ...undoHistory, afterIndex: sceneIndex };
        dispatch(undoOrderScene(undoHistory));
        break;
      case "change-item-index":
        const indexItem = getRoomItemById(state, undoHistory.id);
        if (!indexItem) {
          isDeleted = true;
          break;
        }
        const itemIds = getSortedRoomItemIds(state);
        const itemIndex = itemIds.indexOf(undoHistory.id);
        redoHistory = { ...undoHistory, afterIndex: itemIndex };
        dispatch(undoOrderItem(undoHistory));
        break;
      case "change-effect-index":
        const indexEffect = getRoomEffectById(state, undoHistory.id);
        if (!indexEffect._id) {
          isDeleted = true;
          break;
        }
        const effectIds = getRoomOrderdEffectIds(state);
        const effectIndex = effectIds.indexOf(undoHistory.id);
        redoHistory = { ...undoHistory, afterIndex: effectIndex };
        dispatch(undoOrderEffect(undoHistory));
        break;
      case "change-note-index":
        const indexNote = getRoomNoteById(state, undoHistory.id);
        if (!indexNote._id) {
          isDeleted = true;
          break;
        }
        const noteIds = getRoomOrderdNoteIds(state);
        const noteIndex = noteIds.indexOf(undoHistory.id);
        redoHistory = { ...undoHistory, afterIndex: noteIndex };
        dispatch(undoOrderNote(undoHistory));
        break;
    }

    if (isDeleted) {
      dispatch(
        appStateMutate(
          (state) =>
            (state.errorSnackbarMessage = `対象が削除されているため、元に戻すことができません。`)
        )
      );
      return;
    }
    if (isChanged) {
      dispatch(
        appStateMutate(
          (state) =>
            (state.errorSnackbarMessage = `対象の山札あるいはカード情報が変更されているため、やり直すことができません。`)
        )
      );
      return;
    }
    dispatch(popUndoAddRedo(redoHistory));
    if (IS_ALL_SNACK_MESSAGE || isDisplaySnack) {
      dispatch(
        appStateMutate(
          (state) =>
            (state.errorSnackbarMessage = `${
              MESSAGE_TEXT[undoHistory.kind]
            }を元に戻しました。`)
        )
      );
    }
  }
};

export const redo = (): DefaultThunk => (dispatch, getState) => {
  const state = getState();
  const histories = state.entities.roomHistories.redoHistories;
  const roomId = state.app.state.roomId;
  if (!roomId) return;
  if (histories.length !== 0) {
    const redoHistory = histories[histories.length - 1];
    let undoHistory: HistoryRecord = { ...redoHistory };
    let isDisplaySnack = false;
    let isDeleted = false;
    let isChanged = false;
    switch (redoHistory.kind) {
      case "update-dice":
        const dice = getRoomDiceById(state, redoHistory.id);
        if (redoHistory.before !== null) {
          if (!dice._id) {
            isDeleted = true;
            break;
          }
          undoHistory = { ...redoHistory, before: dice };
        }
        dispatch(redoUpdateDice(redoHistory));
        break;
      case "apply-scene":
        const field = getCurrentRoomField(state);
        undoHistory = { ...redoHistory, before: field };
        dispatch(redoUpdateRoom(redoHistory));
        break;
      case "update-marker":
        const marker = getRoomMarkerById(state, roomId, redoHistory.id);
        const markerExists = getRoomMarkerExists(state, roomId, redoHistory.id);
        if (redoHistory.before !== null) {
          if (!markerExists) {
            isDeleted = true;
            break;
          }
          undoHistory = { ...redoHistory, before: marker };
        }
        dispatch(redoUpdateMarker(redoHistory));
        break;
      case "update-item":
        const item = getRoomItemById(state, redoHistory.id);
        if (redoHistory.before !== null) {
          if (!item) {
            isDeleted = true;
            break;
          }
          undoHistory = { ...redoHistory, before: item };
        }
        dispatch(redoUpdateItem(redoHistory));
        break;
      case "draw-card":
        const drawDeck = getRoomDeckById(state, redoHistory.deckId);
        if (!drawDeck) {
          isDeleted = true;
          break;
        }
        if (redoHistory.after !== null) {
          const drawDeckCards = getCardsByCardsAndDeck(
            redoHistory.after,
            drawDeck
          );
          if (!equalCards(redoHistory.after, drawDeckCards)) {
            // カードを引く操作において、山札が削除済みあるいは山札内でカードの情報が変わっている場合はRedo不可
            isChanged = true;
            break;
          }
        } else if (redoHistory.before !== null) {
          const drawCards = getCardsByFieldItems(state, redoHistory.before);
          if (!equalCards(redoHistory.before, drawCards)) {
            // 山札にカードを戻す操作において、カードの情報が変わっている場合はRedo不可
            isChanged = true;
            break;
          }
          undoHistory = { ...redoHistory, before: drawCards };
        }
        dispatch(redoDrawCard(redoHistory));
        break;
      case "update-deck":
        const updateDeck = getRoomDeckById(state, redoHistory.id);
        const updateCards = getCardsByFieldItems(
          state,
          redoHistory.cards || []
        );
        if (redoHistory.before !== null) {
          if (!updateDeck) {
            isDeleted = true;
            break;
          }
          undoHistory = {
            ...redoHistory,
            before: updateDeck,
          };
        } else {
          if (!equalCards(redoHistory.cards || [], updateCards)) {
            isChanged = true;
            break;
          }
          undoHistory = {
            ...redoHistory,
            cards: updateCards,
          };
        }
        dispatch(redoUpdateDeck(redoHistory));
        break;
      case "merge-decks":
        const fieldDeck = getRoomDeckById(state, redoHistory.id);
        const fieldSourceDeck = getRoomDeckById(
          state,
          redoHistory.sourceDeck.id
        );
        if (!fieldDeck || !fieldSourceDeck) {
          isDeleted = true;
          break;
        }
        const redoDeck = getCardsByDeckAndDeck(redoHistory.before, fieldDeck);
        const sourceDeck = getCardsByDeckAndDeck(
          redoHistory.sourceDeck.deck,
          fieldSourceDeck
        );
        if (
          !equalCards(redoDeck.deckCards, redoDeck.fieldDeckCards) ||
          !equalCards(sourceDeck.deckCards, sourceDeck.fieldDeckCards)
        ) {
          isChanged = true;
          break;
        }
        undoHistory = {
          ...redoHistory,
          before: fieldDeck,
          sourceDeck: { id: redoHistory.sourceDeck.id, deck: fieldSourceDeck },
        };
        dispatch(redoMergeDecks(redoHistory));
        break;
      case "update-character":
        const character = getCharacterById(state, redoHistory.id);
        if (redoHistory.before !== null) {
          if (!character._id) {
            isDeleted = true;
            break;
          }
          undoHistory = { ...redoHistory, before: character };
        }
        dispatch(redoUpdateCharacter(redoHistory));
        break;
      case "update-field":
        const room = getState().entities.rooms.entities[roomId];
        undoHistory = { ...redoHistory, before: room };
        dispatch(redoUpdateField(redoHistory));
        break;
      case "update-note":
        isDisplaySnack = true;
        const note = getRoomNoteById(state, redoHistory.id);
        if (redoHistory.before !== null) {
          if (!note._id) {
            isDeleted = true;
            break;
          }
          undoHistory = { ...redoHistory, before: note };
        }
        dispatch(redoUpdateNote(redoHistory));
        break;
      case "update-effect":
        isDisplaySnack = true;
        const effect = getRoomEffectById(state, redoHistory.id);
        if (redoHistory.before !== null) {
          if (!effect._id) {
            isDeleted = true;
            break;
          }
          undoHistory = { ...redoHistory, before: effect };
        }
        dispatch(redoUpdateEffect(redoHistory));
        break;
      case "update-scene":
        isDisplaySnack = true;
        const scene = getRoomSceneById(state, redoHistory.id);
        if (redoHistory.before !== null) {
          if (!scene._id) {
            isDeleted = true;
            break;
          }
          undoHistory = { ...redoHistory, before: scene };
        }
        dispatch(redoUpdateScene(redoHistory));
        break;
      case "change-scene-index":
        const indexScene = getRoomSceneById(state, redoHistory.id);
        if (!indexScene._id) {
          isDeleted = true;
          break;
        }
        const sceneIds = getRoomSceneOrderedIds(state);
        const sceneIndex = sceneIds.indexOf(redoHistory.id);
        undoHistory = { ...redoHistory, beforeIndex: sceneIndex };
        console.log(redoHistory.afterIndex);
        dispatch(redoOrderScene(redoHistory));
        break;
      case "change-item-index":
        const indexItem = getRoomItemById(state, redoHistory.id);
        if (!indexItem) {
          isDeleted = true;
          break;
        }
        const itemIds = getSortedRoomItemIds(state);
        const itemIndex = itemIds.indexOf(redoHistory.id);
        undoHistory = { ...redoHistory, beforeIndex: itemIndex };
        dispatch(redoOrderItem(redoHistory));
        break;
      case "change-effect-index":
        const indexEffect = getRoomEffectById(state, redoHistory.id);
        if (!indexEffect._id) {
          isDeleted = true;
          break;
        }
        const effectIds = getRoomOrderdEffectIds(state);
        const effectIdex = effectIds.indexOf(redoHistory.id);
        undoHistory = { ...redoHistory, beforeIndex: effectIdex };
        dispatch(redoOrderEffect(redoHistory));
        break;
      case "change-note-index":
        const indexNote = getRoomNoteById(state, redoHistory.id);
        if (!indexNote._id) {
          isDeleted = true;
          break;
        }
        const noteIds = getRoomOrderdNoteIds(state);
        const noteIndex = noteIds.indexOf(redoHistory.id);
        undoHistory = { ...redoHistory, beforeIndex: noteIndex };
        dispatch(redoOrderNote(redoHistory));
        break;
    }

    if (isDeleted) {
      dispatch(
        appStateMutate(
          (state) =>
            (state.errorSnackbarMessage = `対象が削除されているため、やり直すことができません。`)
        )
      );
      return;
    }
    if (isChanged) {
      dispatch(
        appStateMutate(
          (state) =>
            (state.errorSnackbarMessage = `対象の山札あるいはカード情報が変更されているため、やり直すことができません。`)
        )
      );
      return;
    }
    dispatch(popRedoAddUndo(undoHistory));
    if (IS_ALL_SNACK_MESSAGE || isDisplaySnack) {
      dispatch(
        appStateMutate(
          (state) =>
            (state.errorSnackbarMessage = `${
              MESSAGE_TEXT[redoHistory.kind]
            }をやり直しました。`)
        )
      );
    }
  }
};

/**
 * カード情報が一致するかを検証
 * @param cardsA
 * @param cardsB
 * @returns
 */
const equalCards = (cardsA: UpdateItem[], cardsB: UpdateItem[]) => {
  if (cardsA.length !== cardsB.length) {
    // 枚数が異なる場合はUndo/Redo不可
    return false;
  }

  for (const cardA of cardsA) {
    if (!cardA._id) return false;

    const cardB = cardsB.find((cardB) => cardB._id === cardA._id);
    // 一致するIDが存在しない、あるいは対象の画像URL、MEMOが変更されている場合はUndo/Redo不可
    if (
      cardB == null ||
      cardA.imageUrl !== cardB.imageUrl ||
      cardA.memo !== cardB.memo
    ) {
      return false;
    }
  }

  return true;
};

/**
 * 山札内のカードを取得
 * @param cards
 * @param deck
 * @returns
 */
const getCardsByCardsAndDeck = (
  cards: UpdateItem[],
  deck: UpdateDeck
): UpdateItem[] => {
  const drawDeckCards: UpdateItem[] = [];
  const items = deck.items;
  if (!items) {
    return [];
  }
  cards.forEach((item) => {
    if (!item._id) return;
    const deckCard = items[item._id];
    if (deckCard) drawDeckCards.push({ ...deckCard, _id: item._id });
  });
  return drawDeckCards;
};

/**
 * 二つの山札から同じカードを取得
 * @param deck
 * @param fieldDeck
 * @returns
 */
const getCardsByDeckAndDeck = (deck: UpdateDeck, fieldDeck: UpdateDeck) => {
  const deckItems = deck.items || [];
  const deckCards: UpdateItem[] = [];
  const fieldDeckItems = fieldDeck.items || [];
  const fieldDeckCards: UpdateItem[] = [];
  Object.keys(deckItems).forEach((key) => {
    deckCards.push({ _id: key, ...deckItems[key] });
    fieldDeckCards.push({ _id: key, ...fieldDeckItems[key] });
  });

  return { deckCards, fieldDeckCards };
};

/**
 * カード情報に一致する盤面上のカードを取得
 * @param state
 * @param cards
 * @returns
 */
const getCardsByFieldItems = (
  state: DefaultRootState,
  cards: UpdateItem[]
): UpdateItem[] => {
  const drawCards: UpdateItem[] = [];
  cards.forEach((item) => {
    if (!item._id) return;
    const card = getRoomItemById(state, item._id);
    if (card) drawCards.push(card);
  });
  return drawCards;
};
