import React, { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import styled from "styled-components";
import store from "stores/interfaces";

import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  Tooltip,
} from "@mui/material";

import AddIcon from "@mui/icons-material/PersonAdd";
import ArchiveIcon from "@mui/icons-material/Archive";

import { useTranslation } from "react-i18next";
import toCDNUrl from "modules/toCDNUrl";
import { addUndoUpdateCharacter } from "stores/modules/entities.room.characters/operations";

type MyCharacterListItemProps = {
  characterId: string;
};

const MyCharacterListItem = ({ characterId }: MyCharacterListItemProps) => {
  const d = useAppDispatch();
  const [t] = useTranslation();
  const character = useAppSelector((state) =>
    store.getCharacterById(state, characterId)
  );
  const onEdit = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openRoomCharacterId = characterId;
        state.openRoomCharacter = true;
      })
    );
  }, [d, characterId]);
  const onAdd = useCallback(() => {
    d(store.updateRoomCharacter(characterId, { active: true }));
    d(addUndoUpdateCharacter(characterId, { active: true }));
  }, [d, characterId]);
  const onRemove = useCallback(() => {
    d(store.updateRoomCharacter(characterId, { active: false }));
    d(addUndoUpdateCharacter(characterId, { active: false }));
  }, [d, characterId]);
  return (
    <ListItem button dense onClick={onEdit}>
      <ListItemAvatar>
        <StyledAvatar src={toCDNUrl(character.iconUrl)} />
        {/* <StyledAvatar src="/images/0_dice/0_dice[1].png" /> */}
      </ListItemAvatar>
      <ListItemText
        primary={character.name || t("NONAME")}
        secondary={character.memo || t("NOTEXT")}
        secondaryTypographyProps={{ noWrap: true }}
      />
      <ListItemSecondaryAction>
        {character.active ? (
          <Tooltip title={t("駒を盤面から削除")}>
            <IconButton onClick={onRemove} size="large">
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={t("駒として盤面に追加")}>
            <IconButton onClick={onAdd} size="large">
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const StyledAvatar = styled(Avatar)`
  background: rgba(0, 0, 0, 0.4);
  .MuiAvatar-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: unset;
  }
  &.MuiAvatar-colorDefault {
    color: #363636;
  }
`;

export default memo(MyCharacterListItem);
