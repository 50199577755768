import { FormEvent, memo, useCallback, useRef } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { Box, Dialog, TextField, Link } from "@mui/material";
import { getCurrentRoom } from "stores/modules/entities.rooms/selectors";
import { useTranslation } from "react-i18next";
import { ApplyOptions, applyRoomPackage } from "api";

let timer: number | null = null;

const CommandLine = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const roomId = useAppSelector((state) => store.getAppState(state, "roomId"));
  const open = useAppSelector((state) =>
    store.getAppState(state, "openRoomCommandLine")
  );
  const hasParentRoomPackage = useAppSelector((state) => {
    const room = getCurrentRoom(state);
    return room?.parentRoomPackageId != null;
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isOwner = useAppSelector(store.getIsOwner);

  const onClose = useCallback(() => {
    dispatch(
      store.appStateMutate((state) => {
        state.openRoomCommandLine = false;
      })
    );
  }, [dispatch]);

  const commands = {
    log: () => console.log("log"),
    "add timer": () => dispatch(store.updateCurrentRoomFeature("timer", true)),
    "remove timer": () =>
      dispatch(store.updateCurrentRoomFeature("timer", false)),
    "show mb": () =>
      dispatch(store.updateCurrentRoomFeature("hiddenMessage", false)),
    "hide mb": () =>
      dispatch(store.updateCurrentRoomFeature("hiddenMessage", true)),
    "close items --all": () => dispatch(store.closeAllRoomItems()),
    "delete items --all": () => dispatch(store.deleteAllRoomItems()),
    "i am god": () =>
      dispatch(store.appStateMutate((state) => (state.role = "god"))),
    "i am player": () =>
      dispatch(store.appStateMutate((state) => (state.role = null))),
    "duplicate-test room": () => {
      if (
        roomId &&
        !hasParentRoomPackage &&
        window.confirm("Start duplicating this room.")
      ) {
        dispatch(store.duplicateRoom(roomId));
      }
    },
    "U2FsdGVkX1/nOHMPBsj2M4TrxjlJTlHQUAWFq2SXEHs=": () => {
      dispatch(store.insertRoomDataFromPreset("ja"));
    },
    "U2FsdGVkX1/nOHMPBsj2M4TrxjlJTlHQUAWFq2SXEHs=.en": () => {
      dispatch(store.insertRoomDataFromPreset("en"));
    },
    "U2FsdGVkX1/nOHMPBsj2M4TrxjlJTlHQUAWFq2SXEHs=.zhCN": () => {
      dispatch(store.insertRoomDataFromPreset("zhCN"));
    },
    "U2FsdGVkX1/nOHMPBsj2M4TrxjlJTlHQUAWFq2SXEHs=.zhTW": () => {
      dispatch(store.insertRoomDataFromPreset("zhTW"));
    },
    "U2FsdGVkX1/nOHMPBsj2M4TrxjlJTlHQUAWFq2SXEHs=.ko": () => {
      dispatch(store.insertRoomDataFromPreset("ko"));
    },
    "export room": () => {
      if (!isOwner) {
        window.alert(
          t("ルームをエクスポートできるのは、ルームマスターのみです")
        );
        return;
      }

      if (!hasParentRoomPackage) {
        dispatch(
          store.appStateMutate((state) => (state.openRoomExport = true))
        );
      }
    },
    "join team1": () => {
      dispatch(store.updateCurrentRoomMember({ group: "team1" }));
    },
    "join team2": () => {
      dispatch(store.updateCurrentRoomMember({ group: "team2" }));
    },
    "leave team": () => {
      dispatch(store.updateCurrentRoomMember({ group: null }));
    },
    "add dummy messages": () => {
      dispatch(store.addDummyMessages());
    },
    "start message": () => {
      if (roomId) {
        if (timer !== null) {
          window.clearInterval(timer);
          timer = null;
        }

        let i = 0;
        timer = window.setInterval(() => {
          const text = "あ" + i.toString().repeat(Math.random() * 100 + 1);
          i++;
          dispatch(store.addMessage(roomId, null, { text }, false));

          if (i >= 1000 && timer !== null) {
            window.clearInterval(timer);
            timer = null;
          }
        }, 1000);
      }
    },
    "stop message": () => {
      if (timer !== null) {
        window.clearInterval(timer);
        timer = null;
      }
      window.alert("stoped");
    },
  };

  const handlerApplyRoomPackage = useCallback(
    (command: string) => {
      const roomPackageId = command.split(" ")[1];
      if (roomId && roomPackageId) {
        window.alert(`ルームパッケージ ${roomPackageId} を適用します。`);
        applyRoomPackage(roomId, roomPackageId, APPLY_OPTIONS_ALL)
          .then(() => {
            window.alert("適用しました。");
          })
          .catch(() => {
            window.alert("適用に失敗しました。");
          });
      }
    },
    [roomId]
  );

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const input = inputRef.current?.value;
      if (input) {
        if (commands.hasOwnProperty(input)) {
          commands[input](input);
        } else if (input.startsWith("apply-room-package-test ")) {
          handlerApplyRoomPackage(input);
        }
      }
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    },
    [inputRef]
  );
  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <form onSubmit={onSubmit} autoComplete="no">
        <TextField
          label=">"
          variant="filled"
          fullWidth
          inputRef={inputRef}
          autoFocus
        />
      </form>
      <Box p={1}>
        <Link
          variant="caption"
          href="https://ccfolia.fanbox.cc/posts/1139772"
          target="_blank"
          underline="hover"
        >
          {t("開発者コマンドについて（支援者向け）")}
        </Link>
      </Box>
    </Dialog>
  );
};

const APPLY_OPTIONS_ALL: ApplyOptions = {
  room: true,
  characters: true,
  decks: true,
  effects: true,
  items: true,
  notes: true,
  scenes: true,
  savedatas: true,
};

export default memo(CommandLine);
