import React, { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import store from "stores/interfaces";
import { Dialog, Typography, DialogContent, DialogTitle } from "@mui/material";
import { DialogActions, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const UserDeleteDialog = () => {
  const d = useAppDispatch();
  const [t] = useTranslation();
  const open = useAppSelector((state) =>
    store.getAppState(state, "openUserDelete")
  );
  const onClose = useCallback(() => {
    d(
      store.appStateMutate((state) => {
        state.openUserDelete = false;
      })
    );
  }, [d]);
  const inputRef = React.useRef<null | HTMLInputElement>(null);
  const onSubmit = useCallback(() => {
    const value = inputRef.current?.value;
    if (value === "goodbye ccfolia") {
      if (window.confirm(t("本当に削除しますか？"))) {
        d(store.deleteUser());
      }
      if (inputRef.current) inputRef.current.value = "";
    } else {
      window.alert(t("入力値が間違っています"));
    }
  }, [t, d, inputRef]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {t(
          "削除されたアカウントは復旧できません。ルームデータやアップロードされたファイルなど全て利用できなくなります。"
        )}
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom variant="body2">
          {t("アカウントを削除するには「goodbye ccfolia」と入力してください。")}
        </Typography>
        <TextField
          variant="standard"
          label={t("confirmation")}
          name="delete_cmd"
          inputRef={inputRef}
          fullWidth
          margin="normal"
          color="secondary"
          autoComplete="off"
        />
        <Button
          fullWidth
          onClick={onSubmit}
          color="secondary"
          style={{ marginBottom: 16 }}
        >
          {t("アカウントを削除する")}
        </Button>
      </DialogContent>
      <DialogActions style={{ background: "rgba(0, 0, 0, 0.24)" }}>
        <Button fullWidth onClick={onClose} color="primary">
          {t("キャンセル")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(UserDeleteDialog);
