import { actions } from "./slice";
import { db } from "initializer";
import sendEvent from "modules/sendEvent";
import version from "version";
import compareVersions from "compare-versions";
import imageCompression from "browser-image-compression";
import { createSubscribeCollection } from "../firestoreModuleUtils/operators";
import { uploadFile, deleteFile } from "modules/firebase-utils/uploader";
import { getUserFileByHash, getCurrentUserFileIds } from "./selectors";
import { getAppState } from "../app.state/selectors";
import { appStateMutate } from "../app.state/operations";
import {
  addRoomItem,
  updateCurrentRoomItem,
} from "../entities.room.items/operations";
import { updateCurrentRoomEffect } from "../entities.room.effects/operations";
import {
  addRoomMarker,
  updateCurrentRoom,
  updateCurrentRoomField,
  updateCurrentRoomMarker,
} from "../entities.rooms/operations";
import sha256 from "crypto-js/sha256";
import TypedArrays from "crypto-js/lib-typedarrays";
import { DefaultThunk } from "stores";
import { getUid } from "../app.user/selectors";
import { UserFile } from "./records";
import {
  CollectionReference,
  DocumentReference,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { wrap } from "comlink";
import { updateCurrentRoomDeck } from "../entities.room.decks/operations";

export const toSHA256hash = (file: File) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(
        sha256(
          TypedArrays.create(reader.result as unknown as number[])
        ).toString() // TODO: fix type
      );
    };
    reader.readAsArrayBuffer(file);
  });
};

export const tinyPNG = async (file: File): Promise<File> => {
  const worker = new Worker(new URL("modules/upng.worker", import.meta.url));
  const upngOnWebWorker = wrap<(file: File) => Promise<File>>(worker);

  const compressed = await upngOnWebWorker(file).catch(() => file);
  worker.terminate();
  return compressed;
};

const filesRef = (uid: string) =>
  collection(db, "users", uid, "files") as CollectionReference<UserFile>;

export const subscribeUserFiles = createSubscribeCollection(
  actions,
  ({ uid, roomId }: { uid: string; roomId: string }) =>
    query(
      filesRef(uid),
      where("archived", "==", false),
      where("roomId", "==", roomId)
    )
);

export const getAllUserFiles =
  (uid: string, roomId: string): DefaultThunk =>
  async (dispatch) => {
    const snapshot = await getDocs(
      query(
        filesRef(uid),
        where("archived", "==", false),
        orderBy("updatedAt", "desc"),
        limit(3000)
      )
    );
    const addActions = snapshot.docs.map((doc) => {
      return actions.add({
        id: doc.id,
        entity: {
          ...(doc.data() as UserFile),
          _id: doc.id,
        },
      });
    });

    for (const action of addActions) {
      dispatch(action);
    }
  };

const LIMIT_USER_FILE_SIZE = 5 * 1024 * 1024; // 5 MiB

// new api
export const addCurrentUserFile =
  (
    _file: File,
    dir: UserFile["dir"]
  ): DefaultThunk<
    Promise<
      | {
          doc: DocumentReference;
          file: File;
          url: string | null;
        }
      | undefined
      | null
    >
  > =>
  async (_, getState) => {
    sendEvent("addUserFile");
    const state = getState();
    const roomId = getAppState(state, "roomId");
    const uid = getUid(state);
    if (!roomId || !uid || !dir) return null;
    let file: File = _file.type === "image/png" ? await tinyPNG(_file) : _file;
    if (
      file.size > 1024 * 1024 &&
      window.confirm(
        "ファイルサイズが1Mbを超えています。データを圧縮してアップロードしてもよろしいですか？"
      )
    ) {
      file = await imageCompression(_file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      });
    }

    if (file.size >= LIMIT_USER_FILE_SIZE) {
      window.alert(
        "ファイルサイズが5MBを超えているため、アップロードに失敗しました。"
      );
      return;
    }

    const hash: string = await toSHA256hash(file);
    const localFile = getUserFileByHash(getState(), { dir, hash });
    if (localFile) {
      // async override roomId
      const ref = doc(filesRef(uid), localFile._id);
      updateDoc(ref, { roomId, archived: false, updatedAt: Date.now(), dir });
      return { url: localFile.url, file, doc: ref };
    } else {
      const docSnapshot = await getDoc(doc(filesRef(uid), hash));
      const data = docSnapshot.exists() ? docSnapshot.data() : null;
      if (data?.url) {
        updateDoc(docSnapshot.ref, {
          roomId,
          archived: false,
          updatedAt: Date.now(),
          dir,
        });
        return { url: data?.url || null, file, doc: docSnapshot.ref };
      }
    }
    return uploadFile(
      file,
      {
        collectionRef: filesRef(uid),
        data: {
          dir,
          roomId,
          owner: uid,
          hash,
        },
      },
      hash
    );
  };

export const deleteUserFile2 =
  (imageId: string): DefaultThunk =>
  (dispatch, getState) => {
    sendEvent("deleteUserFile");
    const uid = getAppState(getState(), "uid");
    if (!uid) return Promise.reject();
    dispatch(actions.remove(imageId));
    return deleteFile(doc(filesRef(uid), imageId));
  };

export const deleteUserFiles =
  (imageIds: string[]): DefaultThunk =>
  async (dispatch, getState) => {
    sendEvent("deleteUserFiles");
    const uid = getAppState(getState(), "uid");
    if (!uid || imageIds.length === 0) return;

    const deleteFiles = imageIds.map((imageId) =>
      deleteFile(doc(filesRef(uid), imageId))
    );
    await Promise.all(deleteFiles);

    const removeActions = imageIds.map((imageId) => {
      return actions.remove(imageId);
    });

    for (const action of removeActions) {
      dispatch(action);
    }
  };

export const archiveUserFile =
  (imageId: string): DefaultThunk =>
  (dispatch, getState) => {
    sendEvent("archiveUserFile");
    const uid = getAppState(getState(), "uid");
    if (!uid) return Promise.reject();
    dispatch(actions.remove(imageId));
    return setDoc(
      doc(filesRef(uid), imageId),
      {
        archived: true,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
  };

export const archiveCurrentUserFiles =
  (): DefaultThunk => (dispatch, getState) => {
    sendEvent("archiveUserFile");
    const state = getState();
    const uid = getAppState(state, "uid");
    const imageIds = getCurrentUserFileIds(state);
    if (!uid) return Promise.reject();
    const batch = writeBatch(db);
    imageIds.forEach((imageId) => {
      batch.update(doc(filesRef(uid), imageId), { archived: true });
    });
    const removeActions = imageIds.map((imageId) => {
      return actions.remove(imageId);
    });
    for (const action of removeActions) {
      dispatch(action);
    }

    return batch.commit();
  };

export const migrateUserFiles =
  (): DefaultThunk => async (dispatch, getState) => {
    const state = getState();
    const uid = getAppState(state, "uid");
    if (!uid) return;
    const storeVersion = getAppState(state, "storeVersion");
    if (storeVersion && compareVersions(storeVersion, version) >= 0) return;
    sendEvent("migrateUserFile");
    const snapshot = await getDocs(filesRef(uid));
    for (let i = 0; i < snapshot.docs.length; i++) {
      const doc = snapshot.docs[i];
      const data = doc.data();
      if (data.archived === undefined) {
        await setDoc(doc.ref, { archived: false }, { merge: true });
      }
    }
    dispatch(
      appStateMutate((draft) => {
        draft.storeVersion = version;
      })
    );
    // snapshot.docs.map((doc) => {
    //   doc.ref.set({ archived: false }, { merge: true });
    // });
  };

type Position = {
  x: number;
  y: number;
};

const actionsMap = {
  "item/new":
    (imageUrl: string | null, position?: Position): DefaultThunk =>
    (dispatch) => {
      return dispatch(
        addRoomItem({ imageUrl, x: position?.x, y: position?.y })
      );
    },
  "item/update":
    (imageUrl: string | null): DefaultThunk =>
    (dispatch) => {
      return dispatch(updateCurrentRoomItem({ imageUrl }));
    },
  "item/updateCover":
    (coverImageUrl: string | null): DefaultThunk =>
    (dispatch) => {
      return dispatch(updateCurrentRoomItem({ coverImageUrl }));
    },
  "marker/new":
    (imageUrl: string | null, position?: Position): DefaultThunk =>
    (dispatch) => {
      return dispatch(
        addRoomMarker({ imageUrl, x: position?.x, y: position?.y })
      );
    },
  "marker/update":
    (imageUrl: string | null): DefaultThunk =>
    (dispatch) => {
      return dispatch(updateCurrentRoomMarker({ imageUrl }));
    },
  "background/set":
    (imageUrl: string | null): DefaultThunk =>
    (dispatch) => {
      return dispatch(updateCurrentRoomField({ backgroundUrl: imageUrl }));
    },
  "foreground/set":
    (imageUrl: string | null): DefaultThunk =>
    (dispatch) => {
      return dispatch(updateCurrentRoomField({ foregroundUrl: imageUrl }));
    },
  "thumbnail/set":
    (imageUrl: string | null): DefaultThunk =>
    (dispatch) => {
      return dispatch(updateCurrentRoom({ thumbnailUrl: imageUrl }));
    },
  // "character/set": imageUrl => dispatch => {
  //   return dispatch(updateCurrentRoomCharacter({ iconUrl: imageUrl }));
  // },
  "effect/set":
    (imageUrl: string | null): DefaultThunk =>
    (dispatch) => {
      return dispatch(updateCurrentRoomEffect({ imageUrl: imageUrl }));
    },
  "deck/updateCoverImage":
    (imageUrl: string | null): DefaultThunk =>
    (dispatch) => {
      return dispatch(
        updateCurrentRoomDeck({ coverImageUrl: imageUrl ? imageUrl : null })
      );
    },
};

export const selectUserFile =
  (imageUrl: string | null): DefaultThunk =>
  (dispatch, getState) => {
    const state = getState();
    const target = getAppState(state, "openRoomImageSelectTarget");
    if (target && actionsMap[target]) {
      const fromContextMenu = getAppState(state, "fromContextMenu");
      const cellPosition = getAppState(state, "contextMouseCellPosition");

      // ContextMenuから発火されている場合は、その位置を加味する
      if (fromContextMenu) {
        dispatch(actionsMap[target](imageUrl, cellPosition));
        dispatch(
          appStateMutate((state) => {
            state.fromContextMenu = false;
          })
        );
        return;
      }
      dispatch(actionsMap[target](imageUrl));
    }
  };
