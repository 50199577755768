import React, { FC } from "react";
import Deck from "../Deck";
import { useAppSelector } from "stores";
import store from "stores/interfaces";

const Decks: FC = () => {
  // todo
  const deckIds = useAppSelector(store.getRoomDeckIds);
  return (
    <>
      {deckIds.map((deckId) => (
        <Deck deckId={deckId} key={deckId} />
      ))}
    </>
  );
};

export default Decks;
