import { DefaultRootState } from "stores";
import { DocumentReference, Query, onSnapshot } from "firebase/firestore";
import { DefaultThunk } from "stores";
import { createEntitySlice, createEntitySliceGroupBy } from "./slice";

type QueryCreator<T, U> = (
  props: T,
  state: DefaultRootState
) => Query<U> | null;

export const createSubscribeCollection =
  <T, P>(
    actions:
      | ReturnType<typeof createEntitySliceGroupBy<T>>["actions"]
      | ReturnType<typeof createEntitySlice<T>>["actions"],
    queryCreator: QueryCreator<P, T>,
    reversed: boolean = false
  ) =>
  (props: P): DefaultThunk<() => void> =>
  (dispatch, getState) => {
    const query = queryCreator(props, getState());
    if (!query) return () => {};

    const unsubscribe = onSnapshot(query, (snapshot) => {
      const changes = snapshot.docChanges();
      if (reversed) {
        changes.reverse();
      }
      const bachedActions = changes.map(({ type, doc }) => {
        switch (type) {
          case "added": {
            return actions.add({
              id: doc.id,
              entity: {
                _id: doc.id,
                ...doc.data({ serverTimestamps: "previous" }),
              },
            });
          }
          case "modified": {
            return actions.update({
              id: doc.id,
              entity: {
                _id: doc.id,
                ...doc.data({ serverTimestamps: "previous" }),
              },
            });
          }
          case "removed": {
            return actions.remove(doc.id);
          }
          default: {
            return null;
          }
        }
      });

      for (const action of bachedActions) {
        if (action) {
          dispatch(action);
        }
      }
    });

    return () => {
      unsubscribe();
      dispatch(actions.init());
    };
  };

type DocSelector<T> = (
  props: T,
  state: DefaultRootState
) => DocumentReference | null;

export const createSubscribeDocument =
  <T, P>(
    actions:
      | ReturnType<typeof createEntitySliceGroupBy<T>>["actions"]
      | ReturnType<typeof createEntitySlice<T>>["actions"],
    docSelector: DocSelector<P>
  ) =>
  (props: P): DefaultThunk<() => void> =>
  (dispatch, getState) => {
    const query = docSelector(props, getState());
    if (!query) return () => {};
    return onSnapshot(query, (doc) => {
      dispatch(
        actions.update({
          id: doc.id,
          entity: {
            _id: doc.id,
            ...(doc.data({ serverTimestamps: "previous" }) as T),
          },
        })
      );
    });
  };
