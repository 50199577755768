import { actions } from "./slice";
import { db } from "initializer";
import { createSubscribeCollection } from "../firestoreModuleUtils/operators";
import { DefaultThunk } from "stores";
import { getAppState } from "../app.state/selectors";
import { getUid } from "../app.user/selectors";
import {
  collection,
  deleteDoc,
  doc,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

const historiesRef = (uid: string) => collection(db, "users", uid, "histories");

export const subscribeUserHistories = createSubscribeCollection(
  actions,
  (uid: string) => query(historiesRef(uid), orderBy("visitedAt"), limit(10))
);

export const recordCurrentUserHistory = (): DefaultThunk => (_, getState) => {
  const state = getState();
  const roomId = getAppState(state, "roomId");
  const uid = getUid(state);
  if (!roomId || !uid) return null;
  return setDoc(doc(historiesRef(uid), roomId), {
    visitedAt: serverTimestamp(),
  });
};

export const deleteUserHistory =
  (roomId: string): DefaultThunk =>
  (_, getState) => {
    const state = getState();
    const uid = getUid(state);
    if (!uid) return null;
    return deleteDoc(doc(historiesRef(uid), roomId));
  };
