import { Marker, MarkersRecord, UpdateMarker } from "../entities.rooms";

export type Scene = {
  _id?: string;
  name: string;
  text: string;
  backgroundUrl: string | null;
  foregroundUrl: string | null;
  fieldObjectFit: "fill" | "cover";
  fieldWidth: number;
  fieldHeight: number;
  displayGrid: boolean;
  gridSize: number;
  mediaName: string;
  mediaUrl: string | null;
  mediaType: string;
  mediaVolume: number;
  mediaRepeat: boolean;
  soundName: string;
  soundUrl: string | null;
  soundVolume: number;
  soundRepeat: boolean;
  locked: boolean;
  order: number;
  markers: Record<string, Marker>;
  updatedAt: number;
  createdAt: number;
};
export type UpdateScene = {
  _id?: string;
  name?: string;
  text?: string;
  backgroundUrl?: string | null;
  foregroundUrl?: string | null;
  fieldObjectFit?: "fill" | "cover";
  fieldWidth?: number;
  fieldHeight?: number;
  displayGrid?: boolean;
  gridSize?: number;
  mediaName?: string;
  mediaUrl?: string | null;
  mediaType?: string;
  mediaVolume?: number;
  mediaRepeat?: boolean;
  soundName?: string;
  soundUrl?: string | null;
  soundVolume?: number;
  soundRepeat?: boolean;
  locked?: boolean;
  order?: number;
  markers?: Record<string, UpdateMarker>;
  updatedAt?: number;
  createdAt?: number;
};

export const SceneRecord = (data: UpdateScene): Scene => {
  return {
    name: data.name || "",
    backgroundUrl: data.backgroundUrl || null,
    foregroundUrl: data.foregroundUrl || null,
    fieldObjectFit: data.fieldObjectFit || "fill",
    fieldWidth: data.fieldWidth ?? 40,
    fieldHeight: data.fieldHeight ?? 30,
    displayGrid: data.displayGrid ?? false,
    gridSize: data.gridSize ?? 1,
    markers: data.markers ? MarkersRecord(data.markers) : {},
    text: data.text || "",
    mediaName: data.mediaName || "",
    mediaUrl: data.mediaUrl || null,
    mediaType: data.mediaType || "",
    mediaRepeat: data.mediaRepeat ?? true,
    mediaVolume: data.mediaVolume ?? 0.5,
    soundName: data.soundName || "",
    soundUrl: data.soundUrl || null,
    soundRepeat: data.soundRepeat || false,
    soundVolume: data.soundVolume ?? 0.5,
    locked: false,
    order: data.order || 0,
    createdAt: data.createdAt || 0,
    updatedAt: data.updatedAt || 0,
  };
};
