import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { auth } from "initializer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReauthenticateDialog from "./ReauthenticateDialog";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "stores";
import { verifyBeforeUpdateEmail } from "firebase/auth";

type ChangeEmailDialogProps = {
  open: boolean;
  onClose: () => void;
};

type FormParams = {
  email: string;
};

const ChangeEmailDialog = ({ open, onClose }: ChangeEmailDialogProps) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const reauthed = useRef(false);
  const [openMain, setOpenMain] = useState(false);
  const [openReauth, setOpenReauth] = useState(false);

  const onReauthenticated = useCallback(() => {
    reauthed.current = true;
    setOpenReauth(false);
    setOpenMain(true);
  }, [reauthed, setOpenMain, setOpenReauth]);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<FormParams>({
    defaultValues: { email: "" },
    reValidateMode: "onChange",
  });
  const { errorMessage, onError, resetError } = useFirebaseAuthError();

  useEffect(() => {
    reset();
    resetError();
    if (!open) {
      setOpenMain(false);
      setOpenReauth(false);
    } else if (reauthed.current) {
      setOpenReauth(false);
      setOpenMain(true);
    } else {
      setOpenMain(false);
      setOpenReauth(true);
    }
  }, [open, reauthed, dispatch, setOpenMain, setOpenReauth, reset, resetError]);

  const onSubmit = useCallback(
    ({ email }: FormParams) => {
      if (!auth.currentUser) {
        return;
      }

      verifyBeforeUpdateEmail(auth.currentUser, email)
        .then(() => {
          window.alert(
            t(
              "{{email}} に確認メールを送信しました。メールに記載されたURLにアクセスして確認が完了すると、ログインメールアドレスが更新されます。\n\n変更が完了すると自動でログアウトされるので、新しいログインメールアドレスで再ログインしてください。",
              { email }
            )
          );
          onClose();
        })
        .catch((error) => {
          if (error?.code === "auth/requires-recent-login") {
            reauthed.current = false;
            setOpenMain(false);
            setOpenReauth(true);
            reset();
            resetError();
            window.alert(t("再認証が必要です。"));
            return;
          }

          onError(error);
        });
    },
    [
      t,
      reset,
      reauthed,
      setOpenMain,
      setOpenReauth,
      onClose,
      onError,
      resetError,
    ]
  );

  return (
    <>
      <Dialog open={openMain} onClose={onClose} maxWidth="sm">
        <DialogTitle>
          {t("メールアドレスを変更")}
          <IconButton
            onClick={onClose}
            style={{ position: "absolute", right: 6, top: 6 }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ paddingTop: 0, minWidth: "300px" }}>
            <TextField
              variant="standard"
              type="email"
              label={t("新しいメールアドレス")}
              fullWidth
              style={{ marginBottom: "16px" }}
              {...register("email", { required: true })}
            />
            {errorMessage && (
              <Typography color="error" variant="body2" paragraph>
                {errorMessage}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} fullWidth color="secondary">
              {t("キャンセル")}
            </Button>
            <Button type="submit" disabled={!isValid} fullWidth color="primary">
              {t("変更")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ReauthenticateDialog
        open={openReauth}
        onCancelled={onClose}
        onReauthenticated={onReauthenticated}
      />
    </>
  );
};

const useFirebaseAuthError = () => {
  const [t] = useTranslation();
  const [errorCode, setErrorCode] = useState<string>("");
  const onError = useCallback(
    (error: any) => {
      if (typeof error?.code === "string") {
        setErrorCode(error.code);
      } else {
        setErrorCode("unknown-error");
      }
    },
    [setErrorCode]
  );
  const resetError = useCallback(() => {
    setErrorCode("");
  }, [setErrorCode]);
  const errorMessage: string = useMemo(() => {
    if (!errorCode) {
      return "";
    }

    switch (errorCode) {
      case "auth/email-already-in-use":
        return t("このメールアドレスはすでに使われています。");
      case "auth/invalid-email":
        return t("メールアドレスの形式に誤りがあります。");
      default:
        return t("不明なエラーが発生しました。") + `(${errorCode})`;
    }
  }, [errorCode, t]);

  return { errorMessage, onError, resetError };
};

export default ChangeEmailDialog;
