import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Message } from "./records";
import { Timestamp } from "firebase/firestore";

type MessagesState = {
  ids: string[];
  addedIds: string[];
  idsGroupBy: Record<string, string[]>;
  entities: Record<string, Message>;
};

const initializeState = (): MessagesState => {
  return {
    ids: [],
    addedIds: [],
    idsGroupBy: {},
    entities: {},
  };
};

const startTime = Timestamp.now();
const TARGET_CHANNELS = ["main", "info"];

const slice = createSlice({
  name: "roomMessages",
  initialState: initializeState(),
  reducers: {
    init() {
      return initializeState();
    },
    add(
      state,
      action: PayloadAction<{ id: string; entity: Message; reversed?: boolean }>
    ) {
      const { id, entity, reversed = false } = action.payload;
      if (id in state.entities) {
        return;
      }

      const group = entity.channel;
      if (!(group in state.idsGroupBy)) {
        state.idsGroupBy[group] = [];
      }

      if (reversed) {
        state.ids.unshift(id);
        state.idsGroupBy[group].unshift(id);
      } else {
        state.ids.push(id);
        state.idsGroupBy[group].push(id);
      }
      (state.entities as Record<string, Message>)[id] = entity;

      const isSystemMessage = entity.type === "system";
      const isTargetMessage = TARGET_CHANNELS.includes(entity.channel);
      if (
        (entity.createdAt === null || entity.createdAt > startTime) &&
        !isSystemMessage &&
        isTargetMessage
      ) {
        state.addedIds.push(id);
      }
    },
    update(state, action: PayloadAction<{ id: string; entity: Message }>) {
      const { id, entity } = action.payload;
      const group = entity.channel;

      if (!(group in state.idsGroupBy)) {
        state.idsGroupBy[group] = [];
      }

      if (!(id in state.entities)) {
        // add
        state.ids.push(id);
        state.idsGroupBy[group].push(id);
        (state.entities as Record<string, Message>)[id] = entity;
        return;
      }

      // update
      const prevGroup = state.entities[id].channel;
      if (group !== prevGroup) {
        removeId(state.idsGroupBy[prevGroup], id);
        state.idsGroupBy[group].push(id);
      }

      (state.entities as Record<string, Message>)[id] = entity;
    },
    remove(state, action: PayloadAction<string>) {
      const id = action.payload;

      if (state.entities[id]) {
        state.entities[id].removed = true;
      }
    },
    shiftAddedRoomMessage(state) {
      state.addedIds.shift();
    },
  },
});

const removeId = (ids: string[] | undefined, id: string) => {
  if (ids == null) {
    return;
  }

  const index = ids.indexOf(id);
  if (index >= 0) {
    ids.splice(index, 1);
  }
};

export const reducer = slice.reducer;
export const actions = slice.actions;
