import { DefaultThunk } from "stores";
import {
  addRoomDiceById,
  deleteRoomDice,
  updateCurrentRoomDice,
} from "stores/modules/entities.room.dices/operations";
import { UpdateDiceItem } from "stores/modules/entities.room.dices";

export type UpdateDiceRecord = {
  kind: "update-dice";
  id: string;
  before: UpdateDiceItem | null;
  after: UpdateDiceItem | null;
  hasChangeValueMessage?: boolean;
};

export const undoUpdateDice =
  (history: UpdateDiceRecord): DefaultThunk =>
  (dispatch, _) => {
    const before = history.before;
    if (!before) {
      // 変更前データがない場合は追加が行われたとして削除処理
      dispatch(deleteRoomDice(history.id));
      return;
    }

    if (history.hasChangeValueMessage) {
      // ダイスの出目や公開設定が変更されている場合はメッセージ送信の必要な更新処理
      dispatch(updateCurrentRoomDice(before, history.id));
    } else {
      // それ以外の場合は通常の更新処理
      dispatch(addRoomDiceById(history.id, before));
    }
    return;
  };

export const redoUpdateDice =
  (history: UpdateDiceRecord): DefaultThunk =>
  (dispatch, _) => {
    const after = history.after;
    if (!after) {
      // 変更後データがない場合は削除処理
      dispatch(deleteRoomDice(history.id));
      return;
    }

    if (history.hasChangeValueMessage) {
      // ダイスの出目や公開設定が変更されている場合はメッセージ送信の必要な更新処理
      dispatch(updateCurrentRoomDice(after, history.id));
    } else {
      // それ以外の場合は通常の更新処理
      dispatch(addRoomDiceById(history.id, after));
    }
    return;
  };
