// https://github.com/react-grid-layout/react-draggable/issues/219
export const fixSelectionOnDragStop = () => {
  setTimeout(() => {
    //https://github.com/clauderic/dnd-kit/blob/e2a1776d0de657669192d3cfd1558e91905b5fad/packages/core/src/sensors/pointer/AbstractPointerSensor.ts#L151

    const selection = window.getSelection();
    if (selection && document.activeElement) {
      const range = document.createRange();
      range.selectNodeContents(document.activeElement);

      selection.removeAllRanges();
      selection.addRange(range);
      selection.collapse(document.activeElement);
    }
  }, 60);
};
