import { auth } from "initializer";
import { useEffect } from "react";
import { useAppDispatch } from "stores";
import { useTranslation } from "react-i18next";
import store from "stores/interfaces";
import { getRedirectResult } from "firebase/auth";
import { updateUserProfile } from "stores/modules/app.user/operations";

const Auth: React.FC = () => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  useEffect(() => {
    getRedirectResult(auth)
      .then((user) => {
        // アカウント作成時にSNSのアイコンが設定されていたら、使わないようにする。
        if (
          user != null &&
          user.operationType === "link" &&
          user.user.providerData.length === 1 &&
          user.user.photoURL != null
        ) {
          dispatch(updateUserProfile({ photoURL: null }));
        }
      })
      .catch((r) => {
        if (
          r?.code === "auth/credential-already-in-use" ||
          r?.code === "auth/email-already-in-use"
        ) {
          window.alert(t("このアカウントは既に他のユーザーに連携されています"));
          dispatch(
            store.appStateMutate((state) => {
              state.registered = true;
            })
          );
        } else {
          window.alert(t("アカウント情報の取得に失敗しました"));
        }
      });
  }, [dispatch, t]);
  return null;
};

export default Auth;
