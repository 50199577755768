import { DefaultThunk } from "stores";
import { UpdateItem } from "stores/modules/entities.room.items";
import { undoRedoReorderItems } from "stores/modules/entities.room.items/operations";

export type OrderItemRecord = {
  kind: "change-item-index";
  id: string;
  beforeIndex: number;
  afterIndex: number;
  item: UpdateItem;
};

export const undoOrderItem =
  (history: OrderItemRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;

    dispatch(
      undoRedoReorderItems(
        roomId,
        history.id,
        history.beforeIndex,
        history.item
      )
    );
    return;
  };

export const redoOrderItem =
  (history: OrderItemRecord): DefaultThunk =>
  (dispatch, getState) => {
    const roomId = getState().app.state.roomId;
    if (!roomId) return;

    dispatch(
      undoRedoReorderItems(roomId, history.id, history.afterIndex, history.item)
    );
    return;
  };
