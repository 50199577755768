import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { getGamesProductsInLibrary } from "api";
import Footer from "containers/Footer";
import { useTranslation } from "react-i18next";
import LibraryItem from "./LibraryItem";
import useSWR from "swr";
import { useAppSelector } from "stores";
import { getAuthedUid } from "stores/modules/app.user/selectors";
import LibraryItemDefault from "./LibraryItemDefault";

const Library = () => {
  const [t] = useTranslation();
  const uid = useAppSelector(getAuthedUid);

  const {
    data: products,
    isLoading,
    error,
  } = useSWR(
    uid ? ["getGamesProductsInLibrary", uid] : null,
    getGamesProductsInLibrary
  );

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" marginTop="64px">
        <CircularProgress />
      </Box>
    );
  }

  if (!products || error) {
    return (
      <Container style={{ marginTop: "64px" }}>
        <Typography color="textPrimary" align="center">
          {t("データの取得に失敗しました。")}
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <Box margin="16px" minHeight="70vh">
        <Grid container spacing={2} alignItems="stretch">
          {products
            .filter((product) => product.kind === "game")
            .map((product) => (
              <LibraryItem
                key={product.id}
                productId={product.id}
                name={product.name}
                thumbnailUrl={product.thumbnailUrl}
              />
            ))}
          <LibraryItemDefault />
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default memo(Library);
